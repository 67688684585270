import Layout from "../../../../components/Layout";
import styled from "styled-components";

const FueraNorma = () => {
    return (
        <Layout>
            <StyledH1>Informe Fuera de Norma por Inspección</StyledH1>
        </Layout>
    )
}

const StyledH1 = styled.h1`
    color: #64766A;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
`;

export default FueraNorma;