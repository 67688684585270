import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { toast } from "react-toastify";
import useError from "../helpers/useError";

import {
  getCalibreDetallesService,
  getCalibreDetalleByEspecieExportadoraService,
  createCalibreDetalleService,
  updateCalibreDetalleService,
} from "../../services/calibreDetalles";

export default function useCalibres() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;
  const { getStatusCodeError } = useError();

  const getCalibreDetalles = useCallback(
    ({ rowsPerPage, page, calibre }) => {
      const data = {
        cliente: client.id,
      };
      return getCalibreDetallesService({
        token,
        rowsPerPage,
        page,
        data,
        calibre,
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getCalibreDetalleByEspecieExportadora = useCallback(
    ({ especie, exportadora, calibreId }) => {
      const data = {
        cliente: client.id,
        especie,
        exportadora,
        calibre: calibreId,
      };
      return getCalibreDetalleByEspecieExportadoraService({
        token,
        data,
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createCalibreDetalle = useCallback(
    ({dataInput, calibre}) => {
      const data = {
        cliente: client.id,
      };
      return createCalibreDetalleService({ token, data, dataInput, calibre })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Calibre Detalle creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
            return getStatusCodeError(err.response.status);
        });
    },
    [token, client, getStatusCodeError]
  );

  const updateCalibreDetalle = useCallback(
    (data) => {
      return updateCalibreDetalleService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Calibre Detalle actualizado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
            return getStatusCodeError(err.response.status);
        });
    },
    [token, getStatusCodeError]
  );

  return {
    getCalibreDetalles,
    getCalibreDetalleByEspecieExportadora,
    createCalibreDetalle,
    updateCalibreDetalle,
  };
}
