const ControlProductoTerminadoIcon = (props) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3889 4.26802L15.7319 1.61102C15.2224 1.0986 14.6164 0.692361 13.9488 0.415827C13.2812 0.139292 12.5654 -0.00203834 11.8429 2.22109e-05H5.49988C4.04167 0.00161038 2.64365 0.581583 1.61254 1.61269C0.581439 2.6438 0.0014661 4.04182 -0.00012207 5.50002V18.5C0.0014661 19.9582 0.581439 21.3563 1.61254 22.3874C2.64365 23.4185 4.04167 23.9984 5.49988 24H14.4999C15.9581 23.9984 17.3561 23.4185 18.3872 22.3874C19.4183 21.3563 19.9983 19.9582 19.9999 18.5V8.15702C20.0018 7.43447 19.8604 6.7187 19.5839 6.05116C19.3074 5.38361 18.9012 4.77754 18.3889 4.26802ZM14.4999 21H5.49988C4.83684 21 4.20095 20.7366 3.73211 20.2678C3.26327 19.7989 2.99988 19.1631 2.99988 18.5V5.50002C2.99988 4.83698 3.26327 4.2011 3.73211 3.73226C4.20095 3.26341 4.83684 3.00002 5.49988 3.00002H10.9999V7.00002C10.9999 7.53046 11.2106 8.03916 11.5857 8.41424C11.9607 8.78931 12.4694 9.00002 12.9999 9.00002H16.9999V18.5C16.9999 19.1631 16.7365 19.7989 16.2676 20.2678C15.7988 20.7366 15.1629 21 14.4999 21ZM15.0859 11.466C15.36 11.7541 15.5085 12.1392 15.4987 12.5368C15.489 12.9343 15.3218 13.3117 15.0339 13.586L11.4479 17C10.7893 17.6449 9.90297 18.004 8.98124 17.9994C8.05951 17.9947 7.17684 17.6266 6.52488 16.975L4.99988 15.62C4.8528 15.4887 4.73302 15.3297 4.64739 15.1521C4.56176 14.9745 4.51195 14.7817 4.50081 14.5849C4.48967 14.388 4.51741 14.1909 4.58245 14.0047C4.64749 13.8186 4.74856 13.6471 4.87988 13.5C5.0112 13.3529 5.17021 13.2332 5.34782 13.1475C5.52543 13.0619 5.71816 13.0121 5.91503 13.001C6.11189 12.9898 6.30901 13.0176 6.49515 13.0826C6.68129 13.1476 6.8528 13.2487 6.99988 13.38L8.58588 14.794C8.63723 14.8547 8.70043 14.9043 8.77162 14.9397C8.84281 14.9752 8.92048 14.9957 8.99988 15C9.13223 15 9.25917 14.9475 9.35288 14.854L12.9659 11.414C13.254 11.1399 13.6391 10.9914 14.0366 11.0012C14.4342 11.0109 14.8116 11.1781 15.0859 11.466Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlProductoTerminadoIcon;