import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useCumple from "../../../../hooks/api/useCumple";

const ColorClientesForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  const { getCumple } = useCumple();
  const [cumple, setCumple] = useState([]);

  useEffect(() => {
    getCumple().then((res) => {
      setCumple(res.results);
    });
  }, [getCumple]);

  const handleValue = (e) => {
    setDataInput({
      ...dataInput,
      [e.target.name]: e.target.value
    });
  };
  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="ColorCliente"
    >
      <InputContainer>
        <StyledLabel>Color Fondo</StyledLabel>
        <StyledTextInputs
          type="color"
          placeholder="Color"
          name="color"
          defaultValue={dataInput.color !== "" ? dataInput.color : null}
          onChange={handleValue}
        />
      </InputContainer>
      <InputContainer>
        <StyledLabel>Color Texto</StyledLabel>
        <StyledTextInputs
          type="color"
          placeholder="Color Texto"
          name="color_texto"
          defaultValue={dataInput.color_texto !== "" ? dataInput.color_texto : null}
          onChange={handleValue}
        />
      </InputContainer>
      <InputContainer>
        <StyledLabel>CUMPLE</StyledLabel>
        <StyledSelected
          name="cumple"
          defaultValue={dataInput.cumple !== undefined ? dataInput.cumple : null}
          onChange={handleValue}
        >
          {cumple.map((item) => (
            <StyledOption value={item.id}>{item.nombre}</StyledOption>
          ))}
        </StyledSelected>
      </InputContainer>
    </ModalForm>
  );
};

export default ColorClientesForm;

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #000000;
  opacity: 0.5;
  margin-left: 30px;
`;
