import Navbar from "../../components/Navbar";

const Testigos = () => {
    return (
        <div>
            <Navbar active link="https://rioblanco.inglobochile.cl/Reportes/Reporte_Cajas_Testigo/FrontIndex.php"/>
        </div>
    )
}

export default Testigos;