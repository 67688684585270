import React from "react";
import styled from "styled-components";

const CardItem = ({ data }) => {
  return (
    // Create a card item with the data and a delete button with styled components
    <CardItemContainer>
      {
        // if data is not empty, show the data in the card item else show a message
        data?.map((item) => (
          <CardItemContent
            key={item.parametro !== undefined ? item.parametro.id : item.id}
          >
            <CardItemContentText>
              <CardItemTitle>
                {item.parametro !== undefined ? item.parametro.nombre : item.nombre}
              </CardItemTitle>
            </CardItemContentText>
            <CardItemContentButton>
              <CardItemButton>Desactivar</CardItemButton>
            </CardItemContentButton>
          </CardItemContent>
        ))
      }
    </CardItemContainer>
  );
};

const CardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

const CardItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #ff9100;
  height: 50px;
  margin: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #f2f2f2;
    border: 1px solid #64766a;
  }

  &:active {
    background-color: #e2e2e2;
  }
`;

const CardItemContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const CardItemTitle = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 62% */

  color: #64766a;
`;

const CardItemContentButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const CardItemButton = styled.button`
  width: 130px;
  height: 35px;
  background: #ff9100;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  border: none;

  color: #ffffff;
`;

export default CardItem;
