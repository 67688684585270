import { useState, useContext } from "react";
import styled from "styled-components";

import InputSelect from "../../../components/Cajas/InputSelect";
import { PlanillaContext } from "../../../context/PlanillaContext";

const FotosContent = ({
  handlePhotoCajaSave,
  saveImage,
  handleDefectoFoto,
  tabs,
}) => {
  const [numeroFotos] = useState(tabs.find((item) => item.id === 3).numero);

  const { planillaState } = useContext(PlanillaContext);

  const { fotos, planillaDefectos } = planillaState;

  const foto = fotos?.find((item) => item.numero_foto_caja === 1);

  const handleSrcPreview = ({ item, n_foto }) => {
    if (foto !== undefined && foto.length > 0) {
      let url = foto.find((item) => {
        let new_item = item.numero_foto_caja === n_foto;
        return new_item;
      })?.foto;
      if (url === undefined) {
        return "";
      } else {
        return "http://localhost:8000/" + url;
      }
    } else if (saveImage.urls) {
      return saveImage.urls[`foto${item + 1}`];
    } else {
      return "";
    }
  };

  const handleSelectedDefectoFoto = ({ item, n_foto }) => {
    if (foto !== undefined && foto.length > 0) {
      let defecto = foto.find((item) => {
        let defecto = item.numero_foto_caja === n_foto;
        return defecto;
      })?.defecto;
      return defecto.id;
    } else if (saveImage.urls) {
      return planillaDefectos?.find(
        (item) => item.numero_foto_caja === item + 1
      )?.planilla_defecto.defecto.id;
    }
  };

  return (
    <form encType="multipart/form-data">
      <StyledDivFotos>
        {Array.from(Array(numeroFotos).keys()).map((item) => (
          <StyledDivFotosRow key={item}>
            <StyledPhotoNumberDiv>
              <StyledLabel> Foto {item + 1} </StyledLabel>
            </StyledPhotoNumberDiv>
            <StyledInputFile
              type="file"
              name={`foto${item + 1}`}
              accept="image/*"
              onChange={(e) => {
                console.log(e.target.files[0]);
                console.log("item", item);
                handlePhotoCajaSave(e);
              }}
              id={
                foto !== undefined && foto.length > 0
                  ? foto.find((picture) => {
                      console.log("item", picture);
                      let new_item = picture.numero_foto_caja === item + 1;
                      console.log("new_item", new_item);
                      return new_item;
                    })?.id
                  : ""
              }
            />
            <StyledDivPreview>
              <StyledImagePreview
                src={handleSrcPreview({ item, n_foto: item + 1 })}
                name={`preview_image${item + 1}`}
              />
            </StyledDivPreview>
            <InputSelect
              options={planillaDefectos?.map((item) => ({
                value: item.planilla_defecto.defecto.id,
                label: item.planilla_defecto.defecto.nombre,
              }))}
              name={`defecto_foto_${item + 1}`}
              onChange={(e) => handleDefectoFoto(e)}
              selected={handleSelectedDefectoFoto({
                item,
                n_foto: item + 1,
              })}
            />
          </StyledDivFotosRow>
        ))}
      </StyledDivFotos>
    </form>
  );
};

const StyledDivFotos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  align-items: center;
`;

const StyledDivFotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #eaf2e6;
  border-radius: 5px;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 1fr;
    align-items: center;
  }

  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 1fr;
    align-items: center;
  }

  @media screen and (min-width: 1920px) {
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 1fr;
    align-items: center;
  }
  // generate stripe rows
  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const StyledDivPreview = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
`;

const StyledLabel = styled.label`
  color: #64766a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const StyledPhotoNumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

const StyledInputFile = styled.input`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid #ff9100;
  }

  &:disabled {
    background-color: #e0e0e0;
  }

  // edit file input style
  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: "Tomar Fotografia";
    display: inline-block;
    background: -webkit-linear-gradient(top, #ff9100, #ff9100);
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &:hover::before {
    border-color: black;
  }

  &::before {
    margin-right: 10px;
  }

  // placeholder style
  &::-webkit-input-placeholder {
    color: #64766a;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledImagePreview = styled.img`
  width: 100px;
  object-fit: cover;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;

  &:hover {
    border: 1px solid #ff9100;
  }
`;

export default FotosContent;
