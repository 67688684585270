import axios from "../api/axios";

const URL = "/operaciones/";

// Export async function users

export async function getOperacionesService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createOperacionService({ token, data }) {
  console.log("Service Data", data);

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, data, config);
  // Se retorna la respuesta
  return response;
}

export async function updateOperacionService({ token, data, operacion }) {
  console.log("Service Data", data);
  console.log("Service Operacion", operacion);

  let updateOperacion = {
    norma_detalle: data.norma_detalle,
    operacion_tipo: data.operacion_tipo
      ? data.operacion_tipo
      : operacion.operacion_tipo.id,
    parametros: data.parametros,
  };

  console.log("Update Operacion", updateOperacion);

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    URL + operacion.id + "/",
    updateOperacion,
    config
  );
  console.log("Response", response);
  // Se retorna la respuesta
  return response;
}
