import Navbar from "../../../components/Navbar";

const BusInpecciones = () => {
    return (
        <div>
            <Navbar active link="https://contenedor.inglobochile.cl/Reportes/Reportes_Filtro/FrontIndex.php"/>
        </div>
    )
}

export default BusInpecciones;