import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import EmbalajeForm from "./form";
import TabsForm from "./tabs";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useEmbalajes from "../../../../hooks/api/useEmbalajes";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import styled from "styled-components";
import { toast } from "react-toastify";

const Embalajes = () => {
  const [tabsForm, setTabsForm] = useState(false);
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getEmbalajes, createEmbalaje, updateEmbalaje } = useEmbalajes();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const goToTabs = (params) => {
    return (
      <StyledGoToButton onClick={() => handleTabForm(params.data)}>
        Tabs
      </StyledGoToButton>
    );
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "codigo", headerName: "Codigo" },
    { field: "nombre", headerName: "Nombre" },
    { field: "norma.nombre", headerName: "Norma" },
    { field: "exportadora.nombre", headerName: "Exportadora" },
    { field: "especie.nombre", headerName: "Especie" },
    { field: "clasificacion.nombre", headerName: "Clasificacion" },
    { field: "peso_neto_minimo", headerName: "Peso Neto Pinimo" },
    { field: "peso_neto_medio", headerName: "Peso Neto Medio" },
    { field: "peso_neto_maximo", headerName: "Peso Neto Maximo" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
    { headerName: "Action", cellRenderer: goToTabs },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getEmbalajes({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getEmbalajes,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateEmbalaje(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        console.log("Page Res : ", res);
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Embalaje " + res.data.nombre + " editado con exito");
    }).catch((err) => {
      toast.error("Error al editar el embalaje");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = (e) => {
    setCreate(false);
    createEmbalaje(dataInput).then((res) => {
      setRowData([...rowData, res.data]);
      toast.success("Embalaje creado con exito");
    });
    setEdit(false);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
  };

  const handleTabForm = (params) => {
    setTabsForm(true);
    setEdit(false);
    setCreate(false);
    setDataInput(params);
    console.log("value", params);
  };

  const handleCancelTabs = () => {
    setTabsForm(false);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Embalajes"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create === true ? (
        <EmbalajeForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <EmbalajeForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
      {tabsForm === true ? (
        <TabsForm
          dataInput={dataInput}
          handleCancel={handleCancelTabs}
          setTabsForm={setTabsForm}
        />
      ) : null}
    </Layout>
  );
};

const StyledGoToButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #ffffff;
  &:active {
    background: #d88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;

export default Embalajes;
