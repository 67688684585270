import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {
  createVariedadService,
  deleteVariedadService,
  getVariedadesService,
  updateVariedadService,
  createVariedadExportadoraService,
  getVariedadesNoPaginationService,
  getVariedadesByEspecieExportadoraService,
} from "../../services/variedades";

import { toast } from "react-toastify";

export default function useVariedad() {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getVariedades = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente : client.id,
      }
      return getVariedadesService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getVariedadesNoPagination = useCallback(() => {
    const data = {
      cliente : client.id,
    }
    return getVariedadesNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getVariedadesByEspecieExportadora = useCallback(({ especie, exportadora }) => {
    const data = {
      especie,
      exportadora,
      cliente : client.id,
    }
    return getVariedadesByEspecieExportadoraService({ token, data })
      .then((res) => {
        // console.log("Response :", res.data);
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createVariedad = useCallback(
    (data) => {
      return createVariedadService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Variedad creada correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear la variedad");
          return err.response;
        });
    },
    [token]
  );

  const createVariedadExportadora = useCallback(
    (data) => {
      data[0].cliente = client.id;
      return createVariedadExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Exportadoras actualizadas correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al actualizar las exportadoras");
          return err.response;
        });
    },
    [token, client]
  );

  const updateVariedad = useCallback((data) => {
    return updateVariedadService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        toast.success("Variedad actualizada correctamente");
        return res.data;
      }
    }
    ).catch((err) => {
      toast.error("Error al actualizar la variedad");
      return err.response;
    }
    );
  }, [token]);

  const deleteVariedad = useCallback(
    (id) => {
      return deleteVariedadService({ token, id })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Variedad eliminada correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          console.log(id);
          console.log(err);
          toast.error("Error al eliminar la variedad");
          return err.response;
        });
    },
    [token]
  );

  return {
    getVariedades,
    createVariedad,
    updateVariedad,
    deleteVariedad,
    createVariedadExportadora,
    getVariedadesNoPagination,
    getVariedadesByEspecieExportadora,
  };
}
