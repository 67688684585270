import axios from "../api/axios";

const URL = "/etiquetas/";

// Export async function users

export async function getEtiquetasService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getEtiquetasNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getEspeciesEtiquetaNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Data: ", data);
  const ESPECIESURL = URL + "especies/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      etiqueta: data.etiqueta,
    },
  };
  // Se realiza la petición
  const response = await axios.get(ESPECIESURL, config);
  console.log("Response: ", response); 
  // Se retorna la respuesta
  return response;
}

export async function getEtiquetasByEspecieExportadoraService({ token, data }) {
  const newUrl = URL + "especie/exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // Se retorna la respuesta
  return response;
}

export async function createEtiquetaService({ token, data }) {
  console.log("Service: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      estado: data.estado,
      cliente: data.cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Etiqueta: ", response);
  return response;
}

export async function createEtiquetaExportadoraService({ token, data }) {
  const newUrl = URL + "activate_exportadora/"
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data[0].cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    newUrl,
    {
      etiqueta: data[0].etiqueta.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Etiqueta: ", response);
  return response;
}

export async function createEtiquetaEspecieService({ token, data }) {
  const newUrl = URL + "activate_especie/"
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data[0].cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    newUrl,
    {
      etiqueta: data[0].etiqueta.id,
      especies: data[0].especies,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Etiqueta: ", response);
  return response;
}

export async function updateEtiquetaService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Etiqueta Update: ", response);
  return response;
}
