import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getEtiquetasService,
  createEtiquetaService,
  updateEtiquetaService,
  createEtiquetaExportadoraService,
  createEtiquetaEspecieService,
  getEtiquetasNoPaginationService,
  getEtiquetasByEspecieExportadoraService,
  getEspeciesEtiquetaNoPaginationService,
} from "../../services/etiquetas";

export default function useEtiquetas() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getEtiquetas = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getEtiquetasService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getEtiquetasNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getEtiquetasNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getEspeciesEtiquetaNoPagination = useCallback((etiqueta) => {
    const data = {
      cliente: client.id,
      etiqueta : etiqueta.id,
    };
    return getEspeciesEtiquetaNoPaginationService({ token, data, etiqueta })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getEtiquetasByEspecieExportadora = useCallback(
    ({ especie, exportadora }) => {
      const data = {
        especie,
        exportadora,
        cliente: client.id,
      };
      return getEtiquetasByEspecieExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createEtiqueta = useCallback(
    (data) => {
      data.cliente = client.id;
      return createEtiquetaService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createEtiquetaExportadora = useCallback( 
    (data) => {
      data[0].cliente = client.id;
      return createEtiquetaExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createEtiquetaEspecie = useCallback( 
    (data) => {
      data[0].cliente = client.id;
      return createEtiquetaEspecieService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateEtiqueta = useCallback(
    (data) => {
      return updateEtiquetaService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getEtiquetas,
    getEtiquetasNoPagination,
    getEspeciesEtiquetaNoPagination,
    createEtiqueta,
    createEtiquetaExportadora,
    createEtiquetaEspecie,
    updateEtiqueta,
    getEtiquetasByEspecieExportadora,
  };
}
