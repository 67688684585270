import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import EtiquetaForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useEtiquetas from "../../../../hooks/api/useEtiquetas";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import AddExportadoraForm from "./addExportadora";
import AddEspecieForm from "./addEspecie";
import { toast } from "react-toastify";

const Etiquetas = () => {
  const [exportadoraForm, setExportadoraForm] = useState(false);
  const [ especieForm, setEspecieForm ] = useState(false);
  const [dataInputExportadora, setDataInputExportadora] = useState({});
  const [dataInputEspecie, setDataInputEspecie] = useState({});
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getEtiquetas, createEtiqueta, updateEtiqueta } = useEtiquetas();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getEtiquetas({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getEtiquetas,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateEtiqueta(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        console.log("Page Res : ", res);
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Etiqueta actualizada correctamente");
    }).catch((err) => {
      toast.error("Error al actualizar etiqueta");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = (e) => {
    setCreate(false);
    createEtiqueta(dataInput).then((res) => {
      setRowData([...rowData, res.data]);
      toast.success("Etiqueta creada correctamente");
    }).catch((err) => {
      toast.error("Error al crear etiqueta");
    });
    setEdit(false);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    setDataInputEspecie(selectedRows[0]);
    setDataInputExportadora(selectedRows[0]);
  };

  const handleExportadoraForm = () => {
    setCreate(false);
    setEdit(false);
    setDataInput({});
    setExportadoraForm(true);
  };

  const handleEspecieForm = () => {
    setCreate(false);
    setEdit(false);
    setDataInput({});
    setEspecieForm(true);
  };

  const handleCancelExportadoraForm = () => {
    setExportadoraForm(false);
  };

  const handleCancelEspecieForm = () => {
    setEspecieForm(false);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Etiquetas"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
        <FormCrudButton text="Exportadora" onClick={handleExportadoraForm} />
        <FormCrudButton text="Especie" onClick={handleEspecieForm} />
      </Mantainer>
      {create === true ? (
        <EtiquetaForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <EtiquetaForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
      {exportadoraForm === true ? (
        <AddExportadoraForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInputExportadora}
          handleCancel={handleCancelExportadoraForm}
        />
      ) : null}
      {especieForm === true ? (
        <AddEspecieForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInputEspecie}
          handleCancel={handleCancelEspecieForm}
        />
      ) : null}
    </Layout>
  );
};

export default Etiquetas;