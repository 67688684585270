import React, { createContext, useReducer, useEffect } from "react";
import { MANTAINER } from "./Actions";

export const MantainersContext = createContext();

const initialState = {
    planilla_edit: JSON.parse(localStorage.getItem('planilla_edit')) || {},
    calibre: JSON.parse(localStorage.getItem('calibre')) || {},
    norma: JSON.parse(localStorage.getItem('norma')) || {},
};

function reducer(state, action) {
    switch (action.type) {
        case MANTAINER.PLANILLA_EDIT:
            return { ...state, planilla_edit: action.payload };
        case MANTAINER.CALIBRE:
            return { ...state, calibre: action.payload };
        case MANTAINER.NORMA:
            return { ...state, norma: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const MantainersProvider = ({ children }) => {
    const [mantainersState, mantainersDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        localStorage.setItem('planilla_edit', JSON.stringify(mantainersState.planilla_edit));
        localStorage.setItem('calibre', JSON.stringify(mantainersState.calibre));
        localStorage.setItem('norma', JSON.stringify(mantainersState.norma));
    }, [mantainersState]);

    return (
        <MantainersContext.Provider value={{ mantainersState, mantainersDispatch }}>
            {children}
        </MantainersContext.Provider>
    );
};