import { createContext, useReducer } from "react";
import { authReducer, initialState } from "./LoginReducer";

const AuthContext = createContext({initialState});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;