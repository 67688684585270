import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {
    getTurnosService,
    createTurnoService,
    updateTurnoService,
} from "../../services/turnos";

export default function useTurnos() {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getTurnos = useCallback(
    () => {
      const data = {
        cliente : client.id,
      }
      return getTurnosService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createTurno = useCallback(
    (data) => {
      return createTurnoService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateTurno = useCallback((data) => {
    return updateTurnoService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  return {
    getTurnos,
    createTurno,
    updateTurno,
  };
}
