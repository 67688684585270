import React, { createRef, useContext } from "react";

import InputSelect from "../../../components/Cajas/InputSelect";
import InputNumber from "../../../components/Cajas/InputNumber";
import InputDecimal from "../../../components/Cajas/InputDecimal";
import InputText from "../../../components/Cajas/InputText";
import InputDate from "../../../components/Cajas/InputDate";
import InputTime from "../../../components/Cajas/InputTime";

import Semaforo from "../../../components/Semaforo";

import { PlanillaContext } from "../../../context/PlanillaContext";

import {
  StyleDivFilters,
  StyleDiv,
  StyledContainerInput,
  StyledLabel,
  StyledValidationContainer,
} from "./styledCajaContent";

const CajaContent = ({
  filtroEspecieDetalle,
  handleOptions,
  handleUpdateFilter,
  handleSaveDefectoData,
  inputRefs,
}) => {
  const { planillaState, planillaDispatch } = useContext(PlanillaContext);
  const { valoresCaja, validation, filters, planillaDefectos } = planillaState;
  const excludedIds = [6, 5, 8];
  const disabledIds = [1, 2, 3];

  const handleGetActualTime = () => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    const actualTime = hours + ":" + minutes;
    return actualTime;
  };

  return (
    <form>
      <StyleDivFilters>
        {filtroEspecieDetalle.map((filtro, index) => {
          const isExcluded = excludedIds.includes(
            filtro.filtro_detalle.filtro.id
          );
          const isDisabled = disabledIds.includes(
            filtro.filtro_detalle.filtro.id
          );
          return (
            filtro.interno &&
            !isExcluded && (
              <StyledContainerInput key={index}>
                <StyledLabel>{filtro.filtro_detalle.filtro.nombre}</StyledLabel>
                <InputSelect
                  key={filtro.filtro_detalle.filtro.id}
                  label={filtro.filtro_detalle.filtro.nombre}
                  width={"200px"}
                  minWidth={"200px"}
                  name={filtro.filtro_detalle.filtro.nombre}
                  options={handleOptions(filtro)}
                  onChange={(e) => {
                    planillaDispatch({
                      type: "SET_FILTERS",
                      payload: {
                        ...filters,
                        [filtro.filtro_detalle.filtro.nombre]: e.target.value,
                      },
                    });
                    handleUpdateFilter(filtro, e);
                  }}
                  value={filters[filtro.filtro_detalle.filtro.nombre]}
                  disabled={isDisabled}
                />
              </StyledContainerInput>
            )
          );
        })}
      </StyleDivFilters>
      <StyleDiv>
        {planillaDefectos?.map((item, index) => {
          const inputRef = createRef();
          inputRefs.current[item.planilla_defecto.id] = inputRef;

          const InputComponent = {
            Decimal: InputDecimal,
            Number: InputNumber,
            Time: InputTime,
            Date: InputDate,
            Text: InputText,
          }[item.planilla_defecto.tipo_medicion.input_type];

          const commonProps = {
            placeholder: "Ingrese " + item.planilla_defecto.defecto.nombre,
            name: item.planilla_defecto.defecto.id,
            onChange: (e) => handleSaveDefectoData(e, item),
            disabled: item.operaciones === false ? false : true,
            required: true,
            hola: inputRef,
            width: "300px",
            minWidth: "300px",
          };

          const valueProps =
            item.planilla_defecto.tipo_medicion.input_type === "Time"
              ? {
                  defaultValue:
                    valoresCaja[index][item.planilla_defecto.id].value ||
                    handleGetActualTime(),
                }
              : {
                  value:
                    valoresCaja[index][item.planilla_defecto.id].value !== 0
                      ? valoresCaja[index][item.planilla_defecto.id].value
                      : "",
                };

          const renderInputSelect = (filtro) =>
                filtro.filtro_detalle.filtro.id === 5 ? (
                  <InputSelect
                    label={filtro.filtro_detalle.filtro.nombre}
                    width={"300px"}
                    minWidth={"100px"}
                    name={item.planilla_defecto.defecto.id}
                    options={handleOptions(filtro)}
                    onChange={(e) => {
                      handleSaveDefectoData(e, item);
                    }}
                    defaultValue={
                      valoresCaja[index][item.planilla_defecto.id].value !== 0
                        ? valoresCaja[index][item.planilla_defecto.id].value
                        : filters[filtro.filtro_detalle.filtro.nombre] || 0
                    }
                  />
                ) : null;
          return (
            <>
              <label> {item.planilla_defecto.defecto.nombre} </label>
              <StyledValidationContainer>
                {InputComponent ? (
                  <InputComponent {...commonProps} {...valueProps} />
                ) : (
                  filtroEspecieDetalle.map(renderInputSelect)
                )}
                {item.planilla_defecto.tipo_medicion.validar && (
                  <Semaforo validation={validation} item={item} />
                )}
              </StyledValidationContainer>
            </>
          );
        })}
      </StyleDiv>
    </form>
  );
};

export default CajaContent;
