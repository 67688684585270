import axios from "../api/axios";

const URL = "/tab_embalajes/";

// Export async function users

export async function getTabsEmbalajeService({ token, embalaje }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      embalaje: embalaje,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // console.log("Response: ", response);
  console.log("Response: ", response);
  // Se retorna la respuesta
  return response;
}

export async function updateTabsEmbalajeService({ token, key, value, embalaje, tab }) {
    console.log("Service: ", key, value, embalaje, tab);
  // Se crea el objeto de configuración para la petición
  console.log("Key: ", key);
  console.log("Value: ", value);
  console.log("Embalaje: ", embalaje);
  console.log("Tab: ", tab);
  let data = {};
  if (tab === 7) {
    data = {
      id: key,
      embalaje: embalaje,
      tab: tab,
      peso_fijo_minimo: value.peso_fijo_minimo,
      peso_fijo_maximo: value.peso_fijo_maximo,
      numero_cestas: value.numero_cestas,
      numero_cestas_real: value.numero_cestas_real,
      numero_fotos: value.numero_fotos,
      active: value.active
    };
    console.log("Data: ", data);
  } else {
    data = {
      id: key,
      embalaje: embalaje,
      tab: tab,
      numero_cestas: value.numero_cestas,
      numero_fotos: value.numero_fotos,
      active: value.active,
    };
  }
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(URL + key + "/", data, config);
  // Se retorna la respuesta
  console.log("Response: ", response);
  return response;
}
