import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getCajasService,
  getCajasByClienteService,
  getCajasHeadersService,
  getNumeroCajaService,
  createCajaService,
  updateCajaService,
  deleteCajaService,
  updateCumplePackingService,
  updateResolucionCajaService,
} from "../../services/cajas";

export default function useCajas() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getCajas = useCallback(
    ({ dataInput, filters }) => {
      const data = {
        cliente: client.id,
        inspeccion_planilla: dataInput.id,
        filters: filters,
      };
      return getCajasService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getCajasByCliente = useCallback(
    ({ dataInput }) => {
      console.log("dataInput: ", dataInput);
      const data = {
        cliente: client.id,
        exportadora: dataInput.exportadora === null ? "" : dataInput.exportadora,
        especie: dataInput.especie === null ? "" : dataInput.especie,
        zona: dataInput.zona === null ? "" : dataInput.zona,
        calibre: dataInput.calibre === null ? "" : dataInput.calibre,
        productor_real: dataInput.productor_real === null ? "" : dataInput.productor_real,
        productor_rotulado: dataInput.productor_rotulado === null ? "" : dataInput.productor_rotulado,
        variedad_real: dataInput.variedad_real === null ? "" : dataInput.variedad_real,
        embalaje: dataInput.embalaje === null ? "" : dataInput.embalaje,
        turno: dataInput.turno === null ? "" : dataInput.turno,
        fecha_desde: dataInput.fecha_desde === null ? "" : dataInput.fecha_desde,
        fecha_hasta: dataInput.fecha_hasta === null ? "" : dataInput.fecha_hasta,
      };
      return getCajasByClienteService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        }
        )
        .catch((err) => {
          return err.response;
        }
        );
    },
    [token, client]
  );

  const getCajasHeaders = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getCajasHeadersService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getNumeroCaja = useCallback(
    ({ dataInput }) => {
      const data = {
        inspeccion_planilla: dataInput.id,
      };
      return getNumeroCajaService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const createCaja = useCallback(
    ({ saveCaja, valoresCaja, validation, observacion, planillaType }) => {
      console.log("Service Caja Create: ", saveCaja);
      console.log("Service Caja Create: ", valoresCaja);
      console.log("Service Caja Create: ", validation);
      console.log("Service Caja Create: ", observacion);
      console.log("Service Caja Create: ", planillaType);
      return createCajaService({
        token,
        saveCaja,
        valoresCaja,
        validation,
        observacion,
        planillaType,
      })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          } else {
            throw new Error("Error creating Caja");
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateCaja = useCallback(
    ({ saveCaja, valoresCaja, validation, observacion }) => {
      console.log("Service Caja Create: ", saveCaja);
      console.log("Service Caja Create: ", valoresCaja);
      console.log("Service Caja Create: ", validation);
      console.log("Service Caja Create: ", observacion);
      return updateCajaService({ token, saveCaja, valoresCaja, validation, observacion })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const deleteCaja = useCallback(
    ({ id }) => {
      return deleteCajaService({ token, id })
        .then((res) => {
          console.log("deleteCajaService: ", res);
          if (res.status === 204) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateCumplePacking = useCallback(
    (caja) => {
      return updateCumplePackingService({ token, caja })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateResolucionCaja = useCallback(
    ({id, resolucion, resultado_caja, cajas_hijas, cumplePacking}) => {
      return updateResolucionCajaService({ token, id, resolucion, resultado_caja, cajas_hijas, cumplePacking})
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getCajas,
    getCajasByCliente,
    getCajasHeaders,
    getNumeroCaja,
    createCaja,
    updateCaja,
    deleteCaja,
    updateCumplePacking,
    updateResolucionCaja,
  };
}
