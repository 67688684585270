import { useCallback, useContext } from "react";
import {ErrorContext} from "../../context/ErrorProvider";
import { ACTIONS, STATUS_CODE } from "../../context/Actions";
import { toast } from "react-toastify";

export default function useError() {
    const { state, dispatch } = useContext(ErrorContext);

    const { errorMessage } = state;

    const setError = useCallback(({res}) => {
        if (res === 400) {
            dispatch({type: ACTIONS.SET_ERROR, payload: {errorStatus: res, errorMessage: "Usuario o contraseña incorrectos"}});
        } else if (res === 401) {
            dispatch({type: ACTIONS.SET_ERROR, payload: {errorStatus: res, errorMessage: "Sin autorización"}});
        } else {
            dispatch({type: ACTIONS.SET_ERROR, payload: {errorStatus: res, errorMessage: "Error desconocido"}});
        }
    }, [dispatch]);

    const clearError = useCallback(() => {
        dispatch({type: ACTIONS.SET_ERROR, payload: {errorStatus: "", errorMessage: ""}});
    }, [dispatch]);

    const getError = useCallback(() => {
        return errorMessage;
    }, [errorMessage]);

    const getStatusCodeError = useCallback((status) => {
        switch (status) {
            case STATUS_CODE.BAD_REQUEST:
                return toast.error("Bad Request");
            case STATUS_CODE.UNAUTHORIZED:
                return toast.error("Unauthorized");
            case STATUS_CODE.NOT_FOUND:
                return toast.error("Not Found");
            case STATUS_CODE.INTERNAL_SERVER_ERROR:
                return toast.error("Internal Server Error");
            default:
                return toast.error("Error desconocido");
        }
    }, []);

    return {
        setError,
        clearError,
        getError,
        getStatusCodeError,
    }
}