import React from "react";
import styled from "styled-components";
import CumplePacking from "../../CumplePacking";
import { AlertButton } from "../../../components/Boton/alertButton";
import Resolucion from "../../Resolucion";
import InfoModal from "../../Modal/InfoModal";

const PromedioComponent = ({
  promedio,
  renderTableCell,
  planillaDefectosHeaders,
  getBgColor,
  getColor,
  causalesCaja,
  cumplePacking,
  setCumplePacking,
  resolucion,
  setResolucion,
  fetchData,
  data,
  handleOpen,
  handleClose,
  isOpen,
}) => {
  return (
    <TableRow>
      <TableCell position={"sticky"} colSpan={4}>
        Promedio
      </TableCell>
      {renderTableCell("embalaje", "codigo", promedio)}
      {renderTableCell("etiqueta", "nombre", promedio)}
      {renderTableCell("clasificacion", "nombre", promedio)}
      <TableCell>{promedio.caja.numero_caja}</TableCell>
      {renderTableCell("variedad", "nombre", promedio)}
      {renderTableCell("variedad_real", "nombre", promedio)}
      {renderTableCell("productor", "nombre", promedio)}
      {renderTableCell("productor_real", "nombre", promedio)}
      {planillaDefectosHeaders.map((planillaDefectosHeader, index) => {
        const datos_promedio = [promedio.caja.promedio];
        const planillaDefecto = datos_promedio.find((dato_promedio) => {
          return dato_promedio[planillaDefectosHeader.planilla_defecto];
        });
        return planillaDefecto ? (
          (() => {
            const defecto =
              planillaDefecto[planillaDefectosHeader.planilla_defecto];
            const textColor = defecto.cumple || 1;
            const promedio = defecto.promedio;

            return (
              <TableCell key={index} bgColor={getBgColor(textColor)}>
                <TableCellLabel textColor={getColor(textColor)}>
                  {promedio}
                </TableCellLabel>
              </TableCell>
            );
          })()
        ) : (
          <TableCell key={index}>
            <TableCellLabel>-</TableCellLabel>
          </TableCell>
        );
      })}
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>{promedio.caja.promedio.resultado_caja}</TableCell>
      <TableCell></TableCell>
      <TableCell>
        <AlertButton
          text="Causales Promedio"
          handleOpen={handleOpen}
          planillaDefectosHeaders={planillaDefectosHeaders}
          item={promedio.caja.promedio}
          causalesCaja={causalesCaja}
        />
        <InfoModal
          title="Causales"
          isOpen={isOpen}
          data={data}
          handleClose={handleClose}
        />
      </TableCell>
      <TableCell>
        {promedio.caja.resolucion_lote.nombre !== null ? (
          promedio.caja.resolucion_lote.nombre
        ) : (
          <Resolucion
            initialText="Resolución"
            resolucion={resolucion}
            setResolucion={setResolucion}
            caja={promedio.caja}
          />
        )}
      </TableCell>
      <TableCell>
        <CumplePacking
          initialText="Cumple Packing"
          item={promedio}
          caja={promedio.caja}
          cumplePacking={cumplePacking}
          setCumplePacking={setCumplePacking}
          fetchData={fetchData}
        />
      </TableCell>
    </TableRow>
  );
};

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.stripe === true ? "#f5f5f5" : "#ffffff"};
`;

const TableCell = styled.td`
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  text-align: left;
  background-color: ${(props) => props.bgColor};
`;

const TableCellLabel = styled.label`
  color: ${(props) => {
    return props.textColor;
  }};
`;

export default PromedioComponent;
