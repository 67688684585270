import { useState } from "react";

export default function usePagination() {
  const [rowData, setRowData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [next, setNext] = useState(2);
  const [previous, setPrevious] = useState(1);

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    console.log(e.target.value);
  };

  const handleNextPage = () => {
    setPage(next);
  };

  const handlePreviousPage = () => {
    setPage(previous);
    console.log("previous", previous);
  };

  return {
    page,
    rowsPerPage,
    handleRowsPerPage,
    handleNextPage,
    handlePreviousPage,
    rowData,
    setRowData,
    totalRows,
    setTotalRows,
    next,
    setNext,
    previous,
    setPrevious,
  };
}
