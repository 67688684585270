// create a button component
import React from "react";
import styled from "styled-components";

export const FormCrudButton = ({ text, onClick }) => {
  return <StyledButton onClick={onClick}>{text}</StyledButton>;
};

const StyledButton = styled.button`
  background: #FF9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #FFFFFF;
  &:active {
    background: #D88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;
