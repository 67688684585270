import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import InputSelect from "../Cajas/InputSelect";
import InputText from "../Cajas/InputText";
import { FormCrudButton } from "../Boton/formCrudButton";
import CajasTable from "../Table/Cajas";
import { useNavigate } from "react-router-dom";
import useClasificaciones from "../../hooks/api/useClasificaciones";
import useEmbalajes from "../../hooks/api/useEmbalajes";
import useVariedades from "../../hooks/api/useVariedad";
import useProductores from "../../hooks/api/useProductores";
import useCalibres from "../../hooks/api/useCalibres";
import useFiltros from "../../hooks/api/useFiltros";
import useEtiquetas from "../../hooks/api/useEtiquetas";
// import { getRespuestas } from "../../services/ejemplo";
import { PlanillaContext } from "../../context/PlanillaContext";
import { PLANILLA_DATA } from "../../context/Actions";

const CajasLayout = ({ title }) => {
  const { getClasificacionesByEspecieExportadora } = useClasificaciones();
  const {
    getEmbalajesNoPagination,
    getEmbalajesByClasificacionEspecieExportadora,
  } = useEmbalajes();
  const { getVariedadesByEspecieExportadora } = useVariedades();
  const { getProductoresByEspecieExportadora } = useProductores();
  const { getCalibresByEspecieExportadora } = useCalibres();
  const { getFiltroEspecieDetalleByEspecieExportadora } = useFiltros();
  const { getEtiquetasByEspecieExportadora } = useEtiquetas();

  const [clasificaciones, setClasificaciones] = useState([]);
  const [embalajes, setEmbalajes] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [variedadRotulada, setVariedadRotulada] = useState([]);
  const [productores, setProductores] = useState([]);
  const [productorReal, setProductorReal] = useState([]);
  const [calibres, setCalibres] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);

  // const [filters, setFilters] = useState({});

  const [filtroEspecieDetalle, setFiltroEspecieDetalle] = useState([]);

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { planilla, filters, planillaType } = planillaState;

  const [especie, setEspecie] = useState(planilla.especie);
  const [exportadora, setExportadora] = useState(planilla.exportadora);
  const [inspeccionTipo, setInspeccionTipo] = useState(
    planilla.inspeccion_tipo
  );

  const navigate = useNavigate();

  // const inputRef = useRef();
  // inputRef.current = [];
  // console.log("inputRef", inputRef);

  useEffect(() => {

    let especie = planilla.especie ? planilla.especie : null;
    let exportadora = planilla.exportadora ? planilla.exportadora : null;
    let inspeccionTipo = planilla.inspeccion_tipo ? planilla.inspeccion_tipo : null;

    Promise.allSettled([
      getFiltroEspecieDetalleByEspecieExportadora({
        especie,
        exportadora,
        inspeccionTipo,
      }),
      getClasificacionesByEspecieExportadora({ especie, exportadora }),
      getVariedadesByEspecieExportadora({ especie, exportadora }),
      getProductoresByEspecieExportadora({ especie, exportadora }),
      getCalibresByEspecieExportadora({ especie, exportadora }),
      getEtiquetasByEspecieExportadora({ especie, exportadora }),
    ]).then((responses) => {
      const [
        filtroEspecieDetalleRes,
        clasificacionesRes,
        variedadesRes,
        productoresRes,
        calibresRes,
        etiquetasRes,
      ] = responses;

      if (filtroEspecieDetalleRes.status === "fulfilled") {
        setFiltroEspecieDetalle(filtroEspecieDetalleRes.value.results);
        filtroEspecieDetalleRes.value.results.forEach((filtro) => {
          if (
            filtro.filtro_detalle.filtro.id === 1 &&
            filtro.especie_detalle.clasificacion !== true
          ) {
            getEmbalajesNoPagination().then((res) => {
              setEmbalajes(res.results);
            });
          }
        });
      }

      if (clasificacionesRes.status === "fulfilled") {
        setClasificaciones(clasificacionesRes.value.results);
      }

      if (variedadesRes.status === "fulfilled") {
        setVariedades(variedadesRes.value.data);
        setVariedadRotulada(variedadesRes.value.data);
      }

      if (productoresRes.status === "fulfilled") {
        setProductores(productoresRes.value.data);
        setProductorReal(productoresRes.value.data);
      }

      if (calibresRes.status === "fulfilled") {
        setCalibres(calibresRes.value.data);
      }

      if (etiquetasRes.status === "fulfilled") {
        setEtiquetas(etiquetasRes.value.data);
      }
    });
  }, [
    getClasificacionesByEspecieExportadora,
    getVariedadesByEspecieExportadora,
    getProductoresByEspecieExportadora,
    getCalibresByEspecieExportadora,
    getFiltroEspecieDetalleByEspecieExportadora,
    especie,
    exportadora,
    inspeccionTipo,
    getEtiquetasByEspecieExportadora,
    setEspecie,
    setExportadora,
    setInspeccionTipo,
    planillaState,
    getEmbalajesNoPagination,
    planilla.especie,
    planilla.exportadora,
    planilla.inspeccion_tipo,
  ]);

  const handleUpdateFilter = (filtro, e) => {
    const { value } = e.target;
    let clasificacion = value;

    if (filtro.filtro_detalle.filtro.id === 1) {
      console.log("filters", filters);
      const embalajesPromise =
        filtro.especie_detalle.clasificacion === true
          ? getEmbalajesByClasificacionEspecieExportadora({
              clasificacion,
              especie,
              exportadora,
            })
          : getEmbalajesNoPagination();

      embalajesPromise.then((res) => {
        setEmbalajes(
          filtro.especie_detalle.clasificacion === true ? res : res.results
        );
        handleOptions(filtro);
        if (value === "0") {
          planillaDispatch({
            type: "SET_FILTERS",
            payload: {
              ...filters,
              Embalaje: value,
            },
          });
        }
      });
    }
  };

  const handleGoToNewCaja = () => {
    try {
      planillaDispatch({
        type: PLANILLA_DATA.SET_DATA,
        payload: {},
      });
      if (planillaType === 2){
        planillaDispatch({
          type: PLANILLA_DATA.SET_DETALLES_CAJA,
          payload: [],
        });
      }
      planillaDispatch({
        type: PLANILLA_DATA.SET_FOTOS,
        payload: [{}],
      });
      planillaDispatch({
        type: PLANILLA_DATA.SET_PLANILLA_DEFECTOS,
        payload: [],
      });
      planillaDispatch({
        type: PLANILLA_DATA.SET_VALIDATION,
        payload: [],
      });
      navigate("detail");
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOptions = (filtro) => {
    const mapping = {
      1: clasificaciones,
      2: embalajes,
      3: variedades,
      4: productores,
      5: calibres,
      8: variedadRotulada,
      9: etiquetas,
      10: productorReal,
    };

    const id = filtro.filtro_detalle.filtro.id;
    const data = mapping[id];

    if (data === undefined) {
      return null;
    }

    return data.map((item) => {
      return {
        value: item.id,
        label: id === 4 || id === 2 ? item.codigo + " " + item.nombre : item.nombre,
      };
    });
  };

  const renderInputSelect = (filtro) => (
    <StyledFilterContainer>
      <StyledLabelFilter>
        {filtro.filtro_detalle.filtro.nombre}
      </StyledLabelFilter>
      <InputSelect
        label={filtro.filtro_detalle.filtro.nombre}
        width={"200px"}
        minWidth={"200px"}
        name={filtro.filtro_detalle.filtro.nombre}
        options={handleOptions(filtro)}
        onChange={(e) => {
          planillaDispatch({
            type: "SET_FILTERS",
            payload: {
              ...filters,
              [filtro.filtro_detalle.filtro.nombre]: e.target.value,
            },
          });
          handleUpdateFilter(filtro, e);
        }}
        defaultValue={filters[filtro.filtro_detalle.filtro.nombre]}
      />
    </StyledFilterContainer>
  );

  // const shouldShowButton = ["Embalaje", "Clasificación", "Variedad"].every(
  //   (filter) => filters[filter] && filters[filter] !== "0"
  // );

  const shouldShowButton = ["Embalaje", "Clasificación", "Variedad"].every(
    (filter) => filters[filter] && filters[filter] !== "0"
  );

  return (
    <StyledMantainer>
      <StyledHeader>
        <StyledBreadcrumb>
          <StyledBreadcrumbItem>
            Administración / Planilla Pallet / {title}
          </StyledBreadcrumbItem>
        </StyledBreadcrumb>
        <StyledTitle>
          <StyledH1>{title}</StyledH1>
        </StyledTitle>
      </StyledHeader>
      <StyledFiltersContainer>
        {filtroEspecieDetalle.map((filtro) => (
          <div key={filtro.filtro_detalle.filtro.id}>
            {filtro.externo &&
              (filtro.filtro_detalle.filtro.id === 6 ? (
                <InputText placeholder={filtro.filtro_detalle.filtro.nombre} />
              ) : (
                renderInputSelect(filtro)
              ))}
          </div>
        ))}
      </StyledFiltersContainer>
      <StyledButtonsContainer>
        {shouldShowButton && (
          <FormCrudButton
            text="Nueva Caja"
            onClick={() => handleGoToNewCaja()}
          />
        )}
      </StyledButtonsContainer>
      <StyledTableContainer>
        <CajasTable />
      </StyledTableContainer>
    </StyledMantainer>
  );
};

export default CajasLayout;

const StyledMantainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-self: center;
  margin-top: 15px;
  position: sticky;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledBreadcrumb = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledBreadcrumbItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;

  &:before {
    content: "\\2022";
    color: #ff9100;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const StyledH1 = styled.h1`
  color: #64766a;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  padding-left: 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 95%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: scroll;
`;

const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;

  @media screen and (min-width: 768px) {
    margin: 10px;
  }
`;

const StyledLabelFilter = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #64766a;
  margin-bottom: 5px;
`;
