import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/parametros/"; // Se completa el path de la url base

// Export async function users

export async function getParametrosService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getParametrosNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  return response;
}

export async function getParametrosPlanillasNoPaginationService({ token, data }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      planilla: data.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get("planilla_parametros/", config);
  return response;
}

export async function createParametroService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Productor: ", response);
  return response;
}

export async function createPlanillaParametroService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  let newUrl = "planilla_parametros/";
  console.log("Service Create Planilla Parametro: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  console.log("Decimal: ", parseFloat(data.orden_parametro).toFixed(2));
  // Se realiza la petición
  const response = await axios.post(
    newUrl,
    {
      planilla: data.planilla.id,
      parametro: parseInt(data.parametro),
      orden_parametro: parseFloat(data.orden_parametro).toFixed(2),
      estado: true,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Response Create Planilla Parametro: ", response);
  return response;
}

export async function updateParametroService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function deleteParametroService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta

  return response;
}
