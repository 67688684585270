import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getPackingService,
  createPackingService,
  updatePackingService,
  createPackingExportadoraService,
  createPackingEspecieService,
  getPackingNoPaginationService,
  getPackingByEspecieExportadoraService,
} from "../../services/packing";

import { toast } from "react-toastify";

export default function usePackings() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getPackings = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getPackingService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getPackingsNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getPackingNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  }, [token, client]);

  const getPackingsByEspecieExportadora = useCallback(
    (especie, exportadora) => {
      const data = {
        cliente: client.id,
        especie: especie,
        exportadora: exportadora,
      };
      return getPackingByEspecieExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    [token, client]
  );

  const createPacking = useCallback(
    (data) => {
      data.cliente = client.id;
      return createPackingService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Packing creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el packing");
          return err;
        });
    },
    [token, client]
  );

  const createPackingExportadora = useCallback(
    (data) => {
      console.log("Data Hook : ", data);
      data[0].cliente = client.id;
      return createPackingExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Packing exportadora creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el packing exportadora");
          return err;
        });
    },
    [token, client]
  );

  const createPackingEspecie = useCallback(
    (data) => {
      data[0].cliente = client.id;
      return createPackingEspecieService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Packing especie creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el packing especie");
          return err.response;
        });
    },
    [token, client]
  );

  const updatePacking = useCallback(
    (data) => {
      return updatePackingService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Packing actualizado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al actualizar el packing");
          return err;
        });
    },
    [token]
  );

  return {
    getPackings,
    createPacking,
    updatePacking,
    createPackingExportadora,
    createPackingEspecie,
    getPackingsNoPagination,
    getPackingsByEspecieExportadora,
  };
}
