import { createContext, useReducer } from "react";
import { navBarReducer, initialState } from "./NavBarReducer";

const NavBarContext = createContext({initialState});

export const NavBarProvider = ({ children }) => {
    const [stateNavBar, dispatch] = useReducer(navBarReducer, initialState);

    return (
        <NavBarContext.Provider value={{ stateNavBar, dispatch }}>
            {children}
        </NavBarContext.Provider>
    )
}

export default NavBarContext;