const UserIcon = () => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.45248 14.0187C3.19075 14.4039 -0.0560941 18.005 0.000484069 22.2837V22.5007C0.000484069 23.3291 0.672062 24.0007 1.50048 24.0007C2.32891 24.0007 3.00048 23.3291 3.00048 22.5007V22.2237C2.95539 19.5966 4.89414 17.3563 7.50048 17.0237C10.2517 16.7509 12.7032 18.76 12.976 21.5113C12.9922 21.6739 13.0003 21.8373 13.0005 22.0007V22.5007C13.0005 23.3291 13.672 24.0007 14.5005 24.0007C15.3289 24.0007 16.0005 23.3291 16.0005 22.5007V22.0007C15.9956 17.5774 12.4059 13.9956 7.98264 14.0005C7.80578 14.0007 7.62897 14.0068 7.45248 14.0187Z"
        fill="#FF9100"
      />
      <path
        d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
        fill="#FF9100"
      />
    </svg>
  );
};

export default UserIcon;
