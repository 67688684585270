import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/productores/"; // Se completa el path de la url base

// Export async function users

export async function getProductoresService({
  token,
  rowsPerPage,
  page,
  data,
}) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  console.log("Response Productores:", response);
  // Se retorna la respuesta
  return response;
}

export async function getProductoresNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getProductoresByEspecieExportadoraService({
  token,
  data,
}) {
  // Se crea el objeto de configuración para la petición
  const newUrl = URL + "especie/exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // Se retorna la respuesta
  return response;
}

export async function getEspeciesProductorNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const ESPECIESURL = URL + "especies/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      productor: data.productor,
    },
  };
  // Se realiza la petición
  const response = await axios.get(ESPECIESURL, config);
  // Se retorna la respuesta
  return response;
}

export async function createProductorService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      sag: data.sag,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function createProductorExportadoraService({ token, data }) {
  const EXPORTADORASURL = "productores/activate_exportadora/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data[0].cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    EXPORTADORASURL,
    {
      productor: data[0].productor.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}

export async function createProductorEspecieService({ token, data }) {
  const ESPECIESURL = URL + "activate_especie/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    ESPECIESURL,
    {
      productor: data[0].productor.id,
      especies: data[0].especies,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function updateProductorService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      sag: data.sag,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function deleteProductorService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta

  return response;
}
