import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledNavMenu = styled.nav`
  width: ${(props) => (props.active === true ? "240px" : "0")};
  height: calc(100% - 84px);
  background-color: #64766a;
  /* position: static; */
  transition: 550ms;
  -webkit-transition: 550ms;
  -moz-transition: 550ms;
  -ms-transition: 550ms;
  -o-transition: 550ms;
  font-size: 14px;
  margin-top: 84px;
  position: absolute;
  z-index: 1;

  @media screen and (min-width: 768px) {
    width: ${(props) => (props.active === true ? "226px" : "0")};
    // Bring the navbar to the front
    height: calc(100% - 84px);
  }

  @media screen and (min-width: 1024px) {
    width: ${(props) => (props.active === true ? "280px" : "81px")};
    position: relative;
  }

  @media screen and (min-width: 1440px) {
    width: ${(props) => (props.active === true ? "270px" : "81px")};
    position: relative;
  }
`;

const StyledConteinerOverflow = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: absolute;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const StyledNavUl = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
`;

const StyledNavLi = styled.li`
  display: none;
  height: 80px;
  background-color: #64766a;
  width: 100%;
  transition: 850ms;
  -webkit-transition: 850ms;
  -moz-transition: 850ms;
  -ms-transition: 850ms;
  -o-transition: 850ms;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledItem = styled.div`
  text-decoration: none;
  color: #f5f5f5;
  font-size: 14px;
  width: ${(props) => (props.active === true ? "206px" : "61px")};
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  ${(props) =>
    props.active === true
      ? "justify-content: space-between;"
      : "justify-content: center;"};
  border-radius: ${(props) => (props.itemmenu === true ? "14px 14px 0px 0px" : "14px")};
  -webkit-border-radius: ${(props) =>
    props.itemmenu === true ? "14px 14px 0px 0px" : "14px"};
  -moz-border-radius: ${(props) =>
    props.itemmenu === true ? "14px 14px 0px 0px" : "14px"};
  -ms-border-radius: ${(props) =>
    props.itemmenu === true ? "14px 14px 0px 0px" : "14px"};
  -o-border-radius: ${(props) =>
    props.itemmenu === true ? "14px 14px 0px 0px" : "14px"};

  ${(props) =>
    props.itemmenu === true
      ? "background-color: #5A6C60;"
      : "&:hover{background-color: #5A6C60;}"};
  cursor: pointer;
`;

const StyledItemContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 0px 0px 14px 14px;
  flex-direction: column;
  background-color: #5a6c60;
  width: ${(props) => (props.active === true ? "206px" : "61px")};
  align-self: center;
`;

const StyledItemLi = styled.li`
  text-decoration: none;
  color: #f5f5f5;
  font-size: 12px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  padding-bottom: 5px;
`;

const StyleItemLados = styled.div`
  width: ${(props) => (props.active === true ? "206px" : "61px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyleItemMedio = styled.div`
  width: 60%;
  font-weight: 300;
  line-height: 20px;
  font-size: 12px;
`;

const StyledLink = styled(Link)`
  color: #f5f5f3;
  font-size: 12px;
  margin: 5px;
  text-decoration: none;
  opacity: 0.7;
`;

export {
  StyledNavMenu,
  StyledConteinerOverflow,
  StyledNavUl,
  StyledNavLi,
  StyledItemContainer,
  StyledItem,
  StyledItemLi,
  StyleItemLados,
  StyleItemMedio,
  StyledLink,
};
