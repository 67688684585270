import axios from "../api/axios";

const URL = "/cumple/";

export async function getCumpleService({ token }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  console.log("response", response);
  return response;
}
