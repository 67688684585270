import React from "react";
import styled from "styled-components";

const InputNumber = ({
    name,
    placeholder,
    defaultValue,
    onChange,
    disabled,
    required,
    min,
    max,
    step,
    value,
    hola,
    id,
    minWidth,
}) => {
    // console.log("InputNumber: ", value);
    return (
        <StyledInputNumber
            type="number"
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            required={required}
            min={min}
            max={max}
            step={step}
            // only allow numbers, no commas, periods, etc.
            pattern="[0-9]"
            value={value}
            ref={hola}
            id={id}
            minWidth={minWidth}
        />
    );
}

const StyledInputNumber = styled.input`
    width: ${(props) => (props.width ? props.width : "300px")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "300px")};
    height: 40px;
    border-radius: 5px;
    border: 1.5px solid #ff9100;
    padding: 0 15px;
    margin: 5px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    // On click, the input border turns orange
    &:focus {
        outline: none;
        border: 1.5px solid #d88219;
    }

    @media (max-width: 768px) {
        width: 300px;
        min-width: 300px;
    }

    @media (max-width: 425px) {
        width: 250px;
        min-width: 250px;
    }
`;

export default InputNumber;