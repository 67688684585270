import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import useCalibreDetalles from "../../../hooks/api/useCalibreDetalles";
import InputNumber from "../../../components/Cajas/InputNumber";
import { PlanillaContext } from "../../../context/PlanillaContext";

import {
  StyledSemaforo,
  StyledGreenSemaforo,
  StyledYellowSemaforo,
  StyledOrangeSemaforo,
  StyledRedSemaforo,
  StyledGraySemaforo,
} from "./styledCajaContent";

const DiametroFrutoContent = ({
  initialFilters,
  especie,
  exportadora,
  diametroFruto,
  setDiametroFruto,
}) => {
  const [numeroCestas, setNumeroCestas] = useState(0);
  // const [calibreDetalle, setCalibreDetalle] = useState([]);
  const [valorMinimoMM, setValorMinimoMM] = useState(0);
  const [valorMedioMM, setValorMedioMM] = useState(0);
  const [valorMaximoMM, setValorMaximoMM] = useState(0);
  const [calibre, setCalibre] = useState(0);
  const { getEmbalajeFirmeza } = useEmbalajes();
  const { getCalibreDetalleByEspecieExportadora } = useCalibreDetalles();

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);
  const { detallesCaja, validation, valoresCaja } = planillaState;

  var embalaje = initialFilters.Embalaje;

  useEffect(() => {
    getEmbalajeFirmeza(embalaje).then((res) => {
      res.results.forEach((element) => {
        if (element.tab.id === 6) {
          setNumeroCestas(element.numero_cestas);
        }
      });
    });
  }, [getEmbalajeFirmeza, embalaje]);

  useEffect(() => {
    const newCalibre = valoresCaja.filter((valor) => {
      let key = Object.keys(valor)[0];
      if (valor[key].tipo_medicion.id === 13) {
        return valor;
      } else {
        return null;
      }
    })[0];
    const calibreId = newCalibre[Object.keys(newCalibre)[0]].value;
    setCalibre(calibreId);
    getCalibreDetalleByEspecieExportadora({
      especie,
      exportadora,
      calibreId,
    }).then((res) => {
      // setCalibreDetalle(res.results);
      if (res.results.length !== 0) {
        setValorMinimoMM(res.results[0].valor_minimo_milimetros);
        setValorMedioMM(res.results[0].valor_medio_milimetros);
        setValorMaximoMM(res.results[0].valor_maximo_milimetros);
      }
    });
  }, [
    getCalibreDetalleByEspecieExportadora,
    especie,
    exportadora,
    valoresCaja,
    calibre,
    // setCalibreDetalle,
  ]);

  const handleValidateDiametroFrutoTab = (e) => {
    const { name, value } = e.target;
    let cumple = 1;
    if (value >= valorMinimoMM && value <= valorMedioMM) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "green",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 1;
    } else if (value > valorMedioMM && value <= valorMaximoMM) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "yellow",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 2;
    } else if (value < valorMinimoMM || value > valorMaximoMM) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "red",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 4;
    } else {
      delete validation[name];
    }

    if (detallesCaja.length !== 0) {
      setDiametroFruto({
        ...diametroFruto,
        [name]: {
          id: e.target.id,
          diametro: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        },
      });
    } else {
      setDiametroFruto({
        ...diametroFruto,
        [name]: {
          diametro: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        }
      });
    }
  };

  const handleDiametroFrutoCaja = (i) => {
    let valor = "";
    if (detallesCaja.length !== 0) {
      detallesCaja[0].caja.diametro_frutos?.forEach((element) => {
        if (element.numero === i + 1) {
          valor = element.diametro;
        }
      });
    }
    // console.log("valor", valor);
    return valor;
  }

  return (
    <StyleDivFirmeza>
      {[...Array(numeroCestas)].map((e, i) => {
        return (
          <StyledDiv key={i + 1}>
            <label> Diametro Fruto {i + 1} </label>
            <InputNumber
              name={`diametro_fruto${i + 1}`}
              placeholder="Diamentro Fruto"
              min={0}
              max={100}
              step={0.1}
              id={detallesCaja.length !== 0 ? detallesCaja[0].caja.diametro_frutos?.find((element) => element.numero === i + 1)?.id : ""}
              onChange={(e) => {
                handleValidateDiametroFrutoTab(e);
              }}
              defaultValue={handleDiametroFrutoCaja(i)}
            />
            <StyledSemaforo>
              {validation[`diametro_fruto${i + 1}`]?.color === "green" ? (
                <StyledGreenSemaforo>
                  {validation[`diametro_fruto${i + 1}`]?.value}
                </StyledGreenSemaforo>
              ) : validation[`diametro_fruto${i + 1}`]?.color === "yellow" ? (
                <StyledYellowSemaforo>
                  {validation[`diametro_fruto${i + 1}`]?.value}
                </StyledYellowSemaforo>
              ) : validation[`diametro_fruto${i + 1}`]?.color === "orange" ? (
                <StyledOrangeSemaforo>
                  {validation[`diametro_fruto${i + 1}`]?.value}
                </StyledOrangeSemaforo>
              ) : validation[`diametro_fruto${i + 1}`]?.color === "red" ? (
                <StyledRedSemaforo>
                  {validation[`diametro_fruto${i + 1}`]?.value}
                </StyledRedSemaforo>
              ) : (
                <StyledGraySemaforo />
              )}
            </StyledSemaforo>
          </StyledDiv>
        );
      })}
    </StyleDivFirmeza>
  );
};

const StyleDivFirmeza = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export default DiametroFrutoContent;
