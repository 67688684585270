import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/exportadoras/"; // Se completa el path de la url base

// Export async function users
export async function exportadorasService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getExportadorasNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getExportadorasVariedadNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "variedades/exportadoras/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      variedad: data.variedad,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  // Se retorna la respuesta
  console.log("Response ExportadoraVariedad:", response);
  return response;
}

export async function getExportadorasProductorNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "productores/exportadoras/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      productor: data.productor,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  // Se retorna la respuesta
  console.log("Response: ", response);
  return response;
}

export async function getExportadorasPackingNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "packings/exportadoras/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      packing: data.packing,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  // Se retorna la respuesta
  console.log("Response PackingExportadora: ", response);
  return response;
}

export async function getExportadorasPlanillasNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "planillas/exportadoras/";
  const config = {
      headers: {
          Authorization: `Token ${token}`,
      },
      params: {
          planilla: data.planilla,
          count:1000
      },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  return response;
}

export async function getExportadorasEtiquetaNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "etiquetas/exportadoras/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
      headers: {
          Authorization: `Token ${token}`,
      },
      params: {
          etiqueta: data.etiqueta,
          count:1000
      },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  // Se retorna la respuesta
  console.log("Response ExportadoraEtiqueta:", response);
  return response;
}

export async function getExportadorasClasificacionNoPaginationService({ token, data }) {
  const EXPORTADORASURL = "clasificaciones/get_exportadoras/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
      headers: {
          Authorization: `Token ${token}`,
      },
      params: {
          clasificacion: data.clasificacion,
          count:1000
      },
  };
  // Se realiza la petición
  const response = await axios.get(EXPORTADORASURL, config);
  // Se retorna la respuesta
  console.log("Response ExportadoraClasificacion:", response);
  return response;
}

export async function createExportadoraService({ token, data }) {
  // const createUrl = URL + "nueva/";
  // Se crea el objeto de configuración para la petición
  console.log("Service Exportadora: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, {
    alias: data.alias,
    nombre: data.nombre,
    cliente: data.cliente,
    estado: data.estado,
  }, config);
  // Se retorna la respuesta
  console.log("Service Response :", response);
  return response;
}

export async function updateExportadoraService({ token, data }) {
  console.log("Service Data: ", data);
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(updateUrl, {
    alias: data.alias,
    nombre: data.nombre,
    estado: data.estado,
  }, config);
  // Se retorna la respuesta
  console.log("Service Response: ", response);
  return response;
}

export async function deleteExportadoraService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";

  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}