import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { routes } from "./config/routes";
import { ErrorProvider } from "./context/ErrorProvider";
import { ThemeProvider } from "./context/ThemeProvider";
import { NavBarProvider } from "./context/NavBarProvider";
import { PlanillaProvider } from "./context/PlanillaContext";
import { MantainersProvider } from "./context/MantaintersContext";

const Providers = ({ children }) => (
  <ErrorProvider>
    <AuthProvider>
      <NavBarProvider>
        <MantainersProvider>
          <PlanillaProvider>
            <ThemeProvider>
              {children}
            </ThemeProvider>
          </PlanillaProvider>
        </MantainersProvider>
      </NavBarProvider>
    </AuthProvider>
  </ErrorProvider>
);

function App() {
  return (
    <BrowserRouter>
      <Providers>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Providers>
    </BrowserRouter>
  );
}

export default App;