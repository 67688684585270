import Layout from "../../../components/Layout";
import { useEffect, useMemo, useState, useContext } from "react";
import styled from "styled-components";
import { FormCrudButton } from "../../../components/Boton/formCrudButton";
import { useNavigate } from "react-router-dom";
// import usePlanillas from "../../../hooks/api/usePlanillas";
// import usePlanillaDefectos from "../../../hooks/api/usePlanillaDefectos";
import useClasificaciones from "../../../hooks/api/useClasificaciones";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import useEtiquetas from "../../../hooks/api/useEtiquetas";
import useVariedad from "../../../hooks/api/useVariedad";
import useProductores from "../../../hooks/api/useProductores";
import useCajas from "../../../hooks/api/useCajas";
import useFotos from "../../../hooks/api/useFotos";
import useTabs from "../../../hooks/api/useTabs";
import useFiltros from "../../../hooks/api/useFiltros";
import useCalibres from "../../../hooks/api/useCalibres";
import useNormaDetalles from "../../../hooks/api/useNormaDetalles";
import useFirmeza from "../../../hooks/api/useFirmeza";
import usePesoFruto from "../../../hooks/api/usePesoFrutos";
import useDiametroFruto from "../../../hooks/api/useDiametroFrutos";
import usePesoFijo from "../../../hooks/api/usePesoFijos";
import useBrix from "../../../hooks/api/useBrix";

import { MEDICION } from "../../../context/Actions";

import useCajaDetailHelper from "../../../hooks/helpers/ControlProductoTerminado/Cajas/useCajaDetailHelper";

import TabsCajasContent from "./tabsCajasContent";

import AuthContext from "../../../context/AuthProvider";
import { PlanillaContext } from "../../../context/PlanillaContext";
import { PLANILLA_DATA } from "../../../context/Actions";

const CajaDetail = () => {
  const [number] = useState([]);

  const [clasificaciones, setClasificaciones] = useState([]);
  const [embalajes, setEmbalajes] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [variedadReal, setVariedadReal] = useState([]);
  const [variedadRotulada, setVariedadRotulada] = useState([]);
  const [productores, setProductores] = useState([]);
  const [productorReal, setProductorReal] = useState([]);
  const [calibres, setCalibres] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  // const [filters, setFilters] = useState({});
  const [saveImage, setSaveImage] = useState([]);
  const [tab, setTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [numeroCaja, setNumeroCaja] = useState(1);

  const [observacion, setObservacion] = useState("");
  const [firmeza, setFirmeza] = useState([]);
  const [pesoFruto, setPesoFruto] = useState([]);
  const [diametroFruto, setDiametroFruto] = useState([]);
  const [pesoFijoSave, setPesoFijoSave] = useState([]);
  const [brix, setBrix] = useState([]);

  // console.log("observacion", observacion);

  const { getClasificacionesByEspecieExportadora } = useClasificaciones();
  const {
    getEmbalajesNoPagination,
    getEmbalajesByClasificacionEspecieExportadora,
  } = useEmbalajes();
  const { getNormaDetalleByEmbalaje } = useNormaDetalles();
  const { getVariedadesByEspecieExportadora } = useVariedad();
  const { getProductoresByEspecieExportadora } = useProductores();
  const { getCalibresByEspecieExportadora } = useCalibres();
  const { getEtiquetasByEspecieExportadora } = useEtiquetas();
  const { getFiltroEspecieDetalleByEspecieExportadora } = useFiltros();

  const { createFirmezaCaja, updateFirmezaCaja } = useFirmeza();
  const { createPesoFrutoCaja, updatePesoFrutoCaja } = usePesoFruto();
  const { createDiametroFrutoCaja, updateDiametroFrutoCaja } =
    useDiametroFruto();
  const { createPesoFijoCaja, updatePesoFijoCaja } = usePesoFijo();
  const { createBrixCaja, updateBrixCaja } = useBrix();

  const {
    handleSaveDefectoData,
    inputRefs,
    pesoFijo,
    setPesoFijo,
    sumaPesoFijo,
    totalPesoFijo,
    setTotalPesoFijo,
    handlePesoNeto,
    setEmbalaje,
    influye,
  } = useCajaDetailHelper();

  const { getTabsEmbalaje } = useTabs();

  const navigate = useNavigate();

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const {
    planilla,
    contraMuestraCaja,
    copyCaja,
    valoresCaja,
    validation,
    detallesCaja,
    filters,
    planillaType,
  } = planillaState;

  const foto = planillaState.fotos || [];
  // const contraMuestra = false;
  // const copy = false;

  const { state: authState } = useContext(AuthContext);

  const { colors } = authState;

  const initialFilters = useMemo(() => {
    if (detallesCaja.length !== 0) {
      return {
        Embalaje: detallesCaja[0].caja.embalaje.id,
        Clasificación: detallesCaja[0].caja.clasificacion.id,
        Variedad: detallesCaja[0].caja.variedad.id,
        Productor: detallesCaja[0].caja.productor.id,
        "Variedad Real": detallesCaja[0].caja.variedad_real.id,
        "Productor Real": detallesCaja[0].caja.productor_real.id,
        Etiqueta: detallesCaja[0].caja.etiqueta.id,
      };
    }
    return {
      Embalaje: filters.Embalaje,
      Clasificación: filters.Clasificación,
      Variedad: filters.Variedad,
    };
  }, [detallesCaja, filters]);

  const [saveCaja, setSaveCaja] = useState({});

  const { createCaja, updateCaja, getNumeroCaja } = useCajas();

  const { createFotoCaja, updateFotoCaja } = useFotos();

  const [filtroEspecieDetalle, setFiltroEspecieDetalle] = useState([]);

  const [especie, setEspecie] = useState(planilla.especie);
  const [exportadora, setExportadora] = useState(planilla.exportadora);
  const [inspeccionTipo, setInspeccionTipo] = useState(
    planilla.inspeccion_tipo
  );

  const handleGetActualTime = () => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    const actualTime = hours + ":" + minutes;
    return actualTime;
  };

  // console.log("planilla", planilla);

  // console.log("initialFilters", initialFilters);

  // console.log("filters", filters);

  // console.log("Observacion", observacion);

  // console.log("Filtro Especie Detalle", filtroEspecieDetalle);

  useEffect(() => {
    const embalaje = parseInt(initialFilters.Embalaje || filters.Embalaje);

    const fetchNumeroCaja = async () => {
      try {
        const res = await getNumeroCaja({ dataInput: planilla });
        setNumeroCaja(res.numero);
      } catch (error) {
        console.error("Error fetching numeroCaja:", error);
      }
    };

    fetchNumeroCaja();

    const setSaveCajaCommon = (overrides) => {
      setSaveCaja((prevState) => ({
        ...prevState,
        inspeccion_planilla: planilla.id,
        embalaje: embalaje,
        etiqueta: parseInt(filters.Etiqueta) || null,
        clasificacion: parseInt(initialFilters["Clasificación"]) || null,
        calibre: parseInt(filters.Calibre) || null,
        variedad: parseInt(initialFilters.Variedad) || null,
        variedad_real: parseInt(filters["Variedad Real"]) || null,
        productor: parseInt(filters.Productor) || null,
        productor_real: parseInt(filters["Productor Real"]) || null,
        resultado_caja: parseInt(influye.total_caja.resultado),
        sincronizacion: 1,
        estado: true,
        ...overrides,
      }));
    };

    if (detallesCaja.length !== 0) {
      if (planillaType === 1) { // Lote
        if (numeroCaja === 1) {
          setSaveCajaCommon({
            caja_madre: true,
            numero_caja: numeroCaja,
          });
        } else {
          setSaveCajaCommon({
            caja_madre: false,
            caja_madre_id: detallesCaja[0].caja.id,
            numero_caja: numeroCaja,
          });
        }
      } else {
        setObservacion(detallesCaja[0].caja.observacion);

        if (!contraMuestraCaja) {
          setSaveCajaCommon({
            caja_madre: copyCaja, // REVISAR
            numero_caja: copyCaja ? numeroCaja : detallesCaja[0].caja.numero_caja,
            id: copyCaja ? undefined : detallesCaja[0].caja.id,
          });
        } else {
          setSaveCajaCommon({
            caja_madre: false,
            caja_madre_id: detallesCaja[0].caja.id,
            numero_caja: numeroCaja,
          });
        }
      }
    } else {
      setSaveCajaCommon({
        caja_madre: true,
        numero_caja: numeroCaja,
      });
    }
  }, [initialFilters, influye.total_caja.resultado, numeroCaja, filters]);

  useEffect(() => {
    let embalaje = initialFilters.Embalaje || filters.Embalaje || null;
    setEspecie(planilla.especie);
    setExportadora(planilla.exportadora);
    setInspeccionTipo(planilla.inspeccion_tipo);

    Promise.allSettled([
      getTabsEmbalaje(embalaje),
      getFiltroEspecieDetalleByEspecieExportadora({
        especie,
        exportadora,
        inspeccionTipo,
      }),
      getClasificacionesByEspecieExportadora({ especie, exportadora }),
      getVariedadesByEspecieExportadora({ especie, exportadora }),
      getProductoresByEspecieExportadora({ especie, exportadora }),
      getCalibresByEspecieExportadora({ especie, exportadora }),
      getEtiquetasByEspecieExportadora({ especie, exportadora }),
      getNormaDetalleByEmbalaje(initialFilters),
    ]).then((res) => {
      setTabs([
        ...res[0].value.results
          .filter((item) => item.active === true)
          .map((item) => {
            console.log("TAB", item);
            return {
              id: item.tab.id,
              label: item.tab.nombre,
              numero: item.numero_fotos
            };
          }),
      ]);
      // console.log("TABS", tabs);
      setFiltroEspecieDetalle(res[1].value.results);
      getEmbalajesNoPagination().then((res) => {
        setEmbalajes(res.results);
        setEmbalaje(
          res.results.find((item) => {
            return item.id === parseInt(initialFilters.Embalaje);
          })
        );
      });
      setClasificaciones(res[2].value.results);
      setVariedades(res[3].value.data);
      setVariedadReal(res[3].value.data);
      setVariedadRotulada(res[3].value.data);
      setProductores(res[4].value.data);
      setProductorReal(res[4].value.data);
      setCalibres(res[5].value.data);
      setEtiquetas(res[6].value.data);
      planillaDispatch({
        type: PLANILLA_DATA.SET_PLANILLA_DEFECTOS,
        payload: res[7].value.results ? res[7].value.results : [],
      });
      // setPlanillaDefectos(res[7].value.results);
      // console.log("Planilla Defectos", res[7].value.results);

      let valores;
      if (detallesCaja.length !== 0) {
        const colorMapping = {};
        for (let i = 1; i <= 4; i++) {
          colorMapping[i] = colors.find(
            (color) => color.cumple.id === i
          ).color_texto;
        }
        const colorBackgroundMapping = {};
        for (let i = 1; i <= 4; i++) {
          colorBackgroundMapping[i] = colors.find(
            (color) => color.cumple.id === i
          ).color;
        }

        // console.log("Validation", validation)

        let newValidation = {};

        const updateValidation = (item, color, colorBackground) => {
          const validacion = {
            [item.planilla_defecto.defecto.id]: {
              value: item.valor_calculado,
              color_texto: color,
              color: colorBackground,
              cumple: item.cumple_id,
              tipo_medicion: item.planilla_defecto.tipo_medicion,
            },
          };
          newValidation = { ...newValidation, ...validacion };
          return newValidation;
        };

        const createItem = (item, value, cajaDetalleId) => ({
          [item.planilla_defecto.id]: {
            id: item.planilla_defecto.defecto.id,
            nombre: item.planilla_defecto.defecto.nombre,
            value,
            operaciones: item.operaciones,
            tipo_medicion: item.planilla_defecto.tipo_medicion,
            norma_valor_1: item.norma_valor_1,
            norma_valor_2: item.norma_valor_2,
            norma_valor_3: item.norma_valor_3,
            embalaje: item.embalaje,
            influye: item.influye,
            cumple: 1,
            sincronizacion: 1,
            caja_detalle_id: cajaDetalleId,
          },
        });

        detallesCaja[0].caja.caja_detalle.forEach((item) => {
          const color = colorMapping[item.cumple_id] || "red";
          const colorBackground =
            colorBackgroundMapping[item.cumple_id] || "red";
          let get_validation = updateValidation(item, color, colorBackground);
          planillaDispatch({
            type: PLANILLA_DATA.SET_VALIDATION,
            payload: get_validation,
          });
        });

        valores = res[7].value.results.map((item) => {
          const cajaDetalle = detallesCaja[0].caja.caja_detalle.find(
            (cajaDetalle) =>
              cajaDetalle.planilla_defecto.id === item.planilla_defecto.id
          );

          if (!cajaDetalle) {
            return createItem(item, 0);
          }

          let value;
          switch (item.planilla_defecto.tipo_medicion.id) {
            case MEDICION.NUMERO:
            case MEDICION.CANTIDAD_CAJAS:
            case MEDICION.PESO_NETO:
              value = parseInt(cajaDetalle.valor);
              break;
            case MEDICION.NUMERO_FRUTOS:
              value = parseInt(cajaDetalle.valor); // antes tenia un valor fijo de 50
              break;
            case MEDICION.PORCENTAJE:
              value = parseFloat(cajaDetalle.valor);
              break;
            default:
              value = cajaDetalle.valor;
          }

          return createItem(item, value, parseInt(cajaDetalle.id));
        });
      } else {
        // console.log("Res", res[7]);
        valores = res[7].value.results.map((item) => {
          const createItem = (value) => ({
            [item.planilla_defecto.id]: {
              id: item.planilla_defecto.defecto.id,
              nombre: item.planilla_defecto.defecto.nombre,
              value: value,
              operaciones: item.operaciones,
              tipo_medicion: item.planilla_defecto.tipo_medicion,
              norma_valor_1: item.norma_valor_1,
              norma_valor_2: item.norma_valor_2,
              norma_valor_3: item.norma_valor_3,
              embalaje: item.embalaje,
              influye: item.influye,
              cumple: 1,
              sincronizacion: 1,
            },
          });

          let value = 0;
          if (
            item.planilla_defecto.tipo_medicion.id === MEDICION.NUMERO_FRUTOS
          ) {
            value = 100;
          } else if (item.planilla_defecto.tipo_medicion.id === MEDICION.HORA) {
            value = handleGetActualTime();
          } else if (
            initialFilters.Calibre !== undefined &&
            item.planilla_defecto.tipo_medicion.id === MEDICION.CALIBRE
          ) {
            value = initialFilters.Calibre;
          }

          return createItem(value);
        });
      }
      planillaDispatch({
        type: PLANILLA_DATA.SET_VALORES_CAJA,
        payload: valores,
      });
    });
  }, [planilla, initialFilters, filters]);

  const handleUpdateFilter = (filtro, e) => {
    const { value } = e.target;
    let clasificacion = value;

    const handleEmbalajes = (res) => {
      setEmbalajes(res);
      handleOptions(filtro);
      if (value === "0") {
        planillaDispatch({
          type: PLANILLA_DATA.SET_FILTERS,
          payload: {
            ...filters,
            Embalaje: value,
          },
        });
        // setFilters({
        //   ...filters,
        //   Embalaje: value,
        // });
      }
    };

    if (filtro.filtro_detalle.filtro.id === 1) {
      if (filtro.especie_detalle.clasificacion === true) {
        getEmbalajesByClasificacionEspecieExportadora({
          clasificacion,
          especie,
          exportadora,
        }).then(handleEmbalajes);
      } else {
        getEmbalajesNoPagination().then((res) => handleEmbalajes(res.results));
      }
    } else {
      handleOptions(filtro);
    }
  };

  const handleOptions = (filtro) => {
    const mapping = {
      1: clasificaciones,
      2: embalajes,
      3: variedades,
      4: productores,
      5: calibres,
      7: variedadReal,
      8: variedadRotulada,
      9: etiquetas,
      10: productorReal,
    };

    const labelMapping = {
      2: (item) => item.codigo,
      4: (item) => `${item.codigo} ${item.nombre}`,
      default: (item) => item.nombre,
    };

    const items = mapping[filtro.filtro_detalle.filtro.id];
    const labelFunction =
      labelMapping[filtro.filtro_detalle.filtro.id] || labelMapping.default;

    if (items !== undefined) {
      return items.map((item) => ({
        value: item.id,
        label: labelFunction(item),
      }));
    } else {
      return [];
    }
  };

  const handleSaveCaja = (e) => {
    const createIfNotEmpty = (array, createFunction, message, new_caja_id) => {
      if (array.length !== 0) {
        createFunction({ array, new_caja_id }).then((res) => {
          console.log(message, res);
        });
      }
    };

    const createFotos = ({ new_caja_id }) => {
      if (saveImage.fotos !== undefined) {
        Object.entries(saveImage.fotos).forEach((foto) => {
          let key_foto = foto[0];
          let numero_foto_caja = parseInt(key_foto.slice(4));
          let fotito = {
            numero_foto_caja,
            foto: foto[1],
            caja: new_caja_id,
            defecto: foto[1].defecto,
          };
          createFotoCaja(fotito, saveImage).then((result) => {
            console.log("Foto Guardada", result);
          });
        });
      }
    };

    createCaja({ saveCaja, valoresCaja, validation, observacion, planillaType })
      .then((res) => {
        const new_caja_id = res.id;
        createFotos({ new_caja_id });
        createIfNotEmpty(
          firmeza,
          createFirmezaCaja,
          "Firmeza Guardada",
          new_caja_id
        );
        createIfNotEmpty(
          pesoFruto,
          createPesoFrutoCaja,
          "Peso Fruto Guardado",
          new_caja_id
        );
        createIfNotEmpty(
          diametroFruto,
          createDiametroFrutoCaja,
          "Diametro Fruto Guardado",
          new_caja_id
        );
        createIfNotEmpty(
          pesoFijoSave,
          createPesoFijoCaja,
          "Peso Fijo Guardado",
          new_caja_id
        );
        createIfNotEmpty(brix, createBrixCaja, "Brix Guardado");
      })
      .finally(() => {
        if (planillaType === 1) {
          navigate("/producto-terminado/planillas-lote/cajas", {
            state: planillaState.dataInput,
          });
        } else {
          navigate("/producto-terminado/planillas-pallet/cajas", {
            state: planillaState.dataInput,
          });
        }
      });
  };

  const handleSaveEditCaja = (e) => {
    const updateIfNotEmpty = (array, updateFunction, message) => {
      if (array.length !== 0) {
        updateFunction({ array, caja_id: saveCaja.id }).then((res) => {
          console.log(message, res);
        });
      }
    };

    const updateFotos = () => {
      if (saveImage.fotos !== undefined) {
        Object.entries(saveImage.fotos).forEach((foto) => {
          let key_foto = foto[0];
          let numero_foto_caja = parseInt(key_foto.slice(4));
          let fotito = {
            numero_foto_caja,
            foto: foto[1],
            caja: saveCaja.id,
            defecto: foto[1].defecto,
          };
          updateFotoCaja(fotito, saveImage).then((result) => {
            console.log("Foto Guardada", result);
          });
        });
      }
    };

    updateCaja({ saveCaja, valoresCaja, validation, observacion })
      .then((res) => {
        updateFotos();
        updateIfNotEmpty(firmeza, updateFirmezaCaja, "Firmeza Guardada");
        updateIfNotEmpty(pesoFruto, updatePesoFrutoCaja, "Peso Fruto Guardado");
        updateIfNotEmpty(
          diametroFruto,
          updateDiametroFrutoCaja,
          "Diametro Fruto Guardado"
        );
        updateIfNotEmpty(
          pesoFijoSave,
          updatePesoFijoCaja,
          "Peso Fijo Guardado"
        );
        updateIfNotEmpty(brix, updateBrixCaja, "Brix Guardado");
      })
      .finally(() => {
        navigate("/producto-terminado/planillas-pallet/cajas", {
          state: planillaState.dataInput,
        });
      });
  };

  const handleCancelCaja = (e) => {
    alert("Caja Cancelada");
    navigate("/producto-terminado/planillas-pallet/cajas");
  };

  const handleTab = (index) => {
    setTab(index);
  };

  const handleViewTabContent = (index) => {
    if (index === tab) {
      return "block";
    } else {
      return "none";
    }
  };

  const handlePhotoCajaSave = async (e) => {
    let file = e.target.files[0];
    // get the file name
    let fileName = file.name;

    // Reduce el peso de la imagen lo maximo posible sin cambiar el tamaño y perdiendo la menor calidad posible
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;
      img.onload = function () {
        const elem = document.createElement("canvas");
        const width = 300;
        const scaleFactor = width / img.width;
        elem.width = width;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            if (e.target.id) {
              setSaveImage({
                ...saveImage,
                fotos: {
                  ...saveImage.fotos,
                  [e.target.name]: {
                    id: parseInt(e.target.id),
                    foto: file,
                    numero_foto_caja: parseInt(e.target.name.slice(-1)),
                    sincronizacion: 1,
                    estado: true,
                    defecto: 1,
                  },
                },
                urls: {
                  ...saveImage.urls,
                  [e.target.name]: URL.createObjectURL(file),
                },
              });
            } else {
              setSaveImage({
                ...saveImage,
                fotos: {
                  ...saveImage.fotos,
                  [e.target.name]: {
                    foto: file,
                    numero_foto_caja: parseInt(e.target.name.slice(-1)),
                    sincronizacion: 1,
                    estado: true,
                    defecto: 1,
                  },
                },
                urls: {
                  ...saveImage.urls,
                  [e.target.name]: URL.createObjectURL(file),
                },
              });
            }
          },
          "image/jpeg",
          1
        );
      };
    };
  };

  const handleDefectoFoto = (e) => {
    const { value, name } = e.target;
    let numero_foto_caja = parseInt(name.slice(-1));
    Object.entries(saveImage.fotos).forEach((foto) => {
      if (foto[1].numero_foto_caja === numero_foto_caja) {
        setSaveImage({
          ...saveImage,
          fotos: {
            ...saveImage.fotos,
            [foto[0]]: {
              ...saveImage.fotos[foto[0]],
              defecto: parseInt(value),
            },
          },
        });
      }
      return null;
    });
  };

  return (
    <Layout>
      <StyledHeader>
        <StyledBreadcrumb>
          <StyledBreadcrumbItem>
            Control Producto Terminado / Planillas / Cajas / Detalle{" "}
          </StyledBreadcrumbItem>
        </StyledBreadcrumb>
        <StyledTitle>
          <StyledH1> Detalle Caja </StyledH1>
        </StyledTitle>
      </StyledHeader>
      <StyledTab>
        <TabsCajasContent
          tabs={tabs}
          tab={tab}
          handleTab={handleTab}
          handleViewTabContent={handleViewTabContent}
          filtroEspecieDetalle={filtroEspecieDetalle}
          handleOptions={handleOptions}
          handleUpdateFilter={handleUpdateFilter}
          number={number}
          handlePhotoCajaSave={handlePhotoCajaSave}
          foto={foto}
          saveImage={saveImage}
          initialFilters={initialFilters}
          calibres={calibres}
          especie={especie}
          exportadora={exportadora}
          handleSaveDefectoData={handleSaveDefectoData}
          inputRefs={inputRefs}
          pesoFijo={pesoFijo}
          setPesoFijo={setPesoFijo}
          sumaPesoFijo={sumaPesoFijo}
          totalPesoFijo={totalPesoFijo}
          setTotalPesoFijo={setTotalPesoFijo}
          handlePesoNeto={handlePesoNeto}
          influye={influye}
          observacion={observacion}
          setObservacion={setObservacion}
          firmeza={firmeza}
          setFirmeza={setFirmeza}
          handleDefectoFoto={handleDefectoFoto}
          pesoFruto={pesoFruto}
          setPesoFruto={setPesoFruto}
          diametroFruto={diametroFruto}
          setDiametroFruto={setDiametroFruto}
          pesoFijoSave={pesoFijoSave}
          setPesoFijoSave={setPesoFijoSave}
          brix={brix}
          setBrix={setBrix}
        />
        <StyledButtonsContainer>
          {planillaState.detallesCaja.length !== 0 ? (
            contraMuestraCaja === true ? (
              <FormCrudButton
                text="Crear Contra Muestra"
                onClick={handleSaveCaja}
              />
            ) : copyCaja === true ? (
              <FormCrudButton text="Guardar" onClick={handleSaveCaja} />
            ) : (
              planillaType === 1 ? (
                <FormCrudButton text="Guardar LOTE" onClick={handleSaveCaja} />
              ) : (
                <FormCrudButton text="Actualizar" onClick={handleSaveEditCaja} />
              )
            )
          ) : planillaType === 1 ? (
            <FormCrudButton text="Guardar LOTE" onClick={handleSaveCaja} />
          ) : (
            <FormCrudButton text="Guardar" onClick={handleSaveCaja} />
          )}
          <FormCrudButton text="Cancelar" onClick={handleCancelCaja} />
        </StyledButtonsContainer>
      </StyledTab>
    </Layout>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-self: center;
  margin-top: 15px;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledBreadcrumb = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledBreadcrumbItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;

  &:before {
    content: "\\2022";
    color: #ff9100;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const StyledH1 = styled.h1`
  color: #64766a;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  padding-left: 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

const StyledTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default CajaDetail;
