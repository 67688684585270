import React, { useEffect, useState } from "react";
import useResoluciones from "../../hooks/api/useResoluciones";
import useCajas from "../../hooks/api/useCajas";
import ResolucionForm from "./ResolucionForm";
import {
  StyledContainer,
  StyledButton,
} from "./ResolucionStyles";

function Resolucion({ initialText, caja }) {
  const [isResolucionSelected, setIsResolucionSelected] = useState(false);
  const [resoluciones, setResoluciones] = useState([]);
  const [selectedResolucion, setSelectedResolucion] = useState(null);
  const cajasHijasIds = caja.cajas_hijas?.map(({ caja: { id } }) => id) || [];
  const cumplePacking = caja.cumple_packing;
  const { getResolucionesNoPagination } = useResoluciones();
  const { updateResolucionCaja } = useCajas();

  useEffect(() => {
    getResolucionesNoPagination().then((res) => {
      setResoluciones(res.results);
    });
  }, [getResolucionesNoPagination]);

  const handleResolucionSelection = (e) => {
    setSelectedResolucion(e.target.value);
  };

  const handleResolucionSave = (e) => {
    e.preventDefault();

    updateResolucionCaja({ id: caja.id, resolucion: selectedResolucion, resultado_caja: caja.resultado_caja.id, cajas_hijas: cajasHijasIds, cumplePacking: cumplePacking})
      .then((res) => {
        setSelectedResolucion(null);
      })
      .catch((err) => {
        console.log("updateResolucionCaja", err);
      })
      .finally(() => {
        setIsResolucionSelected(!isResolucionSelected);
      });

    // setIsResolucionSelected(!isResolucionSelected);
  };

  return (
    <StyledContainer>
      {isResolucionSelected ? (
        <ResolucionForm
          resoluciones={resoluciones}
          handleResolucionSelection={handleResolucionSelection}
          handleResolucionSave={handleResolucionSave}
        />
      ) : (
        <StyledButton
          onClick={() => setIsResolucionSelected(!isResolucionSelected)}
        >
          {initialText}
        </StyledButton>
      )}
    </StyledContainer>
  );
}

export default Resolucion;
