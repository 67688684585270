import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getPlanillasInspeccionService,
  createPlanillaInspeccionService,
  updatePlanillaInspeccionService,
  getPlanillasInspeccionByInspectorService,
} from "../../services/planillasInspeccion";

export default function usePlanillasInspeccion() {
  const { state } = useContext(AuthContext);
  const { token, client, user } = state;

  const getPlanillasInspeccion = useCallback(
    ({ rowsPerPage, page, inspeccionTipo }) => {
      const data = {
        usuario: user.id,
      };
      return getPlanillasInspeccionService({
        token,
        rowsPerPage,
        page,
        data,
        inspeccionTipo,
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, user]
  );

  const getPlanillasInspeccionByInspector = useCallback(
    ({ rowsPerPage, page, inspeccionTipo }) => {
      const data = {
        usuario: user.id,
      };
      return getPlanillasInspeccionByInspectorService({
        token,
        rowsPerPage,
        page,
        data,
        inspeccionTipo,
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, user]
  );

  const createPlanillaInspeccion = useCallback(
    (data) => {
      data.cliente = client.id;
      data.usuario = user.id;
      return createPlanillaInspeccionService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client, user]
  );

  const updatePlanillaInspeccion = useCallback(
    (data) => {
      return updatePlanillaInspeccionService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getPlanillasInspeccion,
    createPlanillaInspeccion,
    updatePlanillaInspeccion,
    getPlanillasInspeccionByInspector,
  };
}
