import styled from "styled-components";

const StyleDivFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ff9100;
  border-radius: 5px;
  flex-wrap: wrap;
`;

const StyleDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ff9100;
  border-radius: 5px;
  align-items: center;

  @media (max-width: 610px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ff9100;
    border-radius: 5px;
    align-items: center;
  }
`;

const StyledContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLabel = styled.label`
  color: #64766a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const StyledValidationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSemaforo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledGreenSemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00ff00;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  color: #fff;
`;

const StyledYellowSemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffff00;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
`;

const StyledOrangeSemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
`;

const StyledRedSemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
`;

const StyledGraySemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
`;

export {
    StyleDivFilters,
    StyleDiv,
    StyledContainerInput,
    StyledLabel,
    StyledValidationContainer,
    StyledSemaforo,
    StyledGreenSemaforo,
    StyledYellowSemaforo,
    StyledOrangeSemaforo,
    StyledRedSemaforo,
    StyledGraySemaforo,
};