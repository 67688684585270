import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getPlanillaTypesService, createPlanillaTypeService, updatePlanillaTypeService } from "../../services/planillaTypes";

export default function usePlanillaTypes() {

  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getPlanillaTypes = useCallback(() => {
    const data = {
        cliente: client.id,
    };
    return getPlanillaTypesService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createPlanillaType = useCallback((data) => {
    data.cliente = client.id;
    return createPlanillaTypeService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const updatePlanillaType = useCallback((data) => {
    return updatePlanillaTypeService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  return {
    getPlanillaTypes,
    createPlanillaType,
    updatePlanillaType,
  };
}
