import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    getClasificacionesService,
    createClasificacionService,
    updateClasificacionService,
    createClasificacionExportadoraService,
    createClasificacionEspecieService,
    getClasificacionesNoPaginationService,
    getClasificacionesByEspecieExportadoraService,
  } from "../../services/clasificacion";

export default function useClasificaciones() {

  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getClasificaciones = useCallback(({ rowsPerPage, page }) => {
    const data = {
      cliente: client.id,
    };
    return getClasificacionesService({ token, rowsPerPage, page, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getClasificacionesNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getClasificacionesNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getClasificacionesByEspecieExportadora = useCallback(({especie, exportadora}) => {
    const data = {
      cliente: client.id,
      especie,
      exportadora
    };
    return getClasificacionesByEspecieExportadoraService({ token, data })
      .then((res) => {
        // console.log("Data Hook : ",res.data);
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createClasificacion = useCallback((data) => {
    data.cliente = client.id;
    return createClasificacionService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createClasificacionExportadora = useCallback((data) => {
    data[0].cliente = client.id;
    return createClasificacionExportadoraService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createClasificacionEspecie = useCallback((data) => {
    data[0].cliente = client.id;
    return createClasificacionEspecieService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);


  const updateClasificacion = useCallback((data) => {
    return updateClasificacionService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          console.log("Data Hook : ",res.data);
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  }, [token]);

  return {
    getClasificaciones,
    getClasificacionesByEspecieExportadora,
    createClasificacion,
    createClasificacionExportadora,
    createClasificacionEspecie,
    updateClasificacion,
    getClasificacionesNoPagination,
  };
}
