import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useExportadoras from "../../../../hooks/api/useExportadoras";
import useEspecies from "../../../../hooks/api/useEspecies";
import InputDecimal from "../../../../components/Cajas/InputDecimal";

const CalibreDetalleForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
  calibre,
}) => {
  const [especies, setEspecies] = useState([]);
  const [exportadoras, setExportadoras] = useState([]);

  const { getClientEspecies } = useEspecies();
  const { getExportadorasNoPagination } = useExportadoras();

  console.log("dataInput", dataInput);
  console.log("calibre", calibre);

  useEffect(() => {
    getClientEspecies().then((res) => {
      console.log(res.results);
      setEspecies(res.results);
    });
    getExportadorasNoPagination().then((res) => {
      console.log(res.results);
      setExportadoras(res.results);
    });
  }, [getClientEspecies, getExportadorasNoPagination]);

  const handleValueCalibreDetalle = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value,
      });
    }
    console.log("dataInput", dataInput);
  };
  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Calibre Detalle"
    >
      <StyledInputContainer>
        <StyledLabel>Especie</StyledLabel>
        <StyledSelected
          name="especie"
          value={dataInput.especie !== undefined ? dataInput.especie.id : null}
          onChange={handleValueCalibreDetalle}
        >
          <StyledOption value="0">Seleccione una especie</StyledOption>
          {especies.map((especie) => (
            <StyledOption value={especie.id}>{especie.nombre}</StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledLabel>Exportadora</StyledLabel>
        <StyledSelected
          name="exportadora"
          value={
            dataInput.exportadora !== undefined ? dataInput.exportadora.id : null
          }
          onChange={handleValueCalibreDetalle}
        >
          <StyledOption value="0">Seleccione una exportadora</StyledOption>
          {exportadoras.map((exportadora) => (
            <StyledOption value={exportadora.id}>
              {exportadora.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainerRow>
        <StyledValues>
          <StyledLabelValues>Valor Minimo (mm)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_minimo_milimetros"
            placeholder="0"
            defaultValue={
              dataInput.valor_minimo_milimetros !== undefined
                ? dataInput.valor_minimo_milimetros
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
        <StyledValues>
          <StyledLabelValues>Valor Medio (mm)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_medio_milimetros"
            placeholder="0"
            defaultValue={
              dataInput.valor_medio_milimetros !== undefined
                ? dataInput.valor_medio_milimetros
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
        <StyledValues>
          <StyledLabelValues>Valor Maximo (mm)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_maximo_milimetros"
            placeholder="0"
            defaultValue={
              dataInput.valor_maximo_milimetros !== undefined
                ? dataInput.valor_maximo_milimetros
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
      </StyledInputContainerRow>
      <StyledInputContainerRow>
        <StyledValues>
          <StyledLabelValues>Valor Minimo (gr)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_minimo_gramos"
            placeholder="0"
            defaultValue={
              dataInput.valor_minimo_gramos !== undefined
                ? dataInput.valor_minimo_gramos
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
        <StyledValues>
          <StyledLabelValues>Valor Medio (gr)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_medio_gramos"
            placeholder="0"
            defaultValue={
              dataInput.valor_medio_gramos !== undefined
                ? dataInput.valor_medio_gramos
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
        <StyledValues>
          <StyledLabelValues>Valor Maximo (gr)</StyledLabelValues>
          <InputDecimal
            width={"100px"}
            name="valor_maximo_gramos"
            placeholder="0"
            defaultValue={
              dataInput.valor_maximo_gramos !== undefined
                ? dataInput.valor_maximo_gramos
                : null
            }
            onChange={handleValueCalibreDetalle}
          />
        </StyledValues>
      </StyledInputContainerRow>
      <StyledInputContainer>
        <StyledLabel>Orden</StyledLabel>
        <InputDecimal
          width={"400px"}
          name="orden"
          placeholder="Orden"
          defaultValue={dataInput.orden !== undefined ? dataInput.orden : null}
          onChange={handleValueCalibreDetalle}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledLabel>Estado</StyledLabel>
        <StyledSelected
          name="estado"
          defaultValue={
            dataInput.estado !== undefined ? dataInput.estado : true
          }
          onChange={handleValueCalibreDetalle}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledInputContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledValues = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLabelValues = styled.label`
  color: #64766a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  width: 140px;
  text-align: center;
`;

const StyledLabel = styled.label`
  color: #64766a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 400px;
  text-align: left;
`;

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default CalibreDetalleForm;
