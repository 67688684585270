import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {
  createZonaService,
  getZonasService,
  updateZonaService,
  getZonasNoPaginationService,
} from "../../services/zonas";

export default function useZonas() {

  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getZonas = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getZonasService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getZonasNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getZonasNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createZona = useCallback((data) => {
      data.cliente = client.id;
      return createZonaService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateZona = useCallback((data) => {
      return updateZonaService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getZonas,
    createZona,
    updateZona,
    getZonasNoPagination,
  };
}
