import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #ffffff;
  flex-direction: row;
  box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  top: 0;
  position: fixed;

  @media screen and (min-width: 1024px) {
    justify-content: space-around;
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  transition: 350ms;
  border-radius: 100px;
  -webkit-transition: 350ms;
  flex: 1 0 0;
  height: 100%;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1 0 0;

  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
    margin-left: 64px;
  }
`;

const StyledUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex: 1 0 0;
  height: 100%;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    margin-right: 49px;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 98px;
  }
`;

const StyledUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 0;
  visibility: hidden;

  @media (min-width: 768px) {
    margin-right: 10;
    width: 0px;
    visibility: visible;
  }
`;

const StyledUserName = styled.div`
  margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6C6C6C;
`;

const StyledUserIcon = styled.div`
  width: 42px;
  height: 42px;
  background: none;
  transition: 350ms;
  border-radius: 100px;
  background-color: rgba(196, 196, 196, 0.1);
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 600px) {
    margin-left: 14px;
  }
`;

export {
  StyledHeader,
  StyledMenuContainer,
  StyledLogoContainer,
  StyledUserContainer,
  StyledUser,
  StyledUserName,
  StyledUserIcon,
};
