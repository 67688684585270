import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { MEDICION } from "../../../../context/Actions";
import AuthContext from "../../../../context/AuthProvider";
import { PlanillaContext } from "../../../../context/PlanillaContext";
import { PLANILLA_DATA } from "../../../../context/Actions";

export default function useCajaDetailHelper() {
  const [saveDefecto, setSaveDefecto] = useState([]);
  const [numero_frutos, setNumeroFrutos] = useState(100);
  const [pesoFijo, setPesoFijo] = useState([]);
  const [totalPesoFijo, setTotalPesoFijo] = useState(0);
  const [embalaje, setEmbalaje] = useState(null);

  const { state } = useContext(AuthContext);
  const { colors } = state;

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);
  const { valoresCaja, validation } = planillaState;

  const [newValidation, setNewValidation] = useState(validation);

  console.log("newValidation", newValidation);

  const [influye, setInfluye] = useState({
    calidad: { green: 0, yellow: 0, orange: 0, red: 0 },
    condicion: { green: 0, yellow: 0, orange: 0, red: 0 },
    embalaje: { green: 0, yellow: 0, orange: 0, red: 0 },
    total_caja: { resultado: 0 },
  });

  // const getColorCounts = () => ({
  //   calidad: { green: 0, yellow: 0, orange: 0, red: 0 },
  //   condicion: { green: 0, yellow: 0, orange: 0, red: 0 },
  //   embalaje: { green: 0, yellow: 0, orange: 0, red: 0 },
  // });

  // const getCategory = (id) => {
  //   // 1: embalaje, 2: calidad, 3: condicion
  //   return id === 1 ? "embalaje" : id === 2 ? "calidad" : "condicion";
  // };

  // const getColor = (counts) => {
  //   console.log("counts", counts);
  //   return counts.red > 0
  //     ? "red"
  //     : counts.orange > 0
  //     ? "orange"
  //     : counts.yellow > 0
  //     ? "yellow"
  //     : "green";
  // };

  // const getResultado = useCallback((counts) => {
  //   console.log("counts", counts);
  //   const colorToResultMap = {
  //     red: 4,
  //     orange: 3,
  //     yellow: 2,
  //     green: 1,
  //   };

  //   for (let color in colorToResultMap) {
  //     console.log("color", color);
  //     if (hasColor(color, counts)) {
  //       return colorToResultMap[color];
  //     }
  //   }
  // }, []);

  // const hasColor = (color, counts) => {
  //   console.log("color", color);
  //   console.log("counts", counts);
  //   return (
  //     counts.calidad[color] > 0 ||
  //     counts.condicion[color] > 0 ||
  //     counts.embalaje[color] > 0
  //   );
  // };

  useEffect(() => {
    planillaDispatch({
      type: PLANILLA_DATA.SET_VALIDATION,
      payload: {
        ...newValidation,
      },
    });
  }, [newValidation, planillaDispatch]);

  useEffect(() => {
    let green_calidad = 0;
    let yellow_calidad = 0;
    let orange_calidad = 0;
    let red_calidad = 0;
    let green_condicion = 0;
    let yellow_condicion = 0;
    let orange_condicion = 0;
    let red_condicion = 0;
    let green_embalaje = 0;
    let yellow_embalaje = 0;
    let orange_embalaje = 0;
    let red_embalaje = 0;
    let resultado = 0;
    Object.keys(validation).forEach((key) => {
      if (validation[key].influye !== undefined) {
        if (validation[key].tipo_medicion !== undefined) {
          console.log("validation[key].tipo_medicion.id", validation[key]);
          if (
            validation[key].tipo_medicion.id !== 8 ||
            validation[key].tipo_medicion.id !== 9 ||
            validation[key].tipo_medicion.id !== 10
          ) {
            if (validation[key].influye.id === 2) {
              if (validation[key].cumple === 1) {
                green_calidad = green_calidad + 1;
              }
              if (validation[key].cumple === 2) {
                yellow_calidad = yellow_calidad + 1;
              }
              if (validation[key].cumple === 3) {
                orange_calidad = orange_calidad + 1;
              }
              if (validation[key].cumple === 4) {
                red_calidad = red_calidad + 1;
              }
            }
            if (validation[key].influye.id === 3) {
              if (validation[key].cumple === 1) {
                green_condicion = green_condicion + 1;
              }
              if (validation[key].cumple === 2) {
                yellow_condicion = yellow_condicion + 1;
              }
              if (validation[key].cumple === 3) {
                orange_condicion = orange_condicion + 1;
              }
              if (validation[key].cumple === 4) {
                red_condicion = red_condicion + 1;
              }
            }
            if (validation[key].influye.id === 4) {
              if (validation[key].cumple === 1) {
                green_embalaje = green_embalaje + 1;
              }
              if (validation[key].cumple === 2) {
                yellow_embalaje = yellow_embalaje + 1;
              }
              if (validation[key].cumple === 3) {
                orange_embalaje = orange_embalaje + 1;
              }
              if (validation[key].cumple === 4) {
                red_embalaje = red_embalaje + 1;
              }
            }
          }
        }
      }
    });
    // console.log("red_calidad", red_calidad);
    // console.log("red_condicion", red_condicion);
    // console.log("red_embalaje", red_embalaje);

    if (red_calidad === 1) {
      red_calidad = 0;
    }

    if (orange_calidad === 1) {
      orange_calidad = 0;
    }

    if (yellow_calidad === 1) {
      yellow_calidad = 0;
    }

    if (red_condicion === 1) {
      red_condicion = 0;
    }

    if (orange_condicion === 1) {
      orange_condicion = 0;
    }

    if (yellow_condicion === 1) {
      yellow_condicion = 0;
    }

    if (red_embalaje === 1) {
      red_embalaje = 0;
    }

    if (orange_embalaje === 1) {
      orange_embalaje = 0;
    }

    if (yellow_embalaje === 1) {
      yellow_embalaje = 0;
    }

    if (red_calidad > 0 || red_condicion > 0 || red_embalaje > 0) {
      resultado = 4;
    } else if (
      orange_calidad > 0 ||
      orange_condicion > 0 ||
      orange_embalaje > 0
    ) {
      resultado = 3;
    } else if (
      yellow_calidad > 0 ||
      yellow_condicion > 0 ||
      yellow_embalaje > 0
    ) {
      resultado = 2;
    } else {
      resultado = 1;
    }

    setInfluye((prevState) => {
      return {
        ...prevState,
        calidad: {
          green: green_calidad,
          yellow: yellow_calidad,
          orange: orange_calidad,
          red: red_calidad,
        },
        condicion: {
          green: green_condicion,
          yellow: yellow_condicion,
          orange: orange_condicion,
          red: red_condicion,
        },
        embalaje: {
          green: green_embalaje,
          yellow: yellow_embalaje,
          orange: orange_embalaje,
          red: red_embalaje,
        },
        total_caja: {
          resultado: resultado,
        },
      };
    });

    const handleValidation = (value, key, inputValue, color) => {
      const input = inputRefs.current[key] || {current: {value: 1}}; // añadí este valor por si no existe el input para testear, parece funcionar
      input.current.value = inputValue;
      let newValidationValue = {
        [value[key].id]: {
          value: inputValue,
          color: color,
          influye: value[key].influye,
          cumple: inputValue,
        },
      };

      setNewValidation((prevState) => {
        return {
          ...prevState,
          ...newValidationValue,
        };
      });
      return {
        [key]: {
          ...value[key],
          value: inputValue,
        },
      };
    };

    const newValoresCaja = valoresCaja.map((value, indx) => {
      let key = Object.keys(value)[0];
      if (value[key].tipo_medicion.id === 8) {
        if (red_calidad > 0)
          return handleValidation(
            value,
            key,
            4,
            colors.find((color) => color.cumple.id === 4).color_texto
          );
        if (orange_calidad > 0)
          return handleValidation(
            value,
            key,
            3,
            colors.find((color) => color.cumple.id === 3).color_texto
          );
        if (yellow_calidad > 0)
          return handleValidation(
            value,
            key,
            2,
            colors.find((color) => color.cumple.id === 2).color_texto
          );
        return handleValidation(
          value,
          key,
          1,
          colors.find((color) => color.cumple.id === 1).color_texto
        );
      } else if (value[key].tipo_medicion.id === 9) {
        if (red_condicion > 0)
          return handleValidation(
            value,
            key,
            4,
            colors.find((color) => color.cumple.id === 4).color_texto
          );
        if (orange_condicion > 0)
          return handleValidation(
            value,
            key,
            3,
            colors.find((color) => color.cumple.id === 3).color_texto
          );
        if (yellow_condicion > 0)
          return handleValidation(
            value,
            key,
            2,
            colors.find((color) => color.cumple.id === 2).color_texto
          );
        return handleValidation(
          value,
          key,
          1,
          colors.find((color) => color.cumple.id === 1).color_texto
        );
      } else if (value[key].tipo_medicion.id === 10) {
        if (red_embalaje > 0)
          return handleValidation(
            value,
            key,
            4,
            colors.find((color) => color.cumple.id === 4).color_texto
          );
        if (orange_embalaje > 0)
          return handleValidation(
            value,
            key,
            3,
            colors.find((color) => color.cumple.id === 3).color_texto
          );
        if (yellow_embalaje > 0)
          return handleValidation(
            value,
            key,
            2,
            colors.find((color) => color.cumple.id === 2).color_texto
          );
        return handleValidation(
          value,
          key,
          1,
          colors.find((color) => color.cumple.id === 1).color_texto
        );
      } else {
        return value;
      }
    });

    // const newValoresCaja = valoresCaja.map((value, indx) => {
    //   // console.log("value", value);
    //   let key = Object.keys(value)[0];
    //   // console.log("key", key);
    //   // console.log("value[key]", value[key]);
    //   // console.log("inputRefs", inputRefs)
    //   if (value[key].tipo_medicion.id === 8) {
    //     // console.log("value[key].tipo_medicion.id", value[key].tipo_medicion.id);
    //     // console.log("inputRefs.current[key]", inputRefs.current[key]);
    //     const input = inputRefs.current[key];
    //     // console.log("input", input);
    //     if (red_calidad > 0) {
    //       input.current.value = 4;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 4,
    //           color: "red",
    //           influye: value[key].influye,
    //           cumple: 4,
    //         },
    //       };
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 4,
    //         },
    //       };
    //     } else if (orange_calidad > 0) {
    //       input.current.value = 3;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 3,
    //           color: "orange",
    //           influye: value[key].influye,
    //           cumple: 3,
    //         },
    //       };
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 3,
    //         },
    //       };
    //     } else if (yellow_calidad > 0) {
    //       input.current.value = 2;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 2,
    //           color: "yellow",
    //           influye: value[key].influye,
    //           cumple: 2,
    //         },
    //       };
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 2,
    //         },
    //       };
    //     } else {
    //       // input.current.value = 1;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 1,
    //           color: "green",
    //           influye: value[key].influye,
    //           cumple: 1,
    //         },
    //       };
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 1,
    //         },
    //       };
    //     }
    //   } else if (value[key].tipo_medicion.id === 9) {
    //     const input = inputRefs.current[key];
    //     // console.log("input", input.current.value);
    //     if (red_condicion > 0) {
    //       input.current.value = 4;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 4,
    //           color: "red",
    //           influye: value[key].influye,
    //           cumple: 4,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 4,
    //         },
    //       };
    //     } else if (orange_condicion > 0) {
    //       input.current.value = 3;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 3,
    //           color: "orange",
    //           influye: value[key].influye,
    //           cumple: 3,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 3,
    //         },
    //       };
    //     } else if (yellow_condicion > 0) {
    //       input.current.value = 2;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 2,
    //           color: "yellow",
    //           influye: value[key].influye,
    //           cumple: 2,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 2,
    //         },
    //       };
    //     } else {
    //       // input.current.value = 1;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 1,
    //           color: "green",
    //           influye: value[key].influye,
    //           cumple: 1,
    //         },
    //       };
    //       // console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: "SET_VALIDATION",
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 1,
    //         },
    //       };
    //     }
    //   } else if (value[key].tipo_medicion.id === 10) {
    //     const input = inputRefs.current[key];
    //     if (red_embalaje > 0) {
    //       input.current.value = 4;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 4,
    //           color: "red",
    //           influye: value[key].influye,
    //           cumple: 4,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 4,
    //         },
    //       };
    //     } else if (orange_embalaje > 0) {
    //       input.current.value = 3;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 3,
    //           color: "orange",
    //           influye: value[key].influye,
    //           cumple: 3,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 3,
    //         },
    //       };
    //     } else if (yellow_embalaje > 0) {
    //       input.current.value = 2;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 2,
    //           color: "yellow",
    //           influye: value[key].influye,
    //           cumple: 2,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 2,
    //         },
    //       };
    //     } else {
    //       input.current.value = 1;
    //       let newValidation = {
    //         [value[key].id]: {
    //           value: 1,
    //           color: "green",
    //           influye: value[key].influye,
    //           cumple: 1,
    //         },
    //       };
    //       console.log("newValidation", newValidation);
    //       planillaDispatch({
    //         type: PLANILLA_DATA.SET_VALIDATION,
    //         payload: {
    //           ...validation,
    //           ...newValidation,
    //         },
    //       });
    //       return {
    //         [key]: {
    //           ...value[key],
    //           value: 1,
    //         },
    //       };
    //     }
    //   } else {
    //     return value;
    //   }
    // });
    planillaDispatch({
      type: "SET_VALORES_CAJA",
      payload: newValoresCaja,
    });
    // console.log("newValoresCaja", newValoresCaja);
  }, [saveDefecto]);

  // console.log("ValoresCaja", valoresCaja);
  // console.log("validation", validation);

  const inputRefs = useRef([]);

  const handleSaveDefectoData = (e, item) => {
    setSaveDefecto({
      ...saveDefecto,
      [e.target.name]: parseInt(e.target.value),
    });
    handleGetData(e, item);
    handleUpdateValoresCaja({ e });
  };

  const handleGetData = (e, item) => {
    let valor_1 = parseInt(item.norma_valor_1);
    let valor_2 = parseInt(item.norma_valor_2);
    let valor_3 = parseInt(item.norma_valor_3);
    let input = e.target.value;
    handleValidateDefecto({ item, valor_1, valor_2, valor_3, input });
  };

  const handleGetDataFromValidate = ({ valor_nuevo, item }) => {
    let valor_1 = parseInt(item.norma_valor_1);
    let valor_2 = parseInt(item.norma_valor_2);
    let valor_3 = parseInt(item.norma_valor_3);
    let input =
      item.planilla_defecto.tipo_medicion.id === MEDICION.PESO_NETO
        ? valor_nuevo
        : parseInt(valor_nuevo);
    handleValidateDefecto({ item, valor_1, valor_2, valor_3, input });
  };

  // const handleValidateDefecto = ({
  //   item,
  //   valor_1,
  //   valor_2,
  //   valor_3,
  //   input,
  // }) => {
  //   let n_frutos = valoresCaja.find((caja) => {
  //     let key = Object.keys(caja)[0];
  //     return caja[key].tipo_medicion.id === MEDICION.NUMERO_FRUTOS;
  //   });

  //   let n_frutos_key = Object.keys(n_frutos)[0];
  //   let n_frutos_value = n_frutos[n_frutos_key].value;
  //   setNumeroFrutos(n_frutos_value);

  //   const tipoMedicionId = item.planilla_defecto.tipo_medicion.id;

  //   var newData = {};

  //   switch (tipoMedicionId) {
  //     case MEDICION.PESO_NETO:
  //       console.log("Peso Neto");
  //       let validateResultPesoNeto = handleValidatePesoNeto(input, item);
  //       console.log("validateResultPesoNeto", validateResultPesoNeto);
  //       newData = {
  //         ...validateResultPesoNeto,
  //       };
  //       console.log("newData", newData);
  //       break;
  //     case MEDICION.NUMERO_FRUTOS:
  //       setNumeroFrutos(input);
  //       break;
  //     case MEDICION.NOTA:
  //       let validateResultNota = handleValidateNota(
  //         input,
  //         valor_1,
  //         valor_2,
  //         valor_3,
  //         item
  //       );
  //       newData = {
  //         ...validateResultNota,
  //       };
  //       break;
  //     case MEDICION.PORCENTAJE:
  //       let newNumeroFrutos =
  //         n_frutos_value !== 0 ? n_frutos_value : numero_frutos;
  //       let validateResultPorcentaje = handleValidatePorcentaje(
  //         input,
  //         newNumeroFrutos,
  //         valor_1,
  //         valor_2,
  //         valor_3,
  //         item
  //       );
  //       newData = {
  //         ...validateResultPorcentaje,
  //       };
  //       break;
  //     case MEDICION.BRIX:
  //       console.log("Brix");
  //       const validateResultBrix = handleBrix(
  //         input,
  //         valor_1,
  //         valor_2,
  //         valor_3,
  //         item
  //       );
  //       newData = {
  //         ...validateResultBrix,
  //       };
  //       console.log("validation", validation);
  //       break;
  //     case MEDICION.NUMERO:
  //       let validateResultNumber = handleValidateNumber(
  //         input,
  //         valor_1,
  //         valor_2,
  //         valor_3,
  //         item
  //       );
  //       newData = {
  //         ...validateResultNumber,
  //       };
  //       break;
  //     default:
  //       console.log("No se encontro el tipo de medicion");
  //       break;
  //   }

  //   console.log("newData", newData);

  //   planillaDispatch({
  //     type: PLANILLA_DATA.SET_VALIDATION,
  //     payload: newData,
  //   });

  //   console.log("validation", validation);
  // };

  const getColorFromCumpleId = (cumpleId) => {
    console.log("cumpleId", cumpleId);
    return colors.find((color) => color.cumple.id === cumpleId).color_texto;
  };

  const getColorFromCumpleId2 = (cumpleId) => {
    console.log("cumpleId", cumpleId);
    return colors.find((color) => color.cumple.id === cumpleId).color;
  };

  const updateValidation = (
    input,
    color_texto,
    color_background,
    cumple,
    item
  ) => {
    console.log("item", item);
    let newValidation3 = {
      value: input,
      color_texto: color_texto,
      color: color_background,
      influye: item.influye,
      tipo_medicion: item.planilla_defecto.tipo_medicion.id,
      cumple: cumple,
    };
    let newValidation2 = {
      [item.planilla_defecto.defecto.id]: newValidation3,
    };
    return newValidation2;
  };

  const handleValidateNumber = (input, valor_1, valor_2, valor_3, item) => {
    if (input >= valor_1 && input <= valor_2) {
      updateValidation(
        input,
        getColorFromCumpleId(1),
        getColorFromCumpleId2(1),
        1,
        item
      );
    } else if (input > valor_2 && input <= valor_3) {
      updateValidation(
        input,
        getColorFromCumpleId(2),
        getColorFromCumpleId2(2),
        2,
        item
      );
    } else if (input < valor_1 || input > valor_3) {
      updateValidation(
        input,
        getColorFromCumpleId(4),
        getColorFromCumpleId2(4),
        4,
        item
      );
    } else {
      // delete validation[item.planilla_defecto.defecto.id];
      let newValidation = {
        ...validation,
      };
      delete newValidation[item.planilla_defecto.defecto.id];
      setNewValidation(newValidation);
    }
  };

  const handleValidatePesoNeto = (input, valor_1, valor_2, valor_3, item) => {
    let response;
    if (embalaje !== null) {
      console.log("embalaje", embalaje);
      console.log("input", input);
      let peso_1 = parseInt(embalaje.peso_neto_minimo);
      let peso_2 = parseInt(embalaje.peso_neto_medio);
      let peso_3 = parseInt(embalaje.peso_neto_maximo);
      if (input >= peso_1 && input <= peso_2) {
        response = updateValidation(
          input,
          getColorFromCumpleId(1),
          getColorFromCumpleId2(1),
          1,
          item
        );
      } else if (input > peso_2 && input <= peso_3) {
        response = updateValidation(
          input,
          getColorFromCumpleId(2),
          getColorFromCumpleId2(2),
          2,
          item
        );
      } else if (input < peso_1 || input > peso_3) {
        response = updateValidation(
          input,
          getColorFromCumpleId(4),
          getColorFromCumpleId2(4),
          4,
          item
        );
      } else {
        response = updateValidation(
          0,
          getColorFromCumpleId(1),
          getColorFromCumpleId2(1),
          1,
          item
        );
        let newValidations = {
          ...validation,
        };
        delete newValidations[item.planilla_defecto.defecto.id];
        setNewValidation(newValidations);
      }
    } else {
      response = updateValidation(
        input,
        getColorFromCumpleId(1),
        getColorFromCumpleId2(1),
        1,
        item
      );
      let newValidations = {
        ...validation,
      };
      delete newValidations[item.planilla_defecto.defecto.id];
      setNewValidation(newValidations);
    }
    console.log("response", response);
    return response;
  };

  const handleBrix = (input, valor_1, valor_2, valor_3, item) => {
    console.log("input", input);
    const checkAndUpdateValidation = (condition1, condition2, cumpleId) => {
      if (condition1 && condition2) {
        let res = updateValidation(
          input,
          getColorFromCumpleId(cumpleId),
          getColorFromCumpleId2(cumpleId),
          cumpleId,
          item
        );
        console.log("res", res);
        return res;
      }
    };

    // if input is 0 or distint to NaN
    if (input === 0 || isNaN(input)) {
      let newValidations = {
        ...validation,
      };
      console.log("item", newValidations);
      delete newValidations[item.planilla_defecto.defecto.id];
      console.log("item", newValidations);
      setNewValidation(newValidations);
      return;
    } else {
      if (valor_1 >= valor_2 && valor_2 > valor_3) {
        return (
          checkAndUpdateValidation(input <= valor_1, input >= valor_2, 1) ||
          checkAndUpdateValidation(input < valor_2, input >= valor_3, 2) ||
          checkAndUpdateValidation(input > valor_1 || input < valor_3, true, 4)
        );
      } else if (valor_1 < valor_2 && valor_2 <= valor_3) {
        return (
          checkAndUpdateValidation(input >= valor_1, input <= valor_2, 1) ||
          checkAndUpdateValidation(input > valor_2, input <= valor_3, 2) ||
          checkAndUpdateValidation(input > valor_3 || input < valor_1, true, 4)
        );
      }
    }
  };

  const handleValidateNota = (input, valor_1, valor_2, valor_3, item) => {
    let color_texto, color_background, cumple;

    if (input >= valor_1 && input < valor_2) {
      color_texto = getColorFromCumpleId(1);
      color_background = getColorFromCumpleId2(1);
      cumple = 1;
    } else if (input >= valor_2 && input < valor_3) {
      color_texto = getColorFromCumpleId(2);
      color_background = getColorFromCumpleId2(2);
      cumple = 2;
    } else if (input >= valor_3 || input < valor_1) {
      color_texto = getColorFromCumpleId(4);
      color_background = getColorFromCumpleId2(4);
      cumple = 4;
    } else {
      delete validation[item.planilla_defecto.defecto.id];
      return;
    }

    let newValidations = {
      [item.planilla_defecto.defecto.id]: {
        value: input,
        color_texto: color_texto,
        color: color_background,
        influye: item.influye,
        tipo_medicion: item.planilla_defecto.tipo_medicion.id,
        cumple: cumple,
      },
    };
    return newValidations;
  };

  const handleValidatePorcentaje = (input, valor_1, valor_2, valor_3, item) => {
    let porcentaje = (input * 100) / numero_frutos;
    let inputPorcentual = parseFloat(porcentaje.toFixed(1));

    let color_texto, color_background, cumple;

    if (inputPorcentual >= valor_1 && inputPorcentual <= valor_2) {
      color_texto = getColorFromCumpleId(1);
      color_background = getColorFromCumpleId2(1);
      cumple = 1;
    } else if (inputPorcentual > valor_2 && inputPorcentual <= valor_3) {
      color_texto = getColorFromCumpleId(2);
      color_background = getColorFromCumpleId2(2);
      cumple = 2;
    } else if (inputPorcentual < valor_1 || inputPorcentual > valor_3) {
      color_texto = getColorFromCumpleId(4);
      color_background = getColorFromCumpleId2(4);
      cumple = 4;
    } else {
      delete validation[item.planilla_defecto.defecto.id];
      return;
    }

    let newValidations = {
      [item.planilla_defecto.defecto.id]: {
        value: inputPorcentual,
        color_texto: color_texto,
        color: color_background,
        influye: item.influye,
        tipo_medicion: item.planilla_defecto.tipo_medicion.id,
        cumple: cumple,
      },
    };
    return newValidations;
  };

  const validationHandlers = {
    [MEDICION.PESO_NETO]: handleValidatePesoNeto,
    [MEDICION.NUMERO_FRUTOS]: (_, __, ___, ____, item) => setNumeroFrutos(item),
    [MEDICION.NOTA]: handleValidateNota,
    [MEDICION.PORCENTAJE]: handleValidatePorcentaje,
    [MEDICION.BRIX]: handleBrix,
    [MEDICION.NUMERO]: handleValidateNumber,
  };

  const handleValidateDefecto = ({
    item,
    valor_1,
    valor_2,
    valor_3,
    input,
  }) => {
    let n_frutos = valoresCaja.find((caja) => {
      let key = Object.keys(caja)[0];
      return caja[key].tipo_medicion.id === MEDICION.NUMERO_FRUTOS;
    });

    let n_frutos_key = Object.keys(n_frutos)[0];
    let n_frutos_value = n_frutos[n_frutos_key].value;
    setNumeroFrutos(n_frutos_value);

    const tipoMedicionId = item.planilla_defecto.tipo_medicion.id;

    const handler = validationHandlers[tipoMedicionId];
    if (handler) {
      const newData = handler(input, valor_1, valor_2, valor_3, item);
      setNewValidation((prevState) => {
        return {
          ...prevState,
          ...newData,
        };
      });
    } else {
      console.log("No se encontro el tipo de medicion");
    }
  };

  const handleUpdateValoresCaja = ({ e }) => {
    const newValues = valoresCaja.map((value) => {
      let key = Object.keys(value)[0];
      if (value[key].id !== parseInt(e.target.name)) return value;

      let valor = e.target.value === "" ? 0 : parseInt(e.target.value);
      return {
        [key]: {
          ...value[key],
          value: valor,
        },
      };
    });
    handleValidateOperaciones({ newValues });
  };

  const handleValidateOperaciones = ({ newValues }) => {
    const operaciones = newValues.map((value) => {
      let key = Object.keys(value)[0];
      if (value[key].operaciones !== false) {
        let a_operar = value[key].operaciones.parametros;
        let valor_nuevo = newValues.reduce((total, value) => {
          let key = Object.keys(value)[0];
          return a_operar.includes(parseInt(key))
            ? total + value[key].value
            : total;
        }, 0);
        const input = inputRefs.current[key];
        input.current.value = valor_nuevo;
        let validationKey = value[key].id;
        let item = {
          planilla_defecto: {
            defecto: {
              id: validationKey,
            },
            tipo_medicion: {
              id: value[key].tipo_medicion.id,
            },
          },
          operaciones: {
            parametros: a_operar,
          },
          norma_valor_1: value[key].norma_valor_1,
          norma_valor_2: value[key].norma_valor_2,
          norma_valor_3: value[key].norma_valor_3,
          influye: value[key].influye,
        };
        handleGetDataFromValidate({ valor_nuevo, item });
        return {
          [key]: {
            ...value[key],
            value: valor_nuevo,
          },
        };
      } else {
        return value;
      }
    });
    planillaDispatch({
      type: PLANILLA_DATA.SET_VALORES_CAJA,
      payload: operaciones,
    });
    handleFinalCheck({ newValues: operaciones });
  };

  const handleFinalCheck = ({ newValues }) => {
    const defectos = newValues.map((value) => {
      let key = Object.keys(value)[0];
      if (value[key].operaciones !== false) {
        let a_operar = value[key].operaciones.parametros;
        let valor_nuevo = newValues.reduce((total, value) => {
          let key = Object.keys(value)[0];
          return a_operar.includes(parseInt(key))
            ? total + value[key].value
            : total;
        }, 0);
        const input = inputRefs.current[key];
        input.current.value = valor_nuevo;
        let validationKey = value[key].id;
        let item = {
          planilla_defecto: {
            defecto: {
              id: validationKey,
            },
            tipo_medicion: {
              id: value[key].tipo_medicion.id,
            },
          },
          operaciones: {
            parametros: a_operar,
          },
          norma_valor_1: value[key].norma_valor_1,
          norma_valor_2: value[key].norma_valor_2,
          norma_valor_3: value[key].norma_valor_3,
          influye: value[key].influye,
        };
        handleGetDataFromValidate({ valor_nuevo, item });
        return {
          [key]: {
            ...value[key],
            value: valor_nuevo,
          },
        };
      } else {
        return value;
      }
    });
    planillaDispatch({
      type: PLANILLA_DATA.SET_VALORES_CAJA,
      payload: defectos,
    });
  };

  const sumaPesoFijo = useCallback((pesoFijo) => {
    return pesoFijo.reduce((total, value) => total + value, 0);
  }, []);

  // Funcion que valida el peso fijo.
  // Si la suma de los pesos fijos ingresados en la pestaña de pesos fijos es 0, se mantiene el peso neto base de la caja.
  // Si la suma de los pesos fijos es mayor a 0, se reemplaza el valor de la suma de los pesos por el valor base de la caja.
  const handlePesoNeto = (kilogramos) => {
    if (kilogramos === 0) return;

    const newPesoNeto = valoresCaja.map((value) => {
      let key = Object.keys(value)[0];
      if (value[key].tipo_medicion.id !== 3) return value;

      const input = inputRefs.current[key];
      input.current.value = kilogramos;
      let validationKey = value[key].id;
      let item = {
        planilla_defecto: {
          defecto: {
            id: validationKey,
          },
          tipo_medicion: {
            id: value[key].tipo_medicion.id,
          },
        },
        embalaje: value[key].embalaje,
        norma_valor_1: value[key].norma_valor_1,
        norma_valor_2: value[key].norma_valor_2,
        norma_valor_3: value[key].norma_valor_3,
        influye: value[key].influye,
      };
      let valor_nuevo = kilogramos;
      handleGetDataFromValidate({ valor_nuevo, item });

      return {
        [key]: {
          ...value[key],
          value: kilogramos,
        },
      };
    });

    planillaDispatch({
      type: PLANILLA_DATA.SET_VALORES_CAJA,
      payload: newPesoNeto,
    });
  };

  return {
    inputRefs,
    saveDefecto,
    setSaveDefecto,
    pesoFijo,
    setPesoFijo,
    handleSaveDefectoData,
    handleUpdateValoresCaja,
    handleValidateOperaciones,
    sumaPesoFijo,
    totalPesoFijo,
    setTotalPesoFijo,
    handlePesoNeto,
    embalaje,
    setEmbalaje,
    influye,
  };
}
