import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useEspecies from "../../../../hooks/api/useEspecies";
import useExportadoras from "../../../../hooks/api/useExportadoras";
import useTipoInspeccion from "../../../../hooks/api/useTipoInspeccion";

const EspecieDetalleForm = ({
  handleSave,
  handleCancel,
  create,
  dataInput,
  setDataInput,
  handleEdit,
  edit,
}) => {
  const [especies, setEspecies] = useState([]);
  const [exportadoras, setExportadoras] = useState([]);
  const [inspeccion_tipos, setInspeccionTipos] = useState([]);
  const { getClientEspecies } = useEspecies();
  const { getExportadorasNoPagination } = useExportadoras();
  const { getTipoInspeccionNoPagination } = useTipoInspeccion();
  useEffect(() => {
    Promise.allSettled([
      getClientEspecies(),
      getExportadorasNoPagination(),
      getTipoInspeccionNoPagination(),
    ]).then((res) => {
      setEspecies(res[0].value.results);
      setExportadoras(res[1].value.results);
      setInspeccionTipos(res[2].value.results);
    });
  }, [getClientEspecies, getExportadorasNoPagination, getTipoInspeccionNoPagination]);

  const handleValue = (e) => {
    if (dataInput.estado === undefined || dataInput.clasificacion === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        clasificacion: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value,
      });
    }
    console.log("dataInput", dataInput);
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Especie Detalle"
    >
      <StyledInputContainer>
        <StyledInputLabel>Especie</StyledInputLabel>
        <StyledSelected
          name="especie"
          value={dataInput.especie !== undefined ? dataInput.especie.id : 0}
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Especie</StyledOption>
          {especies.map((especie, index) => (
            <StyledOption key={index} value={especie.id}>
              {especie.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Exportadora</StyledInputLabel>
        <StyledSelected
          name="exportadora"
          value={
            dataInput.exportadora !== undefined ? dataInput.exportadora.id : 0
          }
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Exportadora</StyledOption>
          {exportadoras.map((exportadora, index) => (
            <StyledOption key={index} value={exportadora.id}>
              {exportadora.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Inspeccion Tipo</StyledInputLabel>
        <StyledSelected
          name="inspeccion_tipo"
          value={
            dataInput.inspeccion_tipo !== undefined ? dataInput.inspeccion_tipo.id : 0
          }
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Inspeccion Tipo</StyledOption>
          {inspeccion_tipos.map((inspeccion_tipo, index) => (
            <StyledOption key={index} value={inspeccion_tipo.id}>
              {inspeccion_tipo.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Clasificacion</StyledInputLabel>
        <StyledSelected
          name="clasificacion"
          defaultValue={
            dataInput.clasificacion !== undefined ? dataInput.clasificacion : true
          }
          onChange={handleValue}
        >
          <StyledOption value="true">Si</StyledOption>
          <StyledOption value="false">No</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={dataInput.estado !== undefined ? dataInput.estado : true}
          onChange={handleValue}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

export default EspecieDetalleForm;

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 20px;
`;