import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import InputNumber from "../../../components/Cajas/InputNumber";
import useCalibreDetalles from "../../../hooks/api/useCalibreDetalles";
import { PlanillaContext } from "../../../context/PlanillaContext";
import {
  StyledSemaforo,
  StyledGreenSemaforo,
  StyledYellowSemaforo,
  StyledOrangeSemaforo,
  StyledRedSemaforo,
  StyledGraySemaforo,
} from "./styledCajaContent";

const PesoFrutoContent = ({
  especie,
  exportadora,
  pesoFruto,
  setPesoFruto,
}) => {
  const [numeroCestas, setNumeroCestas] = useState(0);
  // const [calibreDetalle, setCalibreDetalle] = useState([]);
  const [valorMinimoGR, setValorMinimoGR] = useState(0);
  const [valorMedioGR, setValorMedioGR] = useState(0);
  const [valorMaximoGR, setValorMaximoGR] = useState(0);
  const { getEmbalajeFirmeza } = useEmbalajes();
  const { getCalibreDetalleByEspecieExportadora } = useCalibreDetalles();
  // var embalaje = initialFilters.Embalaje;
  const [calibre, setCalibre] = useState(0);

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { detallesCaja, filters, valoresCaja, validation } = planillaState;

  useEffect(() => {
    getEmbalajeFirmeza(filters.Embalaje).then((res) => {
      res.results.forEach((element) => {
        if (element.tab.id === 5) {
          setNumeroCestas(element.numero_cestas);
        }
      });
    });
  }, [getEmbalajeFirmeza, filters.Embalaje]);

  useEffect(() => {
    const newCalibre = valoresCaja.filter((valor) => {
      let key = Object.keys(valor)[0];
      if (valor[key].tipo_medicion.id === 13) {
        return valor;
      } else {
        return null;
      }
    })[0];
    const calibreId = newCalibre[Object.keys(newCalibre)[0]].value;
    setCalibre(calibreId);
    getCalibreDetalleByEspecieExportadora({
      especie,
      exportadora,
      calibreId,
    }).then((res) => {
      // setCalibreDetalle(res.results);
      if (res.results.length !== 0) {
        setValorMinimoGR(parseInt(res.results[0].valor_minimo_gramos));
        setValorMedioGR(parseInt(res.results[0].valor_medio_gramos));
        setValorMaximoGR(parseInt(res.results[0].valor_maximo_gramos));
      }
    });
  }, [
    getCalibreDetalleByEspecieExportadora,
    especie,
    exportadora,
    calibre,
    valoresCaja,
  ]);

  const handleValidatePesoFrutoTab = (e) => {
    const { name, value } = e.target;

    let cumple = 1;
    if (value >= valorMinimoGR && value <= valorMedioGR) {
      let newValidation = {
        ...validation,
        [name]: {
          value: value,
          color: "green",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: newValidation,
      });
      cumple = 1;
    } else if (value > valorMedioGR && value <= valorMaximoGR) {
      let newValidation = {
        ...validation,
        [name]: {
          value: value,
          color: "yellow",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: newValidation,
      });
      cumple = 2;
    } else if (value < valorMinimoGR || value > valorMaximoGR) {
      let newValidation = {
        ...validation,
        [name]: {
          value: value,
          color: "red",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: newValidation,
      });
      cumple = 4;
    } else {
      delete validation[name];
    }

    if (detallesCaja.length !== 0) {
      setPesoFruto({
        ...pesoFruto,
        [name]: {
          id: e.target.id,
          peso: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        },
      });
    } else {
      setPesoFruto({
        ...pesoFruto,
        [name]: {
          peso: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        },
      });
    }
  };

  const handleDefaultPesoFruto = (i) => {
    let valor = "";
    if (detallesCaja.length !== 0) {
      detallesCaja[0].caja.peso_frutos.forEach((element) => {
        console.log("element", element);
        if (element.numero === i + 1) {
          valor = element.peso_fruto;
        }
      });
    }
    // console.log("valor", valor);
    return valor;
  }

  return (
    <StyleDivFirmeza>
      {[...Array(numeroCestas)].map((e, i) => {
        return (
          <StyledDiv key={i + 1}>
            <label> Peso Fruto {i + 1} </label>
            <InputNumber
              name={`peso_fruto${i + 1}`}
              placeholder="Peso Fruto"
              min={0}
              max={10000}
              step={0.1}
              // añadir el id del peso fruto si existe
              id={detallesCaja.length !== 0 ? detallesCaja[0].caja.peso_frutos.find((element) => element.numero === i + 1)?.id : ""}
              onChange={(e) => {
                handleValidatePesoFrutoTab(e);
              }}
              defaultValue={
                handleDefaultPesoFruto(i)
              }
            />
            <StyledSemaforo>
              {validation[`peso_fruto${i + 1}`]?.color === "green" ? (
                <StyledGreenSemaforo>
                  {validation[`peso_fruto${i + 1}`]?.value}
                </StyledGreenSemaforo>
              ) : validation[`peso_fruto${i + 1}`]?.color === "yellow" ? (
                <StyledYellowSemaforo>
                  {validation[`peso_fruto${i + 1}`]?.value}
                </StyledYellowSemaforo>
              ) : validation[`peso_fruto${i + 1}`]?.color === "orange" ? (
                <StyledOrangeSemaforo>
                  {validation[`peso_fruto${i + 1}`]?.value}
                </StyledOrangeSemaforo>
              ) : validation[`peso_fruto${i + 1}`]?.color === "red" ? (
                <StyledRedSemaforo>
                  {validation[`peso_fruto${i + 1}`]?.value}
                </StyledRedSemaforo>
              ) : (
                <StyledGraySemaforo />
              )}
            </StyledSemaforo>
          </StyledDiv>
        );
      })}
    </StyleDivFirmeza>
  );
};

const StyleDivFirmeza = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export default PesoFrutoContent;
