import React from "react";
import styled from "styled-components";
import Tab from "../Tab";

const TabLayout = ({
  children,
  columnDefs,
  onSelectionChanged,
  title,
  dataInput,
  handleTab,
  tab,
  tabs,
  defectos,
}) => {
  return (
    <StyledMantainer>
      <StyledHeader>
        <StyledBreadcrumb>
          <StyledBreadcrumbItem>Administración / Mantenedores / {title}</StyledBreadcrumbItem>
        </StyledBreadcrumb>
        <StyledTitle>
          <StyledH1>{title}</StyledH1>
        </StyledTitle>
      </StyledHeader>
      <StyledBody>
        <Tab
          dataInput={dataInput}
          columnDefs={columnDefs}
          children={children}
          handleTab={handleTab}
          tab={tab}
          tabs={tabs}
          defectos={defectos}
          onSelectionChanged={onSelectionChanged}
        />
      </StyledBody>
    </StyledMantainer>
  );
};

export default TabLayout;

const StyledMantainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-self: center;
  margin-top: 15px;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledBreadcrumb = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledBreadcrumbItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;

  &:before {
    content: "\\2022";
    color: #ff9100;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const StyledH1 = styled.h1`
  color: #64766a;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  padding-left: 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;
