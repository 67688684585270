import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/especies/"; // Se completa el path de la url base

// Export async function users
export async function especiesService({ token, rowsPerPage, page }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function especiesServiceNoPagination({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      noactivated: data.cliente,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getAllEspeciesServiceNoPagination({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count:1000,
      cliente: data.cliente
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getEspeciesClasificacionNoPaginationService({ token, data }) {
  const ESPECIESURL = "clasificaciones/get_especies/";
  // Se crea el objeto de configuración para la petición
  console.log("Data :", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      clasificacion: data.clasificacion,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(ESPECIESURL, config);
  // Se retorna la respuesta
  console.log("Response EspeciesClasificacion:", response);
  return response;
}

export async function especiesPackingNoPaginationService({ token, data }) {
  const ESPECIESURL = "packings/especies/";
  // Se crea el objeto de configuración para la petición
  console.log("Data Especies:", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      packing: data.packing,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(ESPECIESURL, config);
  // Se retorna la respuesta
  console.log("Response Especies:", response);
  return response;
}

export async function especiesClienteService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createEspecieService({ token, data }) {
  // const createUrl = URL + "nueva/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      cliente: data.cliente,
    },
    config
  );
  // Se retorna la respuesta

  return response;
}

export async function updateEspecieService({ token, data }) {
  console.log("Service Data: ", data);
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}

export async function activateEspecieService({ token, data, cliente }) {
  console.log("Service Data: ", data.especie);
  console.log("Service Cliente: ", cliente);
  const activateUrl = URL + "activate/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    activateUrl,
    {
      especie: data.especie,
      cliente: cliente.cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response: ", response);
  return response;
}

export async function deleteEspecieService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";

  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}
