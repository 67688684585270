import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import InputNumber from "../../../components/Cajas/InputNumber";
import { PlanillaContext } from "../../../context/PlanillaContext";

import {
  StyledSemaforo,
  StyledGreenSemaforo,
  StyledYellowSemaforo,
  StyledOrangeSemaforo,
  StyledRedSemaforo,
  StyledGraySemaforo,
} from "./styledCajaContent";

const PesoFijoContent = ({
  initialFilters,
  pesoFijo,
  setPesoFijo,
  totalPesoFijo,
  setTotalPesoFijo,
  handlePesoNeto,
  pesoFijoSave,
  setPesoFijoSave,
}) => {
  const [ numeroCestas, setNumeroCestas ] = useState(0);
  const [ numeroCestasReal, setNumeroCestasReal ] = useState(0);
  const [ pesoFijoMinimo, setPesoFijoMinimo ] = useState([]);
  const [ pesoFijoMaximo, setPesoFijoMaximo ] = useState([]);
  const { getEmbalajeFirmeza } = useEmbalajes();

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { detallesCaja, validation } = planillaState;

  var embalaje = initialFilters.Embalaje;
  useEffect(() => {
    getEmbalajeFirmeza(embalaje).then((res) => {
      res.results.forEach((element) => {
        if (element.tab.id === 7) {
          setNumeroCestas(element.numero_cestas);
          setNumeroCestasReal(element.numero_cestas_real);
          setPesoFijoMinimo(parseInt(element.peso_fijo_minimo));
          setPesoFijoMaximo(parseInt(element.peso_fijo_maximo));
        }
      });
    });
  }, [getEmbalajeFirmeza, embalaje]);

  useEffect(() => {
    sumaPesoFijo();
    handlePesoNeto(totalPesoFijo);
  }, [pesoFijo, totalPesoFijo]);

  const handleValidatePesoFijo = (e) => {
    const { name, value } = e.target;
    let cumple = 1;
    if (value >= pesoFijoMinimo && value <= pesoFijoMaximo) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "green",
        },
      }
      planillaDispatch({ type: "SET_VALIDATION", payload: validacion });
      cumple = 1;
    } else{
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "red",
        },
      }
      planillaDispatch({ type: "SET_VALIDATION", payload: validacion });
      cumple = 4;
    }
    setPesoFijo({
      ...pesoFijo,
      [name]: value,
    });

    if (detallesCaja.length !== 0) {
      setPesoFijoSave({
        ...pesoFijoSave,
        [name]: {
          id: e.target.id,
          peso: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        },
      });
    } else {
      setPesoFijoSave({
        ...pesoFijoSave,
        [name]: {
          peso: value,
          numero: name,
          cumple: cumple,
          sincronizacion: 1,
        },
      });
    }
  };

  const sumaPesoFijo = () => {
    let suma = 0;
    Object.keys(pesoFijo).forEach((key) => {
      let numero = key.split("peso_fijo")[1];
      if (parseInt(numero) <= parseInt(numeroCestasReal)) {
        suma += parseInt(pesoFijo[key]);
      }
    });
    let kilogramos = suma / 1000;
    setTotalPesoFijo(kilogramos);
  };

  const handleDefaultPesoFijo = (i) => {
    let valor = "";
    if (detallesCaja.length !== 0) {
      detallesCaja[0].caja.pesos_fijos?.forEach((element) => {
        if (element.numero === i + 1) {
          valor = element.peso_fijo;
        }
      });
    }
    return valor;
  }

  return (
    <StyleDivFirmeza>
      {
        [...Array(numeroCestas)].map((e, i) => {
          return (
            <StyledDiv key={i + 1}>
              <label> Peso Fijo {i + 1} </label>
              <InputNumber
                name={`peso_fijo${i + 1}`}
                placeholder="Peso Fijo"
                min={0}
                max={9000}
                step={0.1}
                id={detallesCaja.length !== 0 ? detallesCaja[0].caja.pesos_fijos?.find((element) => element.numero === i + 1)?.id : ""}
                onChange={(e) => {
                  handleValidatePesoFijo(e);
                }}
                defaultValue={
                  handleDefaultPesoFijo(i)
                }
              />
              <StyledSemaforo>
                {validation[`peso_fijo${i + 1}`]?.color ===
                "green" ? (
                  <StyledGreenSemaforo />
                ) : validation[`peso_fijo${i + 1}`]?.color ===
                  "yellow" ? (
                  <StyledYellowSemaforo />
                ) : validation[`peso_fijo${i + 1}`]?.color ===
                  "orange" ? (
                  <StyledOrangeSemaforo />
                ) : validation[`peso_fijo${i + 1}`]?.color ===
                  "red" ? (
                  <StyledRedSemaforo />
                ) : (
                  <StyledGraySemaforo />
                )}
              </StyledSemaforo>
            </StyledDiv>
          );
        })
      }
    </StyleDivFirmeza>
  );
};

const StyleDivFirmeza = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export default PesoFijoContent;