import React, { useContext } from "react";
import styled from "styled-components";
import NavBarContext from "../../context/NavBarProvider";

const Container = ({ children }) => {
  const { stateNavBar } = useContext(NavBarContext);
  const { navbar } = stateNavBar;
  return <StyledBody navbar={navbar}>{children}</StyledBody>;
};

const StyledBody = styled.div`
  margin-top: 84px;
  width: 100%;
  overflow: auto;
  height: calc(100% - 84px);
  position: fixed;

  @media screen and (min-width: 1024px) {
    width: ${(props) => (props.navbar === true ? "calc(100% - 225px)" : "calc(100% - 81px)")};
  }

  @media screen and (min-width: 1200px) {
    width: ${(props) => (props.navbar === true ? "calc(100% - 234px)" : "calc(100% - 81px)")};
  }

  @media screen and (min-width: 1440px) {
    width: ${(props) => (props.navbar === true ? "calc(100% - 227px)" : "calc(100% - 84px)")};
  }
`;

export default Container;
