const ControlDestino = (props) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5001 14.5C14.5001 14.8978 14.3421 15.2794 14.0608 15.5607C13.7795 15.842 13.3979 16 13.0001 16H11.5001V17.5C11.5001 17.8978 11.3421 18.2794 11.0608 18.5607C10.7795 18.842 10.3979 19 10.0001 19C9.6023 19 9.22077 18.842 8.93946 18.5607C8.65816 18.2794 8.50012 17.8978 8.50012 17.5V16H7.00012C6.6023 16 6.22077 15.842 5.93946 15.5607C5.65816 15.2794 5.50012 14.8978 5.50012 14.5C5.50012 14.1022 5.65816 13.7207 5.93946 13.4394C6.22077 13.1581 6.6023 13 7.00012 13H8.50012V11.5C8.50012 11.1022 8.65816 10.7207 8.93946 10.4394C9.22077 10.1581 9.6023 10 10.0001 10C10.3979 10 10.7795 10.1581 11.0608 10.4394C11.3421 10.7207 11.5001 11.1022 11.5001 11.5V13H13.0001C13.3979 13 13.7795 13.1581 14.0608 13.4394C14.3421 13.7207 14.5001 14.1022 14.5001 14.5ZM20.0001 8.15702V18.5C19.9985 19.9582 19.4186 21.3562 18.3875 22.3874C17.3563 23.4185 15.9583 23.9984 14.5001 24H5.50012C4.04192 23.9984 2.64389 23.4185 1.61279 22.3874C0.581683 21.3562 0.00171024 19.9582 0.00012207 18.5V5.50002C0.00171024 4.04182 0.581683 2.64379 1.61279 1.61269C2.64389 0.581581 4.04192 0.00160807 5.50012 1.99011e-05H11.8431C12.5657 -0.00193016 13.2814 0.139451 13.949 0.415979C14.6165 0.692507 15.2226 1.09869 15.7321 1.61102L18.3891 4.26802C18.9015 4.77754 19.3076 5.38361 19.5842 6.05115C19.8607 6.7187 20.0021 7.43447 20.0001 8.15702ZM17.0001 18.5V9.00002H13.0001C12.4697 9.00002 11.961 8.78931 11.5859 8.41423C11.2108 8.03916 11.0001 7.53045 11.0001 7.00002V3.00002H5.50012C4.83708 3.00002 4.2012 3.26341 3.73236 3.73225C3.26351 4.20109 3.00012 4.83698 3.00012 5.50002V18.5C3.00012 19.1631 3.26351 19.7989 3.73236 20.2678C4.2012 20.7366 4.83708 21 5.50012 21H14.5001C15.1632 21 15.799 20.7366 16.2679 20.2678C16.7367 19.7989 17.0001 19.1631 17.0001 18.5Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlDestino;
