import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getParametroDefectosService,
  getPlanillaDefectosService,
  getPlanillaDefectosHeadersService,
  createDefectoParametroService,
  updateDefectoParametroService,
} from "../../services/planillaDefectos";

export default function usePlanillaDefectos() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getParametroDefectos = useCallback(
    ({ id_parametro, planilla_edit }) => {
      console.log("dataInput", planilla_edit);
      const data = {
        cliente: client.id,
        parametro: id_parametro,
        planilla: planilla_edit.id,
      };
      return getParametroDefectosService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getPlanillaDefectos = useCallback(
    ({ planilla }) => {
      const data = {
        cliente: client.id,
        planilla: planilla,
      };

      return getPlanillaDefectosService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getPlanillaDefectosHeaders = useCallback(
    ({ planillaRes }) => {
      const data = {
        cliente: client.id,
        planilla: planillaRes,
      };

      return getPlanillaDefectosHeadersService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createDefectoParametro = useCallback(
    (dataInput) => {
      return createDefectoParametroService({ token, dataInput })
        .then((res) => {
          if (res.status === 201) {
            console.log("response hook", res);
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateDefectoParametro = useCallback(
    (dataInput) => {
      return updateDefectoParametroService({ token, dataInput })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getParametroDefectos,
    getPlanillaDefectos,
    getPlanillaDefectosHeaders,
    createDefectoParametro,
    updateDefectoParametro,
  };
}
