import axios from "../api/axios";

const URL = "/packings/";

export async function getPackingService({ token, rowsPerPage, page, data }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  const response = await axios.get(URL, config);
  return response;
}

export async function getPackingNoPaginationService({ token, data }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count: 1000,
    },
  };
  const response = await axios.get(URL, config);
  return response;
}

export async function getPackingByEspecieExportadoraService({ token, data }) {
  const newUrl = URL + "especie/exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      especie: data.especie,
      exportadora: data.exportadora,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  return response;
}

export async function createPackingService({ token, data }) {
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axios.post(
    URL,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      tipo_packing: data.tipo_packing,
      estado: data.estado,
      cliente: data.cliente,
      zona: parseInt(data.zona),
    },
    config
  );
  return response;
}

export async function createPackingExportadoraService({ token, data }) {
  const EXPORTADORASURL = "packings/activate_exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    EXPORTADORASURL,
    {
      packing: data[0].packing.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  return response;
}

export async function createPackingEspecieService({ token, data }) {
  const ESPECIESURL = "packings/activate_especie/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axios.post(
    ESPECIESURL,
    {
      packing: data[0].packing.id,
      especies: data[0].especies,
      cliente: data[0].cliente,
    },
    config
  );
  return response;
}

export async function updatePackingService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      tipo_packing: data.tipo_packing.id
        ? data.tipo_packing.id
        : data.tipo_packing,
      zona: data.zona.id ? data.zona.id : data.zona,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Packing Update: ", response);
  return response;
}
