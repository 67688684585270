import styled from 'styled-components'

const Input = ({placeholder, type, onChange, required}) => (
    <StyledInput placeholder={placeholder} type={type} onChange={onChange} required={required} />
)

const StyledInput = styled.input`
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1.5px solid #FF9100;
    margin: 5px;
    box-sizing: border-box;
    color: #6C6C6C;
    padding: 5px 5px 5px 20px;
    font-size: 16px;
    width: 343px;

    &:hover {
        
    }

    &:active {
        border: 1.5px solid #D88219;
    }

    &:disabled {
        border: 1.5px solid rgba(255, 168, 0, 0.32);
    }

    @media (max-width: 480px) {
        width: 220px;
    }

    @media (max-width: 845px) {
        width: 220px;
    }

    @media (max-height: 391px) {
        width: 220px;
    }
`

export default Input;