import React, {useEffect, useState} from "react";
import ModalForm from "../../../../components/Modal";
// import SelectMultiple from "../../../../components/SelectMultiple";
import useEspecies from "../../../../hooks/api/useEspecies";
import styled from "styled-components";

const AddEspecieForm = ({
  dataInput,
  handleCancel,
  handleSaveEspecies,
  setDataInput,
}) => {
  const [ especies, setEspecies ] = useState([]);

  const { getEspeciesNoPagination } = useEspecies();

  useEffect(() => {
    getEspeciesNoPagination().then((res) => {
      setEspecies(res.results);
    });
  }, [getEspeciesNoPagination]);

  const handleValue = (e) => {
    setDataInput({ ...dataInput, [e.target.name]: e.target.value });
  };

  return (
    <ModalForm
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={true}
      handleSave={handleSaveEspecies}
      title="(Activar) Especie"
    >
      {/* <SelectMultiple especies={especies} dataInput={dataInput} setDataInput={setDataInput} /> */}

      <StyledInputContainer>
        <StyledInputLabel>Especie</StyledInputLabel>
        <StyledSelected
          name="especie"
          // selected first option by default
          defaultValue={especies[0]}
          onChange={handleValue}
        >
          {
            especies.map((especie, index) => (
              <StyledOption key={index} value={especie.id}>{especie.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 20px;
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

export default AddEspecieForm;
