let user = '' || JSON.parse(localStorage.getItem('user'));
let token = "" || JSON.parse(localStorage.getItem("token"));
let client = "" || JSON.parse(localStorage.getItem("client"));
let isLogged = false || localStorage.getItem("isLogged");
let colors = "" || JSON.parse(localStorage.getItem("colors"));


export const initialState = {
  user: user,
  token: token,
  client: client,
  isLogged: isLogged,
  colors: colors,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    case "SET_TOKEN":
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        token: action.payload.token,
      };
    case "SET_CLIENT":
      localStorage.setItem("client", JSON.stringify(action.payload.client));
      return {
        ...state,
        client: action.payload.client,
      };
    case "SET_LOGGED":
      localStorage.setItem("isLogged", JSON.stringify(action.payload.isLogged));
      return {
        ...state,
        isLogged: action.payload.isLogged,
      };
    case "SET_COLORS":
      localStorage.setItem("colors", JSON.stringify(action.payload.colors));
      return {
        ...state,
        colors: action.payload.colors,
      };
    case "SET_LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("client");
      localStorage.removeItem("colors");
      return {
        ...state,
        user: "",
        token: "",
        client: "",
        isLogged: false,
        colors: "",
      };
    default:
      return state;
  }
};
