import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/api/useUser";
import useError from "../../hooks/helpers/useError";
import ThemeContext from "../../context/ThemeProvider";

import Boton from "../../components/Boton";
import Logo from "../../components/Logo";
import Input from "../../components/Input";
import "./style.css";

import {
  StyledContainer,
  StyledCardLogin,
  StyledLabel,
  Styledh6,
  StyledForm,
} from "./styledLogin";

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(""); // Guarda el usuario
  const [password, setPassword] = useState(""); // Guarda la password

  const { login, isLogged } = useUser();
  const { getError } = useError();

  const { stateTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (isLogged) {
      navigate("/sistemas");
    }
  }, [isLogged, navigate]);

  /**
   * When the user submits the form, prevent the default action, log the user and password to the
   * console, and call the login function with the user and password as arguments.
   * @param e - the event object
   */
  const handleSubmit = async (e) => {
    // console.log("user: ", user);
    e.preventDefault();
    login({ user, password });
  };

  return (
    <StyledContainer>
      <StyledCardLogin>
        <Logo />
        <StyledLabel theme={stateTheme}>Inicia sesión</StyledLabel>
        <p className={getError() ? "errorMessage" : "offscreen"}>
          {getError()}
        </p>
        <StyledForm onSubmit={handleSubmit}>
          <Input
            placeholder="Ingrese Usuario"
            type="text"
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
          <Input
            placeholder="Ingrese Clave"
            type="password"
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <Styledh6>Olvidé mi usuario y clave</Styledh6>
          <Boton type="submit" texto="Aceptar" />
        </StyledForm>
      </StyledCardLogin>
    </StyledContainer>
  );
};

export default Login;
