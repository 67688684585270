import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getOperacionTiposService, createOperacionTipoService } from "../../services/operacionTipos";

export default function useOperacionTipos() {

  const { state } = useContext(AuthContext);
  const { token } = state;

  const getOperacionTiposNoPagination = useCallback(() => {
    return getOperacionTiposService({ token })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const createOperacionTipo = useCallback((data) => {
    return createOperacionTipoService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  return {
    getOperacionTiposNoPagination,
    createOperacionTipo,
  };
}
