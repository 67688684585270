import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import useError from "../helpers/useError";
import AuthContext from "../../context/AuthProvider";

import {
  getEspecieDetallesService,
  createEspecieDetallesService,
  updateEspecieDetallesService,
} from "../../services/especieDetalles";

export default function useEspecieDetalles() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;
  const { getStatusCodeError } = useError();

  const getEspecieDetalles = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getEspecieDetallesService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createEspecieDetalles = useCallback(
    (data) => {
      return createEspecieDetallesService({ token, data })
        .then((res) => {
          console.log("res", res);
          if (res.status === 201) {
            toast.success("Especie Detalle creada correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          console.log("err", err);
          return getStatusCodeError(err.response.status);
        });
    },
    [token, getStatusCodeError]
  );

  const updateEspecieDetalles = useCallback(
    ( data ) => {
      return updateEspecieDetallesService({ token, data })
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            toast.success("Especie Detalle actualizada correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          console.log("err", err);
          return getStatusCodeError(err.response.status);
        });
    },
    [token, getStatusCodeError]
  );

  return {
    getEspecieDetalles,
    createEspecieDetalles,
    updateEspecieDetalles,
  };
}
