import Layout from "../../../../components/Layout";
import ExportadoraFilter from "../../../../components/Filters/Exportadora";
import EspecieFilter from "../../../../components/Filters/Especie";
import NormaFilter from "../../../../components/Filters/Norma";
import LineaFilter from "../../../../components/Filters/Linea";
import PackingFilter from "../../../../components/Filters/Packing";
import DateFilter from "../../../../components/Filters/Date";
import styled from "styled-components";

const ControlProcesoBd = () => {
    return (
        <Layout>
            <div>
                <StyledH1>Base Datos Inspecciones</StyledH1>
            </div>
            <StyledFilterContainer>
                <ExportadoraFilter/>
                <EspecieFilter/>
                <NormaFilter/>
                <LineaFilter/>
                <PackingFilter/>
                <DateFilter/>
            </StyledFilterContainer>
        </Layout>
    )
}

const StyledH1 = styled.h1`
    color: #64766A;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
`;

const StyledFilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`


export default ControlProcesoBd;