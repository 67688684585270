import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/turnos/";

export async function getTurnosService({ token }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createTurnoService({ token, data }) {
  console.log("Service Variedad: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response :", response);

  return response;
}

export async function updateTurnoService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  console.log("Service Variedad: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response :", response);
  return response;
}
