export const ACTIONS = {
    SET_USER: "SET_USER",
    SET_TOKEN: "SET_TOKEN",
    SET_CLIENT: "SET_CLIENT",
    SET_LOGGED: "SET_LOGGED",
    SET_LOGOUT: "SET_LOGOUT",
    SET_ERROR: "SET_ERROR",
    SET_COLORS: "SET_COLORS",
}

export const STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
}

export const THEME = {
    SET_THEME: "SET_THEME",
}

export const NAVBAR = {
    SET_NAVBAR: "SET_NAVBAR",
}

export const MEDICION = {
    HORA: 1,
    PESO_NETO: 3,
    NUMERO_FRUTOS: 4,
    NOTA: 5,
    PORCENTAJE: 6,
    FECHA: 7,
    NOTA_CALIDAD: 8,
    NOTA_CONDICION: 9,
    NOTA_EMBALAJE: 10,
    CANTIDAD_CAJAS: 11,
    FOLIO_PALLET: 12,
    CALIBRE: 13,
    BRIX: 14,
    NUMERO_PROCESO: 15,
    NUMERO_LINEA: 16,
    NUMERO: 17,
}

export const PLANILLA_DATA = {
    SET_PLANILLA: "SET_PLANILLA",
    SET_DATA: "SET_DATA",
    SET_DETALLES_CAJA: "SET_DETALLES_CAJA",
    SET_FOTOS: "SET_FOTOS",
    SET_FILTERS: "SET_FILTERS",
    SET_CONTRA_MUESTRA_CAJA: "SET_CONTRA_MUESTRA_CAJA",
    SET_COPY_CAJA: "SET_COPY_CAJA",
    SET_VALORES_CAJA: "SET_VALORES_CAJA",
    SET_VALIDATION: "SET_VALIDATION",
    SET_PLANILLA_DEFECTOS: "SET_PLANILLA_DEFECTOS",
    SET_PLANILLA_TYPE: "SET_PLANILLA_TYPE",
}

export const MANTAINER = {
    PLANILLA_EDIT: "PLANILLA_EDIT",
    CALIBRE: "CALIBRE",
    NORMA: "NORMA",
}