import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { ACTIONS } from "../../context/Actions";
import loginService from "../../services/login";
import { useNavigate } from "react-router-dom";
import useError from "../helpers/useError";
import {
  usersService,
  createUserService,
  deleteUserService,
  updateUserService,
} from "../../services/users";

export default function useUser() {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setError } = useError();
  const { token } = state;
  const { client } = state;

  const login = useCallback(
    ({ user, password }) => {
      loginService({ user, password })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            dispatch({
              type: ACTIONS.SET_USER,
              payload: { user: res.data.user },
            });
            dispatch({
              type: ACTIONS.SET_TOKEN,
              payload: { token: res.data.token },
            });
            dispatch({
              type: ACTIONS.SET_CLIENT,
              payload: { client: res.data.cliente },
            });
            dispatch({
              type: ACTIONS.SET_COLORS,
              payload: { colors: res.data.colors },
            });
            dispatch({ type: ACTIONS.SET_LOGGED, payload: { isLogged: true } });
            navigate("/sistemas");
          } else {
            setError({ res });
          }
        })
        .catch((err) => {
          setError({ err });
        });
    },
    [dispatch, navigate, setError]
  );

  const logout = useCallback(() => {
    dispatch({ type: ACTIONS.SET_LOGOUT });
  }, [dispatch]);

  const { isLogged } = state;

  const getUsers = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return usersService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            console.log("Hook", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateUser = useCallback(
    (data) => {
      return updateUserService({ token, data })
        .then((res) => {
          // console.log("Hook Response",res);
          if (res.status === 200) {
            // console.log("Hook Update: ", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          console.log("Hook Error", err);
          return err.response;
        });
    },
    [token]
  );

  const createUser = useCallback(
    (data) => {
      data = {
        ...data,
        cliente: client.id,
        usuario: {
          ...data.usuario,
          user_type: parseInt(data.usuario.user_type),
        },
      };
      return createUserService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const deleteUser = useCallback(
    (id) => {
      return deleteUserService({ token, id })
        .then((res) => {
          if (res.status === 204) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    login,
    logout,
    isLogged,
    getUsers,
    updateUser,
    deleteUser,
    createUser,
  };
}
