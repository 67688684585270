import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/defectos/"; // Se completa el path de la url base

// Export async function users

export async function getDefectosService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getDefectosNoPaginationService({ token, data }) {
  console.log("Service Defectos: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  console.log("Service Response 1 :", response);
  return response;
}

export async function getDefectosPlanillasNoPaginationService({ token, data }) {
  console.log("Service Defectos Planillas: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      planilla: data.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL + "planillas/", config);
  // Se retorna la respuesta
  console.log("Service Response :", response);
  return response;
}

export async function createDefectoService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Productor: ", response);
  return response;
}

export async function createPlanillaDefectoService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service Planilla Parametro: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  // Se realiza la petición
  const response = await axios.post(
    URL + "planillas/",
    {
      planilla: data.id,
      parametro: data.parametro,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Response Planilla Parametro: ", response);
  return response;
}

export async function updateDefectoService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function deleteDefectoService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta

  return response;
}
