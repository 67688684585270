import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/fotos/"; // Se completa el path de la url base

export async function getFotosService({ token, caja }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      caja: caja.caja,
      count: 1000,
    },
  };

  const response = await axios.get(URL, config);
  return response;
}

export async function createFotosService({ token, fotito, image }) {
  console.log("Service FOTITO: ", fotito);
  console.log("Service IMAGE: ", image);

  const formData = new FormData();
  formData.append("caja", fotito.caja);
  formData.append("numero_foto_caja", fotito.numero_foto_caja);
  formData.append("nombre", "test");
  formData.append("defecto", fotito.foto.defecto);
  formData.append("foto", fotito.foto.foto);
  formData.append("sincronizacion", fotito.foto.sincronizacion);

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const response = await axios.post(URL, formData, config);
  console.log("Service RESPONSE FOTO: ", response);
  return response;
}

export async function updateFotosService({ token, fotito, image }) {
  console.log("Service FOTITO: ", fotito);
  console.log("Service IMAGE: ", image);

  const URL_FOTO = URL + "update/";

  const formData = new FormData();
  formData.append("id", fotito.foto.id);
  formData.append("caja", fotito.caja);
  formData.append("numero_foto_caja", fotito.numero_foto_caja);
  formData.append("nombre", "test");
  formData.append("defecto", fotito.foto.defecto);
  formData.append("foto", fotito.foto.foto);
  formData.append("sincronizacion", fotito.foto.sincronizacion);

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const response = await axios.post(URL_FOTO, formData, config);
  console.log("Service RESPONSE FOTO: ", response);
  return response;
}
