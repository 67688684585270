import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSelect = styled.select`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: auto;
  height: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */
  text-align: center;
  /* Neutral / Lightest */
  color: #000000;
`;

export const StyledButtonForm = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */
  text-align: center;
  /* Neutral / Lightest */
  color: #ffffff;
  white-space: nowrap;
`;

export const StyledButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */
  text-align: center;
  /* Neutral / Lightest */
  color: #ffffff;
  white-space: nowrap;
`;