import Navbar from "../../../components/Navbar";

const BusTemperaturas = () => {
    return (
        <div>
            <Navbar active link="https://contenedor.inglobochile.cl/Reportes/Reportes_Temp_Pallet/FrontIndex.php"/>
        </div>
    )
}

export default BusTemperaturas;