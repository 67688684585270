import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getFotosService, createFotosService, updateFotosService } from "../../services/fotos";

export default function useEtiquetas() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getFotos = useCallback(
    (caja) => {
      return getFotosService({ token, caja })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const createFotoCaja = useCallback(
    (fotito, image) => {
      return createFotosService({ token, fotito, image })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateFotoCaja = useCallback(
    (fotito, image) => {
      return updateFotosService({ token, fotito, image })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getFotos,
    createFotoCaja,
    updateFotoCaja,
  };
}
