import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useTipoPacking from "../../../../hooks/api/useTipoPacking";
import useZona from "../../../../hooks/api/useZona";

const PackingForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  console.log("dataInput Packing Inicio", dataInput);
  const [ tipoPackings, setTipoPackings ] = useState([]);
  const [ zonas, setZonas ] = useState([]);

  const { getTipoPackingsNoPagination } = useTipoPacking();

  const { getZonasNoPagination } = useZona();

  useEffect(() => {
    getTipoPackingsNoPagination().then((res) => {
      setTipoPackings(res.results);
    });
    getZonasNoPagination().then((res) => {
      setZonas(res.results);
    });
  }, [getTipoPackingsNoPagination, getZonasNoPagination]);

  const handleValue = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value
      });
    }
    console.log("dataInput Planilla", dataInput);
  };
  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Packing"
    >
      <StyledInputContainer>
        <StyledInputLabel>Codigo</StyledInputLabel>
        <StyledTextInputs
          type="text"
          placeholder="Codigo"
          name="codigo"
          defaultValue={dataInput.codigo !== "" ? dataInput.codigo : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Nombre</StyledInputLabel>
        <StyledTextInputs
          type="text"
          placeholder="Nombre"
          name="nombre"
          defaultValue={dataInput.nombre !== "" ? dataInput.nombre : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Zona</StyledInputLabel>
        <StyledSelected
          name="zona"
          value={dataInput.zona !== undefined ? dataInput.zona.id : 0}
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Zona</StyledOption>
          {
            zonas.map((zona, index) => (
              <StyledOption key={index} value={zona.id}>{zona.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Tipo Packing</StyledInputLabel>
        <StyledSelected
          name="tipo_packing"
          value={dataInput.tipo_packing !== undefined ? dataInput.tipo_packing.id : 0}
          onChange={handleValue}
        >
          <StyledOption value="false">Seleccione tipo packing</StyledOption>
          {
            tipoPackings.map((tipoPacking, index) => (
              <StyledOption key={index} value={tipoPacking.id}>{tipoPacking.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={dataInput.estado === false ? dataInput.estado : true}
          onChange={handleValue}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px;
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default PackingForm;
