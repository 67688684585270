const MenuCloseIcon = ({header, theme}) => {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="3" rx="1.5" fill={header === "active" ? theme.theme.secondary.color : "white"} />
      <rect y="7" width="14" height="3" rx="1.5" fill={header === "active" ? theme.theme.secondary.color : "white"} />
      <rect y="14" width="14" height="3" rx="1.5" fill={header === "active" ? theme.theme.secondary.color : "white"} />
      <path
        d="M18.8362 3.07088C18.3231 2.60906 17.5327 2.65066 17.0709 3.16379C16.6091 3.67693 16.6507 4.46729 17.1638 4.92912L18.8362 3.07088ZM23 8.5L23.8362 9.42912C24.0996 9.19206 24.25 8.85436 24.25 8.5C24.25 8.14564 24.0996 7.80794 23.8362 7.57088L23 8.5ZM17.1638 12.0709C16.6507 12.5327 16.6091 13.3231 17.0709 13.8362C17.5327 14.3493 18.3231 14.3909 18.8362 13.9291L17.1638 12.0709ZM17.1638 4.92912L22.1638 9.42912L23.8362 7.57088L18.8362 3.07088L17.1638 4.92912ZM22.1638 7.57088L17.1638 12.0709L18.8362 13.9291L23.8362 9.42912L22.1638 7.57088Z"
        fill={header === "active" ? theme.theme.secondary.color : "white"}
      />
    </svg>
  );
};

export default MenuCloseIcon;
