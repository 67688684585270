import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import NormaDetalleForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useEspecieDetalles from "../../../../hooks/api/useEspecieDetalles";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import FiltersForm from "./filters";
import { toast } from "react-toastify";
import styled from "styled-components";

const EspecieDetalles = () => {
  const [filtersForm, setFiltersForm] = useState(false);
  const {
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    setDataInput,
    setCreate,
    setEdit,
    create,
    handleClearForm,
  } = useModalHelper();

  const { getEspecieDetalles, createEspecieDetalles, updateEspecieDetalles } =
    useEspecieDetalles();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateClasificacion = (params) => {
    if (params.data.clasificacion === true) {
      return "Si";
    } else {
      return "No";
    }
  };

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const goToFilters = (params) => {
    return (
      <StyledGoToFiltersButton onClick={() => handleFilterForm(params.data)}>
        Filtros
      </StyledGoToFiltersButton>
    );
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID", width: 70 },
    { field: "especie.nombre", headerName: "Especie" },
    { field: "exportadora.nombre", headerName: "Exportadora" },
    { field: "inspeccion_tipo.nombre", headerName: "Inspeccion" },
    {
      field: "clasificacion",
      headerName: "Clasificacion Influye Embalaje",
      cellRenderer: validateClasificacion,
    },
    { field: "estado", headerName: "Estado", cellRenderer: validateEstado },
    { headerName: "Action", cellRenderer: goToFilters },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getEspecieDetalles({ rowsPerPage, page }).then((res) => {
      console.log("Page Res : ", res.results);
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getEspecieDetalles,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleSave = () => {
    setCreate(true);
    setEdit(false);
    createEspecieDetalles(dataInput)
      .then((res) => {
        // refresh rowData
        console.log("res", res);
        const newData = [...rowData, res.data];
        setRowData(newData);
      })
      .catch((err) => {
        toast.error("Error al crear norma detalle");
      })
      .finally(() => {
        setDataInput({});
        setCreate(false);
      });
  };

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateEspecieDetalles(dataInput)
      .then((res) => {
        // refresh rowData
        console.log("res", res);
        const newData = rowData.map((item) => {
          console.log("Page Res : ", res);
          if (item.id === res.data.id) {
            return res.data;
          } else {
            return item;
          }
        });
        setRowData(newData);
      })
      .catch((err) => {
        toast.error("Error al actualizar norma detalle");
      })
      .finally(() => {
        setDataInput({});
        setEdit(false);
      });
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  const handleFilterForm = (params) => {
    setFiltersForm(true);
    setCreate(false);
    setEdit(false);
    setDataInput(params);
    console.log("value", params);
  };

  const handleCancelFilters = () => {
    setFiltersForm(false);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Detalle Especie Exportadora Tipo Inspeccion"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create === true && (
        <NormaDetalleForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      )}
      {edit === true && (
        <NormaDetalleForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      )}
      {filtersForm === true && (
        <FiltersForm
          dataInput={dataInput}
          handleCancel={handleCancelFilters}
          setFilterForm={setFiltersForm}
        />
      )}
    </Layout>
  );
};

const StyledGoToFiltersButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #ffffff;
  &:active {
    background: #d88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;

export default EspecieDetalles;
