import React from "react";
import styled from "styled-components";

const PackingFilter = (props) => {
  return (
    <>
        <StyledContainer>
            <StyledLabel>Packing</StyledLabel>
            <StyledPackingFilter>
                <option>Seleccione</option>
                <option>Packing 1</option>
                <option>Packing 2</option>
            </StyledPackingFilter>
        </StyledContainer>
    </>
    );
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    width: fit-content;
    padding: 2px;
    @media screen and (max-width: 768px) {
        height: 100%;
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
`;

const StyledLabel = styled.label`
    margin-left: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6C6C6C;
`

const StyledPackingFilter = styled.select`
    width: 323px;
    height: 44px;
    background: #FFFFFF;
    border: 1.5px solid #D88219;
    border-radius: 5px;
    color: rgba(108, 108, 108, 1);
    margin: 10px;

    @media screen and (min-width: 768px) {
        width: 269px;
    }

    @media screen and (min-width: 1024px) {
        width: 315px;
    }
`


export default PackingFilter;