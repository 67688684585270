import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/colors/"; // Se completa el path de la url base

// Export async function users
export async function ColorclientesService({ token, rowsPerPage, page, client }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: client.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function updateColorClientesService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(updateUrl, {
    color: data.color,
    color_texto: data.color_texto,
    cumple: data.cumple,
  }, config);
  // Se retorna la respuesta
  return response;
}