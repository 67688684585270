import React from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";

const UserForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  const { usuario } = dataInput;

  const handleData = (e) => {
    const user = { ...dataInput.usuario, [e.target.name]: e.target.value };
    setDataInput({ ...dataInput, usuario: user });
    console.log("Inputs: ", dataInput);
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Usuario"
    >
      <StyledInputContainer>
        <StyledInputLabel>Username</StyledInputLabel>
        <StyledTextInputs
          type="text"
          placeholder="Username"
          defaultValue={usuario ? usuario.username : null}
          name="username"
          onChange={handleData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Nombre</StyledInputLabel>
        <StyledTextInputs
          type="text"
          placeholder="First Name"
          defaultValue={usuario ? usuario.first_name : null}
          name="first_name"
          onChange={handleData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Apellido</StyledInputLabel>
        <StyledTextInputs
          type="text"
          placeholder="Last Name"
          defaultValue={usuario ? usuario.last_name : null}
          name="last_name"
          onChange={handleData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Email</StyledInputLabel>
        <StyledTextInputs
          type="email"
          placeholder="Email"
          defaultValue={usuario ? usuario.email : null}
          name="email"
          onChange={handleData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Password</StyledInputLabel>
        <StyledTextInputs
          type="password"
          placeholder="************"
          name="password"
          onChange={handleData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Tipo de Usuario</StyledInputLabel>
        <StyledSelected
          name="user_type"
          onChange={handleData}
          defaultValue={
            usuario && usuario.user_type ? usuario.user_type.id : null
          }
        >
          <StyledOption value="1">Administrador</StyledOption>
          <StyledOption value="2">Inspector</StyledOption>
          <StyledOption value="3">Cliente</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={usuario ? usuario.estado : null}
          onChange={handleData}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px;
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default UserForm;
