import { createContext, useReducer } from "react";
import { themeReducer, initialState } from "./ThemeReducer";

const ThemeContext = createContext({initialState});

export const ThemeProvider = ({ children }) => {
    const [stateTheme, dispatch] = useReducer(themeReducer, initialState);

    return (
        <ThemeContext.Provider value={{ stateTheme, dispatch }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContext;