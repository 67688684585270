import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/planillas/"; // Se completa el path de la url base

// Export async function users

export async function getPlanillasService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getPlanillaEspecieService({ token, data }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getPlanillasClienteService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createPlanillaService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      especie: data.especie,
      planilla_type: data.planilla_type,
      cliente: data.cliente,
      year: data.year,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Productor: ", response);
  return response;
}

export async function createPlanillaExportadoraService({ token, data }) {
  const EXPORTADORASURL = "planillas/activate_exportadora/";
  // Se crea el objeto de configuración para la petición
  console.log("Service: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data[0].cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    EXPORTADORASURL,
    {
      planilla: data[0].planilla.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Planilla: ", response);
  return response;
}

export async function updatePlanillaService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("Service Planillas Update: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      especie: data.especie.id ? data.especie.id : data.especie,
      planilla_type: data.planilla_type.id ? data.planilla_type.id : data.planilla_type,
      cliente: data.cliente.id ? data.cliente.id : data.cliente,
      year: data.year,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Productor: ", response);
  return response;
}

export async function deletePlanillaService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta

  return response;
}
