const MenuOpenIcon = (props) => {
  return (
    <>
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="3" rx="1.5" fill={props.theme.theme.primary.color} />
      <rect y="7" width="24" height="3" rx="1.5" fill={props.theme.theme.primary.color} />
      <rect y="14" width="24" height="3" rx="1.5" fill={props.theme.theme.primary.color} />
    </svg>
    </>
  );
};

export default MenuOpenIcon;
