import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import InputNumber from "../../../components/Cajas/InputNumber";

import { PlanillaContext } from "../../../context/PlanillaContext";

import {
  StyledSemaforo,
  StyledGreenSemaforo,
  StyledYellowSemaforo,
  StyledOrangeSemaforo,
  StyledRedSemaforo,
  StyledGraySemaforo,
} from "./styledCajaContent";

const FirmezaContent = ({
  initialFilters,
  firmeza,
  setFirmeza,
}) => {
  const [numeroCestas, setNumeroCestas] = useState(0);
  const { getEmbalajeFirmeza } = useEmbalajes();

  const [valor1Initial, setValor1Initial] = useState(0);
  const [valor2Initial, setValor2Initial] = useState(0);
  const [valor3Initial, setValor3Initial] = useState(0);

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { detallesCaja, validation, planillaDefectos } = planillaState;

  var embalaje = initialFilters.Embalaje;
  useEffect(() => {
    getEmbalajeFirmeza(embalaje).then((res) => {
      res.results.forEach((element) => {
        if (element.tab.id === 4) {
          setNumeroCestas(element.numero_cestas);
        }
      });
    });
  }, [getEmbalajeFirmeza, embalaje, initialFilters]);

  useEffect(() => {
    if ( planillaDefectos !== undefined && planillaDefectos.length > 0 ) {
      planillaDefectos.forEach((element) => {
        if (element.planilla_defecto.tipo_medicion.id === 14) {
          setValor1Initial(element.norma_valor_1);
          setValor2Initial(element.norma_valor_2);
          setValor3Initial(element.norma_valor_3);
        }
      });
    }
  }, [planillaDefectos]);

  const handleValidateFirmezaTab = (e) => {
    const { name, value } = e.target;
    let valor1 = parseFloat(valor1Initial);
    let valor2 = parseFloat(valor2Initial);
    let valor3 = parseFloat(valor3Initial);
    let cumple = 1;
    if (value >= valor1 && value <= valor2) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "green",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 1;
    } else if (value > valor2 && value <= valor3) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "yellow",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 2;
    } else if (value < valor1 || value > valor3) {
      let validacion = {
        ...validation,
        [name]: {
          value: value,
          color: "red",
        },
      };
      planillaDispatch({
        type: "SET_VALIDATION",
        payload: validacion,
      });
      cumple = 4;
    } else {
      delete validation[name];
    }

    if (detallesCaja.length !== 0) {
      setFirmeza({
        ...firmeza,
        [name]:{
          id: e.target.id,
          firmeza: value,
          numero: name,
          sincronizacion: 1,
          cumple: cumple,
        },
      });
    } else {
      setFirmeza({
        ...firmeza,
        [name]:{
          firmeza: value,
          numero: name,
          sincronizacion: 1,
          cumple: cumple,
        },
      });
    }
  };

  // console.log("firmeza", firmeza);

  const handleDefaultFirmeza = (i) => {
    let valor = "";
    if (detallesCaja.length !== 0) {
      detallesCaja[0].caja.firmezas.forEach((element) => {
        if (element.numero === i + 1) {
          valor = element.firmeza;
        }
      });
    }
    return valor;
  }

  return (
    <StyleDivFirmeza>
      {[...Array(numeroCestas)].map((e, i) => {
        return (
          <StyledDiv key={i + 1}>
            <label> Firmeza {i + 1} </label>
            <InputNumber
              name={`firmeza${i + 1}`}
              placeholder="Firmeza"
              min={0}
              max={100}
              // añadir el id de la firmeza si existe
              id={detallesCaja.length !== 0 ? detallesCaja[0].caja.firmezas.find((element) => element.numero === i + 1)?.id : ""}
              step={0.1}
              onChange={(e) => {
                handleValidateFirmezaTab(e);
              }}
              defaultValue={
                handleDefaultFirmeza(i)
              }
            />
            <StyledSemaforo>
              {validation[`firmeza${i + 1}`]?.color === "green" ? (
                <StyledGreenSemaforo>
                  {validation[`firmeza${i + 1}`]?.value}
                </StyledGreenSemaforo>
              ) : validation[`firmeza${i + 1}`]?.color === "yellow" ? (
                <StyledYellowSemaforo>
                  {validation[`firmeza${i + 1}`]?.value}
                </StyledYellowSemaforo>
              ) : validation[`firmeza${i + 1}`]?.color === "orange" ? (
                <StyledOrangeSemaforo>
                  {validation[`firmeza${i + 1}`]?.value}
                </StyledOrangeSemaforo>
              ) : validation[`firmeza${i + 1}`]?.color === "red" ? (
                <StyledRedSemaforo>
                  {validation[`firmeza${i + 1}`]?.value}
                </StyledRedSemaforo>
              ) : (
                <StyledGraySemaforo />
              )}
            </StyledSemaforo>
          </StyledDiv>
        );
      })}
    </StyleDivFirmeza>
  );
};

const StyleDivFirmeza = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export default FirmezaContent;
