import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { toast } from "react-toastify";
import useError from "../helpers/useError";

import {
  getOperacionesService,
  createOperacionService,
  updateOperacionService,
} from "../../services/operaciones";

export default function useOperaciones() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;
  const { getStatusCodeError } = useError();

  const getOperaciones = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getOperacionesService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createOperacion = useCallback(
    (data) => {
      return createOperacionService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            console.log("res", res);
            toast.success("Operación creada correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          console.log("err", err);
          return getStatusCodeError(err.response.status);
        });
    },
    [token, getStatusCodeError]
  );

  const updateOperacion = useCallback( ({data, operacion}) => {
    return updateOperacionService({ token, data, operacion })
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          toast.success("Operación actualizada correctamente");
          return res.data;
        }
      })
      .catch((err) => {
        console.log("err", err);
        return getStatusCodeError(err.response.status);
      });
  }, [token, getStatusCodeError]);

  return {
    getOperaciones,
    createOperacion,
    updateOperacion,
  };
}
