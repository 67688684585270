import { useState } from "react";

export default function useModalHelper() {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [copy, setCopy] = useState(false);
  const [dataInput, setDataInput] = useState({});

  const handleCreate = () => {
    setCreate(true);
    setEdit(false);
    setCopy(false);
  };

  const handleEditForm = () => {
    setEdit(true);
    setCreate(false);
    setCopy(false);
  };

  const handleCopyForm = () => {
    setCopy(true);
    setCreate(false);
    setEdit(false);
  };

  const handleCancel = () => {
    setCreate(false);
    setEdit(false);
    setCopy(false);
  };

  const handleClearForm = () => {
    handleCreate();
    setDataInput({});
  };

  return {
    create,
    edit,
    dataInput,
    handleCreate,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
    handleCopyForm,
    copy,
    setCopy,
  };
}
