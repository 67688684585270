import React, { useState, useEffect } from "react";
import styled from "styled-components";

const InputDecimal = ({
    name,
    placeholder,
    defaultValue,
    onChange,
    disabled,
    required,
    width,
    step,
    hola, // Consider renaming this prop to something more descriptive
    value,
    minWidth,
}) => {
    // Initialize inputValue with value or defaultValue
    const [inputValue, setInputValue] = useState(value || defaultValue);

    // Update inputValue when prop `value` changes
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        // Validate newValue for up to 3 decimal places
        if (/^\d*(\.\d{0,3})?$/.test(newValue)) {
            setInputValue(newValue); // Update state only if valid
            if (onChange) onChange(e); // Call external onChange if provided
        }
    };

    return (
        <StyledInputDecimal
            type="number"
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            disabled={disabled}
            required={required}
            step={step ? step : "0.001"}
            pattern="^\d*(\.\d{0,3})?$"
            width={width}
            ref={hola}
            value={inputValue} // Controlled component
            minWidth={minWidth}
        />
    );
}

const StyledInputDecimal = styled.input`
    width: ${(props) => (props.width ? props.width : "300px")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "300px")};
    height: 40px;
    border-radius: 5px;
    border: 1.5px solid #ff9100;
    padding: 0 15px;
    margin: 5px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    // On click, the input border turns orange
    &:focus {
        outline: none;
        border: 1.5px solid #d88219;
    }

    @media (max-width: 768px) {
        width: 300px;
        min-width: 300px;
    }

    @media (max-width: 425px) {
        width: 250px;
        min-width: 250px;
    }
`;

export default InputDecimal;