import { useState } from "react";

export default function useInfoModalHelper() {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
    data,
    setData,
  };
}
