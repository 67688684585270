import axios from "../api/axios";

const URL = "/influye/";

export async function getInfluyeService({ token }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  return response;
}