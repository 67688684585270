import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";

import {
  ColorclientesService,
  updateColorClientesService,
} from "../../services/colorclientes";

export default function useColorClientes() {
  const { state, dispatch } = useContext(AuthContext);
  const { token, client, colors } = state;

  const getColorClientes = useCallback(
    ({ rowsPerPage, page }) => {
      return ColorclientesService({ token, rowsPerPage, page, client })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateColorClientes = useCallback(
    (data) => {
      return updateColorClientesService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            let newColors = colors.map((color) => {
              if (color.id === res.data.data.id) {
                return res.data.data;
              }
              return color;
            });
            dispatch({
              type: "SET_COLORS",
              payload: { colors: newColors },
            });
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, dispatch, colors]
  );

  return {
    getColorClientes,
    updateColorClientes,
  };
}
