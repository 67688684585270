import React from "react";
import styled from "styled-components";

const ModalForm = ({
  handleSave,
  dataInput,
  handleCancel,
  create,
  edit,
  copy,
  handleEdit,
  handleCopy,
  children,
  title,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (create) {
      handleSave(dataInput);
    } else if (edit) {
      handleEdit(dataInput);
    } else if (copy) {
      handleCopy(dataInput);
    }
  };

  let modalTitle;
  if (create) modalTitle = `Crear ${title}`;
  if (edit) modalTitle = `Editar ${title}`;
  if (copy) modalTitle = `Copiar ${title}`;

  return (
    <StyledModalContainer>
      <StyledForm onSubmit={handleSubmit}>
        <div>
          <StyledH1>{modalTitle}</StyledH1>
        </div>
        <StyledInputsDiv>{children}</StyledInputsDiv>
        <StyledButtonContainer>
          <StyledSaveButton type="submit">Guardar</StyledSaveButton>
          <StyledCancelButton onClick={handleCancel}>
            Cancelar
          </StyledCancelButton>
        </StyledButtonContainer>
      </StyledForm>
    </StyledModalContainer>
  );
};

export default ModalForm;

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: auto;
  /* form {
    width: auto;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
  } */
`;

const StyledForm = styled.form`
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (min-width: 320px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 375px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 1680px) {
    
  }

  @media screen and (min-width: 1920px) {
    
  }

  @media screen and (min-width: 2560px) {
    
  }
`;

const StyledInputsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledH1 = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 20px;
  margin-bottom: 40px;
  /* or 62% */


  color: #64766A;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 30px;
`;

const StyledSaveButton = styled.button`
  width: 177px;
  height: 48px;
  background: #ff9100;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #ffffff;
  cursor: pointer;

  border: none;
`;

const StyledCancelButton = styled.button`
  border: 1.5px solid #D88219;
  border-radius: 5px;
  width: 177px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  background-color: #FFFFFF;

  color: #6c6c6c;
  cursor: pointer;
`;
