import React from "react";
import styled from "styled-components";
import Mantainer from "../Layout/mantainer";

const Tab = ({
  children,
  columnDefs,
  onSelectionChanged,
  handleNextPage,
  handlePreviousPage,
  previous,
  next,
  rowsPerPage,
  handleRowsPerPage,
  page,
  handleTab,
  tab,
  tabs,
  defectos,
}) => {
  const handleViewTabContent = (index) => {
    if (index === tab) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <StyledTab>
      <StyledTabHeader>
        {tabs.map((item, index) => (
          <StyledTabItem
            key={index}
            active={tab === index}
            onClick={
              () => {
                handleTab(index, item.parametro.id);
              }
            }
          >
            {item.orden_parametro} - {item.parametro.nombre}
          </StyledTabItem>
        ))}
      </StyledTabHeader>
      <StyledTabContent>
        {tabs.map((item, index) => (
          <StyledTabItemContent
            key={index}
            active={tab === index}
            style={{ display: handleViewTabContent(index) }}
          >
            <Mantainer
              columnDefs={columnDefs}
              rowData={defectos}
              onSelectionChanged={onSelectionChanged}
              previous={previous}
              next={next}
              page={page}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handleRowsPerPage={handleRowsPerPage}
              rowsPerPage={rowsPerPage}
              title="Defectos"
            >
              { children }
            </Mantainer>
          </StyledTabItemContent>
        ))}
      </StyledTabContent>
    </StyledTab>
  );
};

export default Tab;

const StyledTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTabHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledTabItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#3f51b5" : "transparent")};
  color: ${(props) => (props.active ? "#3f51b5" : "#9e9e9e")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
`;

const StyledTabContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTabItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#3f51b5" : "transparent")};
  color: ${(props) => (props.active ? "#3f51b5" : "#9e9e9e")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
`;
