import Layout from "../../../../components/Layout";
import { useEffect, useState, useContext } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import CalibreDetalleForm from "./calibreDetalleForm";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useCalibreDetalles from "../../../../hooks/api/useCalibreDetalles";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";

import { MantainersContext } from "../../../../context/MantaintersContext";

const CalibreDetalles = () => {
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getCalibreDetalles, createCalibreDetalle, updateCalibreDetalle } = useCalibreDetalles();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const { mantainersState } = useContext(MantainersContext);

  const { calibre } = mantainersState;

  console.log("Calibre : ", calibre);

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "especie.nombre", headerName: "Especie" },
    { field: "exportadora.nombre", headerName: "Exportadora" },
    { field: "valor_minimo_milimetros", headerName: "Valor Minimo (mm)" },
    { field: "valor_medio_milimetros", headerName: "Valor Medio (mm)" },
    { field: "valor_maximo_milimetros", headerName: "Valor Maximo (mm)" },
    { field: "valor_minimo_gramos", headerName: "Valor Minimo (gr)" },
    { field: "valor_medio_gramos", headerName: "Valor Medio (gr)" },
    { field: "valor_maximo_gramos", headerName: "Valor Maximo (gr)" },
    { field: "orden", headerName: "Orden" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getCalibreDetalles({ rowsPerPage, page, calibre }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getCalibreDetalles,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    calibre,
  ]);

  const handleEditCalibreDetalle = () => {
    setCreate(false);
    setEdit(true);
    updateCalibreDetalle(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        console.log("Page Res : ", res);
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSaveCalibreDetalle = (e) => {
    setCreate(false);
    createCalibreDetalle({dataInput, calibre}).then((res) => {
      setRowData([...rowData, res.data]);
    });
    setEdit(false);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title={ "Detalle Calibre (" + calibre.nombre + ")"}
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create === true ? (
        <CalibreDetalleForm
          setDataInput={setDataInput}
          handleSave={handleSaveCalibreDetalle}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
          calibre={calibre}
        />
      ) : null}
      {edit === true ? (
        <CalibreDetalleForm
          setDataInput={setDataInput}
          handleEdit={handleEditCalibreDetalle}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
    </Layout>
  );
};

export default CalibreDetalles;