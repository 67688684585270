import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getCajaDetallesService,
  createCajaDetalleService,
  updateCajaDetalleService,
} from "../../services/cajaDetalles";

export default function useCajaDetalles() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getCajaDetalles = useCallback(
    ({ dataInput }) => {
      const data = {
        cliente: client.id,
        inspeccion_planilla: dataInput.id,
      };
      return getCajaDetallesService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createCajaDetalles = useCallback(
    (data) => {
      return createCajaDetalleService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateCajaDetalles = useCallback(
    (data) => {
      return updateCajaDetalleService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getCajaDetalles,
    createCajaDetalles,
    updateCajaDetalles,
  };
}
