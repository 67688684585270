import React from "react";
import styled from "styled-components";

import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import InputSelect from "../../../../components/Cajas/InputSelect";
import InputDate from "../../../../components/Cajas/InputDate";

const FiltersContainer = ({
  exportadora,
  especie,
  zona,
  calibre,
  productor,
  variedad,
  embalaje,
  turno,
  dataInput,
  setDataInput,
  handleFilterForm,
  isLoading,
  setIsLoading,
}) => {
  const handleDataInputChanges = (e) => {
    setDataInput({
      ...dataInput,
      [e.target.name]: e.target.value,
    });
  };
  console.log("FiltersContainer: ", dataInput);
  return (
    <StyledFiltersContainer>
      <StyledInputContainer>
        <StyledInputLabel>Exportadora</StyledInputLabel>
        <InputSelect
          label="Exportadora"
          name="exportadora"
          value={dataInput.exportadora}
          onChange={handleDataInputChanges}
          options={
            exportadora.results !== undefined
              ? exportadora.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Especie</StyledInputLabel>
        <InputSelect
          label="Especie"
          name="especie"
          value={dataInput.especie}
          onChange={handleDataInputChanges}
          options={
            especie.results !== undefined
              ? especie.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Zona</StyledInputLabel>
        <InputSelect
          label="Zona"
          name="zona"
          value={dataInput.zona}
          onChange={handleDataInputChanges}
          options={
            zona.results !== undefined
              ? zona.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Calibre</StyledInputLabel>
        <InputSelect
          label="Calibre"
          name="calibre"
          value={dataInput.calibre}
          onChange={handleDataInputChanges}
          options={
            calibre.results !== undefined
              ? calibre.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Productor Real</StyledInputLabel>
        <InputSelect
          label="Productor Real"
          name="productor_real"
          value={dataInput.productor_real}
          onChange={handleDataInputChanges}
          options={
            productor.results !== undefined
              ? productor.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Productor Rotulado</StyledInputLabel>
        <InputSelect
          label="Productor Rotulado"
          name="productor_rotulado"
          value={dataInput.productor_rotulado}
          onChange={handleDataInputChanges}
          options={
            productor.results !== undefined
              ? productor.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Variedad Real</StyledInputLabel>
        <InputSelect
          label="Variedad"
          name="variedad"
          value={dataInput.variedad_real}
          onChange={handleDataInputChanges}
          options={
            variedad.results !== undefined
              ? variedad.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Embalajeo</StyledInputLabel>
        <InputSelect
          label="Embalaje"
          name="embalaje"
          value={dataInput.embalaje}
          onChange={handleDataInputChanges}
          options={
            embalaje.results !== undefined
              ? embalaje.results.map((item) => {
                  return { value: item.id, label: item.codigo };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Turno</StyledInputLabel>
        <InputSelect
          label="Turno"
          name="turno"
          value={dataInput.turno}
          onChange={handleDataInputChanges}
          options={
            turno.results !== undefined
              ? turno.results.map((item) => {
                  return { value: item.id, label: item.nombre };
                })
              : []
          }
          width="110px"
          minWidth="110px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Fecha Inicio</StyledInputLabel>
        <InputDate
          label="Fecha Inicio"
          name="fecha_desde"
          value={dataInput.fecha_desde}
          onChange={handleDataInputChanges}
          width="180px"
          minWidth="180px"
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Fecha Fin</StyledInputLabel>
        <InputDate
          label="Fecha Fin"
          name="fecha_hasta"
          value={dataInput.fecha_hasta}
          onChange={handleDataInputChanges}
          width="180px"
          minWidth="180px"
        />
      </StyledInputContainer>
      <ButtonsContainer>
        {isLoading ? (
          <FormCrudButton text="Cargando..." />
        ) : (
          <FormCrudButton text="Filtrar" onClick={handleFilterForm} />
        )}
        <FormCrudButton text="Exel" onClick={handleFilterForm} />
      </ButtonsContainer>
    </StyledFiltersContainer>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 99%;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #65756a;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  position: relative;
`;

export default FiltersContainer;
