import React, {useEffect, useState, useContext} from "react";

import AuthContext from "../../context/AuthProvider";

import * as LolosIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";

import {
  StyledItem,
  StyleItemLados,
  StyleItemMedio,
  StyledItemLi,
  StyledLink,
  StyledItemContainer
} from "./styledSidebar";

const SubMenuItems = ({item, sidebar}) => {
  const [itemmenu, setItemMenu] = useState(false);
  const showItemMenu = () => setItemMenu(!itemmenu);

  const { state } = useContext(AuthContext);

  const { user } = state;

  var user_type = user.user_type.id;
  //console.log(itemmenu);

  useEffect(() => {
    if (sidebar === false) {
      setItemMenu(false);
    }
  }, [sidebar]);

  return (
    <>
      <StyledItem active={sidebar} itemmenu={itemmenu} to={item.path} onClick={sidebar === true && item.submenu  ? showItemMenu : null}>
          <StyleItemLados>{itemmenu ? item.iconOrange : item.iconWhite}</StyleItemLados>
          {sidebar === true ? <StyleItemMedio><span>{item.title}</span></StyleItemMedio> : null}
          {sidebar === true ? <StyleItemLados><LolosIcons.IoIosArrowDown /></StyleItemLados> : null}
      </StyledItem>
      <StyledItemContainer active={sidebar}>
        {itemmenu === true && item.submenu.map((subitem, index) => {
          if (subitem.access) {
            for (let i = 0; i < subitem.access.length; i++) {
              if (subitem.access[i] === user_type) {
                return (
                  <StyledItemLi key={index}>
                    <BsIcons.BsDot/><StyledLink to={subitem.path}>{subitem.title}</StyledLink>
                  </StyledItemLi>
                );
              }
            }
          }else{
            return null;
          }
          return null;
        })}
      </StyledItemContainer>
    </>
  );
};

export default SubMenuItems;
