import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/resoluciones/"; // Se completa el path de la url base

// Export async function resoluciones

export async function getResolucionesService({
  token,
  rowsPerPage,
  page,
  data,
}) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  console.log("Response Resoluciones:", response);
  // Se retorna la respuesta
  return response;
}

export async function getResolucionesNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  // console.log("Response Resoluciones:", response);
  return response;
}

export async function createResolucionService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Data Resolucion:", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, data, config);
  console.log("Response Resoluciones:", response);
  // Se retorna la respuesta
  return response;
}

export async function updateResolucionService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(URL + data.id + "/", data, config);
  console.log("Response Resoluciones:", response);
  // Se retorna la respuesta
  return response;
}
