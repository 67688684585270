import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/variedades/";

export async function getVariedadesService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getVariedadesNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getVariedadesByEspecieExportadoraService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const newUrl = URL + "especie/exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // console.log("Service Response :", response);
  // Se retorna la respuesta
  return response;
}

export async function createVariedadService({ token, data }) {
  console.log("Service Variedad: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      especie: data.especie,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response :", response);

  return response;
}

export async function createVariedadExportadoraService({ token, data }) {
  console.log("Service Variedad: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL + "activate_exportadora/",
    {
      variedad: data[0].variedad.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response :", response);

  return response;
}

export async function updateVariedadService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  console.log("Service Variedad: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      especie: data.especie.id ? data.especie.id : data.especie,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Response :", response);
  return response;
}

export async function deleteVariedadService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";

  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}
