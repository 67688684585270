import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getInfluyeService,
} from "../../services/influye";

export default function useInfluye() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getInfluye = useCallback(
    () => {
      return getInfluyeService({ token })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getInfluye,
  };
}
