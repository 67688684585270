import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getNormasService, createNormaService, updateNormaService, getNormaPlanillaService } from "../../services/normas";

export default function useNormas() {

  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getNormas = useCallback(({ rowsPerPage, page }) => {
    const data = {
      cliente: client.id,
    };
    return getNormasService({ token, rowsPerPage, page, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getNormaPlanilla = useCallback((dataInput) => {
    return getNormaPlanillaService({ token, dataInput })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const createNorma = useCallback((data) => {
    data.cliente = client.id;
    return createNormaService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);


  const updateNorma = useCallback((data) => {
    return updateNormaService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          console.log("Data Hook : ",res.data);
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  }, [token]);

  return {
    getNormas,
    createNorma,
    updateNorma,
    getNormaPlanilla,
  };
}
