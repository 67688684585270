import axios from "../api/axios";

const URL = "/firmezas/";

export async function createFirmezaCajaService({ token, array, new_caja_id }) {
    console.log("firmeza", array);
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    let data = {
        firmeza: array,
        caja: new_caja_id,
    };

    const response = await axios.post(URL, data, config);
    console.log("response", response);
    return response;
}

export async function updateFirmezaCajaService({ token, array, caja_id }) {
    const NEWURL = URL + "update/";
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    let data = {
        firmeza: array,
        caja: caja_id,
    };

    // Se realiza la petición
    const response = await axios.post(NEWURL, data, config);
    console.log("response", response);
    return response;
}