import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/clientes/"; // Se completa el path de la url base

// Export async function users
export async function clientsService({ token, rowsPerPage, page }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  console.log("Service :", response);
  return response;
}

export async function createClientService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, {
    nombre: data.nombre,
    estado: data.estado,
  }, config);
  // Se retorna la respuesta
  console.log("Service Response :", response);
  return response;
}

export async function updateClientService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(updateUrl, {
    nombre: data.nombre,
    estado: data.estado,
  }, config);
  // Se retorna la respuesta
  console.log("Service :" , response);
  return response;
}

export async function deleteClientService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";

  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}