const Mantenedores = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 16.5C19 17.328 18.328 18 17.5 18H16.5C15.672 18 15 17.328 15 16.5C15 15.672 15.672 15 16.5 15H17.5C18.328 15 19 15.672 19 16.5ZM24 14.5V19.5C24 21.981 21.981 24 19.5 24H4.5C2.019 24 0 21.981 0 19.5V14.5C0 12.019 2.019 10 4.5 10H5V4.5C5 2.019 7.019 0 9.5 0H14.5C16.981 0 19 2.019 19 4.5V10H19.5C21.981 10 24 12.019 24 14.5ZM8 10H16V4.5C16 3.673 15.327 3 14.5 3H9.5C8.673 3 8 3.673 8 4.5V10ZM4.5 21H10.5V13H4.5C3.673 13 3 13.673 3 14.5V19.5C3 20.327 3.673 21 4.5 21ZM21 14.5C21 13.673 20.327 13 19.5 13H13.5V21H19.5C20.327 21 21 20.327 21 19.5V14.5ZM7.5 15H6.5C5.672 15 5 15.672 5 16.5C5 17.328 5.672 18 6.5 18H7.5C8.328 18 9 17.328 9 16.5C9 15.672 8.328 15 7.5 15ZM14 6.5C14 5.672 13.328 5 12.5 5H11.5C10.672 5 10 5.672 10 6.5C10 7.328 10.672 8 11.5 8H12.5C13.328 8 14 7.328 14 6.5Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default Mantenedores;
