import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useDefectos from "../../../../hooks/api/useDefectos";
import useVariedad from "../../../../hooks/api/useVariedad";
import useClasificaciones from "../../../../hooks/api/useClasificaciones";
import useCalibres from "../../../../hooks/api/useCalibres";
import useEtiquetas from "../../../../hooks/api/useEtiquetas";
import useEmbalajes from "../../../../hooks/api/useEmbalajes";
import useInfluye from "../../../../hooks/api/useInfluye";
import InputDecimal from "../../../../components/Cajas/InputDecimal";

const NormaDetalleForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  edit,
  copy,
  handleEdit,
  handleCopy,
}) => {
  const { getDefectosNoPagination } = useDefectos();
  const { getVariedadesNoPagination } = useVariedad();
  const { getClasificacionesNoPagination } = useClasificaciones();
  const { getCalibresNoPagination } = useCalibres();
  const { getEtiquetasNoPagination } = useEtiquetas();
  const { getEmbalajesNoPagination } = useEmbalajes();
  const { getInfluye } = useInfluye();

  const [defectos, setDefectos] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [calibres, setCalibres] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [embalajes, setEmbalajes] = useState([]);
  const [influye, setInfluye] = useState([]);

  useEffect(() => {
    getDefectosNoPagination().then((res) => {
      setDefectos(res.results);
    });
    getVariedadesNoPagination().then((res) => {
      setVariedades(res.results);
    });
    getClasificacionesNoPagination().then((res) => {
      setClasificaciones(res.results);
    });
    getCalibresNoPagination().then((res) => {
      setCalibres(res.results);
    });
    getEtiquetasNoPagination().then((res) => {
      setEtiquetas(res.results);
    });
    getEmbalajesNoPagination().then((res) => {
      setEmbalajes(res.results);
    });
    getInfluye().then((res) => {
      setInfluye(res.results);
    });
  }, [
    getDefectosNoPagination,
    getVariedadesNoPagination,
    getClasificacionesNoPagination,
    getCalibresNoPagination,
    getEtiquetasNoPagination,
    getEmbalajesNoPagination,
    getInfluye,
  ]);

  console.log("dataInput", dataInput);

  const handleValue = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value,
      });
    }
    console.log("dataInput Planilla", dataInput);
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      copy={copy}
      handleEdit={handleEdit}
      handleCopy={handleCopy}
      title="Norma Detalle"
    >
      <StyledSelected
        name="planilla_defecto"
        value={
          dataInput.planilla_defecto !== null
            ? dataInput.planilla_defecto.defecto.id
            : 0
        }
        onChange={handleValue}
      >
        <StyledOption value="0">Seleccione Defecto</StyledOption>
        {defectos.map((defecto, index) => (
          <StyledOption key={index} value={defecto.id}>
            {defecto.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="variedad"
        value={dataInput.variedad !== null ? dataInput.variedad.id : 0}
        onChange={handleValue}
      >
        <StyledOption value="0">Todas las Variedades</StyledOption>
        {variedades.map((variedad, index) => (
          <StyledOption key={index} value={variedad.id}>
            {variedad.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="clasificacion"
        value={
          dataInput.clasificacion !== null ? dataInput.clasificacion.id : 0
        }
        onChange={handleValue}
      >
        <StyledOption value="0">Todas las Clasificaciones</StyledOption>
        {clasificaciones.map((clasificacion, index) => (
          <StyledOption key={index} value={clasificacion.id}>
            {clasificacion.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="calibre"
        value={dataInput.calibre !== null ? dataInput.calibre.id : 0}
        onChange={handleValue}
      >
        <StyledOption value="0">Todos los Calibres</StyledOption>
        {calibres.map((calibre, index) => (
          <StyledOption key={index} value={calibre.id}>
            {calibre.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="etiqueta"
        value={dataInput.etiqueta !== null ? dataInput.etiqueta.id : 0}
        onChange={handleValue}
      >
        <StyledOption value="0">Todas las Etiquetas</StyledOption>
        {etiquetas.map((etiqueta, index) => (
          <StyledOption key={index} value={etiqueta.id}>
            {etiqueta.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="embalaje"
        value={dataInput.embalaje !== null ? dataInput.embalaje.id : 0}
        onChange={handleValue}
      >
        <StyledOption value="0">Todos los Embalajes</StyledOption>
        {embalajes.map((embalaje, index) => (
          <StyledOption key={index} value={embalaje.id}>
            {embalaje.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="influye"
        value={dataInput.influye !== null ? dataInput.influye.id : 0}
        onChange={handleValue}
      >
        <StyledOption value="0">Seleccione Influye</StyledOption>
        {influye.map((influye, index) => (
          <StyledOption key={index} value={influye.id}>
            {influye.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <InputDecimal
        name="norma_valor_1"
        placeholder="Valor 1"
        step={0.01}
        defaultValue={
          dataInput.norma_valor_1 !== null ? dataInput.norma_valor_1 : null
        }
        onChange={handleValue}
      />
      <InputDecimal
        name="norma_valor_2"
        placeholder="Valor 2"
        step={0.01}
        defaultValue={
          dataInput.norma_valor_2 !== null ? dataInput.norma_valor_2 : null
        }
        onChange={handleValue}
      />
      <InputDecimal
        name="norma_valor_3"
        placeholder="Valor 3"
        step={0.01}
        defaultValue={
          dataInput.norma_valor_3 !== null ? dataInput.norma_valor_3 : null
        }
        onChange={handleValue}
      />
      <InputDecimal
        type="number"
        name="orden_ingreso"
        placeholder="Orden Ingreso"
        step={0.1}
        defaultValue={
          dataInput.orden_ingreso !== null ? dataInput.orden_ingreso : null
        }
        onChange={handleValue}
      />
      <StyledSelected
        name="estado"
        defaultValue={dataInput.estado !== undefined ? dataInput.estado : true}
        onChange={handleValue}
      >
        <StyledOption value="true">Activo</StyledOption>
        <StyledOption value="false">Inactivo</StyledOption>
      </StyledSelected>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

export default NormaDetalleForm;