import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import usePlanillaDefectos from "../../../hooks/api/usePlanillaDefectos";
import usePlanillas from "../../../hooks/api/usePlanillas";
import useCajas from "../../../hooks/api/useCajas";
import { useNavigate } from "react-router-dom";
import useCajaDetalles from "../../../hooks/api/useCajaDetalles";
import useInfoModalHelper from "../../../hooks/helpers/useInfoModalHelper";
import AuthContext from "../../../context/AuthProvider";
import { PlanillaContext } from "../../../context/PlanillaContext";
import PromedioComponent from "./promedioComponent";
import CajaHijaComponent from "./cajaHijaComponent";
import CajaComponent from "./cajaComponent";

import Swal from 'sweetalert2';

const CajasTable = ({ filters }) => {
  const [planillaDefectosHeaders, setPlanillaDefectosHeaders] = useState([]);
  const [cajaDetalles, setCajaDetalles] = useState([]);
  const [cajasHeaders, setCajasHeaders] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [resolucion, setResolucion] = useState(null);
  const [cumplePacking, setCumplePacking] = useState(null);

  const { getPlanillaEspecie } = usePlanillas();

  const { getPlanillaDefectosHeaders } = usePlanillaDefectos();

  const { getCajasHeaders, deleteCaja } = useCajas();

  const { getCajaDetalles } = useCajaDetalles();

  const { isOpen, handleOpen, handleClose, setData, data } =
    useInfoModalHelper();

  const { state } = useContext(AuthContext);
  const { colors } = state;
  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { planilla, planillaType } = planillaState;

  const [initialHeaders] = useState([
    "embalaje",
    "etiqueta",
    "clasificacion",
    "numero_caja",
    "variedad",
    "variedad_real",
    "productor",
    "productor_real",
  ]);

  const [midHeaders] = useState(["resultado_caja", "resultado_pallet"]);

  const [finalHeaders] = useState(["cumple_packing"]);

  const fetchData = async () => {
    try {
      const res = await getPlanillaEspecie(planilla);
      let planillaRes = res.results;

      const cajaHeadersRes = await getCajasHeaders();
      setCajasHeaders(cajaHeadersRes.data);

      const planillaDefectosHeadersRes = await getPlanillaDefectosHeaders({
        planillaRes,
      });
      setPlanillaDefectosHeaders(planillaDefectosHeadersRes.data);

      let dataInput = planilla;

      const cajaDetallesRes = await getCajaDetalles({ dataInput });
      setCajaDetalles(cajaDetallesRes.data);
      console.log("cajaDetallesRes", cajaDetallesRes.data);
      // if (planillaType === 1) {
      planillaDispatch({
        type: "SET_DETALLES_CAJA",
        payload: cajaDetallesRes.data,
      });
      //}
    } catch (error) {
      console.error(error);
      // Handle the error appropriately here
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    setCajasHeaders,
    filters,
    setCajaDetalles,
    setFotos,
    planilla,
    getCajaDetalles,
    getPlanillaDefectosHeaders,
    getPlanillaEspecie,
    getCajasHeaders,
    planillaDispatch,
    planillaType,
  ]);

  const navigate = useNavigate();

  const handleEditCaja = (data) => {
    const detallesCaja = cajaDetalles.filter(
      (item) => item.caja.id === data.caja.id
    );
    const foto = fotos.filter((item) => item.caja.id === data.id);
    console.log("data", data);
    const filtros = {
      Clasificación: data.caja.clasificacion.id,
      Embalaje: data.caja.embalaje.id,
      Variedad: data.caja.variedad.id,
      Productor: data.caja.productor.id,
      "Variedad Real": data.caja.variedad_real.id,
      "Productor Real": data.caja.productor_real.id,
      Etiqueta: data.caja.etiqueta.id,
    };
    console.log("filtros", filtros);

    planillaDispatch({ type: "SET_DATA", payload: data });
    planillaDispatch({ type: "SET_DETALLES_CAJA", payload: detallesCaja });
    planillaDispatch({ type: "SET_FOTOS", payload: foto });
    planillaDispatch({ type: "SET_CONTRA_MUESTRA_CAJA", payload: false });
    planillaDispatch({ type: "SET_COPY_CAJA", payload: false });
    planillaDispatch({ type: "SET_FILTERS", payload: filtros });

    navigate("detail");
  };

  const handleEditCajaHija = (data) => {
    console.log("data", data);
    let detallesCaja = [];
    cajaDetalles.forEach((item) => {
      item.caja.cajas_hijas?.forEach((itemHija) => {
        if (itemHija.caja.id === data.caja.id) {
          detallesCaja.push(itemHija);
        }
      });
    });

    const foto = fotos.filter((item) => item.caja.id === data.id);

    planillaDispatch({ type: "SET_DATA", payload: data });
    planillaDispatch({ type: "SET_DETALLES_CAJA", payload: detallesCaja });
    planillaDispatch({ type: "SET_FOTOS", payload: foto });
    planillaDispatch({ type: "SET_CONTRA_MUESTRA_CAJA", payload: false });
    planillaDispatch({ type: "SET_COPY_CAJA", payload: false });

    navigate("detail");
  };

  const handleGoToContraMuestra = (data) => {
    const detallesCaja = cajaDetalles.filter(
      (item) => item.caja.id === data.caja.id
    );
    const foto = fotos.filter((item) => item.caja.id === data.caja.id);

    const filtros = {
      Clasificación: data.caja.clasificacion.id,
      Embalaje: data.caja.embalaje.id,
      Variedad: data.caja.variedad.id,
      Productor: data.caja.productor.id,
      "Variedad Real": data.caja.variedad_real.id,
      "Productor Real": data.caja.productor_real.id,
      Etiqueta: data.caja.etiqueta.id,
    };

    planillaDispatch({ type: "SET_DATA", payload: data });
    planillaDispatch({ type: "SET_DETALLES_CAJA", payload: detallesCaja });
    planillaDispatch({ type: "SET_FOTOS", payload: foto });
    planillaDispatch({ type: "SET_CONTRA_MUESTRA_CAJA", payload: true });
    planillaDispatch({ type: "SET_COPY_CAJA", payload: false });
    planillaDispatch({ type: "SET_FILTERS", payload: filtros });

    navigate("detail");
  };

  const handleDeleteCaja = (item) => {
    const id = item.caja.id;
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCaja({ id }).then((res) => {
          fetchData();
          Swal.fire(
            'Eliminado!',
            'La caja ha sido eliminada.',
            'success'
          )
        });
      }
    });
  };

  const handleGoToCopy = (data) => {
    const detallesCaja = cajaDetalles.filter(
      (item) => item.caja.id === data.caja.id
    );
    const foto = fotos.filter((item) => item.caja.id === data.caja.id);

    planillaDispatch({ type: "SET_DATA", payload: data });
    planillaDispatch({ type: "SET_DETALLES_CAJA", payload: detallesCaja });
    planillaDispatch({ type: "SET_FOTOS", payload: foto });
    planillaDispatch({ type: "SET_CONTRA_MUESTRA_CAJA", payload: false });
    planillaDispatch({ type: "SET_COPY_CAJA", payload: true });

    navigate("detail");
  };

  const causalesCaja = (planillaDefectosHeaders, data) => {
    let causalesMadre = [];
    planillaDefectosHeaders.forEach((planillaDefectosHeader) => {
      if (Array.isArray(data)) {
        const planillaDefecto = data.find(
          (cajaDetalle) =>
            cajaDetalle.planilla_defecto.id ===
            planillaDefectosHeader.planilla_defecto
        );
        if (planillaDefecto?.cumple_id === 4 || planillaDefecto?.cumple_id === 2) {
          causalesMadre.push(planillaDefecto);
        }
      } else {
        const planillaDefecto = Object.values(data).find(
          (cajaDetalle) =>
            cajaDetalle.planilla_defecto_id ===
            planillaDefectosHeader.planilla_defecto
        );
        if (planillaDefecto?.cumple === 4 || planillaDefecto?.cumple === 2) {
          // add to planillaDefecto the nombre of the planillaDefectosHeader
          planillaDefecto.nombre = planillaDefectosHeader.nombre;
          causalesMadre.push(planillaDefecto);
        }
      }
    });

    setData(causalesMadre);

    return causalesMadre;
  };

  const formatHeader = (item) => {
    let key = Object.keys(item)[0];
    return key
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  };

  const renderTableCell = (property, propertyValue, item) => {
    return (
      <TableCell>
        {item.caja[property] !== null && item.caja[property][propertyValue]}
      </TableCell>
    );
  };

  const getColor = (cumple) => {
    switch (cumple) {
      case 4:
        return colors.find((color) => color.cumple.id === 4)?.color_texto;
      case 3:
        return colors.find((color) => color.cumple.id === 3)?.color_texto;
      case 2:
        return colors.find((color) => color.cumple.id === 2)?.color_texto;
      case 1:
        return colors.find((color) => color.cumple.id === 1)?.color_texto;
      default:
        return colors.find((color) => color.cumple.id === 1)?.color_texto;
    }
  };

  const getBgColor = (cumple) => {
    switch (cumple) {
      case 4:
        return colors.find((color) => color.cumple.id === 4)?.color;
      case 3:
        return colors.find((color) => color.cumple.id === 3)?.color;
      case 2:
        return colors.find((color) => color.cumple.id === 2)?.color;
      // case 1:
      //   return colors.find((color) => color.cumple.id === 1)?.color;
      default:
        return;
    }
  };

  const getColorResult = (cumple) => {
    switch (cumple) {
      case 4:
        return colors.find((color) => color.cumple.id === 4)?.cumple.nombre;
      case 3:
        return colors.find((color) => color.cumple.id === 3)?.cumple.nombre;
      case 2:
        return colors.find((color) => color.cumple.id === 2)?.cumple.nombre;
      case 1:
        return colors.find((color) => color.cumple.id === 1)?.cumple.nombre;
      default:
        return colors.find((color) => color.cumple.id === 1)?.cumple.nombre;
    }
  };

  const renderTableHeaderCell = (item, index, headers) => {
    let valor = Object.values(item)[0];
    if (headers.includes(valor)) {
      return (
        <TableHeaderCell key={valor}>{formatHeader(item)}</TableHeaderCell>
      );
    }
    return null;
  };

  const renderCajaComponent = (item, index) => (
    <React.Fragment key={item.id || index}>
      <CajaComponent
        index={index}
        item={item}
        handleEditCaja={handleEditCaja}
        handleDeleteCaja={handleDeleteCaja}
        planillaDefectosHeaders={planillaDefectosHeaders}
        getBgColor={getBgColor}
        getColor={getColor}
        causalesCaja={causalesCaja}
        getColorResult={getColorResult}
        handleGoToContraMuestra={handleGoToContraMuestra}
        handleGoToCopy={handleGoToCopy}
        handleOpen={handleOpen}
        handleClose={handleClose}
        isOpen={isOpen}
        data={data}
      />
      {item.caja.cajas_hijas?.map((cajaHija, index) => (
        <CajaHijaComponent
          key={cajaHija.id || index}
          cajaHija={cajaHija}
          handleEditCajaHija={handleEditCajaHija}
          handleDeleteCaja={handleDeleteCaja}
          planillaDefectosHeaders={planillaDefectosHeaders}
          getBgColor={getBgColor}
          getColor={getColor}
          causalesCaja={causalesCaja}
          getColorResult={getColorResult}
          item={item}
          index={index}
          handleOpen={handleOpen}
          handleClose={handleClose}
          data={data}
          isOpen={isOpen}
        />
      ))}
      {item.caja.promedio !== null && (
        <PromedioComponent
          key={item.id || index}
          planillaDefectosHeaders={planillaDefectosHeaders}
          promedio={item}
          causalesCaja={causalesCaja}
          resolucion={resolucion}
          setResolucion={setResolucion}
          cumplePacking={cumplePacking}
          setCumplePacking={setCumplePacking}
          renderTableCell={renderTableCell}
          getColor={getColor}
          getBgColor={getBgColor}
          fetchData={fetchData}
          handleOpen={handleOpen}
          handleClose={handleClose}
          data={data}
          isOpen={isOpen}
        />
      )}
    </React.Fragment>
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell position={"sticky"}>{"Editar"}</TableHeaderCell>
          <TableHeaderCell position={"sticky"}>
            {"Contra muestra"}
          </TableHeaderCell>
          <TableHeaderCell position={"sticky"}>{"Eliminar"}</TableHeaderCell>
          <TableHeaderCell position={"sticky"}>{"Copiar"}</TableHeaderCell>
          {cajasHeaders?.map((item, index) =>
            renderTableHeaderCell(item, item.id || index, initialHeaders)
          )}
          {planillaDefectosHeaders?.map((item, index) => (
            <TableHeaderCell key={item.nombre}>{item.nombre}</TableHeaderCell>
          ))}
          <TableHeaderCell>Peso Fruto</TableHeaderCell>
          <TableHeaderCell>Peso Fijo</TableHeaderCell>
          <TableHeaderCell>Firmeza</TableHeaderCell>
          {cajasHeaders?.map((item, index) =>
            renderTableHeaderCell(item, item.id || index, midHeaders)
          )}
          <TableHeaderCell>Causales</TableHeaderCell>
          <TableHeaderCell>Resolución</TableHeaderCell>
          {cajasHeaders?.map((item, index) =>
            renderTableHeaderCell(item, item.id || index, finalHeaders)
          )}
          <TableHeaderCell>Foto 1</TableHeaderCell>
          <TableHeaderCell>Foto 2</TableHeaderCell>
          <TableHeaderCell>Foto 3</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {cajaDetalles.map((item, index) => renderCajaComponent(item, index))}
      </TableBody>
    </Table>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  // lateral scroll the content
  overflow-x: auto;
  color: #64766a;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
`;

const TableHeader = styled.thead`
  background-color: #f5f5f5;
  //fix header
  top: 0;
  z-index: 2;
  height: max-content;
`;

const TableHeaderCell = styled.th`
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  text-align: right;
  writing-mode: vertical-rl;
  width: max-content;
  transform: rotate(180deg);
  text-align: left;
  position: sticky;
`;

const TableBody = styled.tbody`
  background-color: #ffffff;
`;

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.stripe === true ? "#f5f5f5" : "#ffffff"};
`;

const TableCell = styled.td`
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  text-align: left;
  background-color: ${(props) => props.bgColor};
`;

export default CajasTable;
