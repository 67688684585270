import axios from "../api/axios";

const URL = "/norma_detalles/";

export async function getNormaDetallesService({
  token,
  norma,
}) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      norma: norma.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  return response;
}

export async function getNormaDetallesNoPaginationService({ token, norma }) {
  console.log("Service Norma", norma);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      norma: norma,
      operaciones: true,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  console.log("Service RESPONSE: ", response);
  return response;
}

export async function getNormaDetalleByEmbalajeService({token, initialFilters}){
  const newUrl = URL + "embalaje/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      embalaje: initialFilters.Embalaje,
      clasificacion: initialFilters["Clasificación"] || null,
      variedad: initialFilters.Variedad || null,
      calibre: initialFilters.Calibre || null,
      etiqueta: initialFilters.Etiqueta || null,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  return response;
}

export async function createNormaDetallesService({ token, data }) {
  console.log("Service Data", data);
  let variedad = data.variedad !== null ? ( data.variedad.id || (data.variedad === '0' ? null : data.variedad)) : null;
  let clasificacion = data.clasificacion !== null ? ( data.clasificacion.id || (data.clasificacion === '0' ? null : data.clasificacion)) : null;
  let calibre = data.calibre !== null ? ( data.calibre.id || (data.calibre === '0' ? null : data.calibre)) : null;
  let etiqueta = data.etiqueta !== null ? ( data.etiqueta.id || (data.etiqueta === '0' ? null : data.etiqueta)) : null;
  let embalaje = data.embalaje !== null ? ( data.embalaje.id || (data.embalaje === '0' ? null : data.embalaje)) : null;
  let influye = data.influye !== null ? ( data.influye.id || (data.influye === '0' ? null : data.influye)) : null;

  console.log("Variedad", variedad);
  console.log("Clasificacion", clasificacion);
  console.log("Calibre", calibre);
  console.log("Etiqueta", etiqueta);
  console.log("Embalaje", embalaje);
  console.log("Influye", influye);

  let createData = {
    norma: data.norma.id,
    planilla_defecto: data.planilla_defecto.id,
    variedad: variedad,
    clasificacion: clasificacion,
    calibre: calibre,
    etiqueta: etiqueta,
    embalaje: embalaje,
    influye: influye,
    norma_valor_1: data.norma_valor_1,
    norma_valor_2: data.norma_valor_2,
    norma_valor_3: data.norma_valor_3, 
    orden_ingreso: data.orden_ingreso,
    estado: data.estado,
  };
  console.log("Service Create Data", createData);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      norma_detalle: data.id, 
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, createData, config); 
  console.log("Service RESPONSE: ", response);
  return response;
}

export async function updateNormaDetallesService({ token, data }) {
  console.log("Service Data", data);
  let variedad = data.variedad !== null ? ( data.variedad.id || (data.variedad === '0' ? null : data.variedad)) : null;
  let clasificacion = data.clasificacion !== null ? ( data.clasificacion.id || (data.clasificacion === '0' ? null : data.clasificacion)) : null;
  let calibre = data.calibre !== null ? ( data.calibre.id || (data.calibre === '0' ? null : data.calibre)) : null;
  let etiqueta = data.etiqueta !== null ? ( data.etiqueta.id || (data.etiqueta === '0' ? null : data.etiqueta)) : null;
  let embalaje = data.embalaje !== null ? ( data.embalaje.id || (data.embalaje === '0' ? null : data.embalaje)) : null;
  let influye = data.influye !== null ? ( data.influye.id || (data.influye === '0' ? null : data.influye)) : null;

  console.log("Variedad", variedad);
  console.log("Clasificacion", clasificacion);
  console.log("Calibre", calibre);
  console.log("Etiqueta", etiqueta);
  console.log("Embalaje", embalaje);
  console.log("Influye", influye);

  let updateData = {
    norma: data.norma.id,
    planilla_defecto: data.planilla_defecto.id,
    variedad: variedad,
    clasificacion: clasificacion,
    calibre: calibre,
    etiqueta: etiqueta,
    embalaje: embalaje,
    influye: influye,
    norma_valor_1: data.norma_valor_1,
    norma_valor_2: data.norma_valor_2,
    norma_valor_3: data.norma_valor_3,
    orden_ingreso: data.orden_ingreso,
    estado: data.estado,
  };
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(`${URL}${data.id}/`, updateData, config);
  console.log("Service Responce", response);
  return response;
}
