import Navbar from "../../../components/Navbar";

const BusCamion = () => {
    return (
        <div>
            <Navbar active link="https://contenedor.inglobochile.cl/Reportes/Reportes_Camion_Especifico/FrontIndex.php"/>
        </div>
    )
}


export default BusCamion;