import axios from "../api/axios";

const URL = "/brix/";

export async function createBrixCajaService({ token, array, new_caja_id }) {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    let data = {
        brix: array,
        caja: new_caja_id,
    };

    console.log("data", data);

    // Se realiza la petición
    const response = await axios.post(URL, data, config);
    console.log("response", response);
    return response;
}

export async function updateBrixCajaService({ token, array, caja_id }) {
    console.log("Service Caja Create: ", array);
    const NEWURL = URL + "update/";
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    let data = {
        brix: array,
        caja: caja_id,
    };

    console.log("data", data);

    // Se realiza la petición
    const response = await axios.post(NEWURL, data, config);
    console.log("response", response);
    return response;
}