import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useEspecies from "../../../../hooks/api/useEspecies";
import usePlanillaTypes from "../../../../hooks/api/usePlanillaTypes";

const PlanillasForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  const { getClientEspecies } = useEspecies();
  const { getPlanillaTypes } = usePlanillaTypes();

  const [ especies, setEspecies ] = useState([]);
  const [ planillaTypes, setPlanillaTypes ] = useState([]);

  useEffect(() => {
    getClientEspecies().then((res) => {
      setEspecies(res.results);
    });
    getPlanillaTypes().then((res) => {
      setPlanillaTypes(res.results);
    });
  }, [getClientEspecies, getPlanillaTypes]);

  const handleValue = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value
      });
    }
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Planilla"
    >
      <StyledInputContainer>
        <StyledInputLabel>Nombre</StyledInputLabel>
        <StyledTextInputs
          type="text"
          name="nombre"
          placeholder="Nombre"
          defaultValue={dataInput.nombre !== "" ? dataInput.nombre : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Año</StyledInputLabel>
        <StyledTextInputs
          type="text"
          name="year"
          placeholder="Año"
          defaultValue={dataInput.year !== "" ? dataInput.year : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Especie</StyledInputLabel>
        <StyledSelected
          name="especie"
          value={dataInput.especie !== undefined ? dataInput.especie.id : 0}
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Especie</StyledOption>
          {
            especies.map((especie, index) => (
              <StyledOption key={index} value={especie.id}>{especie.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Planilla Tipo</StyledInputLabel>
        <StyledSelected
          name="planilla_type"
          value={dataInput.planilla_type !== undefined ? dataInput.planilla_type.id : 0}
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione tipo planilla</StyledOption>
          {
            planillaTypes.map((planilla_type, index) => (
              <StyledOption key={index} value={planilla_type.id}>{planilla_type.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={dataInput.estado === false ? dataInput.estado : true}
          onChange={handleValue}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px;
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default PlanillasForm;
