import styled from "styled-components";

import React from "react";
import logo from "../../assets/images/Logo/inglobo.png";

const Logo = (props) => {
  return (
    <>
      <StyledLogo
        src={logo}
        alt="logo"
        height={props.height}
        width={props.width}
        marginLeft={props.marginLeft}
      />
    </>
  );
};

const StyledLogo = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};

  @media screen and (max-width: 600px) {
    width: 120px;
    margin-bottom: 52px;
    margin-top: 49px;
    margin-left: 0;
  }
`;

export default Logo;
