import React from "react";
import styled from "styled-components";

const InputSelect = ({
  name,
  placeholder,
  defaultValue,
  onChange,
  disabled,
  required,
  options,
  width,
  minWidth,
  value,
  selected,
}) => {
  return (
    <StyledInputSelect
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue || "0"}
      onChange={onChange}
      disabled={disabled}
      required={required}
      width={width}
      minWidth={minWidth}
      value={value}
      selected={selected}
    >
      <option value="0">Todos</option>
      {options?.map((option, index) => {
        return selected === option.value ? (
          <option key={index} value={option.value} selected>
            {option.label}
          </option>
        ) : (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </StyledInputSelect>
  );
};

const StyledInputSelect = styled.select`
  width: ${(props) => (props.width ? props.width : "300px")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "300px")};
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default InputSelect;
