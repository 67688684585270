import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import { forEach } from "lodash";
import { toast } from "react-toastify";
import useFiltros from "../../../../hooks/api/useFiltros";

const FiltersForm = ({ dataInput, handleCancel, setFilterForm }) => {
  const [inspeccionTipo] = useState(dataInput.inspeccion_tipo.id);
  const [filtros, setFiltros] = useState([]);
  const [especieDetalle] = useState(dataInput.id);
  const [filtroEspecieDetalle, setFiltroEspecieDetalle] = useState([]);
  const [data, setData] = useState([]);

  const {
    getFiltrosDetalle,
    getFiltroEspecieDetalle,
    createFiltroEspecieDetalles,
  } = useFiltros();

  console.log("dataInput", dataInput);

  useEffect(() => {
    getFiltrosDetalle(inspeccionTipo).then((res) => {
      setFiltros(res.results);
      console.log("res", res.results);
    });
    getFiltroEspecieDetalle(especieDetalle).then((res) => {
      setFiltroEspecieDetalle(res.results);
      console.log("res", res.results);
    });
  }, [
    getFiltrosDetalle,
    inspeccionTipo,
    especieDetalle,
    getFiltroEspecieDetalle,
  ]);

  const handleValue = (e, filtro_id) => {
    setData({
      ...data,
      [filtro_id]: {
        ...data[filtro_id],
        especie_detalle: especieDetalle,
        filtro_detalle: filtro_id,
        [e.target.name]: e.target.checked,
      },
    });
    console.log("data", data);
  };

  const handleSaveChangesFilters = () => {
    forEach(data, (value) => {
      createFiltroEspecieDetalles(value);
    });
    toast.success("Filtros guardados correctamente");
    setFilterForm(false);
  };

  const handleCheckInterno = (e, filtro_id) => {
    let interno = "interno" + e.target.name.match(/\d+/g);

    if (e.target.checked) {
      setData({
        ...data,
        [filtro_id]: {
          ...data[filtro_id],
          especie_detalle: especieDetalle,
          filtro_detalle: filtro_id,
          [interno]: e.target.checked,
          [e.target.name]: e.target.checked,
        },
      });
    } else {
      setData({
        ...data,
        [filtro_id]: {
          ...data[filtro_id],
          especie_detalle: especieDetalle,
          filtro_detalle: filtro_id,
          [e.target.name]: e.target.checked,
        },
      });
    }
  };

  const handleRemoveCheckExterno = (e, filtro_id) => {
    let externo = "externo" + e.target.name.match(/\d+/g);

    if (e.target.checked === false) {
      setData({
        ...data,
        [filtro_id]: {
          ...data[filtro_id],
          especie_detalle: especieDetalle,
          filtro_detalle: filtro_id,
          [externo]: false,
          [e.target.name]: e.target.checked,
        },
      });
    }
  };

  const handleTest = (e, filtro) => {
    let hola = filtroEspecieDetalle.find(
      (filtroEspecie) =>
        filtroEspecie.filtro_detalle.filtro.id ===
        filtro.filtro.id
    ).interno
    console.log("hola", hola)
  }

  return (
    <ModalForm
      dataInput={dataInput}
      handleCancel={handleCancel}
      edit={true}
      handleEdit={handleSaveChangesFilters}
      title="Filtros"
    >
      <StyledContainer>
        <StyledContainerCheckboxNames>
          <StyledLabelCheckboxNames> Externo </StyledLabelCheckboxNames>
        </StyledContainerCheckboxNames>
        {filtros.map((filtro) => (
          <StyledRow key={filtro.id}>
            <StyledDivContainer1>
              {filtro.editable ? (
                filtroEspecieDetalle.find(
                  (filtroEspecie) =>
                    filtroEspecie.filtro_detalle.filtro.id === filtro.filtro.id
                ) ? (
                  filtroEspecieDetalle.find(
                    (filtroEspecie) =>
                      filtroEspecie.filtro_detalle.filtro.id ===
                      filtro.filtro.id
                  ).interno ? (
                    <StyledCheckbox
                      type="checkbox"
                      name={"interno" + filtro.id}
                      onChange={(e) => {
                        handleValue(e, filtro.id);
                        handleRemoveCheckExterno(e, filtro.id);
                        handleTest(e, filtro);
                      }}
                      checked={true}
                      disabled={filtro.editable ? false : true}
                    />
                  ) : (
                    <StyledCheckbox
                      type="checkbox"
                      name={"interno" + filtro.id}
                      onChange={(e) => {
                        handleValue(e, filtro.id);
                        handleRemoveCheckExterno(e, filtro.id);
                        handleTest(e, filtro);
                      }}
                      disabled={filtro.editable ? false : true}
                    />
                  )
                ) : (
                  <StyledCheckbox
                    type="checkbox"
                    name={"interno" + filtro.id}
                    onChange={(e) => {
                      handleValue(e, filtro.id);
                      handleRemoveCheckExterno(e, filtro.id);
                      handleTest(e, filtro);
                    }}
                    checked={false}
                    disabled={filtro.editable ? false : true}
                  />
                )
              ) : (
                <StyledCheckbox
                  type="checkbox"
                  name={"interno" + filtro.id}
                  onChange={(e) => {
                    handleValue(e, filtro.id);
                    handleRemoveCheckExterno(e, filtro.id);
                    handleTest(e, filtro);
                  }}
                  checked={true}
                  disabled={true}
                />
              )}
              <StyledLabel>{filtro.filtro.nombre}</StyledLabel>
            </StyledDivContainer1>
            <StyledDivContainer2>
              {filtro.editable ? (
                filtroEspecieDetalle.find(
                  (filtroEspecie) =>
                    filtroEspecie.filtro_detalle.filtro.id === filtro.filtro.id
                ) ? (
                  filtroEspecieDetalle.find(
                    (filtroEspecie) =>
                      filtroEspecie.filtro_detalle.filtro.id ===
                      filtro.filtro.id
                  ).externo ? (
                    <StyledCheckbox
                      type="checkbox"
                      name={"externo" + filtro.id}
                      onChange={(e) => {
                        handleValue(e, filtro.id);
                        handleCheckInterno(e, filtro.id);
                        handleTest(e, filtro);
                      }}
                      checked={true}
                      disabled={filtro.editable ? false : true}
                    />
                  ) : (
                    <StyledCheckbox
                      type="checkbox"
                      name={"externo" + filtro.id}
                      onChange={(e) => {
                        handleValue(e, filtro.id);
                        handleCheckInterno(e, filtro.id);
                        handleTest(e, filtro);
                      }}
                      disabled={filtro.editable ? false : true}
                    />
                  )
                ) : (
                  <StyledCheckbox
                    type="checkbox"
                    name={"externo" + filtro.id}
                    onChange={(e) => {
                      handleValue(e, filtro.id);
                      handleCheckInterno(e, filtro.id);
                      handleTest(e, filtro);
                    }}
                    checked={false}
                    disabled={filtro.editable ? false : true}
                  />
                )
              ) : (
                <StyledCheckbox
                  type="checkbox"
                  name={"externo" + filtro.id}
                  onChange={(e) => {
                    handleValue(e, filtro.id);
                    handleCheckInterno(e, filtro.id);
                    handleTest(e, filtro);
                  }}
                  checked={true}
                  disabled={true}
                />
              )}
            </StyledDivContainer2>
          </StyledRow>
        ))}
      </StyledContainer>
    </ModalForm>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 3px;
  border: 1px solid #ff9100;
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 20px;
`;

const StyledContainerCheckboxNames = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;
`;

const StyledLabelCheckboxNames = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 10px;
`;

const StyledDivContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledDivContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

export default FiltersForm;
