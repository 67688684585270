import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import useEmbalajes from "../../../hooks/api/useEmbalajes";
import InputNumber from "../../../components/Cajas/InputNumber";
import { PlanillaContext } from "../../../context/PlanillaContext";

import {
  StyledSemaforo,
  StyledGreenSemaforo,
  StyledYellowSemaforo,
  StyledOrangeSemaforo,
  StyledRedSemaforo,
  StyledGraySemaforo,
} from "./styledCajaContent";

const BrixContent = ({
  initialFilters,
  brix,
  setBrix,
}) => {
  const [numeroCestas, setNumeroCestas] = useState(0);
  const { getEmbalajeFirmeza } = useEmbalajes();
  const [valor1Initial, setValor1Initial] = useState(0);
  const [valor2Initial, setValor2Initial] = useState(0);
  const [valor3Initial, setValor3Initial] = useState(0);

  const { planillaState, planillaDispatch } = useContext(PlanillaContext);

  const { detallesCaja, validation, planillaDefectos } = planillaState;

  var embalaje = initialFilters.Embalaje;

  useEffect(() => {
    getEmbalajeFirmeza(embalaje).then((res) => {
      res.results.forEach((element) => {
        if (element.tab.id === 8) {
          setNumeroCestas(element.numero_cestas);
        }
      });
    });
  }, [getEmbalajeFirmeza, embalaje, initialFilters]);

  useEffect(() => {
    if (planillaDefectos.length > 0) {
      planillaDefectos.forEach((element) => {
        if (element.planilla_defecto.tipo_medicion.id === 14) {
          setValor1Initial(element.norma_valor_1);
          setValor2Initial(element.norma_valor_2);
          setValor3Initial(element.norma_valor_3);
        }
      });
    }
  }, [planillaDefectos]);

  const handleValidateBrixTab = (e) => {
    const { name, value } = e.target;
    let valor1 = parseFloat(valor1Initial);
    let valor2 = parseFloat(valor2Initial);
    let valor3 = parseFloat(valor3Initial);
    let cumple = 1;
    if (valor1 >= valor2 && valor2 > valor3) {
      if (value <= valor1 && value >= valor2) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "green",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 1;
      } else if (value < valor2 && value >= valor3) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "yellow",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 2;
      } else if (value > valor1 || value < valor3) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "red",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 4;
      }
    } else if (valor1 < valor2 && valor2 <= valor3) {
      if (value >= valor1 && value <= valor2) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "green",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 1;
      } else if (value > valor2 && value <= valor3) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "yellow",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 2;
      } else if (value > valor3 || value < valor1) {
        let newValidation = {
          ...validation,
          [name]: {
            value: value,
            color: "red",
          },
        };
        planillaDispatch({
          type: "SET_VALIDATION",
          payload: newValidation,
        });
        cumple = 4;
      }
    } else {
      delete validation[name];
    }

    let newBrix = {
      ...brix,
      [name]: {
        brix: value,
        numero: name,
        cumple: cumple,
        sincronizacion: 1,
      },
    };

    if (detallesCaja.length !== 0) {
      newBrix[name].id = e.target.id;
    }

    setBrix(newBrix);
  };

  const handleDefaultBrix = (i) => {
    let valor = "";
    if (detallesCaja.length !== 0) {
      detallesCaja[0].caja.brix?.forEach((element) => {
        if (element.numero === i + 1) {
          valor = element.brix;
        }
      });
    }
    return valor;
  };

  return (
    <StyleDivFirmeza>
      {[...Array(numeroCestas)].map((e, i) => {
        return (
          <StyledDiv key={i + 1}>
            <label> Brix {i + 1} </label>
            <InputNumber
              name={`brix${i + 1}`}
              placeholder="Brix"
              min={0}
              max={9000}
              step={0.1}
              id={
                detallesCaja.length !== 0
                  ? detallesCaja[0].caja.brix?.find(
                      (element) => element.numero === i + 1
                    )?.id
                  : ""
              }
              onChange={(e) => handleValidateBrixTab(e)}
              defaultValue={handleDefaultBrix(i)}
            />
            <StyledSemaforo>
              {validation[`brix${i + 1}`]?.color === "green" ? (
                <StyledGreenSemaforo>
                  {validation[`brix${i + 1}`]?.value}
                </StyledGreenSemaforo>
              ) : validation[`brix${i + 1}`]?.color === "yellow" ? (
                <StyledYellowSemaforo>
                  {validation[`brix${i + 1}`]?.value}
                </StyledYellowSemaforo>
              ) : validation[`brix${i + 1}`]?.color === "orange" ? (
                <StyledOrangeSemaforo>
                  {validation[`brix${i + 1}`]?.value}
                </StyledOrangeSemaforo>
              ) : validation[`brix${i + 1}`]?.color === "red" ? (
                <StyledRedSemaforo>
                  {validation[`brix${i + 1}`]?.value}
                </StyledRedSemaforo>
              ) : (
                <StyledGraySemaforo />
              )}
            </StyledSemaforo>
          </StyledDiv>
        );
      })}
    </StyleDivFirmeza>
  );
};

const StyleDivFirmeza = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export default BrixContent;
