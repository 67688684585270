import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";

import {
  createTipoPackingService,
  deleteTipoPackingService,
  getTipoInspeccionService,
  updateTipoPackingService,
  getTipoInspeccionNoPaginationService,
} from "../../services/tipoInspeccion";

export default function useTipoInspeccion() {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getTipoInspeccion = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getTipoInspeccionService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getTipoInspeccionNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getTipoInspeccionNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createTipoPacking = useCallback(
    (data) => {
      data.cliente = client.id;
      return createTipoPackingService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateTipoPacking = useCallback(
    (data) => {
      return updateTipoPackingService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const deleteTipoPacking = useCallback(
    (id) => {
      return deleteTipoPackingService({ token, id })
        .then((res) => {
          if (res.status === 204) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getTipoInspeccion,
    createTipoPacking,
    updateTipoPacking,
    deleteTipoPacking,
    getTipoInspeccionNoPagination,
  };
}
