const ControlProcesosIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5562 11.3849H14.0112C13.8284 11.3856 13.6473 11.3502 13.4782 11.2806C13.3092 11.2111 13.1555 11.1089 13.0261 10.9798C12.8967 10.8508 12.794 10.6974 12.724 10.5286C12.654 10.3597 12.618 10.1787 12.6182 9.99595V5.43295C12.6194 4.90763 12.5001 4.38903 12.2693 3.91711C12.0386 3.44519 11.7025 3.03254 11.2872 2.71095C10.8902 2.398 10.4269 2.1802 9.93263 2.07428C9.4384 1.96836 8.92647 1.97714 8.43616 2.09995C6.52755 2.57503 4.77923 3.54818 3.36977 4.92002C1.96032 6.29187 0.940242 8.01323 0.413726 9.9083C-0.112791 11.8034 -0.126876 13.8042 0.372908 15.7065C0.872692 17.6088 1.86844 19.3444 3.25843 20.7359C4.64843 22.1275 6.38288 23.1251 8.28461 23.627C10.1864 24.129 12.1872 24.1171 14.0829 23.5927C15.9785 23.0683 17.701 22.0501 19.0744 20.6422C20.4479 19.2343 21.423 17.487 21.9002 15.579C22.0241 15.0877 22.0335 14.5745 21.9278 14.079C21.822 13.5834 21.6039 13.1188 21.2902 12.7209C20.9649 12.3063 20.5499 11.9707 20.0764 11.7393C19.6029 11.5079 19.0832 11.3868 18.5562 11.3849ZM18.9892 14.844C18.6425 16.239 17.9315 17.517 16.9289 18.5471C15.9263 19.5772 14.668 20.3225 13.2828 20.7068C11.8976 21.091 10.4352 21.1004 9.04523 20.734C7.65521 20.3676 6.38747 19.6386 5.37168 18.6214C4.35589 17.6043 3.62851 16.3356 3.26395 14.9451C2.89939 13.5546 2.91073 12.0922 3.29681 10.7075C3.68289 9.32285 4.42985 8.06558 5.46129 7.06432C6.49273 6.06306 7.77163 5.35375 9.16716 5.00895C9.19071 5.00305 9.21488 5.00002 9.23916 4.99995C9.31252 5.00273 9.38288 5.02981 9.43916 5.07695C9.49317 5.11942 9.53701 5.17343 9.56746 5.23502C9.59791 5.29662 9.61421 5.36424 9.61516 5.43295V9.99395C9.61503 10.5707 9.72859 11.1419 9.94935 11.6748C10.1701 12.2077 10.4937 12.6918 10.9017 13.0995C11.3097 13.5072 11.7941 13.8305 12.3271 14.0509C12.8601 14.2713 13.4314 14.3845 14.0082 14.384H18.5532C18.6236 14.3845 18.6931 14.4009 18.7563 14.4321C18.8195 14.4633 18.8748 14.5083 18.9182 14.564C18.9518 14.6014 18.9758 14.6464 18.9881 14.6952C19.0005 14.744 19.0009 14.795 18.9892 14.844Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
      <path
        d="M23.6512 7.44593C23.1908 5.7534 22.2964 4.21043 21.0566 2.96971C19.8167 1.72899 18.2744 0.833523 16.5822 0.371932C16.2834 0.287279 15.9691 0.272862 15.6638 0.329806C15.3586 0.38675 15.0706 0.513513 14.8224 0.700189C14.5743 0.886865 14.3726 1.1284 14.2333 1.40591C14.0939 1.68342 14.0207 1.9894 14.0192 2.29993V6.99993C14.0192 7.79558 14.3352 8.55864 14.8978 9.12125C15.4605 9.68386 16.2235 9.99993 17.0192 9.99993H21.7382C22.0468 9.99696 22.3507 9.92287 22.626 9.78341C22.9014 9.64395 23.1409 9.44288 23.326 9.19582C23.5111 8.94876 23.6367 8.66236 23.6931 8.35887C23.7494 8.05538 23.7351 7.74298 23.6512 7.44593Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlProcesosIcon;