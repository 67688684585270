import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {especiesService, createEspecieService, updateEspecieService, especiesClienteService, especiesServiceNoPagination, activateEspecieService, especiesPackingNoPaginationService, getAllEspeciesServiceNoPagination, getEspeciesClasificacionNoPaginationService} from "../../services/especies";

export default function useEspecies() {

  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getEspecies = useCallback(({ rowsPerPage, page }) => {
    return especiesService({ token, rowsPerPage, page })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  const getEspeciesNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return especiesServiceNoPagination({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getAllEspeciesNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getAllEspeciesServiceNoPagination({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getEspeciesPackingNoPagination = useCallback((packing) => {
    console.log("Especie Hook First :", packing);
    const data = {
      cliente : client.id,
      packing : packing.id,
    }
    return especiesPackingNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        console.log("Especies Hook:", res.data);
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getClientEspecies = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return especiesClienteService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getEspeciesClasificacionNoPagination = useCallback((clasificacion) => {
    const data = {
      cliente: client.id,
      clasificacion: clasificacion.id,
    };
    return getEspeciesClasificacionNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const createEspecie = useCallback((data) => {
    return createEspecieService({ token, data })
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  const updateEspecie = useCallback((data) => {
    return updateEspecieService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  const activateEspecie = useCallback((data) => {
    const cliente = {
      cliente: client.id,
    }
    return activateEspecieService({ token, data, cliente })
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);


  return {
    getEspecies,
    createEspecie,
    updateEspecie,
    getClientEspecies,
    getEspeciesNoPagination,
    activateEspecie,
    getEspeciesPackingNoPagination,
    getAllEspeciesNoPagination,
    getEspeciesClasificacionNoPagination,
  };
}
