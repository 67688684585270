import axios from "../api/axios";

const URL = "/clasificaciones/";

// Export async function users

export async function getClasificacionesService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getClasificacionesNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getClasificacionesByEspecieExportadoraService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}


export async function createClasificacionService({ token, data }) {
  console.log("Service: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      estado: data.estado,
      cliente: data.cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Clasificaciones: ", response);
  return response;
}

export async function createClasificacionExportadoraService({ token, data }) {
  const EXPORTADORASURL = "clasificaciones/exportadoras/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    EXPORTADORASURL,
    {
      clasificacion: data[0].clasificacion.id,
      exportadoras: data[0].exportadoras,
      cliente: data[0].cliente,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}

export async function createClasificacionEspecieService({ token, data }) {
  const ESPECIESURL = "clasificaciones/especie/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    ESPECIESURL,
    {
      clasificacion: data[0].clasificacion.id,
      especies: data[0].especies,
      cliente: data[0].cliente,
    },
    config
  );
  return response;
}

export async function updateClasificacionService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      cliente: data.cliente.id,
      estado: data.estado,
    },
    config
  );
  return response;
}
