import Login from "../pages/Login";
// import Landing from './pages/Landing';
import Sistemas from "../pages/Sistemas";
import ControlProcesoBd from "../pages/ControlProcesos/Cliente/BD";
import InformeDiario from "../pages/ControlProcesos/Cliente/Diario";
import Asistencia from "../pages/Terminado/Asistencia";
import Recepcion from "../pages/Recepcion";
import ReportePallet from "../pages/Client/ControlProductoTerminado/ReportePallet/Index";
import Lote from "../pages/Terminado/Lote";
import DiarioTerminado from "../pages/Terminado/Diario";
import Resolucion from "../pages/Terminado/Resolucion";
import Gerencia from "../pages/Terminado/Gerencia";
import RepPacking from "../pages/Terminado/Packing";
import RepGraficos from "../pages/Terminado/Graficos";
import Fotos from "../pages/Terminado/Fotos";
import Comercial from "../pages/Comercial";
import Testigos from "../pages/Testigos";
import DesPallet from "../pages/Destino/DesPallet";
import DesDiario from "../pages/Destino/DesDiario";
import DesCajas from "../pages/Destino/DesCajas";
import BdInspecciones from "../pages/Contenedores/BdInpecciones";
import BusInpecciones from "../pages/Contenedores/BuscarInspecciones";
import BusContenedor from "../pages/Contenedores/BuscarContenedor";
import BusCamion from "../pages/Contenedores/BuscarCamion";
import BusTemperaturas from "../pages/Contenedores/Temperaturas";
import Graphics from "../pages/ControlProcesos/Cliente/Graphics";
import FueraNorma from "../pages/ControlProcesos/Cliente/Fuera_Norma";
import Users from "../pages/Admin/Mantenedores/Users";
import Exportadoras from "../pages/Admin/Mantenedores/Exportadoras";
import Clients from "../pages/Admin/Mantenedores/Clientes";
import ColorClientes from "../pages/Admin/Mantenedores/ColorClientes";
import Calibres from "../pages/Admin/Mantenedores/Calibres";
import CalibreDetalles from "../pages/Admin/Mantenedores/Calibres/calibreDetalles";
import Embalajes from "../pages/Admin/Mantenedores/Embalajes";
import Especies from "../pages/Admin/Mantenedores/Especies";
import Normas from "../pages/Admin/Mantenedores/Normas";
import Packings from "../pages/Admin/Mantenedores/Packings";
import Productores from "../pages/Admin/Mantenedores/Productores";
import Zonas from "../pages/Admin/Mantenedores/Zonas";
import Variedades from "../pages/Admin/Mantenedores/Variedades";
import TipoPacking from "../pages/Admin/Mantenedores/TipoPacking";
import Planillas from "../pages/Admin/Mantenedores/Planillas";
import Parametros from "../pages/Admin/Mantenedores/Parametros";
import Defectos from "../pages/Admin/Mantenedores/Defectos";
import Etiquetas from "../pages/Admin/Mantenedores/Etiqueta";
import Clasificacion from "../pages/Admin/Mantenedores/Clasificacion";
import PlanillasInspeccionPallet from "../pages/ControlProductoTerminado/PlanillaInspeccionPallet";
import PlanillasInspeccionLote from "../pages/ControlProductoTerminado/PlanillaInspeccionLote";
import PlanillaDefectos from "../pages/Admin/Mantenedores/PlanillaDefectos";
import Cajas from "../pages/ControlProductoTerminado/Cajas";
import CajasLote from "../pages/ControlProductoTerminado/CajasLote";
import CajaDetail from "../pages/ControlProductoTerminado/Cajas/detail";
import NormaDetalle from "../pages/Admin/Mantenedores/NormaDetalles";
import EspecieDetalles from "../pages/Admin/Mantenedores/EspecieDetalles";
import Resoluciones from "../pages/Admin/Mantenedores/Resoluciones";

export const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sistemas",
    element: <Sistemas />,
  },
  {
    path: "/control-procesos/graficos",
    element: <Graphics />,
  },
  {
    path: "/control-procesos/informe-diario",
    element: <InformeDiario />,
  },
  {
    path: "/control-procesos/bd",
    element: <ControlProcesoBd />,
  },
  {
    path: "/control-procesos/fuera-norma",
    element: <FueraNorma />,
  },
  {
    path: "/recepcion/planillas",
    element: <Recepcion />,
  },
  {
    path: "/terminado/pallet",
    element: <ReportePallet />,
  },
  {
    path: "/terminado/lote",
    element: <Lote />,
  },
  {
    path: "/terminado/diario",
    element: <DiarioTerminado />,
  },
  {
    path: "/terminado/resolucion",
    element: <Resolucion />,
  },
  {
    path: "/terminado/gerencia",
    element: <Gerencia />,
  },
  {
    path: "/terminado/packing",
    element: <RepPacking />,
  },
  {
    path: "/terminado/graficos",
    element: <RepGraficos />,
  },
  {
    path: "/terminado/fotos",
    element: <Fotos />,
  },
  {
    path: "/terminado/Asistencia",
    element: <Asistencia />,
  },
  {
    path: "/comercial/planillas",
    element: <Comercial />,
  },
  {
    path: "/testigos/planillas",
    element: <Testigos />,
  },
  {
    path: "/contenedores/inspeccion",
    element: <BusInpecciones />,
  },
  {
    path: "/contenedores/contenedor",
    element: <BusContenedor />,
  },
  {
    path: "/contenedores/camion",
    element: <BusCamion />,
  },
  {
    path: "/contenedores/temperaturas",
    element: <BusTemperaturas />,
  },
  {
    path: "/contenedores/bdinspecciones",
    element: <BdInspecciones />,
  },
  {
    path: "/destino/DesPallet",
    element: <DesPallet />,
  },
  {
    path: "/destino/diario",
    element: <DesDiario />,
  },
  {
    path: "/destino/cajas",
    element: <DesCajas />,
  },
  {
    path: "/mantenedores/users",
    element: <Users />,
  },
  {
    path: "/mantenedores/exportadoras",
    element: <Exportadoras />,
  },
  {
    path: "/mantenedores/clientes",
    element: <Clients />,
  },
  {
    path: "/mantenedores/ColorClientes",
    element: <ColorClientes />,
  },
  {
    path: "/mantenedores/calibres",
    element: <Calibres />,
  },
  {
    path: "/mantenedores/calibres/detail",
    element: <CalibreDetalles />,
  },
  {
    path: "/mantenedores/embalajes",
    element: <Embalajes />,
  },
  {
    path: "/mantenedores/especies",
    element: <Especies />,
  },
  {
    path: "/mantenedores/normas",
    element: <Normas />,
  },
  {
    path: "/mantenedores/packings",
    element: <Packings />,
  },
  {
    path: "/mantenedores/productores",
    element: <Productores />,
  },
  {
    path: "/mantenedores/zonas",
    element: <Zonas />,
  },
  {
    path: "/mantenedores/variedades",
    element: <Variedades />,
  },
  {
    path: "/mantenedores/tipo-packings",
    element: <TipoPacking />,
  },
  {
    path: "/mantenedores/planillas",
    element: <Planillas />,
  },
  {
    path: "/mantenedores/parametros",
    element: <Parametros />,
  },
  {
    path: "/mantenedores/defectos",
    element: <Defectos />,
  },
  {
    path: "/mantenedores/resoluciones",
    element: <Resoluciones />,
  },
  {
    path: "/mantenedores/etiquetas",
    element: <Etiquetas />,
  },
  {
    path: "/mantenedores/clasificacion",
    element: <Clasificacion />,
  },
  {
    path: "/producto-terminado/planillas-pallet",
    element: <PlanillasInspeccionPallet />,
  },
  {
    path: "/producto-terminado/planillas-lote",
    element: <PlanillasInspeccionLote />,
  },
  {
    path: "/mantenedores/planillas/planilla-defectos",
    element: <PlanillaDefectos />,
  },
  {
    path: "/producto-terminado/planillas-pallet/cajas",
    element: <Cajas />,
  },
  {
    path: "/producto-terminado/planillas-lote/cajas",
    element: <CajasLote />,
  },
  {
    path: "/producto-terminado/planillas-pallet/cajas/detail",
    element: <CajaDetail />,
  },
  {
    path: "/producto-terminado/planillas-lote/cajas/detail",
    element: <CajaDetail />,
  },
  {
    path: "/mantenedores/normas/detail",
    element: <NormaDetalle />,
  },
  {
    path: "/mantenedores/especie_detalles",
    element: <EspecieDetalles />,
  },
];
