import React, { createContext, useReducer, useEffect } from "react";
import { PLANILLA_DATA } from "./Actions";

export const PlanillaContext = createContext();

const initialState = {
  planilla: JSON.parse(localStorage.getItem('planilla')) || {},
  data: JSON.parse(localStorage.getItem('data')) || [],
  detallesCaja: JSON.parse(localStorage.getItem('detallesCaja')) || [],
  fotos: JSON.parse(localStorage.getItem('fotos')) || [{}],
  filters: JSON.parse(localStorage.getItem('filters')) || {},
  contraMuestraCaja: JSON.parse(localStorage.getItem('contraMuestra')) || false,
  copyCaja: JSON.parse(localStorage.getItem('copyCaja')) || false,
  valoresCaja: JSON.parse(localStorage.getItem('valoresCaja')) || [],
  validation: JSON.parse(localStorage.getItem('validation')) || {},
  planillaDefectos: JSON.parse(localStorage.getItem('planillaDefectos')) || [],
  planillaType: JSON.parse(localStorage.getItem('planillaType')) || '',
};

function reducer(state, action) {
  switch (action.type) {
    case PLANILLA_DATA.SET_PLANILLA:
      return { ...state, planilla: action.payload };
    case PLANILLA_DATA.SET_DATA:
      return { ...state, data: action.payload };
    case PLANILLA_DATA.SET_DETALLES_CAJA:
      return { ...state, detallesCaja: action.payload };
    case PLANILLA_DATA.SET_FOTOS:
      return { ...state, fotos: action.payload };
    case PLANILLA_DATA.SET_FILTERS:
      return { ...state, filters: action.payload };
    case PLANILLA_DATA.SET_CONTRA_MUESTRA_CAJA:
      return { ...state, contraMuestraCaja: action.payload };
    case PLANILLA_DATA.SET_COPY_CAJA:
      return { ...state, copyCaja: action.payload };
    case PLANILLA_DATA.SET_VALORES_CAJA:
      return { ...state, valoresCaja: action.payload };
    case PLANILLA_DATA.SET_VALIDATION:
      return { ...state, validation: action.payload };
    case PLANILLA_DATA.SET_PLANILLA_DEFECTOS:
      return { ...state, planillaDefectos: action.payload };
    case PLANILLA_DATA.SET_PLANILLA_TYPE:
      return { ...state, planillaType: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const PlanillaProvider = ({ children }) => {
  const [planillaState, planillaDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem('planilla', JSON.stringify(planillaState.planilla));
    localStorage.setItem('data', JSON.stringify(planillaState.data));
    localStorage.setItem('detallesCaja', JSON.stringify(planillaState.detallesCaja));
    localStorage.setItem('fotos', JSON.stringify(planillaState.fotos));
    localStorage.setItem('filters', JSON.stringify(planillaState.filters));
    localStorage.setItem('contraMuestraCaja', JSON.stringify(planillaState.contraMuestraCaja));
    localStorage.setItem('copyCaja', JSON.stringify(planillaState.copyCaja));
    localStorage.setItem('valoresCaja', JSON.stringify(planillaState.valoresCaja));
    localStorage.setItem('validation', JSON.stringify(planillaState.validation));
    localStorage.setItem('planillaDefectos', JSON.stringify(planillaState.planillaDefectos));
    localStorage.setItem('planillaType', JSON.stringify(planillaState.planillaType));
  }, [planillaState]);

  return (
    <PlanillaContext.Provider value={{ planillaState, planillaDispatch }}>
      {children}
    </PlanillaContext.Provider>
  );
};