import React from "react";
import styled from "styled-components";
import { AlertButton } from "../../../components/Boton/alertButton";
import CajaEdit from "../../../assets/icons/Cajas/edit";
import InfoModal from "../../Modal/InfoModal";

const CajaHijaComponent = ({
  cajaHija,
  handleEditCajaHija,
  handleDeleteCaja,
  planillaDefectosHeaders,
  getBgColor,
  getColor,
  causalesCaja,
  getColorResult,
  item,
  index,
  handleOpen,
  handleClose,
  isOpen,
  data,
}) => {
  const { caja } = cajaHija;
  const {
    caja_detalle,
    resultado_caja,
    resultado_pallet,
    fotos,
    cumple_packing,
    numero_caja,
  } = caja;

  const renderTableCell = (planillaDefectosHeader, index) => {
    const planillaDefecto = caja_detalle.find(
      (cajaDetalle) =>
        cajaDetalle.planilla_defecto.id ===
        planillaDefectosHeader.planilla_defecto
    );
    return (
      <TableCell key={index} bgColor={getBgColor(planillaDefecto?.cumple_id)}>
        <TableCellLabel textColor={getColor(planillaDefecto?.cumple_id)}>
          {planillaDefecto ? planillaDefecto.valor_calculado : "-"}
        </TableCellLabel>
      </TableCell>
    );
  };

  const renderFoto = (foto, index) => (
    <TableCell key={index}>
      <StyledImageContainer>
        <StyledImg
          src={foto.foto ? `http://localhost:8000/${foto.foto}` : null}
        />
        <StyledName>{foto.defecto.nombre || null}</StyledName>
      </StyledImageContainer>
    </TableCell>
  );

  return (
    <TableRow key={index}>
      <TableCell position={"sticky"}>
        <StyledEditButton onClick={() => handleEditCajaHija(cajaHija)}>
          <CajaEdit />
        </StyledEditButton>
      </TableCell>
      <TableCell position={"sticky"} />
      <TableCell position={"sticky"}>
        <StyledEditButton onClick={() => handleDeleteCaja(cajaHija)}>
          D
        </StyledEditButton>
      </TableCell>
      <TableCell position={"sticky"} />
      <TableCell>{caja.embalaje?.codigo}</TableCell>
      <TableCell>{caja.etiqueta?.nombre}</TableCell>
      <TableCell>{caja.clasificacion?.nombre}</TableCell>
      <TableCell>{numero_caja}</TableCell>
      <TableCell>{caja.variedad?.nombre}</TableCell>
      <TableCell>{caja.variedad_real?.nombre}</TableCell>
      <TableCell>{caja.productor?.nombre}</TableCell>
      <TableCell>{caja.productor_real?.nombre}</TableCell>
      {planillaDefectosHeaders.map(renderTableCell)}
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>{getColorResult(resultado_caja.id)}</TableCell>
      <TableCell>{getColorResult(resultado_pallet.id)}</TableCell>
      <TableCell>
        <AlertButton
          text="Causales Hija"
          handleOpen={handleOpen}
          planillaDefectosHeaders={planillaDefectosHeaders}
          item={caja_detalle}
          causalesCaja={causalesCaja}
        />
        <InfoModal
          title="Causales"
          isOpen={isOpen}
          data={data}
          handleClose={handleClose}
          message={numero_caja}
        />
      </TableCell>
      <TableCell />
      <TableCell>{cumple_packing ? "Cumple" : "No Cumple"}</TableCell>
      {fotos?.filter((foto) => foto.caja_id === item.caja.id).map(renderFoto)}
    </TableRow>
  );
};

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.stripe === true ? "#f5f5f5" : "#ffffff"};
`;

const TableCell = styled.td`
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  text-align: left;
  background-color: ${(props) => props.bgColor || "#ffffff"};
`;

const TableCellLabel = styled.label`
  color: ${(props) => {
    return props.textColor;
  }};
`;

const StyledEditButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 48px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #ffffff;
  &:active {
    background: #d88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled.img`
  width: 60px;
  object-fit: cover;

  border-radius: 5px;

  border: 1px solid #e0e0e0;

  background: #ffffff;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledName = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  margin-top: 5px;
  /* Neutral / Lightest */

  color: #64766a;
`;

export default CajaHijaComponent;
