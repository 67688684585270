import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useDefectos from "../../../../hooks/api/useDefectos";
import useTipoMedicion from "../../../../hooks/api/useTipoMedicion";
import InputDecimal from "../../../../components/Cajas/InputDecimal";

const Form = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  edit,
  handleEdit,
  tabParametroId,
}) => {
  console.log("dataInput Planillas Inicio", dataInput);
  const { getDefectosNoPagination } = useDefectos();
  const { getTipoMedicionNoPagination } = useTipoMedicion();
  const [data, setData] = useState([]);

  const [defectos, setDefectos] = useState([]);
  const [tipoMediciones, setTipoMediciones] = useState([]);

  useEffect(() => {
    Promise.allSettled([
      getDefectosNoPagination(),
      getTipoMedicionNoPagination(),
    ]).then((res) => {
      setDefectos(res[0].value.results);
      setTipoMediciones(res[1].value.results);
    });
    if (create !== true) {
      setData(
        {
          id: dataInput.id,
          defecto: dataInput.defecto,
          tipo_medicion: dataInput.tipo_medicion,
          hijos_de: dataInput.hijos_de,
          codigo_defecto_interno: dataInput.codigo_defecto_interno,
          orden_planilla: dataInput.orden_planilla,
          estado: dataInput.estado,
        }
      );
      console.log("dataInput Planillas Modificado", data);
    }
  }, [getDefectosNoPagination, getTipoMedicionNoPagination, dataInput, create]);

  const handleValue = (e) => {
    if (data.estado === undefined) {
      setData({
        ...data,
        planilla: dataInput,
        parametro: tabParametroId,
        estado: true,
        [e.target.name]: e.target.value,
      });
      console.log("data Final", data);
    } else {
      setData({
        ...data,
        planilla: dataInput,
        parametro: tabParametroId,
        [e.target.name]: e.target.value,
      });
      console.log("data Final", data);
    }
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={data}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Defecto - Parametro"
    >
      <StyledInputContainer>
        <StyledInputLabel>Defecto</StyledInputLabel>
        <StyledSelected
          name="defecto"
          value={data.defecto !== undefined ? data.defecto.id : 0}
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Defecto</StyledOption>
          {defectos.map((defecto, index) => (
            <StyledOption key={index} value={defecto.id}>
              {defecto.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Tipo Medicion</StyledInputLabel>
        <StyledSelected
          name="tipo_medicion"
          value={
            data.tipo_medicion !== undefined ? data.tipo_medicion.id : 0
          }
          onChange={handleValue}
        >
          <StyledOption value={0}>Seleccione Tipo Medicion</StyledOption>
          {tipoMediciones.map((tipoMedicion, index) => (
            <StyledOption key={index} value={tipoMedicion.id}>
              {tipoMedicion.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Codigo Defecto Interno</StyledInputLabel>
        <StyledTextInputs
          type="text"
          name="codigo_defecto_interno"
          placeholder="Codigo Defecto Interno"
          defaultValue={
            data.codigo_defecto_interno !== ""
              ? data.codigo_defecto_interno
              : null
          }
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Orden</StyledInputLabel>
        <InputDecimal
          name="orden_planilla"
          placeholder="Orden"
          step={0.01}
          width={"400px"}
          defaultValue={data.orden_planilla !== "" ? data.orden_planilla : null}
          onChange={handleValue}
          required
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={data.estado === false ? data.estado : true}
          onChange={handleValue}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px;
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default Form;
