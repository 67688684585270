import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const DragAndDrop = ({valores, setValores}) => {
  console.log("valores label", valores.items);
  console.log("valores selected", valores.selected);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};

    result.items = sourceClone;
    result.selected = destClone;

    return result;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    } else {
      console.log("source", source);
      console.log("destination", destination);
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "labels") {
        const items = reorder(valores.items, source.index, destination.index);

        setValores({
          ...valores,
          items,
        });
      } else {
        const selected = reorder(
          valores.selected,
          source.index,
          destination.index
        );
        setValores({
          ...valores,
          selected,
        });
      }
    } else {
      if (source.droppableId === "labels") {
        const result = move(
          valores.items,
          valores.selected,
          source,
          destination
        );

        console.log("result", result);

        setValores({
          ...valores,
          items: result.items,
          selected: result.selected,
        });

        console.log("valores", valores);
      } else {
        const result = move(
          valores.selected,
          valores.items,
          source,
          destination
        );

        console.log("result", result);

        setValores({
          ...valores,
          items: result.selected,
          selected: result.items,
        });

        console.log("valores", valores);
      }
    }
  };

  return (
    <StyledDragAndDropContainer>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="labels">
          {(provided) => (
            <StyledLabelContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <h2>Defectos Disponibles</h2>
              <ul>
                {valores.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.content}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            </StyledLabelContainer>
          )}
        </Droppable>
        <Droppable droppableId="selected">
          {(provided) => (
            <StyledSelectedContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <h2>Defectos Seleccionados</h2>
              <ul>
                {valores.selected.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.content}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            </StyledSelectedContainer>
          )}
        </Droppable>
      </DragDropContext>
    </StyledDragAndDropContainer>
  );
};

const StyledDragAndDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledLabelContainer = styled.div`
  min-width: 350px;
  width: fit-content;
  border: 1px solid #ff9100;
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;
  max-width: 768px;
  h2 {
    margin: 0;
    font-size: 16px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      padding: 1px 8px;
      border: 1px solid #64766a;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
      width: auto;
      border-radius: 20px;
      background: #fff;
      color: #64766a;
    }
  }
`;

const StyledSelectedContainer = styled.div`
  min-width: 350px;
  width: fit-content;
  min-height: 100px;
  border: 1px solid #ff9100;
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;
  max-width: 768px;
  h2 {
    margin: 0;
    font-size: 16px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    li {
      padding: 1px 8px;
      border: 1px solid #fff;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
      width: auto;
      border-radius: 20px;
      background: #ff9100;
      color: #fff;
    }
  }
`;

export default DragAndDrop;
