import Layout from "../../../../components/Layout";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import usePagination from "../../../../hooks/helpers/usePagination";
import PlanillasForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import usePlanillas from "../../../../hooks/api/usePlanillas";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import AddExportadoraForm from "./addExportadora";
import AddParametroForm from "./addParametro";
import { toast } from "react-toastify";
import { MantainersContext } from "../../../../context/MantaintersContext";
import { MANTAINER } from "../../../../context/Actions";

const Planillas = () => {
  const [exportadoraForm, setExportadoraForm] = useState(false);
  const [dataInputExportadora, setDataInputExportadora] = useState({});
  const [parametroForm, setParametroForm] = useState(false);
  const [dataInputParametro, setDataInputParametro] = useState({});

  const { mantainersDispatch } = useContext(MantainersContext);

  const navigate = useNavigate();

  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getPlanillas, createPlanilla, updatePlanilla } = usePlanillas();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre" },
    { field: "year", headerName: "Año" },
    { field: "especie.nombre", headerName: "Especie" },
    { field: "planilla_type.nombre", headerName: "Tipo de Planilla" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getPlanillas({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getPlanillas,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updatePlanilla(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Planilla actualizada correctamente");
    }).catch((err) => {
      toast.error("Error al actualizar planilla");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = (e) => {
    setCreate(false);
    createPlanilla(dataInput).then((res) => {
      console.log("Page :", res.data);
      setRowData([...rowData, res.data]);
      toast.success("Planilla creada correctamente");
    }).catch((err) => {
      toast.error("Error al crear planilla");
    });
    setEdit(false);
    // Reset dataInput
    setDataInput({});
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    setDataInputExportadora(selectedRows[0]);
    setDataInputParametro(selectedRows[0]);
  };

  const handleExportadoraForm = () => {
    setCreate(false);
    setEdit(false);
    setDataInput({});
    setExportadoraForm(true);
  };

  const handleCancelExportadoraeForm = () => {
    setExportadoraForm(false);
  };

  const handleParametrosForm = () => {
    setCreate(false);
    setEdit(false);
    setDataInput({});
    setExportadoraForm(false);
    setParametroForm(true);
    mantainersDispatch({
      type: MANTAINER.PLANILLA_EDIT,
      payload: dataInput,
    });
  };

  const handleCancelParametroForm = () => {
    setParametroForm(false);
  };

  const handleDefectosView = () => {
    // Go to url planilla-defectos with dataInput
    console.log("dataInput", dataInput);
    mantainersDispatch({
      type: MANTAINER.PLANILLA_EDIT,
      payload: dataInput,
    });
    navigate("planilla-defectos");
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Planillas"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
        <FormCrudButton text="Exportadora" onClick={handleExportadoraForm} />
        <FormCrudButton text="Parametros" onClick={handleParametrosForm} />
        <FormCrudButton text="Defectos" onClick={handleDefectosView} />
      </Mantainer>
      {create === true ? (
        <PlanillasForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <PlanillasForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
      {exportadoraForm === true ? (
        <AddExportadoraForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInputExportadora}
          handleCancel={handleCancelExportadoraeForm}
        />
      ) : null}
      {parametroForm === true ? (
        <AddParametroForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInputParametro}
          handleCancel={handleCancelParametroForm}
        />
      ) : null}
    </Layout>
  );
};

export default Planillas;
