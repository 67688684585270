import Layout from "../../../../components/Layout";
import { useEffect, useState, useContext } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import NormaForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useNormas from "../../../../hooks/api/useNormas";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";

import { MantainersContext } from "../../../../context/MantaintersContext";
import { MANTAINER } from "../../../../context/Actions";

const Normas = () => {
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getNormas, createNorma, updateNorma } = useNormas();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const { mantainersDispatch } = useContext(MantainersContext);

  const navigate = useNavigate();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const goToButton = (params) => {
    return (
      <StyledGoToButton
        onClick={() => handleGoToNormaDetalle(params.data)}
      >
        Detalle
      </StyledGoToButton>
    );
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre" },
    { field: "planilla.nombre", headerName: "Planilla" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
    { field: "id", headerName: "Detalle", cellRenderer: goToButton},
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getNormas({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getNormas,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateNorma(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Norma actualizada correctamente");
    }).catch((err) => {
      console.log("Error", err);
      toast.error("Error al actualizar norma");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = (e) => {
    setCreate(false);
    createNorma(dataInput).then((res) => {
      console.log("Page :", res.data);
      setRowData([...rowData, res.data]);
      toast.success("Norma creada correctamente");
    }).catch((err) => {
      toast.error("Error al crear norma");
    });
    setEdit(false);
    // Reset dataInput
    setDataInput({});
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  const handleGoToNormaDetalle = (params) => {
    mantainersDispatch({
      type: MANTAINER.NORMA,
      payload: params,
    });
    navigate("detail");
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Normas"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create === true ? (
        <NormaForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <NormaForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
    </Layout>
  );
};

const StyledGoToButton = styled.button`
  background: #FF9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #FFFFFF;
  &:active {
    background: #D88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;

export default Normas;
