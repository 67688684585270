import styled from "styled-components";

const StyledSemaforoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSemaforo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color ? props.color : '#808080'};
  color: ${(props) => props.colorBackground ? props.colorBackground : '#808080'};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #64766a;
`;

export {
    StyledSemaforoContainer,
    StyledSemaforo,
};