import React from "react";
import styled from "styled-components";

const ObservacionContent = ({
  observacion,
  setObservacion,
}) => {
  return (
    <StyleDivObservation>
      <label> Observacion </label>
      <StyledTextArea name="observacion" rows="4" cols="50" value={observacion} onChange={(e) => setObservacion(e.target.value)} />
    </StyleDivObservation>
  );
};

const StyleDivObservation = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  align-items: center;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
  }
`;

const StyledTextArea = styled.textarea`
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  width: auto;
  height: 35px;
  font-family: "Poppins";
  resize: none;
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`;

export default ObservacionContent;
