const ControlContenedores = (props) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.682 3.99939H16.815C16.5237 2.85805 15.8615 1.84582 14.9324 1.12178C14.0033 0.397745 12.8599 0.0029333 11.682 -0.000610352H5.318C3.90807 0.000977711 2.55633 0.561775 1.55936 1.55875C0.562386 2.55572 0.00158806 3.90746 0 5.31739L0 14.6364C0.00329542 15.5553 0.297208 16.4495 0.839667 17.1912C1.38213 17.9328 2.14532 18.4839 3.02 18.7654C3.014 18.8434 3 18.9194 3 18.9994C3 19.795 3.31607 20.5581 3.87868 21.1207C4.44129 21.6833 5.20435 21.9994 6 21.9994C6.79565 21.9994 7.55871 21.6833 8.12132 21.1207C8.68393 20.5581 9 19.795 9 18.9994H15C15 19.795 15.3161 20.5581 15.8787 21.1207C16.4413 21.6833 17.2044 21.9994 18 21.9994C18.7956 21.9994 19.5587 21.6833 20.1213 21.1207C20.6839 20.5581 21 19.795 21 18.9994C21 18.9194 20.986 18.8444 20.98 18.7664C21.8548 18.4847 22.618 17.9335 23.1604 17.1916C23.7029 16.4498 23.9968 15.5554 24 14.6364V9.27139C23.9926 7.86748 23.4285 6.52386 22.4314 5.53545C21.4344 4.54704 20.0859 3.9946 18.682 3.99939ZM21 9.27139V9.99939H17V6.99939H18.682C18.9842 6.99313 19.2847 7.04729 19.5657 7.15867C19.8468 7.27006 20.1028 7.43644 20.3187 7.64805C20.5346 7.85967 20.706 8.11226 20.823 8.39101C20.94 8.66977 21.0002 8.96908 21 9.27139ZM4.364 15.9994C4.0025 15.9991 3.65587 15.8555 3.40015 15.5999C3.14444 15.3444 3.00053 14.9979 3 14.6364V5.31739C3.00079 4.70286 3.24527 4.11373 3.6798 3.67919C4.11434 3.24466 4.70347 3.00018 5.318 2.99939H11.682C12.2965 3.00018 12.8857 3.24466 13.3202 3.67919C13.7547 4.11373 13.9992 4.70286 14 5.31739V15.9994H4.364ZM19.637 15.9994H17V12.9994H21V14.6364C20.9997 14.9978 20.856 15.3443 20.6005 15.5999C20.3449 15.8554 19.9984 15.9991 19.637 15.9994Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlContenedores;
