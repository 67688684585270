import Layout from "../../../../components/Layout";
import { useEffect, useState, useContext } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import NormaDetalleForm from "./form";
import OperacionesForm from "./operaciones";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useNormaDetalles from "../../../../hooks/api/useNormaDetalles";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import { toast } from "react-toastify";

import { MantainersContext } from "../../../../context/MantaintersContext";

const NormaDetalle = () => {
  const [operaciones, setOperaciones] = useState(false);
  const {
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    setDataInput,
    setCreate,
    setEdit,
    handleCopyForm,
    copy,
    setCopy,
    create,
  } = useModalHelper();

  const { createNormaDetalles, getNormaDetalles, updateNormaDetalles } =
    useNormaDetalles();

  const { rowData, setRowData } = usePagination();

  const { mantainersState } = useContext(MantainersContext);

  const { norma } = mantainersState;

  const validateVariedad = (params) => {
    if (params.data.variedad !== null) {
      return params.data.variedad.nombre;
    } else {
      return "Todas";
    }
  };

  const validateClasificacion = (params) => {
    if (params.data.clasificacion !== null) {
      return params.data.clasificacion.nombre;
    } else {
      return "Todas";
    }
  };

  const validateCalibre = (params) => {
    if (params.data.calibre !== null) {
      return params.data.calibre.nombre;
    } else {
      return "Todas";
    }
  };

  const validateEtiqueta = (params) => {
    if (params.data.etiqueta !== null) {
      return params.data.etiqueta.nombre;
    } else {
      return "Todas";
    }
  };

  const validateEmbalaje = (params) => {
    if (params.data.embalaje !== null) {
      return params.data.embalaje.nombre;
    } else {
      return "Todas";
    }
  };

  const validateInfluye = (params) => {
    if (params.data.influye !== null) {
      return params.data.influye.nombre;
    } else {
      return "";
    }
  };

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const goToOperacion = (params) => {
    return params.data.operaciones !== false ? (
      <FormCrudButton
        text="Ver"
        onClick={() => {
          handleAddOperaciones();
          setOperaciones(params.data.operaciones);
          setDataInput(params.data);
        }}
      />
    ) : (
      <FormCrudButton
        text="Crear"
        onClick={() => {
          handleAddOperaciones();
          setDataInput(params.data);
        }}
      />
    );
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID", width: 70 },
    { field: "planilla_defecto.defecto.nombre", headerName: "Defecto" },
    {
      field: "variedad.nombre",
      headerName: "Variedad",
      valueGetter: validateVariedad,
    },
    {
      field: "clasificacion.nombre",
      headerName: "Clasificacion",
      valueGetter: validateClasificacion,
    },
    {
      field: "calibre.nombre",
      headerName: "Calibre",
      valueGetter: validateCalibre,
    },
    {
      field: "etiqueta.nombre",
      headerName: "Etiqueta",
      valueGetter: validateEtiqueta,
    },
    {
      field: "embalaje.nombre",
      headerName: "Embalaje",
      valueGetter: validateEmbalaje,
    },
    {
      field: "influye.nombre",
      headerName: "Influye",
      valueGetter: validateInfluye,
    },
    { field: "norma_valor_1", headerName: "Valor 1", width: 100 },
    { field: "norma_valor_2", headerName: "Valor 2", width: 100 },
    { field: "norma_valor_3", headerName: "Valor 3", width: 100 },
    { field: "orden_ingreso", headerName: "Orden", width: 100 },
    { field: "estado", headerName: "Estado", cellRenderer: validateEstado },
    { headerName: "Operaciones", cellRenderer: goToOperacion },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getNormaDetalles({ norma }).then((res) => {
      setRowData(res.results);
    });
  }, [getNormaDetalles, setRowData, norma, operaciones]);

  // const handleSave = (e) => {
  //   alert("Norma Detalle Guardada");
  //   setCreate(false);
  //   setEdit(false);
  //   setCopy(false);
  // };

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateNormaDetalles(dataInput)
      .then((res) => {
        // refresh rowData
        console.log("res", res);
        const newData = rowData.map((item) => {
          console.log("Page Res : ", res);
          if (item.id === res.data.id) {
            return res.data;
          } else {
            return item;
          }
        });
        setRowData(newData);
        toast.success("Norma detalle actualizada");
      })
      .catch((err) => {
        toast.error("Error al actualizar norma detalle");
      });
    setDataInput({});
    setEdit(false);
  };

  const handleCreateStatus = (data) => {
    setRowData([...rowData, data]);
    toast.success("Norma detalle creada");
  };

  const handleCopy = () => {
    console.log("dataInput", dataInput);
    setCreate(false);
    setEdit(false);
    createNormaDetalles(dataInput).then((res) => {
      // if res.data.id exists in rowData, don't add it again to rowData and add toast to show error else add it and add toast
      rowData.find((item) => item.id === res.data.id)
        ? toast.error("Error al copiar, el detalle para esta norma ya existe")
        : handleCreateStatus(res.data);
    });
    setCopy(false);
    setDataInput({});
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  const handleAddOperaciones = () => {
    setCreate(true);
    setEdit(false);
    setCopy(false);
    setOperaciones(
      dataInput.operaciones !== undefined ? dataInput.operaciones : false
    );
  };

  const handleCancelOperaciones = () => {
    setCreate(false);
    setEdit(false);
    setCopy(false);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        title="Detalle Norma"
      >
        <FormCrudButton text="Copiar" onClick={handleCopyForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
        <FormCrudButton text="Operacion" onClick={handleAddOperaciones} />
      </Mantainer>
      {copy === true ? (
        <NormaDetalleForm
          setDataInput={setDataInput}
          handleCopy={handleCopy}
          dataInput={dataInput}
          handleCancel={handleCancel}
          copy={copy}
        />
      ) : null}
      {edit === true ? (
        <NormaDetalleForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
      {create === true ? (
        <OperacionesForm
          dataInput={dataInput}
          handleCancel={handleCancelOperaciones}
          setCreate={setCreate}
          operaciones={operaciones}
          setOperaciones={setOperaciones}
        />
      ) : null}
    </Layout>
  );
};

export default NormaDetalle;
