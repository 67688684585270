import React from "react";
import styled from "styled-components";

import FotosContent from "./fotosContent";
import ObservacionContent from "./observacionContent";
import FirmezaContent from "./firmezaContent";
import CajaContent from "./cajaContent";
import PesoFrutoContent from "./pesoFrutoContent";
import DiametroFrutoContent from "./diametroFrutoContent";
import PesoFijoContent from "./pesoFijoContent";
import BrixContent from "./brixContent";

const TabsCajasContent = ({
  tabs,
  tab,
  handleTab,
  handleViewTabContent,
  filtroEspecieDetalle,
  handleOptions,
  handleUpdateFilter,
  number,
  handlePhotoCajaSave,
  foto,
  saveImage,
  initialFilters,
  calibres,
  especie,
  exportadora,
  handleSaveDefectoData,
  inputRefs,
  pesoFijo,
  setPesoFijo,
  sumaPesoFijo,
  totalPesoFijo,
  setTotalPesoFijo,
  handlePesoNeto,
  influye,
  observacion,
  setObservacion,
  firmeza,
  setFirmeza,
  handleDefectoFoto,
  pesoFruto,
  setPesoFruto,
  diametroFruto,
  setDiametroFruto,
  pesoFijoSave,
  setPesoFijoSave,
  brix,
  setBrix,
}) => {
  return (
    <>
      <StyledTabHeader>
        {tabs.map((item, index) => (
          <StyledTabItem
            key={index}
            active={tab === index}
            onClick={() => handleTab(index)}
          >
            {item.label}
          </StyledTabItem>
        ))}
      </StyledTabHeader>
      <StyledTabContent>
        {tabs.map((item, index) => (
          <StyledTabItemContent
            key={item.id}
            active={tab === index}
            style={{ display: handleViewTabContent(index) }}
          >
            {item.id === 1 ? (
              <CajaContent
                filtroEspecieDetalle={filtroEspecieDetalle}
                handleOptions={handleOptions}
                handleUpdateFilter={handleUpdateFilter}
                number={number}
                initialFilters={initialFilters}
                calibres={calibres}
                handleSaveDefectoData={handleSaveDefectoData}
                inputRefs={inputRefs}
                influye={influye}
              />
            ) : item.id === 2 ? (
              <ObservacionContent
                observacion={observacion}
                setObservacion={setObservacion}
              />
            ) : item.id === 3 ? (
              // form for photos
              <FotosContent
                handlePhotoCajaSave={handlePhotoCajaSave}
                foto={foto}
                saveImage={saveImage}
                handleDefectoFoto={handleDefectoFoto}
                initialFilters={initialFilters}
                tabs={tabs}
              />
            ) : item.id === 4 ? (
              <FirmezaContent
                initialFilters={initialFilters}
                firmeza={firmeza}
                setFirmeza={setFirmeza}
              />
            ) : item.id === 5 ? (
              <PesoFrutoContent
                initialFilters={initialFilters}
                especie={especie}
                exportadora={exportadora}
                pesoFruto={pesoFruto}
                setPesoFruto={setPesoFruto}
              />
            ) : item.id === 6 ? (
              <DiametroFrutoContent
                initialFilters={initialFilters}
                especie={especie}
                exportadora={exportadora}
                diametroFruto={diametroFruto}
                setDiametroFruto={setDiametroFruto}
              />
            ) : item.id === 7 ? (
              <PesoFijoContent
                initialFilters={initialFilters}
                pesoFijo={pesoFijo}
                setPesoFijo={setPesoFijo}
                sumaPesoFijo={sumaPesoFijo}
                totalPesoFijo={totalPesoFijo}
                setTotalPesoFijo={setTotalPesoFijo}
                handlePesoNeto={handlePesoNeto}
                pesoFijoSave={pesoFijoSave}
                setPesoFijoSave={setPesoFijoSave}
              />
            ) : item.id === 8 ? (
              <BrixContent
                initialFilters={initialFilters}
                brix={brix}
                setBrix={setBrix}
              />
            ) : null}
          </StyledTabItemContent>
        ))}
      </StyledTabContent>
    </>
  );
};

const StyledTabHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;

  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

const StyledTabItem = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#ff9100" : "transparent")};
  color: ${(props) => (props.active ? "#64766a" : "#64766a")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  background-color: ${(props) => props.active && "#f5f5f5"};
  border-radius: 8px 8px 0 0;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    border-bottom: 2px solid
      ${(props) => (props.active ? "#ff9100" : "transparent")};
    color: ${(props) => (props.active ? "#64766a" : "#64766a")};
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
    cursor: pointer;
  }

  @media screen and (min-width: 1280px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    border-bottom: 2px solid
      ${(props) => (props.active ? "#ff9100" : "transparent")};
    color: ${(props) => (props.active ? "#64766a" : "#64766a")};
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
    cursor: pointer;
  }
`;

const StyledTabContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTabItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#ff9100" : "transparent")};
  color: ${(props) => (props.active ? "#64766a" : "#64766a")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
`;

export default TabsCajasContent;
