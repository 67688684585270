import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {
  exportadorasService,
  getExportadorasNoPaginationService,
  getExportadorasVariedadNoPaginationService,
  createExportadoraService,
  deleteExportadoraService,
  updateExportadoraService,
  getExportadorasProductorNoPaginationService,
  getExportadorasPackingNoPaginationService,
  getExportadorasPlanillasNoPaginationService,
  getExportadorasEtiquetaNoPaginationService,
  getExportadorasClasificacionNoPaginationService,
} from "../../services/exportadoras";

export default function useExportadoras() {

  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getExportadoras = useCallback(({ rowsPerPage, page }) => {
    const data = {
      cliente : client.id,
    }
    return exportadorasService({ token, rowsPerPage, page, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasNoPagination = useCallback(() => {
    const data = {
      cliente : client.id,
    }
    return getExportadorasNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasVariedadNoPagination = useCallback((variedad) => {
    console.log("Variedad :", variedad);
    const data = {
      cliente : client.id,
      variedad : variedad.id,
    }
    return getExportadorasVariedadNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasProductorNoPagination = useCallback((productor) => {
    console.log("Productor :", productor);
    const data = {
      cliente : client.id,
      productor : productor.id,
    }
    return getExportadorasProductorNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasPackingNoPagination = useCallback((packing) => {
    console.log("Packing :", packing);
    const data = {
      cliente : client.id,
      packing : packing.id,
    }
    return getExportadorasPackingNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasPlanillasNoPagination = useCallback((planilla) => {
    const data = {
      cliente : client.id,
      planilla : planilla.id,
    }
    return getExportadorasPlanillasNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasEtiquetaNoPagination = useCallback((etiqueta) => {
    console.log("Etiqueta :", etiqueta);
    const data = {
      cliente : client.id,
      etiqueta : etiqueta.id,
    }
    return getExportadorasEtiquetaNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const getExportadorasClasificacionNoPagination = useCallback((clasificacion) => {
    console.log("Clasificacion :", clasificacion);
    const data = {
      cliente : client.id,
      clasificacion : clasificacion.id,
    }
    return getExportadorasClasificacionNoPaginationService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const createExportadora = useCallback((data) => {
    data.cliente = client.id;
    return createExportadoraService({ token, data })
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const updateExportadora = useCallback((data) => {
    return updateExportadoraService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  const deleteExportadora = useCallback((id) => {
    return deleteExportadoraService({ token, id })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token]);

  return {
    getExportadoras,
    createExportadora,
    deleteExportadora,
    updateExportadora,
    getExportadorasNoPagination,
    getExportadorasVariedadNoPagination,
    getExportadorasProductorNoPagination,
    getExportadorasPackingNoPagination,
    getExportadorasPlanillasNoPagination,
    getExportadorasEtiquetaNoPagination,
    getExportadorasClasificacionNoPagination,
  };
}
