import Layout from "../../../components/Layout";
import { useEffect, useState, useContext } from "react";
import usePagination from "../../../hooks/helpers/usePagination";
import PlanillaInspeccionForm from "./form";
import { FormCrudButton } from "../../../components/Boton/formCrudButton";
import Mantainer from "../../../components/Layout/mantainer";
import usePlanillasInspeccion from "../../../hooks/api/usePlanillasInspeccion";
import useModalHelper from "../../../hooks/helpers/useModalHelper";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { PlanillaContext } from "../../../context/PlanillaContext";

const PlanillasInspeccionPallet = () => {
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const {
    getPlanillasInspeccion,
    createPlanillaInspeccion,
    updatePlanillaInspeccion,
    getPlanillasInspeccionByInspector,
  } = usePlanillasInspeccion();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const navigate = useNavigate();

  const [inspeccionTipo] = useState(2);

  const { state } = useContext(AuthContext);
  const { user } = state;
  const { user_type } = user;
  const { id: userTypeId } = user_type;

  const { planillaDispatch } = useContext(PlanillaContext);

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Abierta";
    } else {
      return "Cerrada";
    }
  };

  const handleCajaView = (planilla) => {
    planillaDispatch({ type: "SET_PLANILLA", payload: planilla });
    planillaDispatch({ type: "SET_PLANILLA_TYPE", payload: 2 });
    navigate("cajas");
  };

  // create a button function fot add to columnDefs
  const goToButton = (params) => {
    return (
      <StyledGoToButton
        onClick={() => {
          handleCajaView(params.data);
        }}
      >
        Ir
      </StyledGoToButton>
    );
  };

  const showUser = userTypeId === 1 ? true : false;

  const initialColumns = [
    { field: "id", headerName: "ID" },
    { field: "especie.nombre", headerName: "Especie" },
    { field: "exportadora.nombre", headerName: "Exportadora" },
    { field: "packing.nombre", headerName: "Packing" },
  ];

  const additionalColumns = [
    { field: "fecha", headerName: "Fecha" },
    { field: "turno.nombre", headerName: "Turno" },
    { field: "sincronizacion.nombre", headerName: "Sincronizacion" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
    { field: "button", headerName: "Action", cellRenderer: goToButton },
  ];

  const getNameAndLastName = (params) => {
    return params.data.usuario.first_name + " " + params.data.usuario.last_name;
  };

  const [columnDefs] = useState([
    ...initialColumns,
    ...(showUser
      ? [
          { field: "usuario.username", headerName: "ID Usuario" },
          {
            field: "usuario.first_name",
            headerName: "Usuario",
            valueGetter: getNameAndLastName,
          },
          { field: "usuario.user_type.name", headerName: "Tipo" },
        ]
      : []),
    ...additionalColumns,
  ]);

  useEffect(() => {
    planillaDispatch({ type: "SET_PLANILLA", payload: {} });
    planillaDispatch({ type: "SET_DATA", payload: [] });
    planillaDispatch({ type: "SET_DETALLES_CAJA", payload: [] });
    planillaDispatch({ type: "SET_FOTOS", payload: [{}] });
    planillaDispatch({ type: "SET_FILTERS", payload: {} });
    planillaDispatch({ type: "SET_CONTRA_MUESTRA_CAJA", payload: false });
    planillaDispatch({ type: "SET_COPY_CAJA", payload: false });
    planillaDispatch({ type: "SET_VALORES_CAJA", payload: [] });
    planillaDispatch({ type: "SET_VALIDATION", payload: {} });
    planillaDispatch({ type: "SET_PLANILLA_DEFECTOS", payload: [] });
    planillaDispatch({ type: "SET_PLANILLA_TYPE", payload: 2 });
  }, [planillaDispatch]);

  useEffect(() => {
    const fetchPlanillasInspeccion = async () => {
      try {
        const res =
          userTypeId === 1
            ? await getPlanillasInspeccion({ rowsPerPage, page, inspeccionTipo })
            : await getPlanillasInspeccionByInspector({ rowsPerPage, page, inspeccionTipo });

        setRowData(res.results);
        setTotalRows(res.count);
        setNext(res.links.next_page);
        setPrevious(res.links.previous_page);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlanillasInspeccion();
  }, [
    getPlanillasInspeccion,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    getPlanillasInspeccionByInspector,
    userTypeId,
    inspeccionTipo,
  ]);

  const handleEdit = async () => {
    setCreate(false);
    setEdit(true);
    try {
      const res = await updatePlanillaInspeccion(dataInput);
      const newData = rowData.map((item) =>
        item.id === res.data.id ? res.data : item
      );
      setRowData(newData);
      // Reset dataInput
      setDataInput({});
    } catch (error) {
      console.error(error);
    } finally {
      setEdit(false);
    }
  };

  const handleSave = async (e) => {
    setCreate(false);
    try {
      const res = await createPlanillaInspeccion(dataInput);
      setRowData((prevRowData) => [...prevRowData, res.data]);
      // Reset dataInput
      setDataInput({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePlanilla = (e) => {
    setCreate(false);
    setEdit(false);
    alert("Planilla Cerrada " + dataInput.id);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Planillas Pallet"
      >
        <FormCrudButton text="Cerrar" onClick={handleClosePlanilla} />
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create && (
        <PlanillaInspeccionForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      )}
      {edit && (
        <PlanillaInspeccionForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
        />
      )}
    </Layout>
  );
};

const StyledGoToButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #ffffff;
  &:active {
    background: #d88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;

export default PlanillasInspeccionPallet;
