import React from "react";
import styled from "styled-components";

const Item = ({ item }) => {
  const getCumple = (item) => {
    if (item.item.cumple === 4 || item.item.cumple_id === 4 ) {
      return "#FF0000";
    } else if (item.item.cumple === 2 || item.item.cumple_id === 2) {
      // return amarillo
      return "#FFD700";
    }

    return "#000000";
  }
  return (
    <StyledItem>
      <StyledP>{item.planilla_defecto?.defecto.nombre || item.nombre}</StyledP>
      <StyledP2 cumple={getCumple({item})}>{item.valor_calculado || item.promedio}</StyledP2>
    </StyledItem>
  );
};

const InfoModal = ({
  isOpen,
  handleClose,
  title,
  message,
  data,
}) => {
  return (
    <ModalForm isOpen={isOpen}>
      <StyledTitle>{title}</StyledTitle>
      <StyledMessage>
        {Array.isArray(data) ? (
          data.map((item, index) => <Item key={index} item={item} />)
        ) : (
          <p>{message}</p>
        )}
      </StyledMessage>
      <StyledButtons>
        <StyledButton onClick={handleClose}>Cerrar</StyledButton>
      </StyledButtons>
    </ModalForm>
  );
};

const ModalForm = styled.div`
  width: 400px;
  height: auto;
  max-height: 90vh;
  overflow-y: scroll; // Changed from auto to scroll
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  border: 2px solid #ff9100;
  padding: 20px;
  @media screen and (min-width: 768px) {
    width: 600px;
  }
  &::-webkit-scrollbar {
    display: none; // For Chrome, Safari and Opera
  }
`;

const StyledTitle = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  text-align: center;
  color: #000000;
`;

const StyledMessage = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 150% */
  text-align: center;
  color: #000000;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 113.12px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */
  text-align: center;
  /* Neutral / Lightest */
  color: #ffffff;
`;

const StyledItem = styled.div`
  border-bottom: 1px solid #ddd; // Added a border to separate items
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledP = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 150% */
  text-align: center;
  color: #000000;
  padding: 0px 15px;
`;

const StyledP2 = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 150% */
  text-align: center;
  color: ${(props) => props.cumple};
  padding: 0px 15px;
`;

export default InfoModal;
