import styled from "styled-components"
import LoginBackground from '../../assets/images/Login/login.png';

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(${LoginBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`

const StyledCardLogin = styled.div`
    display: flex;
    width: 465px;
    height: 560px;
    border-radius: 10px; 
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 10px 100px 5px rgba(0, 0, 0, 0.4);

    @media screen and (max-width: 600px) {
        width: 322px;
        height: 543px;
    }
    
    @media screen and (max-width: 768px) {
        width: 300px;
        height: 450px;
    }
`

const StyledLabel = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 20px;
    text-align: center;
    color: ${(props) => props.theme.theme.secondary.color};
    margin-top: 70px;
    margin-bottom: 31px;

    @media screen and (max-width: 600px) {
        font-size: 28px;  
        margin-top: 0px;
    }
`

const Styledh6 = styled.h6`
    color: #FF9100;
    margin: 2px;
    font-size: 12px;
    font-weight: 700;
`

const StyledForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export { 
    StyledContainer, 
    StyledCardLogin, 
    StyledLabel, 
    Styledh6,
    StyledForm
}