import Navbar from "../../../components/Navbar";

const Fotos = () => {
    return (
        <div>
            <Navbar active link="https://rioblanco.inglobochile.cl/Reportes/Reporte_Fotos/FrontIndex.php"/>
        </div>
    )
}

export default Fotos;