import axios from "../api/axios";

const URL = "/calibres/";

// Export async function users

export async function getCalibresService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getCalibresNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getCalibresByEspecieExportadoraService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const newUrl = URL + "especie/exportadora/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // Se retorna la respuesta
  return response;
}

export async function createCalibreService({ token, data }) {
  console.log("Service: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      estado: data.estado,
      cliente: data.cliente,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Calibre: ", response);
  return response;
}

export async function updateCalibreService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      cliente: data.cliente.id,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Calibre Update: ", response);
  return response;
}
