import Navbar from "../../../components/Navbar";

const BdInspecciones = () => {
    return (
        <div>
            <Navbar active link="https://contenedor.inglobochile.cl/Reportes/Reportes_base_completa/FrontIndex.php"/>        
        </div>
    )
}

export default BdInspecciones;