import { useState, useEffect, useContext } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import Form from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import usePlanillaDefectos from "../../../../hooks/api/usePlanillaDefectos";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import Layout from "../../../../components/Layout";
import TabLayout from "../../../../components/Layout/tabLayout";
import useParametros from "../../../../hooks/api/useParametros";
import { toast } from "react-toastify";

import { MantainersContext } from "../../../../context/MantaintersContext";

const PlanillaDefectos = () => {
  const [tab, setTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [defectos, setDefectos] = useState([]);
  const [tabParametroId, setTabParametroId] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);

  const {
    create,
    edit,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const {
    getParametroDefectos,
    createDefectoParametro,
    updateDefectoParametro,
  } = usePlanillaDefectos();
  const { getParametrosPlanillasNoPagination } = useParametros();

  const { mantainersState } = useContext(MantainersContext);

  const { planilla_edit } = mantainersState;

  console.log("planilla_edit", planilla_edit);

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "orden_planilla", headerName: "Orden" },
    { field: "defecto.nombre", headerName: "Defecto" },
    { field: "tipo_medicion.nombre", headerName: "Tipo Medicion" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  // const { state } = useLocation(); // Read values passed on state
  // const dataInput = state;
  const { rowData, setRowData } = usePagination();

  useEffect(() => {
    getParametrosPlanillasNoPagination(planilla_edit).then((res) => {
      setTabs(res.results);
      setRowData(res.results);
      if (tab === 0) {
        setTabParametroId(res.results[0].parametro.id);
        let id_parametro = tabParametroId;
        getParametroDefectos({ id_parametro, planilla_edit }).then((res) => {
          setDefectos(res.results);
        });
      } else {
        let id_parametro = tabParametroId;
        getParametroDefectos({ id_parametro, planilla_edit }).then((res) => {
          setDefectos(res.results);
        });
      }
    });
  }, [
    getParametrosPlanillasNoPagination,
    planilla_edit,
    tab,
    setRowData,
    getParametroDefectos,
    tabParametroId,
  ]);

  const handleSave = (data) => {
    setCreate(false);
    createDefectoParametro(data)
      .then((res) => {
        console.log("response", data);
        setDefectos([...defectos, res.data]);
        toast.success("Defecto añadido correctamente al parametro");
      })
      .catch((err) => {
        toast.error("Error al añadir defecto al parametro");
      });
    setEdit(false);
    // Reset dataInput
    setDataInput({});
  };

  const handleEdit = (data) => {
    console.log("Handle EDIT", data);
    setCreate(false);
    setEdit(true);
    updateDefectoParametro(data)
      .then((res) => {
        console.log("response", res.data);
        console.log("rowData", defectos);
        const newData = defectos.map((item) => {
          console.log("item", item);
          if (item.id === res.data.id) {
            console.log("item", res.data);
            return res.data;
          } else {
            console.log("item", item);
            return item;
          }
        });
        setDefectos(newData);
        console.log("newData", newData);
        console.log("rowData", rowData);
        toast.success("Defecto editado correctamente");
      })
      .catch((err) => {
        toast.error("Error al editar defecto");
      });
    setDataInput({});
    setEdit(false);
  };

  const handleTab = (index, id_parametro) => {
    setTab(index);
    setTabParametroId(id_parametro);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataSelected(selectedRows[0]);
    console.log("selectedRows", dataSelected);
  };

  return (
    <Layout>
      <TabLayout
        title="Planilla Defectos"
        dataInput={planilla_edit}
        columnDefs={columnDefs}
        handleTab={handleTab}
        tab={tab}
        tabs={tabs}
        setTabs={setTabs}
        defectos={defectos}
        onSelectionChanged={onSelectionChanged}
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </TabLayout>
      {create === true ? (
        <Form
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={planilla_edit}
          handleCancel={handleCancel}
          create={create}
          tabParametroId={tabParametroId}
        />
      ) : null}
      {edit === true ? (
        <Form
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataSelected}
          handleCancel={handleCancel}
          tabParametroId={tabParametroId}
          edit={edit}
        />
      ) : null}
    </Layout>
  );
};

export default PlanillaDefectos;
