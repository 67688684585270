import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const LOGIN_URL = "/usuarios/login/"; // Se completa el path de la url base

export default function login({user, password}) {
  return axios 
    .post(LOGIN_URL, { // Se agregan los datos a enviar en el post
        username: user, // La llave debe ser la definida en el back
        password: password
        })
    .then((res) => {
        console.log(res);
        return res; // Se obtiene el json de la respuesta
    })
    .catch((err) => { // Se validan los errores en caso de ocurrir
        return err.response.status;
    });
}