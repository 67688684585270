const Light = {
  primary: {
    color: "#FF9100",
    hover: "#D88219",
    disabled: "rgba(255, 168, 0, 0.32)",
    text: "#FFFFFF",
    background: "#FFFFFF",
  },
  secondary: {
    color: "#64766A",
    hover: "#3C4A4D",
    disabled: "rgba(0, 0, 0, 0.32)",
    text: "#FFFFFF",
    background: "#3C4A4D",
    border: {
      normal: "#FFFFFF",
      hover: "#D88219",
      disabled: "#FFFFFF",
    },
  },
};
const Dark = {
  primary: {
    color: "#3C4A4D",
    hover: "#FF0000",
    disabled: "rgba(255, 168, 0, 0.32)",
    text: "#FFFFFF",
    background: "#FFFFFF",
  },
  secondary: {
    color: "#64766A",
    hover: "#3C4A4D",
    disabled: "rgba(0, 0, 0, 0.32)",
    text: "#FFFFFF",
    background: "#3C4A4D",
    border: {
      normal: "#FFFFFF",
      hover: "#D88219",
      disabled: "#FFFFFF",
    },
  },
};
const Red = {
    primary: {
      color: "#FF0000",
      hover: "#FF0000",
      disabled: "rgba(255, 168, 0, 0.32)",
      text: "#FFFFFF",
      background: "#FFFFFF",
    },
    secondary: {
      color: "#64766A",
      hover: "#3C4A4D",
      disabled: "rgba(0, 0, 0, 0.32)",
      text: "#FFFFFF",
      background: "#3C4A4D",
      border: {
        normal: "#FFFFFF",
        hover: "#D88219",
        disabled: "#FFFFFF",
      },
    },
  };

export { Light, Dark, Red };
