import React from 'react';
import {
  StyledSemaforoContainer,
  StyledSemaforo,
} from './styledSemaforoCss';

const Semaforo = ({ item, validation }) => {

  const color = validation[item.planilla_defecto.defecto.id]?.color_texto;
  const colorBackground = validation[item.planilla_defecto.defecto.id]?.color;

  return (
    item.planilla_defecto.tipo_medicion.validar && (
      <StyledSemaforoContainer>
        <StyledSemaforo color={color} colorBackground={colorBackground}>
          {validation[item.planilla_defecto.defecto.id]?.value}
        </StyledSemaforo>
      </StyledSemaforoContainer>
    )
  );
};

export default Semaforo;