import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    createPesoFijoCajaService,
    updatePesoFijoCajaService,
} from "../../services/pesoFijos";

export default function usePesoFijos() {
    const { state } = useContext(AuthContext);
    const { token } = state;

    const createPesoFijoCaja = useCallback(
        ({ array, new_caja_id }) => {
            return createPesoFijoCajaService({ token, array, new_caja_id })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    const updatePesoFijoCaja = useCallback(
        ({ array, caja_id }) => {
            return updatePesoFijoCajaService({ token, array, caja_id })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    return {
        createPesoFijoCaja,
        updatePesoFijoCaja,
    };
}