const ControlCajasTestigoIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 5.49998C23.9967 2.4638 21.5362 0.00332812 18.5 0H5.49998C2.4638 0.00332812 0.00332812 2.4638 0 5.49998V6.49997C0.000375 7.41356 0.359484 8.2905 0.999984 8.94197V18.5C1.00331 21.5362 3.46383 23.9967 6.50002 24H17.5C20.5362 23.9967 22.9967 21.5362 23 18.5V8.94202C23.6406 8.29055 23.9997 7.41361 24 6.50002V5.49998ZM3 5.49998C3 4.11928 4.11928 3 5.49998 3H18.5C19.8807 3 21 4.11928 21 5.49998V6.49997C21 6.77611 20.7761 6.99998 20.5 6.99998H3.50002C3.22388 6.99998 3 6.77611 3 6.49997V5.49998ZM20 18.5C20 19.8807 18.8807 21 17.5 21H6.50002C5.11931 21 4.00003 19.8807 4.00003 18.5V9.99998H20V18.5Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlCajasTestigoIcon;