import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/planilla_defectos/"; // Se completa el path de la url base

// Export async function users

export async function getParametroDefectosService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("data", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      parametro: data.parametro,
      planilla: data.planilla,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  return response;
}

export async function getPlanillaDefectosService({ token, data }) {
  const { planilla } = data;
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      planilla: planilla[0].id,
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  return response;
}

export async function getPlanillaDefectosHeadersService({ token, data }) {
  const URL2 = "get-columns/";
  const NEWURL = URL + URL2;
  const { planilla } = data;
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      planilla: planilla[0].id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(NEWURL, config);
  return response;
}

export async function createDefectoParametroService({ token, dataInput }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL,
    {
      planilla: dataInput.planilla.id,
      parametro: dataInput.parametro,
      defecto: dataInput.defecto,
      tipo_medicion: dataInput.tipo_medicion,
      orden_planilla: dataInput.orden_planilla,
      codigo_defecto_interno: dataInput.codigo_defecto_interno,
      estado: dataInput.estado,
    },
    config);
  return response;
}

export async function updateDefectoParametroService({ token, dataInput }) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(URL + dataInput.planilla.id + "/",
    {
      planilla: dataInput.planilla.id,
      parametro: dataInput.parametro,
      defecto: dataInput.defecto.id ? dataInput.defecto.id : dataInput.defecto,
      tipo_medicion: dataInput.tipo_medicion.id ? dataInput.tipo_medicion.id : dataInput.tipo_medicion,
      orden_planilla: dataInput.orden_planilla,
      codigo_defecto_interno: dataInput.codigo_defecto_interno,
      estado: dataInput.estado,
    },
    config);
  // Se retorna la respuesta
  console.log("Response Planilla Defectos UPDATE: ", response);
  return response;
}
