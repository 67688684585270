import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../components/Modal";
import useEspecies from "../../../hooks/api/useEspecies";
import useExportadoras from "../../../hooks/api/useExportadoras";
import usePacking from "../../../hooks/api/usePacking";
import useTurnos from "../../../hooks/api/useTurnos";
import InputDate from "../../../components/Cajas/InputDate";

const PlanillaInspeccionForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
}) => {
  const { getClientEspecies } = useEspecies();
  const { getExportadorasNoPagination } = useExportadoras();
  const { getPackingsByEspecieExportadora } = usePacking();
  const { getTurnos } = useTurnos();

  const [especies, setEspecies] = useState([]);
  const [exportadoras, setExportadoras] = useState([]);
  const [packings, setPackings] = useState([]);
  const [turnos, setTurnos] = useState([]);

  const [especieId, setEspecieId] = useState(0);
  const [exportadoraId, setExportadoraId] = useState(0);

  useEffect(() => {
    Promise.allSettled([
      getClientEspecies(),
      getExportadorasNoPagination(),
      getTurnos(),
    ]).then((res) => {
      setEspecies(res[0].value.results);
      setExportadoras(res[1].value.results);
      setTurnos(res[2].value.results);
      console.log("res", res);
    });
  }, [getClientEspecies, getExportadorasNoPagination, getTurnos]);

  useEffect(() => {
    if (especieId !== 0 && exportadoraId !== 0) {
      console.log(especieId, exportadoraId);
      getPackingsByEspecieExportadora(especieId, exportadoraId).then((res) => {
        console.log("res", res);
        setPackings(res.data);
      });
    } else {
      console.log("else");
      setPackings([]);
    }
  }, [especieId, exportadoraId, getPackingsByEspecieExportadora]);

  const handleValue = (e) => {
    setEspecieId(
      e.target.name === "especie" ? parseInt(e.target.value) : especieId
    );
    setExportadoraId(
      e.target.name === "exportadora" ? parseInt(e.target.value) : exportadoraId
    );

    setDataInput({
      ...dataInput,
      planilla_type: 1,
      inspeccion_tipo: 1,
      sincronizacion: 1,
      fecha: handleGetActualDate(),
      estado: dataInput.estado === undefined ? true : dataInput.estado,
      [e.target.name]: e.target.value,
    });
  };

  const handleGetActualDate = () => {
    return new Date().toISOString().slice(0, 10);
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      handleEdit={handleEdit}
      title="Planilla"
    >
      <StyledSelected
        name="especie"
        // selected first option by default
        value={dataInput.especie !== undefined ? dataInput.especie.id : 0}
        onChange={(e) => {
          handleValue(e);
        }}
      >
        <StyledOption value={0}>Seleccione una especie</StyledOption>
        {especies.map((especie, index) => (
          <StyledOption key={index} value={especie.id}>
            {especie.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="exportadora"
        // selected first option by default
        value={
          dataInput.exportadora !== undefined ? dataInput.exportadora.id : 0
        }
        onChange={(e) => {
          handleValue(e);
        }}
      >
        <StyledOption value={0}>Seleccione una exportadora</StyledOption>
        {exportadoras.map((exportadora, index) => (
          <StyledOption key={index} value={exportadora.id}>
            {exportadora.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <StyledSelected
        name="packing"
        // selected first option by default
        value={dataInput.packing !== undefined ? dataInput.packing.id : 0}
        onChange={handleValue}
      >
        <StyledOption value={0}>Seleccione un packing</StyledOption>
        {packings.map((packing, index) => (
          <StyledOption key={index} value={packing.id}>
            {packing.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <InputDate
        name="fecha"
        placeholder="Fecha"
        defaultValue={dataInput.fecha ? dataInput.fecha : handleGetActualDate()}
        onChange={handleValue}
      />
      <StyledSelected
        name="turno"
        // selected first option by default
        value={dataInput.turno !== undefined ? dataInput.turno.id : 0}
        onChange={handleValue}
      >
        <StyledOption value={0}>Seleccione un turno</StyledOption>
        {turnos.map((turno, index) => (
          <StyledOption key={index} value={turno.id}>
            {turno.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

export default PlanillaInspeccionForm;
