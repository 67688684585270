import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";

import {
  getTipoMedicionService,
  getTipoMedicionNoPaginationService,
} from "../../services/tipoMedicion";

export default function useTipoMedicion() {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const getTipoMedicion = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getTipoMedicionService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getTipoMedicionNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getTipoMedicionNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  return {
    getTipoMedicion,
    getTipoMedicionNoPagination,
  };
}
