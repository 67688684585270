import axios from "../api/axios";

const URL = "/calibre_detalles/";

// Export async function users

export async function getCalibreDetallesService({
  token,
  rowsPerPage,
  page,
  data,
  calibre,
}) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
      calibre: calibre.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getCalibreDetalleByEspecieExportadoraService({
  token,
  data,
}) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      calibre: data.calibre,
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  const response = await axios.get(URL, config);
  return response;
}

export async function createCalibreDetalleService({ token, data, dataInput, calibre }) {
  console.log("Service: ", data);
  console.log("Service: ", dataInput);
    console.log("Service: ", calibre);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      calibre: calibre.id,
      exportadora: dataInput.exportadora,
      especie: dataInput.especie,
      valor_minimo_milimetros: dataInput.valor_minimo_milimetros || 0,
      valor_medio_milimetros: dataInput.valor_medio_milimetros || 0,
      valor_maximo_milimetros: dataInput.valor_maximo_milimetros || 0,
      valor_minimo_gramos: dataInput.valor_minimo_gramos || 0,
      valor_medio_gramos: dataInput.valor_medio_gramos || 0,
      valor_maximo_gramos: dataInput.valor_maximo_gramos || 0,
      orden: dataInput.orden || 0,
      estado: dataInput.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Calibre: ", response);
  return response;
}

export async function updateCalibreDetalleService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
        exportadora: data.exportadora.id,
        especie: data.especie.id,
        valor_minimo_milimetros: data.valor_minimo_milimetros,
        valor_medio_milimetros: data.valor_medio_milimetros,
        valor_maximo_milimetros: data.valor_maximo_milimetros,
        valor_minimo_gramos: data.valor_minimo_gramos,
        valor_medio_gramos: data.valor_medio_gramos,
        valor_maximo_gramos: data.valor_maximo_gramos,
        orden: data.orden,
        estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Calibre Update: ", response);
  return response;
}
