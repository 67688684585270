import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import useError from "../helpers/useError";

import AuthContext from "../../context/AuthProvider";
import {
  getFiltrosService,
  getFiltrosDetalleService,
  getFiltrosNoPaginationService,
  createFiltroEspecieDetallesService,
  getFiltroEspecieDetalleService,
  getFiltroEspecieDetalleByEspecieExportadoraService,
} from "../../services/filtros";

export default function useFiltros() {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const { client } = state;

  const { getStatusCodeError } = useError();

  const getFiltros = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getFiltrosService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getFiltrosNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getFiltrosNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getFiltrosDetalle = useCallback(
    (data) => {
      return getFiltrosDetalleService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return getStatusCodeError(err.response.status);
        });
    },
    [token, getStatusCodeError]
  );

  const getFiltroEspecieDetalle = useCallback((data) => {
    return getFiltroEspecieDetalleService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const getFiltroEspecieDetalleByEspecieExportadora = useCallback( ({especie, exportadora, inspeccionTipo}) => {
    return getFiltroEspecieDetalleByEspecieExportadoraService({ token, especie, exportadora, inspeccionTipo })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const createFiltroEspecieDetalles = useCallback( (data) => {
    return createFiltroEspecieDetallesService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Filtro creado correctamente");
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  return {
    getFiltros,
    getFiltrosNoPagination,
    getFiltrosDetalle,
    getFiltroEspecieDetalle,
    getFiltroEspecieDetalleByEspecieExportadora,
    createFiltroEspecieDetalles,
  };
}
