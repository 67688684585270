import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    createBrixCajaService,
    updateBrixCajaService,
} from "../../services/brix";

export default function useBrix() {
    const { state } = useContext(AuthContext);
    const { token } = state;

    const createBrixCaja = useCallback(
        ({ array, new_caja_id }) => {
            return createBrixCajaService({ token, array, new_caja_id })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    const updateBrixCaja = useCallback(
        ({ array, caja_id }) => {
            return updateBrixCajaService({ token, array, caja_id })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    return {
        createBrixCaja,
        updateBrixCaja,
    };
}