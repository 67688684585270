import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/usuarios/"; // Se completa el path de la url base

// Export async function users
export async function usersService({ token, rowsPerPage, page, data }) {
  const getUrl = "/members/"; // Se completa el path de la url base
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(getUrl, config);
  // Se retorna la respuesta
  return response;
}

export async function createUserService({ token, data }) {

  console.log("Service: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const new_data = {
    username: data.usuario.username,
    first_name: data.usuario.first_name,
    last_name: data.usuario.last_name,
    password: data.usuario.password,
    email: data.usuario.email,
    user_type: data.usuario.user_type,
    cliente: data.cliente,
  };
  console.log("Service: ", new_data);
  const response = await axios.post(URL, new_data, config);
  // Se retorna la respuesta

  console.log("Service: ", response);

  return response;
}

export async function updateUserService({ token, data }) {
  console.log("Data Service :", data);
  const { usuario } = data;
  console.log("Usuario Data Service :", usuario);
  const UPDATEURL = URL + usuario.id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(UPDATEURL, {
    username: usuario.username,
    first_name: usuario.first_name,
    last_name: usuario.last_name,
    password: usuario.password,
    email: usuario.email,
    user_type: usuario.user_type.id ? usuario.user_type.id : usuario.user_type,
  }, config);
  // Se retorna la respuesta
  console.log("Service User:" , response);
  return response;
}

export async function deleteUserService({ token, id }) {
  const deleteUrl = URL + id + "/";

  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}
