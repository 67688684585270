let errorStatus = "";
let errorMessage = "";

export const initialState = {
  errorStatus: errorStatus,
  errorMessage: errorMessage,
};

export const errorReducer = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        errorStatus: action.payload.errorStatus,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};
