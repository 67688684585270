import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    createPesoFrutoCajaService,
    updatePesoFrutoCajaService,
} from "../../services/pesoFrutos";

export default function usePesoFrutos() {
    const { state } = useContext(AuthContext);
    const { token } = state;

    const createPesoFrutoCaja = useCallback(
        ({ array, new_caja_id }) => {
            return createPesoFrutoCajaService({ token, array, new_caja_id })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    const updatePesoFrutoCaja = useCallback(
        ({ array, caja_id }) => {
            return updatePesoFrutoCajaService({ token, array, caja_id })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    return {
        createPesoFrutoCaja,
        updatePesoFrutoCaja,
    };
}