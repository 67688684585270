import React from "react";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { RowsPerPage, Pagination } from "./Pagination";
// import usePagination from "../../hooks/usePagination";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const Table = ({ data, headers, onSelectionChanged, rowsPerPage, handleRowsPerPage, handleNextPage, next, handlePreviousPage, previous, page }) => {
  return (
    <MainTable
      className="ag-theme-balham"
      style={{ height: "100%", width: "95%" }}
    >
      {
        // if rowsPerPage and handleRowsPerPage is seted, show pagination
        rowsPerPage && handleRowsPerPage && (
          <RowsPerPage rowsPerPage={rowsPerPage} handleRowsPerPage={handleRowsPerPage} />
        )
      }
      {/* <div>
        {previous} {page} {next}
      </div> */}
      <AgGridReact
        rowData={data}
        columnDefs={headers}
        pagination={false}
        defaultColDef={{
          sortable: true,
          filter: false,
          resizable: true,
          editable: false,
          flex: 1,
          minWidth: 150,
          cellClass: "no-border",
        }}
        rowSelection="single"
        // Stripe rows on alternating rows
        rowClassRules={{
          "odd-row": function (params) {
            return params.node.rowIndex % 2 === 0;
          },
          "even-row": function (params) {
            return params.node.rowIndex % 2 !== 1;
          },
        }}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
        }}
        animateRows={true}
        editType="fullRow"
        onSelectionChanged={onSelectionChanged}
        // width content to fit
        onGridSizeChanged={(params) => {
          params.api.sizeColumnsToFit();
        }}
        rowHeight={65}
        enableCellChangeFlash={true}
        alwaysShowHorizontalScroll={true}
        suppressHorizontalScroll={false}
      >
      </AgGridReact>
      <Pagination handleNextPage={handleNextPage} next={next} handlePreviousPage={handlePreviousPage} previous={previous} page={page} />
    </MainTable>
  );
};

const MainTable = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px;
  background-color: white;
  border-radius: 0 0 16px 16px;
  * {
    border: none;
  }

  // HEADER
  .ag-header,
  .ag-header-row {
    height: 55px !important;
    min-height: 55px !important;
    font-size: 12px;
    color: #6c6c6c;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 20px;
    padding-right: 18px;
  }

  .ag-header-cell-resize::after {
    background-color: var(
      --ag-header-column-resize-handle-color,
      rgba(186, 191, 199, 0.5)
    );
    width: 2px;
    height: 30%;
    position: absolute;
    left: calc(50% - 1px);
    content: "";
    top: 35%;
    display: block;
  }

  // COMMON ROW
  .ag-row,
  .ag-header {
    color: #171717;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    height: 55px;
    border-radius: 8px;
    transition: border-radius ease 200ms, background-color ease 200ms;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    color: #171717;
  }

  .ag-row-odd {
    background: rgba(196, 196, 196, 0.2);
  }
  .ag-row-even {
    background-color: #ffffff;
  }

  .ag-row-hover,
  .ag-row-hover.ag-row-odd {
    background-color: var(--ag-row-hover-background-color, #f5f5f5);
  }

  .ag-row-selected,
  .ag-row-selected.ag-row-odd {
    background-color: var(--ag-row-selected-background-color, #f5f5f5);
    border-radius: 0;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .no-border.ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .no-border {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .ag-row-animation,
  .ag-row {
    transition: transform 0.4s, top 0.4s, height 0.4s, background-color 0.1s,
      opacity 0.3s;
    transition-property: transform, top, height, background-color, opacity;
    transition-duration: 0.4s, 0.4s, 0.4s, 0.1s, 0.2s;
    transition-timing-function: ease, ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s, 0s;
  }

  .ag-selection-checkbox {
    input {
      width: 14px;
      height: 14px;
      margin-right: 14px;
    }
  }

  ///////////////
  // UNCHECKED //
  ///////////////

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
    border: none;
  }

  .ag-header-select-all .ag-checkbox-input-wrapper input {
    width: 15px;
    height: 15px;
    margin-right: 0px;
  }

  .ag-header-container,
  .ag-header-row,
  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-body-viewport .ag-center-cols-clipper .ag-center-cols-viewport .ag-cell {
    transform: scale(0.8);
  }

  .ag-checked :after {
    color: #ff538d !important;
  }

  .ag-header-select-all {
    margin-left: 30px;
  }

  .ag-list-item {
    margin: 7px 0;
  }

  .ag-row .ag-cell-data-changed {
    background-color: #f39121 !important;
  }

  .ag-list-item.ag-select-list-item {
    background-color: #e2e2e2 !important;
    color: #484848 !important;
    height: 24px;
    border: none;
    cursor: default;
    /* display: flex; */
    outline: 0;
    padding: 5px 10px;
    margin-left: 10px;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    white-space: nowrap;
    width: fit-content;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
  }

  .ag-list-item.ag-select-list-item.ag-active-item {
    /* background-color: rgba(33, 150, 243, 0.1) !important; */
    background: #1876d2 !important;
    color: white !important;
  }

  .bottom-bar {
    height: 50px;
  }

  .bottom-bar .ag-overlay {
    display: none;
  }

  .bs_select_cell {
    background-color: #e2e2e2;
    color: #484848;
    border-radius: 60px;
    padding: 10px;
  }

  /* TEXT AREA */
  .ag-popup-editor {
    background-color: white;
  }

  // Pagination
  .ag-paging-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 0 0 16px 16px;
  }

  // scrollbar x
  .ag-body-viewport .ag-body-viewport-wrapper {
    overflow-x: auto;
  }

  // pagination buttons style
  .ag-paging-button {
    border: none;
    background-color: #f5f5f5;
    color: #ff9100;
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // pagination text style
  .ag-paging-row-summary-panel {
    color: #171717;
    visibility: hidden;
  }
  // pagination page 1 of 1 style
  .ag-paging-page-summary-panel {
    color: #171717;
  }
`;

export default Table;
