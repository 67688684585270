import axios from "../api/axios";

const URL = "/embalajes/";

// Export async function users

export async function getEmbalajesService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getEmbalajesNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getEmbalajeService({ token, embalaje }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL + embalaje + "/", config);
  // Se retorna la respuesta
  return response;
}

export async function getEmbalajesByClasificacionEspecieExportadoraService({ token, data }) {
  const newUrl = URL + "get-embalajes-by-clasificacion/";
  // console.log("data", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      clasificacion: parseInt(data.clasificacion),
      especie: data.especie.id,
      exportadora: data.exportadora.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // console.log("Service Embalajes: ", response);
  // Se retorna la respuesta
  return response;
}

export async function getEmbalajeFirmezaService ({ token, embalaje }) {
  const newUrl = "/tab_embalajes/";
  // console.log("data", embalaje);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      embalaje: embalaje,
      count: 1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(newUrl, config);
  // console.log("Service Embalajes: ", response);
  // Se retorna la respuesta
  return response;
}

export async function createEmbalajeService({ token, data }) {
  console.log("Service: ", data);
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      norma: data.norma,
      exportadora: data.exportadora,
      especie: data.especie,
      clasificacion: data.clasificacion,
      peso_neto_minimo: data.peso_neto_minimo,
      peso_neto_medio: data.peso_neto_medio,
      peso_neto_maximo: data.peso_neto_maximo,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Embalaje: ", response);
  return response;
}

export async function updateEmbalajeService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("data", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      codigo: data.codigo,
      nombre: data.nombre,
      norma: data.norma.id !== undefined ? data.norma.id : data.norma,
      exportadora: data.exportadora.id !== undefined ? data.exportadora.id : data.exportadora,
      especie: data.especie.id !== undefined ? data.especie.id : data.especie,
      clasificacion: data.clasificacion.id !== undefined ? data.clasificacion.id : data.clasificacion,
      peso_neto_minimo: data.peso_neto_minimo,
      peso_neto_medio: data.peso_neto_medio,
      peso_neto_maximo: data.peso_neto_maximo,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Embalaje Update: ", response);
  return response;
}
