import {Link} from 'react-router-dom';
import styled from 'styled-components';
import BotonSistemas from '../../components/BotonSistemas';
import Navbar from "../../components/Navbar";
import Fruits from '../../assets/images/Sistemas/fruits.png';

const Sistemas = () => {
    return (
        <div>
            <Navbar/>
            <StyledContainer>
                <StyledTitulo>Seleccion de portal</StyledTitulo>
                <Link to="/control-procesos/graficos">
                    <BotonSistemas texto="Sistemas Técnicos" arrow=">" icon="SC"/>
                </Link>
                <a href="https://agroinm.cl" target="_blank" rel="noreferrer">
                    <BotonSistemas texto="Sistemas Complementarios" arrow=">" icon="ST"> </BotonSistemas>
                </a>
                <StyledDiv></StyledDiv>
            </StyledContainer>
        </div>
    );
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    height: calc(100vh - 86px);
    flex-direction: column;
    margin-top: 0;
    width: 100%;
`

const StyledTitulo = styled.span`
    color: #64766A;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 20px;
    margin-bottom: 20px;
    margin-top: 176px;
    
    @media only screen and (min-width: 1200px) {
        margin-top: 100px;
    }
    @media only screen and (max-width: 992px) {
        
    }
    @media only screen and (max-width: 600px) {
        font-size: 24px;
    }
`

const StyledDiv = styled.div`
    width: 100%;
    height: 200px;
    position: absolute; 
    bottom: 0px;
    background-image : url(${Fruits});
    background: linear-gradient(180deg, #FFFFFF 6.77%, rgba(255, 255, 255, 0) 76.04%), url(${Fruits});


    @media (max-width: 600px) {
        height: 150px;
    }
    @media screen and (max-height: 810px) {
        background-image: none;
    }
`
export default Sistemas;