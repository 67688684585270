import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import UserIcon from "../../assets/icons/Generales/usericon";
import ArrowDown from "../../assets/icons/Generales/arrowdown";
import MenuCloseIcon from "../../assets/icons/Generales/menucloseicon";
import MenuOpenIcon from "../../assets/icons/Generales/menuopenicon";

import Logo from "../../components/Logo";

import {
  StyledHeader,
  StyledMenuContainer,
  StyledLogoContainer,
  StyledUserContainer,
  StyledUser,
  StyledUserName,
  StyledUserIcon,
} from "./styledNavbar";
import AuthContext from "../../context/AuthProvider";
import ThemeContext from "../../context/ThemeProvider";
import NavBarContext from "../../context/NavBarProvider";

import { NAVBAR } from "../../context/Actions";

const Navbar = (props) => {
  const { state } = useContext(AuthContext);
  const { stateNavBar, dispatch } = useContext(NavBarContext);
  // const [sidebar, setSidebar] = useState(false);

  const { stateTheme } = useContext(ThemeContext);

  // console.log(sidebar);

  const { user } = state;

  function showSidebar() {
    dispatch({
      type: NAVBAR.SET_NAVBAR,
      payload: { navbar: !stateNavBar.navbar },
    });
  }

  const { navbar } = stateNavBar;

  const renderMenuIcon = () => {
    return navbar ? (
      <MenuOpenIcon theme={stateTheme} header="active" />
    ) : (
      <MenuCloseIcon theme={stateTheme} header="active" />
    );
  };

  return (
    <>
      <StyledHeader>
        <StyledMenuContainer>
          <StyledLink to="#" onClick={showSidebar}>
            {renderMenuIcon()}
          </StyledLink>
        </StyledMenuContainer>
        <StyledLogoContainer className="logo">
          <Logo height="55px" />
        </StyledLogoContainer>
        <StyledUserContainer>
          <StyledUser>
            <ArrowDown />{" "}
            <StyledUserName>
              {user.first_name} {user.last_name}
            </StyledUserName>
          </StyledUser>
          <StyledUserIcon>
            <UserIcon />
          </StyledUserIcon>
        </StyledUserContainer>
      </StyledHeader>
    </>
  );
};

const StyledLink = styled(Link)`
  width: 42px;
  height: 42px;
  background: none;
  transition: 350ms;
  border-radius: 100px;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(196, 196, 196, 0.1);
`;

export default Navbar;
