import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";

import {
  getTabsEmbalajeService,
  updateTabsEmbalajeService,
} from "../../services/tabs";

export default function useTabs() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getTabsEmbalaje = useCallback(
    (embalaje) => {
      return getTabsEmbalajeService({ token, embalaje })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateTabsEmbalaje = useCallback(
    ({key, value, embalaje, tab}) => {
      return updateTabsEmbalajeService({ token, key, value, embalaje, tab })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getTabsEmbalaje,
    updateTabsEmbalaje,
  };
}
