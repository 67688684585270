import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/inspeccion_planillas/"; // Se completa el path de la url base

// Export async function users

export async function getPlanillasInspeccionService({ token, rowsPerPage, page, data, inspeccionTipo }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      usuario: data.usuario,
      inspeccion_tipo: inspeccionTipo,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getPlanillasInspeccionByInspectorService({ token, rowsPerPage, page, data, inspeccionTipo }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      user_id: data.usuario,
      inspeccion_tipo: inspeccionTipo,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createPlanillaInspeccionService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service Planilla Inspeccion: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
        folio_tablet: data.folio_tablet,
        inspeccion_tipo: data.inspeccion_tipo,
        especie: data.especie,
        exportadora: data.exportadora,
        packing: data.packing,
        usuario: data.usuario,
        planilla_type: data.planilla_type,
        fecha: data.fecha,
        turno: data.turno,
        sincronizacion: data.sincronizacion,
        estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
    console.log("Service Planilla Inspeccion Response: ", response);
  return response;
}

export async function updatePlanillaInspeccionService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log("Service Planillas Update: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      inspeccion_tipo: data.inspeccion_tipo.id ? data.inspeccion_tipo.id : data.inspeccion_tipo,
      especie: data.especie.id ? data.especie.id : data.especie,
      exportadora: data.exportadora.id ? data.exportadora.id : data.exportadora,
      packing: data.packing.id ? data.packing.id : data.packing,
      usuario: data.usuario.id ? data.usuario.id : data.usuario,
      planilla_type: data.planilla_type,
      fecha: data.fecha,
      turno: data.turno.id ? data.turno.id : data.turno,
      sincronizacion: data.sincronizacion,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  console.log("Service Planillas Response: ", response);
  return response;
}

export async function deletePlanillaService({ token, id }) {
  console.log(id);
  const deleteUrl = URL + id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta

  return response;
}
