import axios from "../api/axios";

const URL = "/especie_detalles/";

export async function getEspecieDetallesService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createEspecieDetallesService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("data", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, data, config);
  // Se retorna la respuesta
  return response;
}

export async function updateEspecieDetallesService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("data", data);
  let especie = data.especie.id || data.especie;
  let exportadora = data.exportadora.id || data.exportadora;
  let inspeccion_tipo = data.inspeccion_tipo.id || data.inspeccion_tipo;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    URL + data.id + "/",
    {
      especie: especie,
      exportadora: exportadora,
      inspeccion_tipo: inspeccion_tipo,
      clasificacion: data.clasificacion,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}
