import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getEmbalajesService,
  getEmbalajeService,
  createEmbalajeService,
  updateEmbalajeService,
  getEmbalajesNoPaginationService,
  getEmbalajesByClasificacionEspecieExportadoraService,
  getEmbalajeFirmezaService,
} from "../../services/embalajes";

export default function useEmbalajes() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getEmbalajes = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getEmbalajesService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getEmbalajesNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getEmbalajesNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getEmbalaje = useCallback(
    (embalaje) => {
      return getEmbalajeService({ token, embalaje })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const getEmbalajesByClasificacionEspecieExportadora = useCallback(({clasificacion, especie, exportadora}) => {
    const data = {
      cliente: client.id,
      clasificacion,
      especie,
      exportadora
    };
    return getEmbalajesByClasificacionEspecieExportadoraService({ token, data })
      .then((res) => {
        // console.log("Response :", res.data.data);
        if (res.status === 200) {
          return res.data.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getEmbalajeFirmeza = useCallback( (embalaje) => {
    return getEmbalajeFirmezaService({ token, embalaje })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const createEmbalaje = useCallback(
    (data) => {
      data.cliente = client.id;
      return createEmbalajeService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateEmbalaje = useCallback(
    (data) => {
      return updateEmbalajeService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            console.log("Data Hook : ", res.data);
            return res.data;

          }
        })
        .catch((err) => {
          console.log("Error : ", err.response);
          return err;
        });
    },
    [token]
  );

  return {
    getEmbalajes,
    getEmbalaje,
    createEmbalaje,
    updateEmbalaje,
    getEmbalajesNoPagination,
    getEmbalajesByClasificacionEspecieExportadora,
    getEmbalajeFirmeza,
  };
}
