import axios from "../api/axios";

const URL = "/inspeccion_tipos/";

// Export async function users

export async function getTipoInspeccionService({ token, rowsPerPage, page }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getTipoInspeccionNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count:1000
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createTipoPackingService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(
    URL,
    {
      nombre: data.nombre,
      cliente: data.cliente,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta

  return response;
}

export async function updateTipoPackingService({ token, data }) {
  const updateUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  console.log(data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    updateUrl,
    {
      nombre: data.nombre,
      estado: data.estado,
    },
    config
  );
  // Se retorna la respuesta

  return response;
}

export async function deleteTipoPackingService({ token, data }) {
  const deleteUrl = URL + data.id + "/";
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(deleteUrl, config);
  // Se retorna la respuesta
  return response;
}
