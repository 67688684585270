import React, {useEffect, useState, useContext } from "react";
import ModalForm from "../../../../components/Modal";
// import SelectMultiple from "../../../../components/SelectMultiple";
import useParametros from "../../../../hooks/api/useParametros";
import styled from "styled-components";
import CardItem from "../../../../components/Modal/CardItem";
import { MantainersContext } from "../../../../context/MantaintersContext";

const AddParametroForm = ({
  handleCancel,
}) => {
  const [ parametrosSelect, setParametrosSelect ] = useState([]);
  const [ parametrosTable, setParametrosTable ] = useState([]);
  const [ data, setData ] = useState([]);

  const { getParametrosNoPagination, getParametrosPlanillasNoPagination, createPlanillaParametro } = useParametros();

  const { mantainersState } = useContext(MantainersContext);

  const { planilla_edit } = mantainersState;

  useEffect(() => {
    getParametrosNoPagination().then((res) => {
      setParametrosSelect(res.results);
    });
    getParametrosPlanillasNoPagination(planilla_edit).then((res) => {
      setParametrosTable(res.results);
    });
  }, [getParametrosPlanillasNoPagination, planilla_edit, getParametrosNoPagination ]);

  const handleValue = (e) => {
    setData({ ...data,
      planilla: planilla_edit,
      [ e.target.name ]: e.target.value,
    });
  };

  const handleSaveParametro = () => {
    createPlanillaParametro(data).then((res) => {
      setParametrosTable([...parametrosTable, res.data]);
    });
  };

  return (
    <ModalForm
      dataInput={planilla_edit}
      handleCancel={handleCancel}
      create={true}
      handleSave={handleSaveParametro}
      title="Relacion Planilla - Parametros"
    >
      {/* <SelectMultiple especies={especies} dataInput={dataInput} setDataInput={setDataInput} /> */}
      <StyledDiv>
        <StyledSelected
          name="parametro"
          // selected first option by default
          defaultValue={parametrosSelect[0]}
          onChange={handleValue}
          required
        >
          {
            parametrosSelect.map((parametro, index) => (
              <StyledOption key={index} value={parametro.id}>{parametro.nombre}</StyledOption>
            ))
          }
        </StyledSelected>
        <StyledTextInputOrder
          type="number"
          name="orden_parametro"
          placeholder="Orden"
          defaultValue={data.orden_parametro !== "" ? data.orden_parametro : null}
          onChange={handleValue}
          required
        />
      </StyledDiv>
      <CardItem data={parametrosTable} handleDelete={handleCancel} />
    </ModalForm>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledTextInputOrder = styled.input`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default AddParametroForm;
