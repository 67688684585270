import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    createFirmezaCajaService,
    updateFirmezaCajaService,
} from "../../services/firmezas";

export default function useFirmeza() {
    const { state } = useContext(AuthContext);
    const { token } = state;

    const createFirmezaCaja = useCallback(
        ({ array, new_caja_id }) => {
            return createFirmezaCajaService({ token, array, new_caja_id })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    const updateFirmezaCaja = useCallback(
        ({ array, caja_id }) => {
            return updateFirmezaCajaService({ token, array, caja_id })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    return {
        createFirmezaCaja,
        updateFirmezaCaja,
    };
}