import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import DefectosForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useDefectos from "../../../../hooks/api/useDefectos";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import { toast } from "react-toastify";

const Defectos = () => {
  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getDefectos, createDefecto, updateDefecto } = useDefectos();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  // "actual_page": 3,
  //  "next_page": 4,
  //  "previous_page": 2,
  //  "total_pages": 36

  useEffect(() => {
    getDefectos({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getDefectos,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateDefecto(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Defecto actualizado correctamente");
    }).catch((err) => {
      toast.error("Error al actualizar defecto");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = (e) => {
    setCreate(false);
    createDefecto(dataInput).then((res) => {
      console.log("Page :", res.data);
      setRowData([...rowData, res.data]);
      toast.success("Defecto creado correctamente");
    }).catch((err) => {
      toast.error("Error al crear defecto");
    });
    setEdit(false);
    // Reset dataInput
    setDataInput({});
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Defectos"
      >
        <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {create === true ? (
        <DefectosForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <DefectosForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
    </Layout>
  );
};

export default Defectos;
