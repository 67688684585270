import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useTabs from "../../../../hooks/api/useTabs";
import { forEach } from "lodash";
import { toast } from "react-toastify";

const TabsForm = ({ dataInput, handleCancel, setTabsForm }) => {
  const [embalaje] = useState(dataInput.id);
  const [tabs, setTabs] = useState([]);
  const [check, setCheck] = useState([]);

  const { getTabsEmbalaje, updateTabsEmbalaje } = useTabs();

  useEffect(() => {
    getTabsEmbalaje(embalaje).then((res) => {
      setTabs(res.results);
      console.log("res", res.results);
    });
  }, [getTabsEmbalaje, embalaje]);

  const handleValue = (e) => {
    const { name, checked, value } = e.target;
    // validate if e.target is an input checkbox or an input number
    if (value !== "on" && value !== "off") {
      let nameId = e.target.name.match(/\d+/g);
      let nameString = e.target.name.replace(/\d+/g, "");
      setCheck({
        ...check,
        [nameId]: {
          ...check[nameId],
          [nameString]: value,
        },
      });
    } else {
      setCheck({
        ...check,
        [name]: {
          ...check[name],
          active: checked,
        },
      });
    }
  };

  const handleSaveChangesTabs = () => {
    forEach(check, (value, key) => {
      let oneTab = tabs.filter((tab) => tab.id === parseInt(key));
      let tab = oneTab[0].tab.id;
      updateTabsEmbalaje({ key, value, embalaje, tab });
    });
    setTabsForm(false);
    toast.success(
      "Pestañas para el embalaje " + dataInput.nombre + " actualizadas"
    );
  };

  console.log("tabs: ", tabs); 

  return (
    <ModalForm
      dataInput={dataInput}
      handleCancel={handleCancel}
      edit={true}
      handleEdit={handleSaveChangesTabs}
      title="Tabs Inspeccion Caja"
    >
      <StyledContainer>
        {tabs.map((tab) =>
          tab.tab.id === 1 ? (
            <StyledRow key={tab.id}>
              <StyledDivCheckbox>
                <StyledCheckbox
                  type="checkbox"
                  name={tab.id}
                  defaultChecked={tab.estado}
                  onChange={handleValue}
                  disabled
                  checked
                />
              </StyledDivCheckbox>
              <StyledDivLabel>
                <StyledLabel>{tab.tab.nombre}</StyledLabel>
              </StyledDivLabel>
              <StyledDiv></StyledDiv>
            </StyledRow>
          ) : tab.active === true ? (
            tab.tab.id === 2 || tab.tab.id === 3 ? (
              <StyledRow key={tab.id}>
                <StyledDivCheckbox>
                  <StyledCheckbox
                    type="checkbox"
                    name={tab.id}
                    onChange={handleValue}
                    defaultChecked
                  />
                </StyledDivCheckbox>
                <StyledDivLabel>
                  <StyledLabel>{tab.tab.nombre}</StyledLabel>
                </StyledDivLabel>
                <StyledDiv>
                  {tab.tab.id === 3 ? (
                    <StyledTextInputs
                      type="number"
                      name={"numero_fotos" + tab.id}
                      defaultValue={tab.numero_fotos}
                      onChange={handleValue}
                    />
                  ) : null}
                </StyledDiv>
              </StyledRow>
            ) : tab.tab.id === 7 ? (
              <StyledRow key={tab.id}>
                <StyledDivPesoFijo>
                  <StyledDivColumns>
                    <StyledDivCheckbox>
                      <StyledCheckbox
                        type="checkbox"
                        name={tab.id}
                        onChange={handleValue}
                        defaultChecked
                      />
                    </StyledDivCheckbox>
                    <StyledDivLabel>
                      <StyledLabel>{tab.tab.nombre}</StyledLabel>
                    </StyledDivLabel>
                  </StyledDivColumns>
                  <StyledDivInputPesoFijo>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StyledLabel>Minimo</StyledLabel>
                      <StyledTextInputs
                        type="number"
                        name={"peso_fijo_minimo" + tab.id}
                        placeholder="Min"
                        defaultValue={tab.peso_fijo_minimo}
                        onChange={handleValue}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StyledLabel>Maximo</StyledLabel>
                      <StyledTextInputs
                        type="number"
                        name={"peso_fijo_maximo" + tab.id}
                        placeholder="Max"
                        defaultValue={tab.peso_fijo_maximo}
                        onChange={handleValue}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StyledLabel>Cestas</StyledLabel>
                      <StyledTextInputs
                        type="number"
                        name={"numero_cestas" + tab.id}
                        placeholder="0"
                        defaultValue={tab.numero_cestas}
                        onChange={handleValue}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StyledLabel>Cestas Real</StyledLabel>
                      <StyledTextInputs
                        type="number"
                        name={"numero_cestas_real" + tab.id}
                        placeholder="0"
                        defaultValue={tab.numero_cestas_real}
                        onChange={handleValue}
                      />
                    </div>
                  </StyledDivInputPesoFijo>
                </StyledDivPesoFijo>
              </StyledRow>
            ) : (
              <StyledRow key={tab.id}>
                <StyledDivCheckbox>
                  <StyledCheckbox
                    type="checkbox"
                    name={tab.id}
                    onChange={handleValue}
                    defaultChecked
                  />
                </StyledDivCheckbox>
                <StyledDivLabel>
                  <StyledLabel>{tab.tab.nombre}</StyledLabel>
                </StyledDivLabel>
                <StyledDiv>
                  <StyledTextInputs
                    type="number"
                    name={"numero_cestas" + tab.id}
                    placeholder="0"
                    defaultValue={tab.numero_cestas}
                    onChange={handleValue}
                  />
                </StyledDiv>
              </StyledRow>
            )
          ) : tab.tab.id === 2 || tab.tab.id === 3 ? (
            <StyledRow key={tab.id}>
              <StyledDivCheckbox>
                <StyledCheckbox
                  type="checkbox"
                  name={tab.id}
                  onChange={handleValue}
                />
              </StyledDivCheckbox>
              <StyledDivLabel>
                <StyledLabel>{tab.tab.nombre}</StyledLabel>
              </StyledDivLabel>
              <StyledDiv></StyledDiv>
            </StyledRow>
          ) : tab.tab.id === 7 ? (
            <StyledRow key={tab.id}>
              <StyledDivPesoFijo>
                <StyledDivColumns>
                  <StyledDivCheckbox>
                    <StyledCheckbox
                      type="checkbox"
                      name={tab.id}
                      onChange={handleValue}
                    />
                  </StyledDivCheckbox>
                  <StyledDivLabel>
                    <StyledLabel>{tab.tab.nombre}</StyledLabel>
                  </StyledDivLabel>
                  <StyledDiv></StyledDiv>
                </StyledDivColumns>
                <StyledDivInputPesoFijo>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledLabel>Minimo</StyledLabel>
                    <StyledTextInputs
                      type="number"
                      name={"peso_fijo_minimo" + tab.id}
                      placeholder="Min"
                      defaultValue={tab.peso_fijo_minimo}
                      onChange={handleValue}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledLabel>Maximo</StyledLabel>
                    <StyledTextInputs
                      type="number"
                      name={"peso_fijo_maximo" + tab.id}
                      placeholder="Max"
                      defaultValue={tab.peso_fijo_maximo}
                      onChange={handleValue}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledLabel>Cestas</StyledLabel>
                    <StyledTextInputs
                      type="number"
                      name={"numero_cestas" + tab.id}
                      placeholder="0"
                      defaultValue={tab.numero_cestas}
                      onChange={handleValue}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledLabel>Cestas Real</StyledLabel>
                    <StyledTextInputs
                      type="number"
                      name={"numero_cestas_real" + tab.id}
                      placeholder="0"
                      defaultValue={tab.numero_cestas_real}
                      onChange={handleValue}
                    />
                  </div>
                </StyledDivInputPesoFijo>
              </StyledDivPesoFijo>
            </StyledRow>
          ) : (
            <StyledRow key={tab.id}>
              <StyledDivCheckbox>
                <StyledCheckbox
                  type="checkbox"
                  name={tab.id}
                  onChange={handleValue}
                />
              </StyledDivCheckbox>
              <StyledDivLabel>
                <StyledLabel>{tab.tab.nombre}</StyledLabel>
              </StyledDivLabel>
              <StyledDiv>
                <StyledTextInputs
                  type="number"
                  name={"numero_cestas" + tab.id}
                  placeholder="0"
                  defaultValue={tab.numero_cestas}
                  onChange={handleValue}
                />
              </StyledDiv>
            </StyledRow>
          )
        )}
      </StyledContainer>
    </ModalForm>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 3px;
  border: 1px solid #ff9100;
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 20px;
`;

const StyledTextInputs = styled.input`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledDivCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
`;

const StyledDivLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30%;
`;

const StyledDivPesoFijo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const StyledDivInputPesoFijo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 3px;
`;

const StyledDivColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export default TabsForm;
