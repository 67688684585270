import Layout from "../../../../components/Layout";
import useColorClientes from "../../../../hooks/api/useColorClientes";
import { useEffect, useState } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import ColorClientesForm from "./form";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import Mantainer from "../../../../components/Layout/mantainer";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import { toast } from "react-toastify";

const ColorClientes = () => {
  const {
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const { getColorClientes, updateColorClientes } = useColorClientes();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    {
      field: "cumple",
      headerName: "Cumple",
      valueGetter: (params) => params.data.cumple.nombre,
    },
    {
      field: "color",
      headerName: "Color Fondo",
      cellStyle: (params) => {
        return { backgroundColor: params.value };
      },
    },
    {
      field: "color_texto",
      headerName: "Color Texto",
      cellStyle: (params) => {
        return { color: params.value };
      },
    },
    {
      headerName: "Preview",
      field: "preview",
      valueGetter: (params) => "Texto a mostrar",
      cellStyle: (params) => {
        return {
          backgroundColor: params.data.color,
          color: params.data.color_texto,
        };
      },
    },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  useEffect(() => {
    getColorClientes({ rowsPerPage, page }).then((res) => {
      setRowData(res.results);
      setTotalRows(res.count);
      setNext(res.links.next_page);
      setPrevious(res.links.previous_page);
    });
  }, [
    getColorClientes,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateColorClientes(dataInput)
      .then((res) => {
        console.log("Page :", res.data);
        const newData = rowData.map((item) => {
          if (item.id === res.data.id) {
            return res.data;
          } else {
            return item;
          }
        });
        setRowData(newData);
        toast.success("Cliente actualizado correctamente");
      })
      .catch((err) => {
        toast.error("Error al actualizar cliente");
      });
    setDataInput({});
    setEdit(false);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Color Clientes"
      >
        <FormCrudButton text="Editar" onClick={handleEditForm} />
      </Mantainer>
      {edit && (
        <ColorClientesForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      )}
    </Layout>
  );
};

export default ColorClientes;
