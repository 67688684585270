import { createContext, useReducer } from "react";
import { errorReducer, initialState } from "./ErrorReducer";

export const ErrorContext = createContext({initialState});

export const ErrorProvider = ({ children }) => {
    const [state, dispatch] = useReducer(errorReducer, initialState);

    return (
        <ErrorContext.Provider value={{ state, dispatch }}>
            {children}
        </ErrorContext.Provider>
    )
}