import Layout from "../../../../components/Layout";
// import ExportadoraFilter from "../../../../components/Filters/Exportadora";
// import EspecieFilter from "../../../../components/Filters/Especie";
// import NormaFilter from "../../../../components/Filters/Norma";
// import LineaFilter from "../../../../components/Filters/Linea";
// import PackingFilter from "../../../../components/Filters/Packing";
// import DateFilter from "../../../../components/Filters/Date";
// import Boton from "../../../../components/Boton";
// import styled from "styled-components";

const Graphics = () => {
  return (
    <Layout>
      {/* <StyledContainer>
        <StyledBreadcrumb>
          <StyledBreadcrumbItem>
            Sistema Técnico / Control Procesos / Gráficos
          </StyledBreadcrumbItem>
        </StyledBreadcrumb>
        <StyledTitle>
          <StyledH1>Resultados en Gráficos</StyledH1>
        </StyledTitle>
        <StyledFilters>
          <ExportadoraFilter />
          <EspecieFilter />
          <NormaFilter />
          <LineaFilter />
          <PackingFilter />
          <DateFilter />
        </StyledFilters>
        <StyledButtonsContainer>
          <Boton texto="Exportar a Excel" />
          <Boton texto="Exportar a PDF" />
        </StyledButtonsContainer>
      </StyledContainer> */}
    </Layout>
  );
};

// const StyledContainer = styled.div`
//   width: 100%;
//   height: max-content;
//   position: relative;

//   ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
//     border-radius: 10px;
//   }
// `;

// const StyledBreadcrumb = styled.ul`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   list-style: none;
//   padding: 0;

//   @media screen and (min-width: 1024px) {
//     display: none;
//   }
// `;

// const StyledBreadcrumbItem = styled.li`
//   font-family: "Poppins";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 11px;
//   line-height: 20px;
//   color: #64766a;

//   &:before {
//     content: "\\2022";
//     color: #ff9100;
//     font-weight: bold;
//     display: inline-block;
//     width: 1em;
//     margin-left: -1em;
//   }
// `;

// const StyledTitle = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   width: 100%;

//   @media screen and (min-width: 1024px) {
//     justify-content: flex-start;
//   }
// `;

// const StyledH1 = styled.h1`
//   color: #64766a;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 20px;
//   padding-left: 10px;
// `;

// const StyledFilters = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   width: 100%;

//   @media screen and (min-width: 1024px) {
//     align-items: center;
//     justify-content: flex-start;
//     width: 100%;
//   }
// `;

// const StyledButtonsContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   margin-top: 20px;
// `;

export default Graphics;
