import React, { useEffect, useState } from "react";
import ModalForm from "../../../../components/Modal";
// import SelectMultiple from "../../../../components/SelectMultiple";
import styled from "styled-components";
import DragAndDrop from "../../../../components/DragAndDrop";
import useNormaDetalles from "../../../../hooks/api/useNormaDetalles";
import useOperacionTipos from "../../../../hooks/api/useOperacionTipos";
import useOperaciones from "../../../../hooks/api/useOperaciones";
import { toast } from "react-toastify";

const OperacionesForm = ({
  dataInput,
  handleCancel,
  setCreate,
  operaciones,
  setOperaciones,
}) => {
  const [data, setData] = useState({});
  const [norma] = useState(dataInput.norma.id);
  const [operacionTipos, setOperacionTipos] = useState([]);
  const [valores, setValores] = useState({
    items: [],
    selected: [],
  });

  const { getNormaDetallesNoPagination } = useNormaDetalles();
  const { getOperacionTiposNoPagination } = useOperacionTipos();
  const { createOperacion, updateOperacion } = useOperaciones();

  console.log("operaciones", operaciones);

  useEffect(() => {
    getNormaDetallesNoPagination(norma).then((res) => {
      if (operaciones) {
        let op = operaciones[0] ? operaciones[0] : operaciones;
        setValores((values) => ({
          ...values,
          items: res.results
            .filter((item) => !op.parametros.includes(item.planilla_defecto.id))
            .map((item) => ({
              id: String(item.planilla_defecto.id),
              content: item.planilla_defecto.defecto.nombre,
            })),
          selected: res.results
            .filter((item) => op.parametros.includes(item.planilla_defecto.id))
            .map((item) => ({
              id: String(item.planilla_defecto.id),
              content: item.planilla_defecto.defecto.nombre,
            })),
        }));
      } else {
        setValores((values) => ({
          ...values,
          items: res.results.map((item) => ({
            id: String(item.planilla_defecto.id),
            content: item.planilla_defecto.defecto.nombre,
          })),
        }));
      }
    });
    getOperacionTiposNoPagination().then((res) => {
      setOperacionTipos(res.results);
      console.log("OPERACIONES", res.results);
    });
  }, [
    getNormaDetallesNoPagination,
    norma,
    setValores,
    getOperacionTiposNoPagination,
    operaciones,
  ]);

  const handleValue = (e) => {
    let parametros = valores.selected.map((item) => {
      return parseInt(item.id);
    });
    if (data.estado === undefined) {
      setData({
        ...data,
        norma_detalle: dataInput.id,
        parametros: parametros,
        estado: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setData({
        ...data,
        norma_detalle: dataInput.id,
        parametros: parametros,
        [e.target.name]: e.target.value,
      });
    }
    console.log("dataInput Planilla", data);
  };

  const handleSaveOperacion = (e) => {
    createOperacion(data)
      .catch((err) => {
        toast.error("Error al ejecutar crear Operacion");
      })
      .finally(() => {
        setCreate(false);
        setOperaciones(undefined);
      });
  };

  const handleUpdateOperacion = (e) => {
    let operacion = operaciones[0];
    updateOperacion({ data, operacion })
      .catch((err) => {
        toast.error("Error al ejecutar Actualizar Operacion");
      })
      .finally(() => {
        setOperaciones(false);
        setCreate(false);
      });
  };

  useEffect(() => {
    setData((datos) => ({
      ...datos,
      norma_detalle: dataInput.id,
      parametros: valores.selected.map((item) => {
        return parseInt(item.id);
      }),
    }));
  }, [dataInput, valores.selected]);

  return (
    <ModalForm
      dataInput={data}
      handleCancel={handleCancel}
      create={operaciones === false ? true : false}
      edit={operaciones !== false ? true : false}
      handleSave={handleSaveOperacion}
      handleEdit={handleUpdateOperacion}
      title="Operacion"
    >
      <StyledSelected name="operacion_tipo" onChange={handleValue}>
        <StyledOption value="0">Seleccione Tipo Operacion</StyledOption>
        {operacionTipos.map((operacionTipo, index) => (
          <StyledOption
            key={index}
            value={operacionTipo.id}
            selected={
              operaciones !== false
                ? operaciones.operacion_tipo.id === operacionTipo.id
                  ? true
                  : false
                : false
            }
          >
            {operacionTipo.nombre}
          </StyledOption>
        ))}
      </StyledSelected>
      <DragAndDrop valores={valores} setValores={setValores} />
      {/* Constante */}
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

export default OperacionesForm;
