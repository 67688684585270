import styled from 'styled-components'
import { IconContext } from "react-icons";

import * as FiIcons from "react-icons/fi";
import IconST from '../../assets/icons/Generales/iconst';
import IconSC from '../../assets/icons/Generales/iconsc';

const BotonSistemas = (props) => {
    return (
        <>
            <IconContext.Provider value={{ color: "#fff", size: "55px" }}>
                <StyledBotonSistemas disabled={props.disabled}>
                    <StyledSpan>
                        {props.icon === "ST" ? <StyledSpanIcon><IconST /></StyledSpanIcon> : props.icon === "SC" ? <StyledSpanIcon><IconSC /></StyledSpanIcon> : null}
                        {props.icon ? <StyledSpanText>{props.texto}</StyledSpanText> : props.texto}
                        {props.arrow ? <span><FiIcons.FiChevronRight /></span> : null}
                    </StyledSpan>
                </StyledBotonSistemas>
            </IconContext.Provider>
        </>
    )
}

const StyledBotonSistemas = styled.button`
    background-color: #FF9100;
    width: 492px;
    height: 123px;
    color: #FFFFFF;
    font-size: 24px;
    border-radius: 100px;
    border: none;
    margin: 20px;

    &:hover {
        background-color: #D88219;
    }

    &:active {
        background-color: green;
    }

    &:disabled {
        background-color: rgba(255, 168, 0, 0.32);
    }

    @media (max-width: 600px) {
        width: 323px;
        height: 68px;
    }
`

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;   
`

const StyledSpanIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    height: 111px;
    width: 111px;
    margin: 0;
    padding: 0;

    @media (max-width: 600px) {
        height: 56px;
        width: 56px;
    }
`

const StyledSpanText = styled.span`
    width: 264px;
    margin-left: 20px;
    padding: 0;
    text-align: start;

    @media (max-width: 600px) {
        width: 227px;
        font-size: 16px;
        line-height: 24px;
    }
`

export default BotonSistemas;

