const ControlRecepcionIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 19H15.149C14.8034 18.2784 14.2216 17.6966 13.5 17.351V15H16.5C17.6931 14.9987 18.8369 14.5241 19.6805 13.6805C20.5241 12.8369 20.9987 11.6931 21 10.5V5.5C21 4.57174 20.6313 3.6815 19.9749 3.02513C19.3185 2.36875 18.4283 2 17.5 2H12.466L9.94 0.264C9.69018 0.0922232 9.39418 0.00017889 9.091 0L6.5 0C5.57174 0 4.6815 0.368749 4.02513 1.02513C3.36875 1.6815 3 2.57174 3 3.5V10.5C3.00132 11.6931 3.47585 12.8369 4.31948 13.6805C5.16311 14.5241 6.30693 14.9987 7.5 15H10.5V17.351C9.77841 17.6966 9.1966 18.2784 8.851 19H1.5C1.10218 19 0.720644 19.158 0.43934 19.4393C0.158035 19.7206 0 20.1022 0 20.5C0 20.8978 0.158035 21.2794 0.43934 21.5607C0.720644 21.842 1.10218 22 1.5 22H8.851C9.1316 22.5985 9.5769 23.1047 10.1348 23.4593C10.6926 23.8139 11.34 24.0022 12.001 24.0022C12.662 24.0022 13.3094 23.8139 13.8672 23.4593C14.4251 23.1047 14.8704 22.5985 15.151 22H22.5C22.8978 22 23.2794 21.842 23.5607 21.5607C23.842 21.2794 24 20.8978 24 20.5C24 20.1022 23.842 19.7206 23.5607 19.4393C23.2794 19.158 22.8978 19 22.5 19ZM7.5 12C7.10218 12 6.72064 11.842 6.43934 11.5607C6.15804 11.2794 6 10.8978 6 10.5V7H18V10.5C18 10.8978 17.842 11.2794 17.5607 11.5607C17.2794 11.842 16.8978 12 16.5 12H7.5Z"
        fill={props.status === true ? "#FF9100" : "white"}
      />
    </svg>
  );
};

export default ControlRecepcionIcon;