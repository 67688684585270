import React from "react";
import styled from "styled-components";
import Table from "../Table";
// import SearchInput from "../Search";
import Toast from "../Toast";

const ClientMantainer = ({
  children,
  columnDefs,
  onSelectionChanged,
  rowData,
  handleNextPage,
  handlePreviousPage,
  previous,
  next,
  rowsPerPage,
  handleRowsPerPage,
  page,
  title
}) => {
  return (
    <StyledMantainer>
      <StyledHeader>
        <StyledBreadcrumb>
          <StyledBreadcrumbItem>Cliente / Reportes / {title}</StyledBreadcrumbItem>
        </StyledBreadcrumb>
        <StyledTitle>
          <StyledH1>{title}</StyledH1>
        </StyledTitle>
      </StyledHeader>
      <StyledAccionsContainer>
        <StyledSearchContainer>
          {/* <SearchInput placeholder="Buscar" /> */}
        </StyledSearchContainer>
        <StyledButtonsContainer>
          {children}
        </StyledButtonsContainer>
      </StyledAccionsContainer>
      <StyledBody>
        <Table
          headers={columnDefs}
          data={rowData}
          onSelectionChanged={onSelectionChanged}
          handleRowsPerPage={handleRowsPerPage}
          rowsPerPage={rowsPerPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          previous={previous}
          next={next}
          page={page}
        />
      </StyledBody>
      <Toast />
    </StyledMantainer>
  );
};

export default ClientMantainer;

const StyledMantainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  align-self: center;
  margin-top: 15px;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledBreadcrumb = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledBreadcrumbItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #64766a;

  &:before {
    content: "\\2022";
    color: #ff9100;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const StyledH1 = styled.h1`
  color: #64766a;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  padding-left: 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const StyledAccionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  width: 95%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    width: 50%;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;
