import React from "react";
import Header from "../Navbar";
import Sidebar from "../Sidebar";
import Container from "./container";

import styled from "styled-components";

const Layout = ({ children }) => {
  return (
    <StyledContainer>
      <Header></Header>
      <StyledDiv>
        <Sidebar active />
        <StyledDiv2>
          <Container> {children} </Container>
        </StyledDiv2>
      </StyledDiv>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
`;

const StyledDiv2 = styled.div`
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
export default Layout;
