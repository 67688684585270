import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getDefectosService,
  createDefectoService,
  updateDefectoService,
  getDefectosNoPaginationService,
  getDefectosPlanillasNoPaginationService,
  createPlanillaDefectoService,
} from "../../services/defectos";

export default function useDefectos() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getDefectos = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getDefectosService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getDefectosNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getDefectosNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getDefectosPlanillasNoPagination = useCallback(
    (data) => {
      data.cliente = client.id;
      return getDefectosPlanillasNoPaginationService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createDefecto = useCallback(
    (data) => {
      data.cliente = client.id;
      return createDefectoService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const createPlanillaDefecto = useCallback(
    (data) => {
      data.cliente = client.id;
      return createPlanillaDefectoService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateDefecto = useCallback(
    (data) => {
      return updateDefectoService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getDefectos,
    getDefectosNoPagination,
    getDefectosPlanillasNoPagination,
    createDefecto,
    createPlanillaDefecto,
    updateDefecto,
  };
}
