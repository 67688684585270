// create a button component
import React from "react";
import styled from "styled-components";

export const AlertButton = ({ text, handleOpen, planillaDefectosHeaders, item, causalesCaja }) => {
  const handleClick = () => {
    handleOpen();
    causalesCaja(planillaDefectosHeaders, item);
  };

  return <StyledButton onClick={handleClick}>{text}</StyledButton>;
};

const StyledButton = styled.button`
  background: #FF9100;
  border-radius: 5px;
  border: none;
  height: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 6.25px;
  // alinear texto en una linea
  white-space: nowrap;
  /* or 150% */

  text-align: center;

  /* Neutral / Lightest */

  color: #FFFFFF;
  &:active {
    background: #D88219;
    box-shadow: 0px 7px 21px -4px rgba(0, 0, 0, 0.1);
  }
`;