import Navbar from "../../../components/Navbar";

const Asistencia = () => {
    return (
        <div>
            <Navbar active link="https://rioblanco.inglobochile.cl/Reportes/Reporte_Inspector/FrontIndex.php"/>
        </div>
    )
}

export default Asistencia;