import React from 'react'

import ControlProcesosIcon from '../../assets/icons/sistemas/controlProcesosIcon'
import ControlRecepcionIcon from '../../assets/icons/sistemas/controlRecepcionIcon'
import ControlMercadoInternoIcon from '../../assets/icons/sistemas/controlMercadoInternoIcon'
import ControlProductoTerminadoIcon from '../../assets/icons/sistemas/controlProductoTerminadoIcon'
import ControlCajasTestigoIcon from '../../assets/icons/sistemas/controlCajasTestigoIcon'
import ControlContenedores from '../../assets/icons/sistemas/controlContenedores'
import ControlDestino from '../../assets/icons/sistemas/controlDestino'
import Mantenedores from '../../assets/icons/sistemas/mantenedores'

const UserTypes = {
    ADMIN: 1,
    INSPECTOR: 2,
    CLIENT: 3,
}

export const SidebarData = [
    {
        id: 1,
        title: 'Mantenedores',
        submenu:[
            {
                "title":"Clientes",
                "path":"/mantenedores/clientes",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Clientes Color",
                "path":"/mantenedores/ColorClientes",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Usuarios",
                "path":"/mantenedores/users",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Exportadoras",
                "path":"/mantenedores/exportadoras",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Especies",
                "path":"/mantenedores/especies",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Especie Detalles",
                "path":"/mantenedores/especie_detalles",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Variedades",
                "path":"/mantenedores/variedades",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Productores",
                "path":"/mantenedores/productores",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Zonas",
                "path":"/mantenedores/zonas",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Tipo de Packing",
                "path":"/mantenedores/tipo-packings",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Packings",
                "path":"/mantenedores/packings",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Resoluciones",
                "path":"/mantenedores/resoluciones",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Defectos",
                "path":"/mantenedores/defectos",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Parametros",
                "path":"/mantenedores/parametros",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Planillas",
                "path":"/mantenedores/planillas",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Etiquetas",
                "path":"/mantenedores/etiquetas",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Clasificación",
                "path":"/mantenedores/clasificacion",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Embalajes",
                "path":"/mantenedores/embalajes",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Calibres",
                "path":"/mantenedores/calibres",
                "access": [UserTypes.ADMIN],
            },
            {
                "title":"Normas",
                "path":"/mantenedores/normas",
                "access": [UserTypes.ADMIN],
            },
        ],
        path: '#',
        iconOrange: <Mantenedores status={true} />,
        iconWhite: <Mantenedores status={false} />,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN],
    },
    {
        id: 2,
        title: 'Control Procesos',
        submenu:[
            {
                "title":"Resultados en Gráficos",
                "path":"/control-procesos/graficos",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Informe Diario",
                "path":"/control-procesos/informe-diario",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Base Datos Inspecciones",
                "path":"/control-procesos/bd",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Fuera de Norma",
                "path":"/control-procesos/fuera-norma",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlProcesosIcon status={true} />,
        iconWhite: <ControlProcesosIcon status={false} />,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
    {
        id: 3,
        title: 'Control Recepción',
        submenu:[
            {
                "title":"Reporte Recepción",
                "path":"/recepcion/planillas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlRecepcionIcon status={true}/>,
        iconWhite: <ControlRecepcionIcon status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [1,2,3],
    },
    {
        id: 4,
        title: 'Control Producto Terminado',
        submenu:[
            {
                "title":"Reporte Pallet",
                "path":"/terminado/pallet",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Lote",
                "path":"/terminado/lote",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Base de datos Cajas",
                "path":"/terminado/cajas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Informe Diario",
                "path":"/terminado/diario",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Resolución",
                "path":"/terminado/resolucion",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Gerencia",
                "path":"/terminado/gerencia",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Packing",
                "path":"/terminado/packing",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Resultados en Gráficos",
                "path":"/terminado/graficos",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte Fotografías",
                "path":"/terminado/fotos",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Reporte de Asistencia",
                "path":"/terminado/Asistencia",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Planilla Pallet",
                "path":"/producto-terminado/planillas-pallet",
                "access": [UserTypes.ADMIN, UserTypes.INSPECTOR],
            },
            {
                "title":"Planilla Lote",
                "path":"/producto-terminado/planillas-lote",
                "access": [UserTypes.ADMIN, UserTypes.INSPECTOR],
            }
        ],
        path: '#',
        iconOrange: <ControlProductoTerminadoIcon status={true}/>,
        iconWhite: <ControlProductoTerminadoIcon status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
    {
        id: 5,
        title: 'Control Mercado Interno',
        submenu:[
            {
                "title":"Reporte Comercial",
                "path":"/comercial/planillas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlMercadoInternoIcon status={true}/>,
        iconWhite: <ControlMercadoInternoIcon status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
    {
        id: 6,
        title: 'Control Cajas Testigo',
        submenu:[
            {
                "title":"Reporte Cajas Testigo",
                "path":"/testigos/planillas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlCajasTestigoIcon status={true}/>,
        iconWhite: <ControlCajasTestigoIcon status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
    {
        id: 7,
        title: 'Consolidación Contenedores',
        submenu:[
            {
                "title":"Buscar Inspecciones",
                "path":"/contenedores/inspeccion",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Buscar Contenedores",
                "path":"/contenedores/contenedor",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Buscar Camión",
                "path":"/contenedores/camion",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Excel Temperaturas",
                "path":"/contenedores/temperaturas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Base de datos Inspecciones",
                "path":"/contenedores/bdinspecciones",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlContenedores status={true}/>,
        iconWhite: <ControlContenedores status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
    {
        id: 8,
        title: 'Control Destino',
        submenu:[
            {
                "title":"Planilla Inspección Destino",
                "path":"/destino/despallet",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Informe Diario",
                "path":"/destino/diario",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
            {
                "title":"Base Datos Cajas",
                "path":"/destino/cajas",
                "access": [UserTypes.ADMIN, UserTypes.CLIENT],
            },
        ],
        path: '#',
        iconOrange: <ControlDestino status={true}/>,
        iconWhite: <ControlDestino status={false}/>,
        cName: 'nav-text',
        status: true,
        access: [UserTypes.ADMIN, UserTypes.INSPECTOR, UserTypes.CLIENT],
    },
]
