import Layout from "../../../../components/Layout";
import ExportadoraFilter from "../../../../components/Filters/Exportadora";
import EspecieFilter from "../../../../components/Filters/Especie";
import NormaFilter from "../../../../components/Filters/Norma";
import LineaFilter from "../../../../components/Filters/Linea";
import PackingFilter from "../../../../components/Filters/Packing";
import DateFilter from "../../../../components/Filters/Date";
import styled from "styled-components";

const InformeDiario = () => {


    return (
        <Layout>
            <div>
                <StyledH1>Reporte Informe Diario</StyledH1>
            </div>
            <div>
                <ExportadoraFilter/>
                <EspecieFilter/>
                <NormaFilter/>
                <LineaFilter/>
                <PackingFilter/>
                <DateFilter/>
            </div>
        </Layout>
    )
}

const StyledH1 = styled.h1`
    color: #64766A;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
`;


export default InformeDiario;