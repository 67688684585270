import axios from "../api/axios";

const URL = "/caja_detalles/";

export async function getCajaDetallesService({ token, data }) {
  // console.log("getCajaDetallesService", data);
  const newURL = "/cajas/get-cajas-by-inspeccion-planilla/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      inspeccion_planilla: data.inspeccion_planilla,
    },
  };
  // Se realiza la petición
  const response = await axios.get(newURL, config);
  return response;
}

export async function createCajaDetalleService({ token, data }) {
  // generate multiple post requests
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axios.post(
    URL,
    {
      caja: data.caja,
      planilla_defecto: parseInt(data.planilla_defecto),
      valor: data.valor,
      valor_calculado: data.valor,
      cumple: 1,
      sincronizacion: 1,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}

export async function updateCajaDetalleService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("updateCajaDetalleService", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.put(
    URL + data.id + "/",
    {
      id: data.id,
      planilla_defecto: parseInt(data.planilla_defecto),
      valor: data.valor,
      valor_calculado: data.valor_calculado,
    },
    config
  );
  // Se retorna la respuesta
  return response;
}
