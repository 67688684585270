import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getPlanillasService, getPlanillaEspecieService, getPlanillasClienteService, createPlanillaService, updatePlanillaService, createPlanillaExportadoraService } from "../../services/planillas";

export default function useProductores() {

  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getPlanillas = useCallback(({ rowsPerPage, page }) => {
    const data = {
      cliente: client.id,
    };
    return getPlanillasService({ token, rowsPerPage, page, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getPlanillaEspecie = useCallback(( dataInput ) => {
    const data = {
      cliente: client.id,
      especie: dataInput.especie.id,
    };
    return getPlanillaEspecieService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getClientPlanillas = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getPlanillasClienteService({ token, data })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    }
    ).catch((err) => {
      return err.response;
    }
    );
  }, [token, client]);

  const createPlanilla = useCallback((data) => {
    data.cliente = client.id;
    return createPlanillaService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createPlanillaExportadora = useCallback((data) => {
    data[0].cliente = client.id;
    return createPlanillaExportadoraService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const updatePlanilla = useCallback((data) => {
    return updatePlanillaService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  return {
    getPlanillas,
    getPlanillaEspecie,
    getClientPlanillas,
    createPlanilla,
    createPlanillaExportadora,
    updatePlanilla,
  };
}
