import React from "react";
import { StyledButtonContainer, StyledSelect, StyledButtonForm } from "./ResolucionStyles";

function ResolucionForm({ resoluciones, handleResolucionSelection, handleResolucionSave }) {
  return (
    <StyledButtonContainer>
      <form onSubmit={handleResolucionSave}>
        <StyledSelect name="resolucion" onChange={handleResolucionSelection}>
          <option value="">Seleccione una resolución</option>
          {resoluciones.map((res) => (
            <option value={res.id} key={res.id}>
              {res.nombre}
            </option>
          ))}
        </StyledSelect>
        <StyledButtonForm type="submit">Guardar</StyledButtonForm>
      </form>
    </StyledButtonContainer>
  );
}

export default ResolucionForm;