import axios from "../api/axios";

const URL = "/operacion_tipos/";

// Export async function users

export async function getOperacionTiposService({ token }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function createOperacionTipoService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service Data", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URL, data, config);
  // Se retorna la respuesta
  return response;
}
