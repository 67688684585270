import styled from 'styled-components'

const Boton = (props) => {
    return (
        <>
            <StyledBoton onClick={props.onClick}>
                {props.texto}
            </StyledBoton>
        </>
    )
}

const StyledBoton = styled.button`
    background-color: #FF9100;
    width: 177px;
    height: 48px;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    margin: 20px;

    &:hover {
        background-color: #D88219;
    }

    &:active {
        background-color: green;
    }

    &:disabled {
        background-color: rgba(255, 168, 0, 0.32);
    }
`

export default Boton;

