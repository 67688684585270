import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getProductoresService,
  createProductorService,
  updateProductorService,
  createProductorExportadoraService,
  createProductorEspecieService,
  getProductoresNoPaginationService,
  getProductoresByEspecieExportadoraService,
  getEspeciesProductorNoPaginationService,
} from "../../services/productores";

import { toast } from "react-toastify";

export default function useProductores() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getProductores = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getProductoresService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getProductoresNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getProductoresNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getProductoresByEspecieExportadora = useCallback( ({ especie, exportadora }) => {
    const data = {
      especie,
      exportadora,
      cliente: client.id,
    };
    return getProductoresByEspecieExportadoraService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getEspeciesProductorNoPagination = useCallback((productor) => {
    const data = {
      cliente: client.id,
      productor : productor.id,
    };
    return getEspeciesProductorNoPaginationService({ token, data, productor })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createProductor = useCallback(
    (data) => {
      data.cliente = client.id;
      return createProductorService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Productor creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el productor");
          return err.response;
        });
    },
    [token, client]
  );

  const createProductorExportadora = useCallback(
    (data) => {
      data[0].cliente = client.id;
      return createProductorExportadoraService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Productor exportadora creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el productor exportadora");
          return err.response;
        });
    },
    [token, client]
  );

  const createProductorEspecie = useCallback(
    (data) => {
      data[0].cliente = client.id;
      return createProductorEspecieService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Productor especie creado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al crear el productor especie");
          return err.response;
        });
    },
    [token, client]
  );

  const updateProductor = useCallback(
    (data) => {
      return updateProductorService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Productor actualizado correctamente");
            return res.data;
          }
        })
        .catch((err) => {
          toast.error("Error al actualizar el productor");
          return err.response;
        });
    },
    [token]
  );

  return {
    getProductores,
    createProductor,
    updateProductor,
    createProductorExportadora,
    createProductorEspecie,
    getProductoresNoPagination,
    getProductoresByEspecieExportadora,
    getEspeciesProductorNoPagination,
  };
}
