import React from "react";
import styled from "styled-components";

export const RowsPerPage = ({handleRowsPerPage, rowsPerPage}) => {
  return (
    <>
      <StyledSelect onChange={handleRowsPerPage} value={rowsPerPage} id="page-size">
        <StyledOptions value="10">10</StyledOptions>
        <StyledOptions value="25">25</StyledOptions>
        <StyledOptions value="50">50</StyledOptions>
        <StyledOptions value="100">100</StyledOptions>
      </StyledSelect>
    </>
  );
};

export const Pagination = ({previous, handlePreviousPage, page, next, handleNextPage}) => {
  return (
    <StyledContainer>
      <StyledFirstPage>
        {"<<"}
      </StyledFirstPage>
      {previous !== 0 ? (
        <StyledPaginationButtonsPrevious onClick={handlePreviousPage}>
          {"<"}
        </StyledPaginationButtonsPrevious>
      ) : null}
      {/* Actual Page html */}
      <StyledPaginate>{page}</StyledPaginate>
      {next !== 0 ? (
        <StyledPaginationButtonsNext onClick={handleNextPage}>
          {">"}
        </StyledPaginationButtonsNext>
      ) : null}
      <StyledLastPage>
        {">>"}
      </StyledLastPage>
    </StyledContainer>
  );
};

const StyledSelect = styled.select`
  border: 1.5px solid #D88219;
  border-radius: 5px;
  width: 76px;
  height: 44px;
  left: 406px;
  margin-bottom: 10px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6C6C6C;

  opacity: 0.5;
`;

const StyledOptions = styled.option`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6C6C6C;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 245px;
  height: 40px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledPaginationButtonsPrevious = styled.button`
  height: 40px;
  gap: 8px;
  border: 1px solid #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF9100;
  &:hover {
    cursor: pointer;
  }
`;

const StyledPaginationButtonsNext = styled.button`
  height: 40px;
  gap: 8px;
  border: 1px solid #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF9100;
  &:hover {
    cursor: pointer;
  }
`;

const StyledLastPage = styled.button`
  height: 40px;
  gap: 8px;
  border: 1px solid #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0px 8px 8px 0px;
  color: #FF9100;
  &:hover {
    cursor: pointer;
  }
`;

const StyledFirstPage = styled.button`
  height: 40px;
  gap: 8px;
  border: 1px solid #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px 0px 0px 8px;
  color: #FF9100;
  &:hover {
    cursor: pointer;
  }
`;

const StyledPaginate = styled.span`
  height: 38px;
  width: 85px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  letter-spacing: -0.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
`;
