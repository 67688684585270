import React, { useEffect, useState } from "react";
import ModalForm from "../../../../components/Modal";
// import SelectMultiple from "../../../../components/SelectMultiple";
import useExportadoras from "../../../../hooks/api/useExportadoras";
import styled from "styled-components";
import useProductores from "../../../../hooks/api/useProductores";

const AddExportadoraForm = ({ dataInput, handleCancel }) => {
  const [exportadorasCliente, setExportadorasCliente] = useState([]);
  const [exportadoraChecked, setExportadoraChecked] = useState([]);

  const { getExportadorasNoPagination, getExportadorasProductorNoPagination } =
    useExportadoras();
  const { createProductorExportadora } = useProductores();

  useEffect(() => {
    getExportadorasNoPagination().then((res) => {
      setExportadorasCliente(res.results);
    });
    getExportadorasProductorNoPagination(dataInput).then(({data}) => {
      setExportadoraChecked(data.map((item) => item.id.toString()));
    });
  }, [
    getExportadorasProductorNoPagination,
    dataInput,
    getExportadorasNoPagination,
  ]);

  const handleValue = ({ target: { name, checked } }) => {
    if (checked) {
      setExportadoraChecked([...exportadoraChecked, name]);
    } else {
      setExportadoraChecked(exportadoraChecked.filter((item) => item !== name));
    }
  };

  const handleSaveExportadora = () => {
    let data = [];
    data.push({ productor: dataInput, exportadoras: exportadoraChecked });
    createProductorExportadora(data).then((res) => {
      console.log("Response: ", res);
      handleCancel();
    });
  };

  return (
    <ModalForm
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={true}
      handleSave={handleSaveExportadora}
      title={"Activar Exportadora a " + dataInput.nombre}
    >
      <StyledCheckboxList>
        {exportadorasCliente.map((exportadora) => (
          <StyledCheckboxItem key={exportadora.id}>
            <StyledCheckbox
              type="checkbox"
              name={exportadora.id}
              checked={exportadoraChecked.includes(exportadora.id.toString())}
              onChange={handleValue}
            />
            <StyledLabel>{exportadora.nombre}</StyledLabel>
          </StyledCheckboxItem>
        ))}
      </StyledCheckboxList>
    </ModalForm>
  );
};

const StyledCheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 20px;
  width: 100%;
`;

const StyledCheckboxItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ff9100;
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px 20px;
`;

const StyledCheckbox = styled.input`
  margin-right: 10px;
`;

const StyledLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export default AddExportadoraForm;
