import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import MenuCloseIcon from "../../assets/icons/Generales/menucloseicon";
import MenuOpenIcon from "../../assets/icons/Generales/menuopenicon";
import styled from "styled-components";

import { SidebarData } from "./sidebarData";

import SubMenuItems from "./subMenuItems";

import {
  StyledNavMenu,
  StyledNavUl,
  StyledNavLi,
  StyledConteinerOverflow,
} from "./styledSidebar";

import useUser from "../../hooks/api/useUser";

import ThemeContext from "../../context/ThemeProvider";
import NavBarContext from "../../context/NavBarProvider";
import AuthContext from "../../context/AuthProvider";
import { NAVBAR } from "../../context/Actions";

const Sidebar = (props) => {
  // const [sidebar, setSidebar] = useState(false);
  // const showSidebar = () => setSidebar(!sidebar);
  //console.log(sidebar);
  const navigate = useNavigate();
  const { logout } = useUser();

  const { stateTheme  } = useContext(ThemeContext);
  const { stateNavBar, dispatch } = useContext(NavBarContext);

  const { state } = useContext(AuthContext);

  const { user } = state;

  var user_type = user.user_type.id;

  function showSidebar () {
    dispatch({type: NAVBAR.SET_NAVBAR, payload: {navbar: !stateNavBar.navbar}});
  }

  const { navbar } = stateNavBar;

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <StyledNavMenu active={navbar} theme={stateTheme}>
        <StyledConteinerOverflow>
          <StyledNavUl>
            <StyledNavLi>
              <StyledLink to="#" onClick={showSidebar}>
                {navbar === true ? (
                  <MenuOpenIcon theme={stateTheme} />
                ) : (
                  <MenuCloseIcon theme={stateTheme} />
                )}
              </StyledLink>
            </StyledNavLi>

            {SidebarData.map((item, index) => {
              if(item.access.includes(user_type) && item.submenu.some(subitem => subitem.access.includes(user_type))){
                return (
                  <SubMenuItems
                    key={index}
                    item={item}
                    sidebar={navbar}
                  />
                );
              }
              return null;
            })}
            {navbar === true ? (
              <ButtonLogout theme={stateTheme} onClick={handleLogout} texto="Logout">
                Logout
              </ButtonLogout>
            ) : null}
          </StyledNavUl>
        </StyledConteinerOverflow>
      </StyledNavMenu>
    </>
  );
};

const ButtonLogout = styled.div`
  background-color: ${(props) => props.theme.theme.primary.color};
  width: 70%;
  height: 48px;
  color: ${(props) => props.theme.theme.primary.text};
  font-size: 12px;
  border-radius: 5px;
  border: none;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 42px;
  height: 42px;
  background: none;
  transition: 350ms;
  border-radius: 100px;
  background-color: #5a6c60;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Sidebar;
