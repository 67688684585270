let navbar = false || JSON.parse(localStorage.getItem("navbar"));


export const initialState = {
    navbar: navbar,
};

export const navBarReducer = (stateNavBar, action) => {
  switch (action.type) {
    case "SET_NAVBAR":
      localStorage.setItem("navbar", action.payload.navbar);
      return {
        ...stateNavBar,
        navbar: action.payload.navbar,
      };
    default:
      return stateNavBar;
  }
};
