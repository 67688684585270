import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
    getResolucionesService,
    createResolucionService,
    updateResolucionService,
    getResolucionesNoPaginationService,
} from "../../services/resoluciones";

export default function useResoluciones() {
    const { state } = useContext(AuthContext);
    const { token, client } = state;

    const getResoluciones = useCallback(
        ({ rowsPerPage, page }) => {
            const data = {
                cliente: client.id,
            };
            return getResolucionesService({ token, rowsPerPage, page, data })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token, client]
    );

    const getResolucionesNoPagination = useCallback(() => {
        const data = {
            cliente: client.id,
        };
        return getResolucionesNoPaginationService({ token, data })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
            })
            .catch((err) => {
                return err.response;
            });
    }, [token, client]);

    const createResolucion = useCallback(
        (data) => {
            return createResolucionService({ token, data })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    const updateResolucion = useCallback(
        (data) => {
            return updateResolucionService({ token, data })
                .then((res) => {
                    if (res.status === 201) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        },
        [token]
    );

    return {
        getResoluciones,
        getResolucionesNoPagination,
        createResolucion,
        updateResolucion,
    };
}