import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getCalibresService,
  createCalibreService,
  updateCalibreService,
  getCalibresNoPaginationService,
  getCalibresByEspecieExportadoraService,
} from "../../services/calibres";

export default function useCalibres() {
  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getCalibres = useCallback(
    ({ rowsPerPage, page }) => {
      const data = {
        cliente: client.id,
      };
      return getCalibresService({ token, rowsPerPage, page, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const getCalibresNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getCalibresNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getCalibresByEspecieExportadora = useCallback( ({ especie, exportadora }) => {
    const data = {
      especie,
      exportadora,
      cliente: client.id,
    };
    return getCalibresByEspecieExportadoraService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createCalibre = useCallback(
    (data) => {
      data.cliente = client.id;
      return createCalibreService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token, client]
  );

  const updateCalibre = useCallback(
    (data) => {
      return updateCalibreService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            console.log("Data Hook : ", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getCalibres,
    createCalibre,
    updateCalibre,
    getCalibresNoPagination,
    getCalibresByEspecieExportadora,
  };
}
