import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import { getParametrosService, createParametroService, updateParametroService, getParametrosNoPaginationService, getParametrosPlanillasNoPaginationService, createPlanillaParametroService } from "../../services/parametros";

export default function useProductores() {

  const { state } = useContext(AuthContext);
  const { token, client } = state;

  const getParametros = useCallback(({ rowsPerPage, page }) => {
    const data = {
      cliente: client.id,
    };
    return getParametrosService({ token, rowsPerPage, page, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getParametrosNoPagination = useCallback(() => {
    const data = {
      cliente: client.id,
    };
    return getParametrosNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const getParametrosPlanillasNoPagination = useCallback((data) => {
    data.cliente = client.id;
    return getParametrosPlanillasNoPaginationService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createParametro = useCallback((data) => {
    data.cliente = client.id;
    return createParametroService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const createPlanillaParametro = useCallback((data) => {
    data.cliente = client.id;
    return createPlanillaParametroService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token, client]);

  const updateParametro = useCallback((data) => {
    return updateParametroService({ token, data })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  return {
    getParametros,
    getParametrosNoPagination,
    getParametrosPlanillasNoPagination,
    createParametro,
    createPlanillaParametro,
    updateParametro,
  };
}
