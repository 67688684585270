import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";
import useNormas from "../../../../hooks/api/useNormas";
import usePagination from "../../../../hooks/helpers/usePagination";
import useExportadoras from "../../../../hooks/api/useExportadoras";
import useEspecies from "../../../../hooks/api/useEspecies";
import useClasificaciones from "../../../../hooks/api/useClasificaciones";

import InputDecimal from "../../../../components/Cajas/InputDecimal";

const ClasificacionForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  const [normas, setNormas] = useState([]);
  const [exportadoras, setExportadoras] = useState([]);
  const [especies, setEspecies] = useState([]);
  const [clasificaciones, setClasificaciones] = useState([]);

  const { page, rowsPerPage } = usePagination();

  const { getNormas } = useNormas();
  const { getExportadorasNoPagination } = useExportadoras();
  const { getAllEspeciesNoPagination } = useEspecies();
  const { getClasificacionesNoPagination } = useClasificaciones();

  useEffect(() => {
    getNormas({ rowsPerPage, page }).then((res) => {
      setNormas(res.results);
    });
    getExportadorasNoPagination({ rowsPerPage, page }).then((res) => {
      setExportadoras(res.results);
    });
    getAllEspeciesNoPagination({ rowsPerPage, page }).then((res) => {
      setEspecies(res.results);
    });
    getClasificacionesNoPagination({ rowsPerPage, page }).then((res) => {
      setClasificaciones(res.results);
    });
  }, [
    dataInput,
    getNormas,
    page,
    rowsPerPage,
    getExportadorasNoPagination,
    getAllEspeciesNoPagination,
    getClasificacionesNoPagination,
  ]);

  const handleValue = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Embalaje"
    >
      <StyledInputContainer>
        <StyledInputLabel>Codigo</StyledInputLabel>
        <StyledTextInputs
          type="text"
          name="codigo"
          placeholder="Codigo"
          defaultValue={dataInput.codigo !== "" ? dataInput.codigo : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Nombre</StyledInputLabel>
        <StyledTextInputs
          type="text"
          name="nombre"
          placeholder="Nombre"
          defaultValue={dataInput.nombre !== "" ? dataInput.nombre : null}
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Norma</StyledInputLabel>
        <StyledSelected
          name="norma"
          value={dataInput.norma !== undefined ? dataInput.norma.id : null}
          onChange={handleValue}
        >
          <StyledOption value="0">Seleccione Norma</StyledOption>
          {normas.map((norma, index) => (
            <StyledOption key={index} value={norma.id}>
              {norma.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Exportadora</StyledInputLabel>
        <StyledSelected
          name="exportadora"
          value={
            dataInput.exportadora !== undefined ? dataInput.exportadora.id : null
          }
          onChange={handleValue}
        >
          <StyledOption value="0">Seleccione Exportadora</StyledOption>
          {exportadoras.map((exportadora, index) => (
            <StyledOption key={index} value={exportadora.id}>
              {exportadora.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Especie</StyledInputLabel>
        <StyledSelected
          name="especie"
          value={dataInput.especie !== undefined ? dataInput.especie.id : null}
          onChange={handleValue}
        >
          <StyledOption value="0">Seleccione Especie</StyledOption>
          {especies.map((especie, index) => (
            <StyledOption key={index} value={especie.id}>
              {especie.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Clasificacion</StyledInputLabel>
        <StyledSelected
          name="clasificacion"
          value={
            dataInput.clasificacion !== undefined
              ? dataInput.clasificacion.id
              : null
          }
          onChange={handleValue}
        >
          <StyledOption value="0">Seleccione Clasificacion</StyledOption>
          {clasificaciones.map((clasificacion, index) => (
            <StyledOption key={index} value={clasificacion.id}>
              {clasificacion.nombre}
            </StyledOption>
          ))}
        </StyledSelected>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Peso Neto Minimo</StyledInputLabel>
        <InputDecimal
          width={"400px"}
          step={0.001}
          type="number"
          name="peso_neto_minimo"
          placeholder="Peso Neto Minimo"
          defaultValue={
            dataInput.peso_neto_minimo !== "" ? dataInput.peso_neto_minimo : null
          }
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Peso Neto Medio</StyledInputLabel>
        <InputDecimal
          width={"400px"}
          step={0.001}
          type="number"
          name="peso_neto_medio"
          placeholder="Peso Neto Medio"
          defaultValue={
            dataInput.peso_neto_medio !== "" ? dataInput.peso_neto_medio : null
          }
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Peso Neto Maximo</StyledInputLabel>
        <InputDecimal
          width={"400px"}
          step={0.001}
          type="number"
          name="peso_neto_maximo"
          placeholder="Peso Neto Maximo"
          defaultValue={
            dataInput.peso_neto_maximo !== "" ? dataInput.peso_neto_maximo : null
          }
          onChange={handleValue}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledInputLabel>Estado</StyledInputLabel>
        <StyledSelected
          name="estado"
          defaultValue={dataInput.estado !== undefined ? dataInput.estado : true}
          onChange={handleValue}
        >
          <StyledOption value="true">Activo</StyledOption>
          <StyledOption value="false">Inactivo</StyledOption>
        </StyledSelected>
      </StyledInputContainer>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
`;

const StyledInputLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 20px;
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default ClasificacionForm;
