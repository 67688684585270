import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import {
  clientsService,
  createClientService,
  deleteClientService,
  updateClientService,
} from "../../services/clients";

export default function useClients() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getClients = useCallback(
    ({ rowsPerPage, page }) => {
      return clientsService({ token, rowsPerPage, page })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const createClient = useCallback(
    (data) => {
      return createClientService({ token, data })
        .then((res) => {
          if (res.status === 201) {
            console.log("Hook: ", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const updateClient = useCallback(
    (data) => {
      return updateClientService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const deleteClient = useCallback(
    (id) => {
      console.log(id);
      return deleteClientService({ token, id })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          console.log(id);
          console.log(err);
          return err.response;
        });
    },
    [token]
  );

  return {
    getClients,
    createClient,
    deleteClient,
    updateClient,
  };
}
