import {Light, Dark, Red} from '../themes'
import { THEME } from "./Actions";

let theme = {}

if ( JSON.parse(localStorage.getItem('theme')) === "Dark" ) {
  theme = Dark;
}else if ( JSON.parse(localStorage.getItem('theme')) === "Red" ) {
  theme = Red;
}else{
  theme = Light;
}

export const initialState = {
    theme,
};

export const themeReducer = (stateTheme, action) => {
  switch (action.type) {
    case THEME.SET_THEME:
      localStorage.setItem("theme", JSON.stringify(action.payload.name));
      return {
        ...stateTheme,
        theme: action.payload.theme,
      };
    default:
      return stateTheme;
  }
};