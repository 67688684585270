import axios from "../api/axios";

const URL = "/cajas/";

export async function getCajasService({ token, data }) {
  console.log("Service Cajas: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      inspeccion_planilla: data.inspeccion_planilla,
      clasificacion:
        data.filters["Clasificación"] === "0"
          ? null
          : data.filters["Clasificación"],
      embalaje: data.filters.Embalaje === "0" ? null : data.filters.Embalaje,
      variedad: data.filters.Variedad === "0" ? null : data.filters.Variedad,
      productor: data.filters.Productor === "0" ? null : data.filters.Productor,
      calibre: data.filters.Calibre === "0" ? null : data.filters.Calibre,
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  console.log("Service Cajas: ", response);
  return response;
}

export async function getCajasByClienteService({ token, data }) {
  const URL2 = "get-cajas-by-cliente/";
  const NEWURL = URL + URL2;
  console.log("Service Cajas: ", data);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      exportadora: data.exportadora === "" ? undefined : data.exportadora,
      especie: data.especie === "" ? undefined : data.especie,
      zona: data.zona === "" ? undefined : data.zona,
      calibre: data.calibre === "" ? undefined : data.calibre,
      productor_real: data.productor_real === "" ? undefined : data.productor_real,
      productor_rotulado: data.productor_rotulado === "" ? undefined : data.productor_rotulado,
      variedad_real: data.variedad_real === "" ? undefined : data.variedad_real,
      embalaje: data.embalaje === "" ? undefined : data.embalaje,
      turno: data.turno === "" ? undefined : data.turno,
      fecha_desde: data.fecha_desde === "" ? undefined : data.fecha_desde,
      fecha_hasta: data.fecha_hasta === "" ? undefined : data.fecha_hasta,
    },
  };

  // Se realiza la petición
  const response = await axios.get(NEWURL, config);
  // Se retorna la respuesta
  console.log("Service Cajas: ", response);
  return response;
}

export function getNumeroCajaService({ token, data }) {
  // console.log("Service Cajas: ", data);
  const newUrl = URL + "get-numero-caja/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      inspeccion_planilla: data.inspeccion_planilla,
    },
  };
  // Se realiza la petición
  const response = axios.get(newUrl, config);
  // Se retorna la respuesta
  return response;
}

export async function getCajasHeadersService({ token }) {
  const URL2 = "get-columns/";
  const NEWURL = URL + URL2;
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.get(NEWURL, config);
  // Se retorna la respuesta
  return response;
}

export async function createCajaService({
  token,
  saveCaja,
  valoresCaja,
  validation,
  observacion,
  planillaType,
}) {
  console.log("Service Caja Create: ", saveCaja);
  console.log("Service Caja Create: ", valoresCaja);
  console.log("Service Caja Create: ", validation);
  console.log("Service Caja Create: ", observacion);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      planilla_type: planillaType,
    },
  };
  saveCaja.observacion = observacion;
  saveCaja.caja_detalles = valoresCaja;
  saveCaja.validation = validation;

  console.log("Service Caja Create Data: ", saveCaja);

  // Se realiza la petición
  const response = await axios.post(URL, saveCaja, config);
  console.log("Service Caja Create Response: ", response);
  // Se retorna la respuesta
  return response;
}

export async function updateCajaService({
  token,
  saveCaja,
  valoresCaja,
  validation,
  observacion,
}) {
  console.log("Service Caja Create: ", saveCaja);
  console.log("Service Caja Create: ", valoresCaja);
  console.log("Service Caja Create: ", validation);
  console.log("Service Caja Create: ", observacion);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  saveCaja.observacion = observacion;
  saveCaja.caja_detalles = valoresCaja;
  saveCaja.validation = validation;

  console.log("Service Caja Update Data: ", saveCaja);
  // Se realiza la petición
  const response = await axios.put(URL + saveCaja.id + "/", saveCaja, config);
  // Se retorna la respuesta
  console.log("Service Caja Update Response: ", response);
  return response;
}

export async function deleteCajaService({ token, id }) {
  // Se crea el objeto de configuración para la petición
  console.log("Service Caja Delete ID: ", id);
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.delete(URL + id + "/", config);
  console.log("Service Caja Delete Response: ", response);
  // Se retorna la respuesta
  return response;
}

export async function updateCumplePackingService({ token, caja }) {
  const NEWURL = URL + "update-cumple-packing/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  console.log("Service Caja Update Data: ", caja);
  const response = await axios.put(
    NEWURL,
    {
      cajas: caja,
    },
    config
  );
  console.log("Service Caja Update Response: ", response);
  return response;
}

export async function updateResolucionCajaService({
  token,
  id,
  resolucion,
  resultado_caja,
  cajas_hijas,
  cumplePacking,
}) {
  const NEWURL = URL + "update-resolucion-caja/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  console.log("Service Caja Update Data: ", id);
  console.log("Service Caja Update Data: ", resolucion);
  console.log("Service Caja Update Data: ", resultado_caja);
  console.log("Service Caja Update Data: ", cajas_hijas);
  console.log("Service Caja Update Data: ", cumplePacking);
  const response = await axios.put(
    NEWURL,
    {
      caja: id,
      resolucion: parseInt(resolucion),
      resultado_caja: resultado_caja,
      cumple_packing: cumplePacking,
      cajas_hijas: cajas_hijas,
    },
    config
  );
  console.log("Service Caja Update Response: ", response);
  return response;
}
