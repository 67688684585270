import React from "react";
import styled from "styled-components";
import ModalForm from "../../../../components/Modal";

const EspecieForm = ({
  setDataInput,
  handleSave,
  dataInput,
  handleCancel,
  create,
  handleEdit,
  edit,
}) => {
  const handleValue = (e) => {
    if (dataInput.estado === undefined) {
      setDataInput({
        ...dataInput,
        estado: true,
        [e.target.name]: e.target.value
      });
    } else {
      setDataInput({
        ...dataInput,
        [e.target.name]: e.target.value
      });
    }
    console.log("dataInput", dataInput);
  };
  return (
    <ModalForm
      handleSave={handleSave}
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={create}
      edit={edit}
      handleEdit={handleEdit}
      title="Cliente"
    >
      <StyledTextInputs
        type="text"
        placeholder="Nombre"
        defaultValue={dataInput.nombre ? dataInput.nombre : null}
        name="nombre"
        onChange={handleValue}
      />
      <StyledSelected
        name="estado"
        defaultValue={dataInput.estado !== undefined ? dataInput.estado : null}
        onChange={handleValue}
      >
        <StyledOption value="true">Activo</StyledOption>
        <StyledOption value="false">Inactivo</StyledOption>
      </StyledSelected>
    </ModalForm>
  );
};

const StyledSelected = styled.select`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

const StyledOption = styled.option`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  opacity: 0.5;

  background-color: #ffffff;

  &:checked {
    background-color: #ff9100;
    color: #ffffff;
  }
`;

const StyledTextInputs = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1.5px solid #ff9100;
  padding: 0 15px;
  margin: 5px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  // On click, the input border turns orange
  &:focus {
    outline: none;
    border: 1.5px solid #d88219;
  }
`;

export default EspecieForm;
