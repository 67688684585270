import Layout from "../../../../components/Layout";
import useEspecies from "../../../../hooks/api/useEspecies";
import { useEffect, useState, useContext } from "react";
import usePagination from "../../../../hooks/helpers/usePagination";
import Mantainer from "../../../../components/Layout/mantainer";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import EspecieForm from "./form";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import AuthContext from "../../../../context/AuthProvider";
import AddEspecieForm from "./addEspecieForm";
import { toast } from "react-toastify";

const Especies = () => {
  const [activateFormEspecie, setActivateFormEspecie] = useState(false);
  const { state } = useContext(AuthContext);

  const { client } = state;

  const {
    create,
    edit,
    dataInput,
    handleEditForm,
    handleCancel,
    handleClearForm,
    setDataInput,
    setCreate,
    setEdit,
  } = useModalHelper();

  const {
    getEspecies,
    createEspecie,
    updateEspecie,
    getClientEspecies,
    activateEspecie,
  } = useEspecies();

  const {
    page,
    rowsPerPage,
    handleRowsPerPage,
    handleNextPage,
    handlePreviousPage,
    rowData,
    setRowData,
    setTotalRows,
    next,
    setNext,
    previous,
    setPrevious,
  } = usePagination();

  const validateEstado = (params) => {
    if (params.data.estado === true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre" },
    { field: "estado", headerName: "Estado", valueGetter: validateEstado },
  ]);

  useEffect(() => {
    client.id !== 1
      ? getEspecies({ rowsPerPage, page }).then((res) => {
          setRowData(res.results);
          setTotalRows(res.count);
          setNext(res.links.next_page);
          setPrevious(res.links.previous_page);
        })
      : getClientEspecies({ rowsPerPage, page }).then((res) => {
          setRowData(res.results);
          setTotalRows(res.count);
          setNext(res.links.next_page);
          setPrevious(res.links.previous_page);
        });
  }, [
    getEspecies,
    rowsPerPage,
    page,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    getClientEspecies,
    client,
  ]);

  const handleEdit = () => {
    setCreate(false);
    setEdit(true);
    updateEspecie(dataInput).then((res) => {
      const newData = rowData.map((item) => {
        if (item.id === res.data.id) {
          return res.data;
        } else {
          return item;
        }
      });
      setRowData(newData);
      toast.success("Especie actualizada correctamente");
    }).catch((err) => {
      toast.error("Error al actualizar especie");
    });
    setDataInput({});
    setEdit(false);
  };

  const handleSave = () => {
    setCreate(false);
    createEspecie(dataInput).then((res) => {
      setRowData([...rowData, res.data]);
      toast.success("Especie creada correctamente");
    }).catch((err) => {
      toast.error("Error al crear especie");
    });
    setEdit(false);
  };

  const handleSaveEspecies = () => {
    setActivateFormEspecie(false);
    activateEspecie(dataInput).then((res) => {
      setRowData([...rowData, res.data]);
      console.log("Page res :", res.data);
      toast.success("Especie activada correctamente");
    }).catch((err) => {
      toast.error("Error al activar especie");
    });
    setEdit(false);
  };

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
    console.log("value", selectedRows[0]);
  };

  const handleAddEspecie = () => {
    setActivateFormEspecie(true);
  };

  const handleCancelEspecie = () => {
    setActivateFormEspecie(false);
  };

  return (
    <Layout>
      <Mantainer
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
        title="Especies"
      >
        {client.id !== 1 ? (
          <div>
            <FormCrudButton text="Crear" onClick={handleClearForm} />
            <FormCrudButton text="Editar" onClick={handleEditForm} />
          </div>
        ) : (
          <FormCrudButton text="Especies" onClick={handleAddEspecie} />
        )}
        {/* <FormCrudButton text="Crear" onClick={handleClearForm} />
        <FormCrudButton text="Editar" onClick={handleEditForm} /> */}
      </Mantainer>
      {create === true ? (
        <EspecieForm
          setDataInput={setDataInput}
          handleSave={handleSave}
          dataInput={dataInput}
          handleCancel={handleCancel}
          create={create}
        />
      ) : null}
      {edit === true ? (
        <EspecieForm
          setDataInput={setDataInput}
          handleEdit={handleEdit}
          dataInput={dataInput}
          handleCancel={handleCancel}
          edit={edit}
        />
      ) : null}
      {activateFormEspecie === true ? (
        <AddEspecieForm
          setDataInput={setDataInput}
          dataInput={dataInput}
          handleCancel={handleCancelEspecie}
          handleSaveEspecies={handleSaveEspecies}
        />
      ) : null}
    </Layout>
  );
};

export default Especies;
