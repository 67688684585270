import { useCallback, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import { getCumpleService } from "../../services/cumple";

export default function useCumple() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getCumple = useCallback(
    () => {
      return getCumpleService({ token })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  return {
    getCumple,
  };
}
