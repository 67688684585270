import { useCallback, useContext } from "react";
import AuthContext from "../../context/AuthProvider";

import {
  getNormaDetallesService,
  getNormaDetallesNoPaginationService,
  createNormaDetallesService,
  updateNormaDetallesService,
  getNormaDetalleByEmbalajeService,
} from "../../services/normaDetalles";

export default function useNormaDetalles() {
  const { state } = useContext(AuthContext);
  const { token } = state;

  const getNormaDetalles = useCallback(
    ({ norma }) => {
      return getNormaDetallesService({ token, norma })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    [token]
  );

  const getNormaDetallesNoPagination = useCallback( ( norma ) => {
    return getNormaDetallesNoPaginationService({ token, norma })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  }, [token]);

  const getNormaDetalleByEmbalaje = useCallback( ( initialFilters ) => {
    return getNormaDetalleByEmbalajeService({ token, initialFilters })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  }, [token]);

  const createNormaDetalles = useCallback( ( data ) => {
    return createNormaDetallesService({ token, data })
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  }, [token]);

  const updateNormaDetalles = useCallback(
    ( data ) => {
      return updateNormaDetallesService({ token, data })
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    [token]
  );

  return {
    getNormaDetalles,
    getNormaDetallesNoPagination,
    createNormaDetalles,
    updateNormaDetalles,
    getNormaDetalleByEmbalaje,
  };
}
