import React from "react";
import Layout from "../../../components/Layout";
import CajasLayout from "../../../components/Layout/cajasLayout";

const CajasLote = () => {
  return (
    <Layout>
      <CajasLayout title="Cajas Lote" />
    </Layout>
  );
};

export default CajasLote;
