import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import ClientMantainer from "../../../../components/Layout/ClientMantainer";
import styled from "styled-components";
import { FormCrudButton } from "../../../../components/Boton/formCrudButton";
import useModalHelper from "../../../../hooks/helpers/useModalHelper";
import InputSelect from "../../../../components/Cajas/InputSelect";
import InputDate from "../../../../components/Cajas/InputDate";
import useExportadoras from "../../../../hooks/api/useExportadoras";
import useEspecies from "../../../../hooks/api/useEspecies";
import useZonas from "../../../../hooks/api/useZona";
import useCalibres from "../../../../hooks/api/useCalibres";
import useProductores from "../../../../hooks/api/useProductores";
import useVariedades from "../../../../hooks/api/useVariedad";
import useEmbalajes from "../../../../hooks/api/useEmbalajes";
import useTurnos from "../../../../hooks/api/useTurnos";
import usePagination from "../../../../hooks/helpers/usePagination";
import useCajas from "../../../../hooks/api/useCajas";

import FiltersContainer from "./FiltersContainer";

const ReportePallet = () => {
  const { dataInput, handleEditForm, handleClearForm, setDataInput } =
    useModalHelper();

  const {
    page,
    rowsPerPage,
    rowData,
    setRowData,
    setTotalRows,
    setNext,
    setPrevious,
    handleNextPage,
    handlePreviousPage,
    previous,
    next,
    handleRowsPerPage,
  } = usePagination();

  const [exportadora, setExportadora] = useState({});
  const [especie, setEspecie] = useState({});
  const [zona, setZona] = useState({});
  const [calibre, setCalibre] = useState({});
  const [productor, setProductor] = useState({});
  const [variedad, setVariedad] = useState({});
  const [embalaje, setEmbalaje] = useState({});
  const [turno, setTurno] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { getExportadoras } = useExportadoras();
  const { getEspecies } = useEspecies();
  const { getZonas } = useZonas();
  const { getCalibres } = useCalibres();
  const { getProductores } = useProductores();
  const { getVariedades } = useVariedades();
  const { getEmbalajes } = useEmbalajes();
  const { getTurnos } = useTurnos();
  const { getCajasByCliente } = useCajas();

  useEffect(() => {
    const fetchData = async (apiFunc, setter, params = null) => {
      const data = await apiFunc(params);
      console.log("data", data.results);
      setter(data);
    };

    // For API calls that require parameters
    const apisWithParams = [
      [getExportadoras, setExportadora],
      [getEspecies, setEspecie],
      [getZonas, setZona],
      [getCalibres, setCalibre],
      [getProductores, setProductor],
      [getVariedades, setVariedad],
      [getEmbalajes, setEmbalaje],
    ];

    // Execute all fetches that require parameters concurrently
    Promise.all(
      apisWithParams.map(([apiFunc, setter]) =>
        fetchData(apiFunc, setter, { rowsPerPage, page })
      )
    );

    // For API calls that do not require parameters
    fetchData(getTurnos, setTurno);
  }, [
    getExportadoras,
    getEspecies,
    getZonas,
    getCalibres,
    getProductores,
    getVariedades,
    getEmbalajes,
    getTurnos,
    page,
    rowsPerPage,
  ]);

  // useEffect(() => {
  //   getCajasByCliente({ rowsPerPage, page, dataInput }).then((res) => {
  //     console.log("res", res.data);
  //     setRowData(res.data);
  //   });
  // }, [
  //   getCajasByCliente,
  //   rowsPerPage,
  //   page,
  //   setRowData,
  //   setTotalRows,
  //   setNext,
  //   setPrevious,
  //   dataInput,
  // ]);

  const getCajaResult = (params) => {
    console.log("params", params.data.caja.resultado_caja.id);
    const color = params.data.caja.resultado_caja.id;
    console.log("color", color);
    let text = "";
    let colorText = "";
    let colorBackground = "";
    switch (color) {
      case 1:
        text = "Verde";
        colorText = "white";
        colorBackground = "green";
        break;
      case 2:
        text = "Amarillo";
        colorText = "black";
        colorBackground = "yellow";
        break;
      case 3:
        text = "Naranjo";
        colorText = "black";
        colorBackground = "orange";
        break;
      case 4:
        text = "Rojo";
        colorText = "white";
        colorBackground = "red";
        break;
      default:
        text = "";
        colorText = "white";
        colorBackground = "white";
        break;
    }
    return (
      <StyledCajaResult background={colorBackground} colorText={colorText}>
        {text}
      </StyledCajaResult>
    );
  };

  const getIrButton = (params) => {
    return (
      <FormCrudButton
        text="IR"
        onClick={() => {
          console.log("params", params.data);
          // history.push(`/client/control-producto-terminado/ir/${params.data.id}`);
        }}
      />
    );
  };

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      filter: true,
      width: 500,
      valueGetter: (params) => params.data.caja.id,
    },
    {
      field: "pallet",
      headerName: "Pallet",
      valueGetter: (params) => {
        // Use optional chaining to safely access nested properties
        const cajaDetalle = params.data?.caja?.caja_detalle;
        // Find the item in the array safely
        const item = cajaDetalle?.find(
          (item) => item.planilla_defecto.defecto.id === 18
        );
        // Return the value if item and the target index exist, otherwise return undefined
        return item?.valor;
      },
      width: 500,
      filter: true,
      // filterParams: {
      //   buttons: ["reset", "apply"],
      //   debounceMs: 200,
      //   caseSensitive: true,
      //   newRowsAction: "keep",
      //   suppressAndOrCondition: true,
      // },
    },
    {
      field: "cajas_hijas",
      headerName: "CM",
      width: 500,
      valueGetter: (params) => params.data.caja.cajas_hijas,
    },
    {
      field: "especie",
      headerName: "Especie",
      valueGetter: (params) => params.data.caja.especie.nombre,
      width: 500,
    },
    {
      field: "fecha_creacion",
      headerName: "Fecha",
      valueGetter: (params) => params.data.caja.fecha_creacion,
      width: 500,
      filter: "agDateColumnFilter",
    },
    {
      field: "exportadora.nombre",
      headerName: "Exportadora",
      valueGetter: (params) => params.data.caja.exportadora.nombre,
      width: 500,
    },
    {
      field: "packing",
      headerName: "Packing",
      valueGetter: (params) => params.data.caja.packing.nombre,
      width: 500,
    },
    {
      field: "productorReal",
      headerName: "Productor Real",
      valueGetter: (params) => params.data.caja.productor_real.nombre,
      width: 500,
    },
    {
      field: "productorRotulado",
      headerName: "Productor Rotulado",
      valueGetter: (params) => params.data.caja.productor.nombre,
      width: 500,
    },
    {
      field: "variedadReal",
      headerName: "Variedad Real",
      valueGetter: (params) => params.data.caja.variedad_real.nombre,
      width: 500,
    },
    {
      field: "variedadRotulado",
      headerName: "Variedad Rotulado",
      valueGetter: (params) => params.data.caja.variedad.nombre,
      width: 500,
    },
    {
      field: "clasificacion",
      headerName: "Clasificacion",
      valueGetter: (params) => params.data.caja.clasificacion.nombre,
      width: 500,
    },
    {
      field: "embalaje",
      headerName: "Embalaje",
      valueGetter: (params) => params.data.caja.embalaje.codigo,
      width: 500,
    },
    {
      field: "etiqueta",
      headerName: "Etiqueta",
      valueGetter: (params) => params.data.caja.etiqueta.nombre,
      width: 500,
    },
    {
      field: "calibre",
      headerName: "Calibre",
      valueGetter: (params) => params.data.caja.calibre.nombre,
      width: 500,
    },
    {
      field: "cantidad_cajas",
      headerName: "Cantidad Cajas",
      valueGetter: (params) => {
        // Use optional chaining to safely access nested properties
        const cajaDetalle = params.data?.caja?.caja_detalle;
        // Find the item in the array safely
        const item = cajaDetalle?.find(
          (item) => item.planilla_defecto.defecto.id === 19
        );
        // Return the value if item and the target index exist, otherwise return undefined
        return item?.valor;
      },
      width: 500,
    },
    {
      field: "resultado_caja",
      headerName: "Resultado",
      cellRenderer: (params) => getCajaResult(params),
      width: 500,
    },
    {
      field: "nota_calidad_pallet",
      headerName: "Nota Calidad Pallet",
      valueGetter: (params) => params.data.caja.nota_calidad_pallet,
      width: 500,
    },
    {
      field: "nota_condicion_pallet",
      headerName: "Nota Condicion Pallet",
      valueGetter: (params) => params.data.caja.nota_condicion_pallet,
    },
    {
      field: "numero_proceso",
      headerName: "Numero Proceso",
      valueGetter: (params) => params.data.caja.numero_proceso,
    },
    {
      field: "turno.nombre",
      headerName: "Turno",
      valueGetter: (params) => params.data.caja.turno.nombre,
    },
    {
      field: "inspeccion_tipo",
      headerName: "Inspeccion Tipo",
      valueGetter: (params) => params.data.caja.inspeccion_tipo.nombre,
    },
    {
      field: "planilla",
      headerName: "Planilla",
    },
    {
      field: "planilla_lote",
      headerName: "Planilla Lote",
    },
    {
      field: "estado",
      headerName: "Estado",
    },
    {
      field: "ir",
      headerName: "IR",
      // add button to go to the IR
      cellRenderer: (params) => getIrButton(params),
    },
  ]);

  const onSelectionChanged = (e) => {
    const selectedRows = e.api.getSelectedRows();
    setDataInput(selectedRows[0]);
  };

  const handleFilterForm = () => {
    setIsLoading(true);
    getCajasByCliente({ dataInput })
      .then((res) => {
        setRowData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <ClientMantainer
        title="Reporte Pallet"
        columnDefs={columnDefs}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        previous={previous}
        next={next}
        page={page}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleRowsPerPage={handleRowsPerPage}
        rowsPerPage={rowsPerPage}
      >
        <StyledContainer>
          <FiltersContainer
            exportadora={exportadora}
            especie={especie}
            zona={zona}
            calibre={calibre}
            productor={productor}
            variedad={variedad}
            embalaje={embalaje}
            turno={turno}
            dataInput={dataInput}
            setDataInput={setDataInput}
            handleClearForm={handleClearForm}
            handleFilterForm={handleFilterForm}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </StyledContainer>
      </ClientMantainer>
    </Layout>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const StyledCajaResult = styled.div`
  background: ${(props) => props.background};
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 6.25px;
  /* or 150% */

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.colorText};
`;

export default ReportePallet;
