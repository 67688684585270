import React, { useState } from "react";
import styled from "styled-components";
import useCajas from "../../hooks/api/useCajas";

function CumplePacking({ initialText, item, fetchData }) {
  const [ cumplePackingClick, setCumplePackingClick ] = useState(false);
  const { updateCumplePacking } = useCajas();
  const handleCumple = (e) => {
    let valor = e.target.value === 'true' ? true : false;
    let caja_madre = {
      id: item.caja.id,
      cumple_packing: valor
    }
    let cajas_hijas = item.caja.cajas_hijas.map((caja) => {
      return {
        id: caja.caja.id,
        cumple_packing: valor
      }
    });
    let cajas = [caja_madre, ...cajas_hijas];
    updateCumplePacking(cajas).finally(() => {
      setCumplePackingClick(!cumplePackingClick);
    });
    fetchData();
  };
  return (
    <StyledContainer>
      {cumplePackingClick === true ? (
        <StyledButtonContainer>
            <StyledButtonForm onClick={(e)=>handleCumple(e)} value={true}>Si</StyledButtonForm>
            <StyledButtonForm onClick={(e)=>handleCumple(e)} value={false}>No</StyledButtonForm>
        </StyledButtonContainer>
      ) : (
        <StyledButton onClick={() => setCumplePackingClick(!cumplePackingClick)}>{initialText}</StyledButton>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const StyledButtonForm = styled.button`
    background: #ff9100;
    border-radius: 5px;
    border: none;
    width: 40px;
    height: 35px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 6.25px;
    /* or 150% */
    text-align: center;
    /* Neutral / Lightest */
    color: #ffffff;
    white-space: nowrap;
`;

const StyledButton = styled.button`
  background: #ff9100;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 6.25px;
  /* or 150% */
  text-align: center;
  /* Neutral / Lightest */
  color: #ffffff;
  white-space: nowrap;
`;

export default CumplePacking;
