import React, { useEffect, useState } from "react";
import ModalForm from "../../../../components/Modal";
// import SelectMultiple from "../../../../components/SelectMultiple";
import useEspecies from "../../../../hooks/api/useEspecies";
import styled from "styled-components";
import useProductores from "../../../../hooks/api/useProductores";

const AddEspecieForm = ({ dataInput, handleCancel }) => {
  const [especiesCliente, setEspeciesCliente] = useState([]);
  const [especieChecked, setEspecieChecked] = useState([]);

  const { getAllEspeciesNoPagination } = useEspecies();
  const { getEspeciesProductorNoPagination, createProductorEspecie } = useProductores();

  useEffect(() => {
    getAllEspeciesNoPagination().then((res) => {
      setEspeciesCliente(res.results);
    });
    getEspeciesProductorNoPagination(dataInput).then((res) => {
      setEspecieChecked(res.data.map((item) => item.id.toString()));
    });
  }, [getEspeciesProductorNoPagination, dataInput, getAllEspeciesNoPagination]);

  // const handleValue = (e) => {
  //   setData({
  //     ...dataInput,
  //     productor: dataInput,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleSaveEspecie = () => {
  //   createProductorEspecie(data).then((res) => {
  //     setEspeciesTable([...especiesTable, res.data]);
  //   });
  // };

  const handleValue = ({ target: { name, checked } }) => {
    if (checked) {
      setEspecieChecked([...especieChecked, name]);
    } else {
      setEspecieChecked(especieChecked.filter((item) => item !== name));
    }
  };

  const handleSaveEspecie = () => {
    let data = [];
    data.push({ productor: dataInput, especies: especieChecked });
    createProductorEspecie(data).then((res) => {
      handleCancel();
    });
  }

  return (
    <ModalForm
      dataInput={dataInput}
      handleCancel={handleCancel}
      create={true}
      handleSave={handleSaveEspecie}
      title={"Activar Especie a " + dataInput.nombre}
    >
      {/* <SelectMultiple especies={especies} dataInput={dataInput} setDataInput={setDataInput} /> */}

      <StyledCheckboxList>
        {especiesCliente.map((especie) => (
          <StyledCheckboxItem key={especie.id}>
            <StyledCheckbox
              type="checkbox"
              name={especie.id}
              checked={especieChecked.includes(especie.id.toString())}
              onChange={handleValue}
            />
            <StyledLabel>{especie.nombre}</StyledLabel>
          </StyledCheckboxItem>
        ))}
      </StyledCheckboxList>
    </ModalForm>
  );
};

const StyledCheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 20px;
  width: 100%;
`;

const StyledCheckboxItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ff9100;
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px 20px;
`;

const StyledCheckbox = styled.input`
  margin-right: 10px;
`;

const StyledLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export default AddEspecieForm;
