import Navbar from "../../../components/Navbar";

const RepGraficos = () => {
    return (
        <div>
            <Navbar active link="https://rioblanco.inglobochile.cl/Reportes/Reporte_Graficos_Pallet/"/>
        </div>
    )
}

export default RepGraficos;