import axios from "../api/axios"; // Se carga axios como componente con la url predefinida}

const URL = "/filtros/"; // Se completa el path de la url base

// Export async function users
export async function getFiltrosService({ token, rowsPerPage, page, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: rowsPerPage,
      page: page,
      cliente: data.cliente,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getFiltrosNoPaginationService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      cliente: data.cliente,
      count: 1000,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URL, config);
  // Se retorna la respuesta
  return response;
}

export async function getFiltrosDetalleService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const URLDETALLE = "/filtro_detalles/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      inspeccion_tipo: data,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URLDETALLE, config);
  // Se retorna la respuesta
  return response;
}

export async function createFiltroEspecieDetallesService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const URLFILTROESPECIEDETALLE = "/filtro_especie_detalles/";
  let interno;
  let externo;
  // find inside data where key is like "interno"
  for (const [key, value] of Object.entries(data)) {
    if (key.includes("interno")) {
      interno = value;
    }
    if (key.includes("externo")) {
      externo = value;
    }
  }

  let newData = {
    especie_detalle: data.especie_detalle,
    filtro_detalle: data.filtro_detalle,
    externo: externo,
    interno: interno,
  };
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // Se realiza la petición
  const response = await axios.post(URLFILTROESPECIEDETALLE, newData, config);
  // Se retorna la respuesta
  return response;
}

export async function getFiltroEspecieDetalleService({ token, data }) {
  // Se crea el objeto de configuración para la petición
  const URLFILTROESPECIEDETALLE = "/filtro_especie_detalles/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      especie_detalle: data,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URLFILTROESPECIEDETALLE, config);
  // Se retorna la respuesta
  return response;
}

export async function getFiltroEspecieDetalleByEspecieExportadoraService({ token, especie, exportadora, inspeccionTipo }) {
  // Se crea el objeto de configuración para la petición
  const URLFILTROESPECIEDETALLE = "/filtro_especie_detalles/";
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      count: 1000,
      especie: especie.id,
      exportadora: exportadora.id,
      inspeccion_tipo: inspeccionTipo.id,
    },
  };
  // Se realiza la petición
  const response = await axios.get(URLFILTROESPECIEDETALLE, config);
  // Se retorna la respuesta
  return response;
}
