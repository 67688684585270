import React from "react";
import styled from "styled-components";

const InputDate = ({
    name,
    placeholder,
    defaultValue,
    onChange,
    disabled,
    required,
    hola,
    width,
    minWidth,
}) => {
    return (
        <StyledInputDate
            type="date"
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            required={required}
            ref={hola}
            width={width}
            minWidth={minWidth}
        />
    );
}

const StyledInputDate = styled.input`
    width: ${(props) => (props.width ? props.width : "300px")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "300px")};
    height: 40px;
    border-radius: 5px;
    border: 1.5px solid #ff9100;
    padding: 0 15px;
    margin: 5px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    // On click, the input border turns orange
    &:focus {
        outline: none;
        border: 1.5px solid #d88219;
    }

    @media (max-width: 768px) {
        width: 300px;
        min-width: 300px;
    }

    @media (max-width: 425px) {
        width: 250px;
        min-width: 250px;
    }
`;

export default InputDate;